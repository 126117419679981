import React, { Component, PropTypes } from 'react';

import { Col } from 'components/grid'
import { ContextMenu } from 'components/kesko'
import { RelativeLink } from 'components/Routing'
import { ExclamatedNote } from 'components/kesko'

import StatusAndDateHeader from '../StatusAndDateHeader'
import FeedbackComments from '../FeedbackComments'
import FeedbackCustomerInfo from '../FeedbackCustomerInfo'
import FeedbackMessages from '../FeedbackMessages'
import FacebookMessageHistory from '../FacebookMessageHistory'
import FeedbackEvents from './FeedbackEvents'
import FeedbackMeta from './FeedbackMeta'
import FeedbackStatusInfo from '../FeedbackStatusInfo'
import { Navigator } from 'components/Menu'

import { Text, TranslateChildrenProps } from 'containers/Text'
import { Confirmation } from 'containers/Dialog'
import { Feedback, Context, Status } from 'domain'
import Button from 'components/kesko/common/Button';
import KeskoIcons from 'components/Icons/KeskoIcons';

class SingleFeedbackView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  static defaultProps = {
    actions: {}
  };
  static propTypes = {
    feedback: PropTypes.instanceOf(Feedback),
    routeContext: PropTypes.instanceOf(Context).isRequired
  };

  bindMarkAsDoneConfirmation = (dialog) => this._markAsDoneConfirmation = dialog;

  bindReopenConfirmation = (dialog) => this._reopenConfirmation = dialog;

  openMarkAsDoneConfirmation = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this._markAsDoneConfirmation.open()
  };

  openReopenConfirmation = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this._reopenConfirmation.open()
  };

  openContextMenu = () => {
    this.setState({menuOpen: true});
  };

  closeContextMenu = () => {
    this.setState({menuOpen: false});
  };

  exportMessages = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.props.actions.exportMessages()
  };

  renderNavigator() {
    const {feedback, isReadOnly, routeContext} = this.props;
    const markAsDoneButton = feedback.status !== Status.DONE && !isReadOnly ? (
      <Button
        onClick={this.openMarkAsDoneConfirmation}
        translation="feedback.markAsDone.link"
        theme={Button.theme.navigation}
      />
    ) : null;

    const reopenConfirmationButton = feedback.status === Status.DONE && !isReadOnly ? (
      <Button
        onClick={this.openReopenConfirmation}
        translation="feedback.reopen.link"
        theme={Button.theme.navigation}
      />
    ) : null;

    return (
      <Navigator backUrl={{pathname: routeContext.url.toString(), query: {scrollTo: feedback.id}}}>
        <div className="center-button-section">
          {markAsDoneButton}
          {reopenConfirmationButton}
          <Button
            onClick={this.exportMessages}
            translation="feedback.exportMessages.link"
            theme={Button.theme.navigation}
            className="sm-hide"
          />
        </div>
        <div className="actions-menu">
          <Button
            onClick={!this.state.menuOpen ? this.openContextMenu : this.closeContextMenu}
            translation="actions.title"
            theme={Button.theme.navigation}
            iconRight={!this.state.menuOpen ? KeskoIcons.arrowSDown : KeskoIcons.crossSmall}
          />
          {this.renderContextMenu()}
        </div>
      </Navigator>
    );
  }

  renderContextMenu() {
    if (!this.state.menuOpen) {
      return null;
    }

    const {feedback, isReadOnly, isAdminUser} = this.props;
    return (
      <ContextMenu closeMenu={this.closeContextMenu}>
        <RelativeLink onClick={this.exportMessages} to={''} className="export-messages-link">
          <Text k="feedback.exportMessages.link" />
        </RelativeLink>
        {feedback.status !== Status.DONE && !isReadOnly && (
          <RelativeLink to="changeHandler">
            <Text k="feedback.changeHandler.link" />
          </RelativeLink>
        )}
        {feedback.status !== Status.DONE && !isReadOnly && (
          <RelativeLink to="requestComment">
            <Text k="feedback.requestComment.link" />
          </RelativeLink>
        )}
        {!isReadOnly && (
          <RelativeLink to="transfer">
            <Text k="feedback.transfer.link" />
          </RelativeLink>
        )}
        {!feedback.usageObjected && !isReadOnly && (
          <RelativeLink to="forward">
            <Text k="feedback.forward.link" />
          </RelativeLink>
        )}
        {(feedback.status !== Status.DONE || isReadOnly) && (
          <RelativeLink to="addComment">
            <Text k="feedback.comments.goToCommenting" />
          </RelativeLink>
        )}
        {!isReadOnly && !(feedback.customer && feedback.customer.removed) && (
          <RelativeLink to="changeContact">
            <Text k="feedback.changeContact.link" />
          </RelativeLink>
        )}
        {isAdminUser && (
          <RelativeLink to="adminTools">
            <Text k="feedback.adminTools.link" />
          </RelativeLink>
        )}
      </ContextMenu>
    );
  }

  renderMarkAsDoneConfirmation() {
    const {actions: {markAsDone}} = this.props;

    return (
      <TranslateChildrenProps>
        <Confirmation
          translate={{
            title: 'feedback.markAsDone.dialog.title',
            body: 'feedback.markAsDone.dialog.body',
            yesText: 'yes',
            noText: 'no'
          }}
          ref={this.bindMarkAsDoneConfirmation}
          onConfirm={markAsDone}
        />
      </TranslateChildrenProps>
    );
  }

  renderReopenConfirmation() {
    const {actions: {reopen}} = this.props;

    return (
      <TranslateChildrenProps>
        <Confirmation
          translate={{
            title: 'feedback.reopen.dialog.title',
            body: 'feedback.reopen.dialog.body',
            yesText: 'yes',
            noText: 'no'
          }}
          ref={this.bindReopenConfirmation}
          onConfirm={reopen}
        />
      </TranslateChildrenProps>
    );
  }

  render() {
    const {feedback, actions, actions: {removeComment}, isStoreUser, isReadOnly} = this.props;

    return (
      <section className="single-feedback-view">

        {this.renderNavigator()}

        <StatusAndDateHeader status={feedback.status} date={feedback.created} fullDate={true} />
        <FeedbackStatusInfo feedback={feedback} />

        <FeedbackCustomerInfo customer={feedback.customer} />

        {feedback.usageObjected && (
          <Col className="usage-objected-info">
            <ExclamatedNote translate={{note: 'feedback.usageObjected.note'}} />
          </Col>
        )}

        <Col className="mb2">
          <hr />
        </Col>

        <FacebookMessageHistory
          collapseId={`SingleFeedbackView-${feedback.id}-facebookHistoryMessages`}
          facebookHistoryMessages={feedback.facebookHistoryMessages}
          initiallyVisible={false}
        />
        {feedback.facebookHistoryMessages && feedback.facebookHistoryMessages.size > 0 && (<Col className="mt2 mb2">
          <hr />
        </Col>)}

        <FeedbackMessages
          feedback={feedback}
          messages={feedback.messages}
          showEditLinks={feedback.canReply && !isReadOnly}
          collapseId={`SingleFeedbackView-${feedback.id}-messages`}
        />
        <Col className="mt2 mb2">
          <hr />
        </Col>

        <FeedbackComments
          comments={feedback.comments}
          collapseId={`SingleFeedbackView-${feedback.id}-comments`}
          removeComment={removeComment}
        />
        <Col className="mt2 mb2">
          <hr />
        </Col>

        <FeedbackMeta feedback={feedback} actions={actions} isStoreUser={isStoreUser} isReadOnly={isReadOnly} />

        <Col className="mb2">
          <hr />
        </Col>

        <FeedbackEvents
          events={feedback.events}
          collapseId={`SingleFeedbackView-${feedback.id}-events`}
        />

        {this.renderMarkAsDoneConfirmation()}
        {this.renderReopenConfirmation()}

      </section>
    );
  }
}

export default SingleFeedbackView
