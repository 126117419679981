export const FeedbackReplyActions = {
  SET_REPLY_FORM_VALUES: 'ReplyForm/SetValues',
  RESET_FORM: 'ReplyForm/Reset'
}

export const setReplyFormValues = (message, templateIds) => dispatch => dispatch({
  type: FeedbackReplyActions.SET_REPLY_FORM_VALUES,
  message,
  templateIds
})

export const resetForm = () => dispatch => dispatch({
  type: FeedbackReplyActions.RESET_FORM
})
