import Serializable from './Serializable'
import ContextType from './ContextType'
import { defined, oneOf } from 'utils'

class Context extends Serializable {

  constructor(data) {
    super(data, 'Context')
    oneOf(Object.values(ContextType), defined(this.type, 'Context#type'))
    defined(this.url)
  }

  get type() {
    return this._data.get('type')
  }

  get url() {
    return this._data.get('url')
  }

}

export default Context
