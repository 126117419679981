import Serializable from './Serializable'

const NBSP = '\u00a0';
class Message extends Serializable {
  constructor(data) {
    super(data, 'Message')
    if (!!this._data.get('created')) {
      const date = new Date(Date.parse(this._data.get('created')));
      this._data = this._data.set('created', date)
    }
  }

  get id() {
    return this._data.get('id')
  }

  get messageType() {
    return this._data.get('messageType')
  }

  get message() {
    return this._data.get('message')
  }

  get fullMessage() {
    return this._data.get('fullMessage')
  }

  get created() {
    return this._data.get('created')
  }

  get author() {
    // TODO: Temporary fix until the author information is always present
    return this._data.get('authorName') || NBSP
  }

  get receiverEmail() {
    return this._data.get('receiverEmail')
  }

  get attachmentFiles() {
    return this._data.get('attachmentFiles')
  }

  get attachmentImages() {
    return this._data.get('attachmentImages')
  }

  get attachmentAudios() {
    return this._data.get('attachmentAudios')
  }

  get isRemovable() {
    return this._data.get('isRemovable')
  }

}

export default Message
