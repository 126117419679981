import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form'

import { Col } from 'components/grid'
import { Text } from 'containers/Text'
import { Navigator } from 'components/Menu'
import { Feedback, Handler } from 'domain'
import { ErrorBlock, TokenInput, TextArea } from 'components/Forms'
import { createValidator, required } from 'utils/Validation'
import { find } from 'utils'
import SaveToLocalStorage from 'components/Forms/SaveToLocalStorage'

import FeedbackCollapsingSections from '../FeedbackCollapsingSections';
import Button from 'components/kesko/common/Button';

const SavingTextArea = SaveToLocalStorage(TextArea)

const handlerName = handler => handler.userName + ' (' + handler.userId + (!!handler.email ? ', ' + handler.email : '') + ')'

const handlerToOption = handler => ({
  id: handler.userId,
  name: handlerName(handler),
  value: handler.userId
})
const handlerShape = PropTypes.arrayOf(PropTypes.shape({
  userId: PropTypes.any.isRequired,
  userName: PropTypes.any.isRequired
}))

class BaseChangeFeedbackHandlerForm extends Component {

  static propTypes = {
    possibleHandlers: handlerShape.isRequired,
    initialValues: PropTypes.shape({
      handler: handlerShape
    })
  };

  static defaultProps = {
    saveHandler: () => {
    }
  }

  bindTextArea = (area) => {
    this._savingTextArea = area
  };

  forgetStoredCommentIfSuccessful = (cb) => {
    return function (event) {
      const promise = cb(event)
      promise.then(this._savingTextArea.clear)
      return promise
    }.bind(this)
  }

  render() {
    const {fields, fields: {handlerId, comment}, handleSubmit, onCancel, possibleHandlers, error, submitting, localStorageKey} = this.props

    return (
      <form className="basic-form">

        <ErrorBlock fields={fields} submitError={error} className="mb2" translatePrefix="feedback.changeHandler" />

        <Col className="mb2">
          <TokenInput
            disabled={submitting}
            options={possibleHandlers}
            multiple={false}
            optionMapper={handlerToOption}
            translate={{
              placeholder: 'feedback.changeHandler.none',
              notSelectedLabel: 'feedback.changeHandler.none'
            }}
            {...handlerId}
          />
        </Col>

        <Col className="mb2">
          <SavingTextArea
            ref={this.bindTextArea}
            rows="5"
            disabled={submitting}
            translate={{placeholder: 'feedback.changeHandler.comment.placeholder'}}
            localStorageKey={localStorageKey}
            {...comment}
          />
        </Col>

        <div className="form-buttons-group">
          <Button
            onClick={onCancel}
            translation="actions.cancel"
            disabled={submitting}
            theme={Button.theme.cancel}
          />
          <Button
            onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(this.props.saveHandler))}
            translation="actions.save"
            type="submit"
            disabled={submitting}
            theme={Button.theme.primary}
          />
        </div>

      </form>
    )
  }
}

export const ChangeFeedbackHandlerForm = reduxForm({
  form: 'changeFeedbackHandler',
  fields: ['handlerId', 'comment'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    handlerId: required()
  })
})(BaseChangeFeedbackHandlerForm)

class ChangeFeedbackHandlerView extends Component {
  render() {
    const {feedback, possibleHandlers, actions: {saveHandler, cancel}} = this.props
    /*
    We need to use .toJSON() since redux-form's initialValues messes up by
    calling immutable's deprecated .length getter when comparing the values for deep equality.
    This causes unwrapping and wrapping the Handler domain object again
     */
    const allHandlers = this.addCurrentlySelectedHandlerIfNotPresent(feedback.handler, possibleHandlers)
      .map(h => h.toJSON())
    const triggerSaveHandler = ({handlerId, comment}) => saveHandler(handlerId, comment)
    const handlerId = feedback.handler && feedback.handler.userId

    return (
      <section className="change-handler-view">
        <Navigator backUrl=".." />
        <Col>
          <hr />
        </Col>
        <FeedbackCollapsingSections
          feedback={feedback}
        />
        <Col>
          <hr />
        </Col>

        <Col><h2 className="blue mt3 mb3"><Text k="feedback.changeHandler.title" /></h2></Col>

        <ChangeFeedbackHandlerForm
          initialValues={{handlerId}}
          possibleHandlers={allHandlers}
          saveHandler={triggerSaveHandler}
          onCancel={cancel}
          localStorageKey={`${feedback.id}-changeHandler`}
        />

      </section>
    )
  }

  addCurrentlySelectedHandlerIfNotPresent(handler, possibleHandlers) {
    if (handler && !find(possibleHandlers, h => h.userId === handler.userId)) {
      return [
        handler,
        ...possibleHandlers
      ]
    }
    return possibleHandlers;
  }
}

ChangeFeedbackHandlerView.propTypes = {
  feedback: PropTypes.instanceOf(Feedback).isRequired,
  possibleHandlers: PropTypes.arrayOf(PropTypes.instanceOf(Handler)),
  actions: PropTypes.shape({
    saveHandler: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired
  }).isRequired
}

export default ChangeFeedbackHandlerView
