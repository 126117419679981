import React, {Component, PropTypes} from 'react'
import {Map} from 'immutable'

import AddErrorDisplay from 'components/Forms/common/AddErrorDisplay'
import TokenInput from 'components/Forms/TokenInput'
import { getText } from 'containers/Text/utils'
import { Col } from 'components/grid'

/**
 * The values in templates and value props should be
 * mappable by optionMapper to a shape like:
 *
 <pre>{
  id: string, // The key for the templates, should be unique and defined
  name: string, // the displayed label for the option
  value: any // The value for redux-form, e.g., the original object
}</pre>
 *
 * If a translated option is wished, instead of returning the label
 * attribute, return a 'k' attribute like this:
 <pre>{
  id: string, // The key for the templates, should be unique and defined
  k: string/array, // the displayed label for the option
  value: any // The value for redux-form, e.g., the original object
}</pre>
 *
 * The k attribute will be translated to label
 */

class ResponseTemplateSelection extends Component {
  static propTypes = {
    templateIds: PropTypes.object,
    possibleTemplates: PropTypes.array
  };
  static contextTypes = {
    translations: PropTypes.instanceOf(Map)
  };

  templateNameSuffix = template => template.isProposal ? getText(this.context.translations, 'proposal.nameSuffix') : '';

  optionMapper = template => ({
    id: template.id,
    name: template.name + this.templateNameSuffix(template),
    value: template.id
  });

  render() {
    const {
      disabled, possibleTemplates, templateIds, onTemplateChange
    } = this.props;
    return (
      <Col className="mb2">
        <TokenInput
          disabled={disabled}
          clearable={false}
          options={possibleTemplates}
          optionMapper={this.optionMapper}
          translate={{
            notSelectedLabel: 'feedback.reply.fields.template.placeholder'
          }}
          {...templateIds}
          onChange={onTemplateChange}
        />
      </Col>
    );
  }
}

export default AddErrorDisplay(ResponseTemplateSelection);
