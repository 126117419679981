import React, { Component, PropTypes, isValidElement } from 'react';
import { connect } from 'react-redux'

import Collapse from './Collapse'

/**
 * Collapsing container that toggles its state when the title is clicked.
 * Stores its state in Redux store with the collapseId key.
 * <p>
 * The title attribute can be either a component or a function to component. The function
 * will receive { visible, handleClick } as parameters. If a function is supplied, the
 * user is responsible for calling handleClick to toggle the container. Otherwise, the
 * container is toggled by clicking the title.
 *
 * @example
 * <CollapsingContainer collapseId="myToggle" initiallyVisible={true} title={<MyTitle />}}>
 *   <h1>This will collapse away</h1>
 * </CollapsingContainer>
 *
 * @return CollapsingContainer
 */
class CollapsingContainer extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.registerCollapse = this.registerCollapse.bind(this)
  }

  handleClick() {
    this._collapse.toggle()
  }

  registerCollapse(collapse) {
    this._collapse = collapse
  }

  renderTitle(title, visible) {
    if (isValidElement(title)) {
      return (<div onClick={this.handleClick}>{title}</div>)
    } else {
      return title({visible, handleClick: this.handleClick})
    }
  }

  render() {
    const {initiallyVisible, collapseId, children, title, visible, animated, ...props} = this.props;
    return (
      <section {...props}>
        <div className="clickable no-user-select clearfix">
          {this.renderTitle(title, visible)}
        </div>
        <Collapse
          initiallyVisible={initiallyVisible}
          collapseId={collapseId}
          ref={this.registerCollapse}
          animated={animated}
        >
          {children}
        </Collapse>
      </section>
    )
  }
}

CollapsingContainer.defaultProps = {
  initiallyVisible: false,
  animated: true
};

CollapsingContainer.propTypes = {
  initiallyVisible: PropTypes.bool.isRequired,
  collapseId: PropTypes.any.isRequired,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element
  ]).isRequired,
  animated: PropTypes.bool
};

const mapStateToProps = (state, props) => ({
  visible: state.getIn(['collapses', props.collapseId], props.initiallyVisible)
})

export default connect(mapStateToProps)(CollapsingContainer)
