import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import flatten from 'lodash/flatten'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import merge from 'lodash/merge'

export { debounce, find, flatten, mapValues, merge, throttle }

export const noop = () => {
}

export const defined = (val, field = 'field') => {
  if (typeof val === 'undefined') {
    throw field + ' is required'
  }
  return val
}

export const oneOf = (arr = [], val) => {
  if (arr.indexOf(val) === -1) {
    throw `Value '${val}' is not in the supported values: ${arr}`
  }
  return val
}

/**
 * Null-safely converts immutablejs objects to javascript objects/arrays.
 * @param what
 */
export const toJS = what => what ? what.toJS() : what

export const hashCode = (str = '') => {
  if (typeof str !== 'string') {
    throw 'Non-strings not supported'
  }
  let hash = 0;
  if (str.length == 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export const trimStringsInMap = obj => {
  forEach(obj, (val, key) => {
    obj[key] = typeof obj[key] === 'string' ? obj[key].trim() : obj[key]
  })
  return obj
}

export const trimWhitespaces = str => str ? str.replace(/\ /g, '').trim() : str

export const reformatPhoneNumber = str => str ? trimWhitespaces(str).replace(/-/g, '') : str;

export const hasItems = (array) => array && array.length > 0;
export const hasItemsInList = (list) => list && list.size > 0;
