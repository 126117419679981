import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'

import { GiveFeedbackView } from 'components/GiveFeedback'
import { relativizeTarget } from 'components/Routing/RoutingUtils'
import { sendFeedback } from 'actions/FeedbackActions'

class GiveFeedbackRoute extends Component {
  static contextTypes = {
    currentPath: PropTypes.string,
    router: PropTypes.any
  };

  navigateToFeedbackView = () => {
    this.context.router.push(relativizeTarget(this.context.currentPath, '..'))
  };

  giveFeedback = (fields) => {
    const {feedback, responseRequested} = fields
    return this.props.actions.giveFeedback(feedback, responseRequested).then(this.navigateToFeedbackView)
  };

  render() {
    return (
      <GiveFeedbackView
        {...this.props}
        giveFeedback={this.giveFeedback}
        cancel={this.navigateToFeedbackView}
      />
    )
  }
}

export default connect(() => ({}), (dispatch) => ({
  actions: {
    giveFeedback: (feedback, responseRequested) => dispatch(sendFeedback(feedback, responseRequested))
  }
}))(GiveFeedbackRoute)
