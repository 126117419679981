import React, { Component, PropTypes } from 'react';
import classNames from 'classnames'

class Col extends Component {
  render() {
    const classes = classNames(
      'clearfix',
      this.props.className,
      {
        ['col']: !this.props.centered,
        ['mx-auto']: this.props.centered,
        [`col-${this.props.xs}`]: true,
        [`sm-col-${this.props.sm}`]: !!this.props.sm,
        [`md-col-${this.props.md}`]: !!this.props.md,
        [`lg-col-${this.props.lg}`]: !!this.props.lg
      }
    );
    return (
      <div {...this.props} className={classes}>
        {this.props.children}
      </div>
    );
  }
}
Col.defaultProps = {
  xs: 12,
  centered: false
};

const strOrNumber = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number
])

Col.propTypes = {
  xs: strOrNumber.isRequired,
  sm: strOrNumber,
  md: strOrNumber,
  lg: strOrNumber,
  centered: PropTypes.bool.isRequired
}

export default Col;
