import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { hashHistory as history } from 'react-router'

import { FeedbackReplyByPhoneView } from 'components/Feedback'
import { relativizeTarget } from 'components/Routing/RoutingUtils'
import { replyByPhone as addResponseComment } from 'actions/FeedbackActions'
import { startResponding, stopResponding } from 'actions/ClientActions'
import { WaitFor } from 'components/Utils'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch, { feedbackId }) => ({
  actions: bindActionCreators({
    replyByPhone: (comment, markAsDone) => dispatch(() => addResponseComment(feedbackId, comment, markAsDone)),
    startResponding: () => dispatch(() => startResponding(feedbackId)),
    stopResponding: () => dispatch(() => stopResponding(feedbackId))
  }, dispatch)
})

class ReplyToFeedbackRoute extends Component {

  static contextTypes = {
    currentPath: PropTypes.string
  };

  static propTypes = {
    actions: PropTypes.shape({
      replyByPhone: PropTypes.func.isRequired,
      startResponding: PropTypes.func.isRequired,
      stopResponding: PropTypes.func.isRequired
    }).isRequired
  };

  init = () => this.props.actions.startResponding();
  cleanup = () => this.props.actions.stopResponding();

  render() {
    const { feedback, actions: { replyByPhone }, ...props } = this.props
    const back = () => setTimeout(() => history.push(relativizeTarget(this.context.currentPath, '..')), 1)
    const actions = {
      sendReplyAndMarkDone: ({ comment }) => replyByPhone(comment, true).then(back),
      sendReplyAndMarkWaiting: ({ comment }) => replyByPhone(comment, false).then(back),
      cancel: back,
      init: this.init,
      cleanup: this.cleanup
    }
    return (
      <WaitFor condition={feedback}>
        {feedback && (<FeedbackReplyByPhoneView {...props} feedback={feedback} actions={actions}/>)}
      </WaitFor>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplyToFeedbackRoute);
