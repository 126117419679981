import React, { Component, PropTypes } from 'react'
import { TextWithHeading } from 'components/kesko'
import { CommaSeparated } from 'components/Formatting'

import { Feedback } from 'domain'
import { Text, TranslateChildrenProps } from 'containers/Text'
import { Confirmation } from 'containers/Dialog'
import KeskoIcons from 'components/Icons/KeskoIcons';
import Button from 'components/kesko/common/Button';

function formatDate(date) {
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();
  return `${d}.${m}.${y}`
}

class FeedbackMeta extends Component {

  static propTypes = {
    feedback: PropTypes.instanceOf(Feedback).isRequired
  };

  static contextTypes = {
    locale: PropTypes.string.isRequired
  };

  reportAmbianceError = (event) => {
    event.preventDefault()
    event.stopPropagation()
  };

  reportTopicError = (event) => {
    event.preventDefault()
    event.stopPropagation()
  };

  reportThreat = (event) => {
    event.preventDefault()
    event.stopPropagation()
  };

  bindReportAmbianceErrorConfirmation = (dialog) => this._reportAmbianceErrorConfirmation = dialog;

  bindReportTopicErrorConfirmation = (dialog) => this._reportTopicErrorConfirmation = dialog;

  bindReportThreatConfirmation = (dialog) => this._reportThreatConfirmation = dialog;

  openReportAmbianceErrorConfirmation = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this._reportAmbianceErrorConfirmation.open()
  };

  openReportTopicErrorConfirmation = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this._reportTopicErrorConfirmation.open()
  };

  openReportThreatConfirmation = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this._reportThreatConfirmation.open()
  };

  render() {
    const {
      isStoreUser,
      isReadOnly,
      feedback,
      feedback: {
        id,
        receiverName,
        facebookReceiverName,
        businessUnitIds,
        receiverArea,
        nps,
        feedbackChannel,
        feedbackSubChannel,
        category,
        purchaseDate,
        customer,
        deviceModel,
        applicationVersion,
        orderExperienceSentiment,
        clientBrowser,
        clientLocation,
        clientReferrer,
        clientScreenSize,
        clientWindowSize,
        orderNumber,
        tempPlussaCardNumber,
        carRegistrationNumber,
        carBrand,
        originalBusinessUnitId,
        originalBusinessUnitDisplayName,
        rating,
        handlingTime
      },
      actions: {
        reportAmbianceError,
        reportTopicError,
        reportThreat
      }
    } = this.props
    const topicsPos = feedback.analysisTopicsPosInLocale(this.context.locale)
    const topicsNeu = feedback.analysisTopicsNeuInLocale(this.context.locale)
    const topicsNeg = feedback.analysisTopicsNegInLocale(this.context.locale)
    const receiver = businessUnitIds
      ? receiverName + ' (' + businessUnitIds.join(', ') + ')'
      : receiverName;
    const channel = !!feedbackSubChannel
      ? feedbackChannel + ' (' + feedbackSubChannel + ')'
      : feedbackChannel;
    return (
      <div className="feedback-meta">
        <TextWithHeading
          icon={KeskoIcons.tag}
          heading="feedback.meta.id"
          body={id.toString()}
        />
        <TextWithHeading
          heading="feedback.meta.category.title"
          body={`feedback.category.${category}`}
        />
        <TextWithHeading
          heading="feedback.meta.channel.title"
          body={channel}
        />
        <TextWithHeading
          heading="feedback.meta.receiverName"
          body={receiver}
        />
        {receiverArea && (
          <TextWithHeading
            heading="feedback.meta.area.title"
            body={receiverArea}
          />
        )}
        {facebookReceiverName && (
          <TextWithHeading
            heading="feedback.meta.facebookReceiverName.title"
            body={facebookReceiverName}
          />
        )}
        {originalBusinessUnitId && (
          <TextWithHeading
            heading="feedback.meta.originalBusinessUnit.title"
            body={originalBusinessUnitDisplayName + ' (' + originalBusinessUnitId + ')'}
          />
        )}
        {nps !== undefined && (
          <TextWithHeading
            heading="feedback.meta.nps.title"
            body={nps + ''}
          />
        )}
        {rating !== undefined && (
          <TextWithHeading
            heading="feedback.meta.rating.title"
            body={rating}
          />
        )}
        {purchaseDate && (
          <TextWithHeading
            heading="feedback.meta.purchaseDate.title"
            body={formatDate(purchaseDate)}
          />
        )}
        {topicsPos.length > 0 && (
          <TextWithHeading
            heading="feedback.meta.topicsPos"
            bodyElement={<CommaSeparated values={topicsPos} />}
          />
        )}
        {topicsNeu.length > 0 && (
          <TextWithHeading
            heading="feedback.meta.topicsNeu"
            bodyElement={<CommaSeparated values={topicsNeu} />}
          />
        )}
        {topicsNeg.length > 0 && (
          <TextWithHeading
            heading="feedback.meta.topicsNeg"
            bodyElement={<CommaSeparated values={topicsNeg} />}
          />
        )}
        {deviceModel && (
          <TextWithHeading
            heading="feedback.meta.deviceModel.title"
            body={deviceModel}
          />
        )}
        {applicationVersion && (
          <TextWithHeading
            heading="feedback.meta.applicationVersion.title"
            body={applicationVersion}
          />
        )}
        {customer && customer.plussaCardNumber && (
          <TextWithHeading
            heading="feedback.meta.plussaCardNumber.title"
            body={customer.plussaCardNumber}
          />
        )}
        {tempPlussaCardNumber && (
          <TextWithHeading
            heading="feedback.meta.tempPlussaCardNumber.title"
            body={tempPlussaCardNumber}
          />
        )}
        {customer && customer.plussaPersonId && (
          <TextWithHeading
            heading="feedback.meta.plussaPersonId.title"
            body={customer.plussaPersonId}
          />
        )}
        {!isStoreUser && customer && customer.b2cAccountId && (
          <TextWithHeading
            heading="feedback.meta.b2cAccountId.title"
            body={customer.b2cAccountId}
          />
        )}
        {orderNumber && (
          <TextWithHeading
            heading="feedback.meta.orderNumber.title"
            body={orderNumber}
          />
        )}
        {!isStoreUser && orderExperienceSentiment && (
          <TextWithHeading
            heading="feedback.meta.orderExperienceSentiment.title"
            body={`feedback.meta.ambiance.${orderExperienceSentiment}`}
          />
        )}
        {carRegistrationNumber && (
          <TextWithHeading
            heading="feedback.meta.carRegistrationNumber.title"
            body={carRegistrationNumber}
          />
        )}
        {carBrand && (
          <TextWithHeading
            heading="feedback.meta.carBrand.title"
            body={carBrand}
          />
        )}
        {!isStoreUser && clientBrowser && (
          <TextWithHeading
            heading="feedback.meta.clientBrowser.title"
            body={clientBrowser}
            showTooltip
          />
        )}
        {!isStoreUser && clientLocation && (
          <TextWithHeading
            heading="feedback.meta.clientLocation.title"
            body={clientLocation}
            showTooltip
          />
        )}
        {!isStoreUser && clientReferrer && (
          <TextWithHeading
            heading="feedback.meta.clientReferrer.title"
            body={clientReferrer}
            showTooltip
          />
        )}
        {!isStoreUser && clientScreenSize && (
          <TextWithHeading
            heading="feedback.meta.clientScreenSize.title"
            body={clientScreenSize}
          />
        )}
        {!isStoreUser && clientWindowSize && (
          <TextWithHeading
            heading="feedback.meta.clientWindowSize.title"
            body={clientWindowSize}
          />
        )}
        {!isStoreUser && handlingTime && (
          <TextWithHeading
            heading="feedback.meta.handlingTime.title"
            body={handlingTime}
          />
        )}
        <div className="buttons-section">
          
          {!feedback.isThreatReported && !isReadOnly && (
            <Button
              onClick={this.openReportThreatConfirmation}
              translation="feedback.meta.reportThreat.link"
              theme={Button.theme.navigation}
            />
          )}
          {feedback.isThreatReported && (
            <span className="already-reported">
              <Text k="feedback.meta.reportThreat.reported" className="italic" />
            </span>
          )}
        </div>
        <TranslateChildrenProps>
          <Confirmation
            translate={{
              title: 'feedback.meta.reportAmbianceError.confirmTitle',
              body: 'feedback.meta.reportAmbianceError.confirmBody',
              yesText: 'yes',
              noText: 'no'
            }}
            ref={this.bindReportAmbianceErrorConfirmation}
            onConfirm={reportAmbianceError}
          />
        </TranslateChildrenProps>
        <TranslateChildrenProps>
          <Confirmation
            translate={{
              title: 'feedback.meta.reportTopicError.confirmTitle',
              body: 'feedback.meta.reportTopicError.confirmBody',
              yesText: 'yes',
              noText: 'no'
            }}
            ref={this.bindReportTopicErrorConfirmation}
            onConfirm={reportTopicError}
          />
        </TranslateChildrenProps>
        <TranslateChildrenProps>
          <Confirmation
            translate={{
              title: 'feedback.meta.reportThreat.confirmTitle',
              body: 'feedback.meta.reportThreat.confirmBody',
              yesText: 'yes',
              noText: 'no'
            }}
            ref={this.bindReportThreatConfirmation}
            onConfirm={reportThreat}
          />
        </TranslateChildrenProps>
      </div>
    )
  }
}

export default FeedbackMeta
