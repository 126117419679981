import React, { PropTypes } from 'react';
import Text from '../../../containers/Text/Text';
import Icon from '../../Icons/Icon';
import Spinner from '../../Icons/Spinner';

const ButtonTheme = {
  primary: 'primary',
  secondary: 'secondary',
  cancel: 'cancel',
  navigation: 'navigation',
  state_pending: 'state-pending',
  state_complete: 'state-complete'
};

export default class Button extends React.Component {
  leftIcon = () => this.props.iconLeft ? (
    <Icon
      icon={this.props.iconLeft}
      size={Icon.sizes.unset}
      className="button-icon-left"
    />
  ) : null;

  rightIcon = () => this.props.iconRight ? (
    <Icon
      icon={this.props.iconRight}
      size={Icon.sizes.unset}
      className="button-icon-right"
    />
  ) : null;

  loadingSpinner = () => this.props.loading ? (
    <Spinner className="button-spinner" />
  ) : null;

  render() {
    const {translation, theme, onClick, disabled, type, className, uppercase, loading} = this.props;

    const classNames = `hymy-button ${theme} ${className ? className : ''} ${uppercase ? 'uppercase' : ''}`;

    const buttonText = translation ? (
      <Text k={translation} />
    ) : null;

    return (
      <button className={classNames} onClick={onClick} disabled={disabled} type={type}>
        {this.leftIcon()}
        {buttonText}
        {!loading ? this.rightIcon() : this.loadingSpinner()}
        {this.props.children}
      </button>
    )
  }
}

Button.defaultProps = {
  theme: 'primary',
  type: 'button',
  uppercase: false,
  loading: false,
  disabled: false
};

Button.propTypes = {
  translation: PropTypes.string,
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(Object.values(ButtonTheme)),
  disabled: PropTypes.bool,
  type: PropTypes.string,
  uppercase: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string
};

Button.theme = ButtonTheme;
