import React, { Component, PropTypes } from 'react'

class PillSelectionOptions extends Component {
  static propTypes = {
    className: PropTypes.string,
    options: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.any,
    clearValueText: PropTypes.any,
    noResultsText: PropTypes.any,
    placeholder: PropTypes.any,
  };

  render() {
    const { options, value, placeholder, onChange} = this.props;

    const handlePillClick = (pillOption) => {
      if (pillOption) {
        if (isSelected(pillOption, value)) {
          onChange('');
        } else {
            onChange(pillOption.value)
        }
      } else {
        onChange('');
      }
    }

    const isSelected = (option, value) => {
      if (value === '') {
        return false
      }
      return value === option.value;
    }

    return (
      <div className='pill-layout'>
        <button
          className={value.length == 0 || value.length === options.length ? 'pill-item selected' : 'pill-item'}
          onClick={() => handlePillClick()}
          type='button'
        >
          {placeholder}
        </button>
        {options.map(option =>
          <button
            className={isSelected(option, value) ? 'pill-item selected' : 'pill-item'}
            key={option.label}
            onClick={() => handlePillClick(option)}
            type='button'
          >
            {option.label}
          </button>
        )}
      </div>
    )
  }
}

export default PillSelectionOptions;
