import React, { PropTypes } from 'react';
import CollapsingCustomerInfo from './CollapsingCustomerInfo';
import FacebookMessageHistory from './FacebookMessageHistory';
import FeedbackMessages from './FeedbackMessages';
import FeedbackComments from './FeedbackComments';

import { Feedback } from 'domain'

const FeedbackCollapsingSections = ({feedback, messagesVisible}) => (
  <div className="collapsing-sections">
    <CollapsingCustomerInfo
      collapseId={`FeedbackCommentView-${feedback.id}-customerinfo`}
      customer={feedback.customer}
      initiallyVisible={false}
    />
    <FacebookMessageHistory
      collapseId={`FeedbackCommentView-${feedback.id}-facebookHistoryMessages`}
      facebookHistoryMessages={feedback.facebookHistoryMessages}
      initiallyVisible={false}
    />
    <FeedbackMessages
      collapseId={`FeedbackCommentView-${feedback.id}-messages`}
      messages={feedback.messages}
      showEditLinks={false}
      initiallyVisible={messagesVisible}
    />
    <FeedbackComments
      collapseId={`FeedbackCommentView-${feedback.id}-comments`}
      comments={feedback.comments}
      showEditLinks={false}
      showNote={false}
      initiallyVisible={false}
    />
  </div>
);

FeedbackCollapsingSections.defaultProps = {
  messagesVisible: false
};

FeedbackCollapsingSections.propTypes = {
  feedback: PropTypes.instanceOf(Feedback).isRequired,
  messagesVisible: PropTypes.bool
};

export default FeedbackCollapsingSections;
