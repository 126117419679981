import React, { Component, PropTypes } from 'react';
import { RelativeLink } from 'components/Routing'
import TextWithIcon from '../kesko/TextWithIcon';
import KeskoIcons from '../Icons/KeskoIcons';

class Navigator extends Component {

  render() {
    const {backUrl, ...rest} = this.props;
    const hasLinks = this.props.children && this.props.children.length !== 0;

    return (
      <div {...rest} className={`url-navigator ${hasLinks ? 'has-links' : ''}`}>
        <RelativeLink to={backUrl}>
          <TextWithIcon
            translation="back"
            icon={KeskoIcons.arrowSLeft}
          />
        </RelativeLink>
        <div className="navigator-content">
          {this.props.children}
        </div>
      </div>
    )
  }
}

Navigator.defaultProps = {
  backUrl: '..'
};

Navigator.propTypes = {
  backUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.object
    })
  ]).isRequired
};

export default Navigator
