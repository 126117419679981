import React, {Component, PropTypes} from 'react'
import {connect} from 'react-redux'

import TextWithHeading from 'components/kesko/TextWithHeading'
import {TextFormatter} from 'components/Formatting'
import {Text} from 'containers/Text'
import {AudioTranscriptStatus as Status} from 'domain'
import KeskoIcons from '../Icons/KeskoIcons';
import TextWithIcon from '../kesko/TextWithIcon';
import {find} from 'utils/Helpers'

class AttachmentAudioGallery extends Component {
  render() {
    const {audios, isAdminUser} = this.props
    if (!audios) {
      return
    }
    const renderTranscripts = (
      <div className="section">
        {audios.map((audio, i) => {
          if (audio.audioTranscriptStatus !== Status.notNeeded) {
            return <AudioAttachment audio={audio} key={i} isAdminUser={isAdminUser}/>
          }
        })}
      </div>
    )

    const renderUntranscribableAudio = !!find(audios, ['audioTranscriptStatus', Status.notNeeded]) ? (
        <div className="section">
          <h4><Text k="feedback.audios"/></h4>
          {audios.map((audio, k) => {
            if (audio.audioTranscriptStatus === Status.notNeeded) {
              return <AudioAttachment audio={audio} key={k}/>
            }
          })}
        </div>
      ) : null

    return (
      <div>
        {renderTranscripts}
        {renderUntranscribableAudio}
      </div>
    )
  }
  static propTypes = {
    audios: PropTypes.array,
    isAdminUser: PropTypes.bool
  }
}

export default connect(state => ({
  isAdminUser: state.getIn(['client','admin'], false)
}))(AttachmentAudioGallery)

class AudioAttachment extends Component {
  renderTitle(audioTranscriptStatus) {
    return audioTranscriptStatus !== Status.notNeeded ? (
      <h3>
        <TextWithHeading
          className={'message-title'}
          heading={`feedback.transcript.${audioTranscriptStatus}`}
          icon={KeskoIcons.robot}
        />
      </h3>
    ) : null
  }

  renderTranscript(audioTranscript, audioTranscriptStatus, isAdminUser) {
    return audioTranscript && (audioTranscriptStatus !== Status.failedQuality || isAdminUser) ? (
      <TextFormatter className="message-text" text={audioTranscript} />
    ) : null
  }

  renderAttachment() {
    const {audio} = this.props
    const audioClassName = audio.audioTranscriptStatus !== Status.notNeeded ? 'audio-with-transcript' : ''
    return (
      <div className={`audio-attachment ${audioClassName}`}>
        <audio className="audio-player" controls src={audio.src} preload="none"/>
        <div className="attachment-text">
          <a className="truncate" href={audio.src}>
            <TextWithIcon
              translation={audio.fileName}
              icon={KeskoIcons.paperclip}
            />
          </a>
          <span className="truncate filesize">{audio.fileSize}</span>
        </div>
      </div>
    )
  }

  render() {
    const {audio: {audioTranscript, audioTranscriptStatus}, isAdminUser} = this.props
    return (
      <div className="flex flex-column attachment-audio-gallery">
        {this.renderTitle(audioTranscriptStatus)}
        {this.renderTranscript(audioTranscript, audioTranscriptStatus, isAdminUser)}
        {this.renderAttachment()}
      </div>
    )
  }
  static propTypes = {
    audio: PropTypes.object.isRequired,
    isAdminUser: PropTypes.bool
  };
}
