import { List } from 'immutable'
import Serializable from './Serializable'
import ChainUnitArea from './ChainUnitArea'

class ChainUnit extends Serializable {
  constructor(data) {
    super(data, 'ChainUnit')
    this._areas = this._data.get('areas', List())
      .map(area => new ChainUnitArea(area))
      .sortBy(area => area.name)
  }

  get id() {
    return this._data.get('chainUnitId')
  }

  get name() {
    return this._data.get('displayName')
  }

  get areas() {
    return this._areas
  }

  get isAuthorized() {
    return this._data.get('isAuthorized')
  }

  get companyDisplayName() {
    return this._data.get('companyDisplayName')
  }
}

export default ChainUnit
