import { destroy } from 'redux-form'

import { Context, ContextType } from 'domain'
import { UriHandler } from 'utils'
import { QUICK_SEARCH_FORM_NAME } from 'components/Menu/QuickSearchForm'

export const ContextActions = {
  SET: 'Context/Set',
  CLEAR: 'Context/Clear'
}

export const setContext = (type, uri) => ({
  type: ContextActions.SET,
  context: new Context({ type, url: new UriHandler(uri) })
})

export const changeContext = (type, url) => dispatch => {
  dispatch(setContext(type, url))
  // Clear the search form if moving out of search results
  if (type !== ContextType.search) {
    dispatch(destroy(QUICK_SEARCH_FORM_NAME))
  }
}

// TODO: not used anymore?
export const clearContext = () => ({ type: ContextActions.CLEAR })
