import URI from 'urijs'
import { trace } from 'utils/Log'

const getPath = (target, currentPath) => {
  if ( target.substr(0, 1) === '/' || !currentPath ) {
    return target
  } else {
    return currentPath + '/' + target
  }
}

const getNormalized = (uri, search) => !!search ? uri.query(search) : uri

export const relativizeTarget = (currentPath, to) => {
  const target = typeof to === 'string' ? to : to.pathname
  const path = getPath(target, currentPath)
  const normalized = getNormalized(URI(path), to.query).normalizePath().resource()
  trace('RoutingUtils: relativized path:', path, to, normalized)
  return normalized
}
