import React, { Component, PropTypes, Children } from 'react';

import { trace } from 'utils/Log'
import { mapValues } from 'utils'
import { getText } from './utils'

export class TranslateChildrenProps extends Component {
  static contextTypes = {
    translations: PropTypes.object.isRequired
  };

  render() {
    const translations = this.context.translations
    const child = Children.only(this.props.children);
    const {translate, ...props} = child.props
    const translatedProps = mapValues(translate, k => getText(translations, k))
    const newProps = { ...props, ...translatedProps }
    trace('TranslateChildrenProps: mapped child props', newProps)
    return React.cloneElement(child, newProps)
  }
}

export default TranslateChildrenProps
