import { fromJS } from 'immutable'

import createReducer from './createReducer'
import { SearchActions } from 'actions/SearchActions'
import { ContextActions } from 'actions/ContextActions'
import { ContextType } from 'domain'

const initialState = fromJS({ })
export const reducer = createReducer(initialState, {
  [SearchActions.SET_QUERY]: (state, { query }) => state.set('query', fromJS(query)),
  [SearchActions.CLEAR_QUERY]: (state) => state.delete('query'),

  [SearchActions.SET_RESULTS]: (state, { feedbacks }) => state.set('results', fromJS({ feedbacks })),
  [SearchActions.CLEAR_RESULTS]: (state) => state.delete('results'),

  // When moving out of search context, we should clear the search context to save memory
  [ContextActions.CLEAR]: () => initialState,
  [ContextActions.SET]: (state, { context }) => context.type === ContextType.search ? state : initialState
})
