import Serializable from './Serializable'

class FacebookReceiver extends Serializable {

  constructor(data) {
    super(data, 'FacebookReceiver')
  }

  get id() {
    return this._data.get('id')
  }

  get pageId() {
    return this._data.getIn(['page', 'pageId'])
  }

  get pageName() {
    return this._data.getIn(['page', 'pageName'])
  }

  get chainUnitId() {
    return this._data.get('chainId')
  }

  get subjectId() {
    return this._data.get('feedbackSubject')
  }

  get storeId() {
    return this._data.get('businessUnitId')
  }

  get userName() {
    return this._data.get('userName')
  }
}

export default FacebookReceiver