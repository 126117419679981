import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { WaitFor } from 'components/Utils'

class TextProvider extends Component {
  static childContextTypes = {
    locale: PropTypes.string,
    translations: PropTypes.instanceOf(Map)
  };
  static propTypes = {
    locale: PropTypes.oneOf(['fi','sv','en']).isRequired,
    translations: PropTypes.instanceOf(Map)
  };

  getChildContext = () => ({
    locale: this.props.locale,
    translations: this.props.translations
  });

  localeReady = () => !!this.props.translations;

  render() {
    const { children, locale } = this.props
    // The key attribute is changed to force rerendering of the whole subtree when locale changes
    return (
      <WaitFor key={locale} condition={this.localeReady}>
        {children}
      </WaitFor>
    )
  }
}

export default connect(state => {
  const locale = state.getIn(['i18n', 'locale'])
  return {
    locale,
    translations: state.getIn(['i18n', 'translations', locale])
  };
})(TextProvider)
