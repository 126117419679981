import { fromJS } from 'immutable'
import { SettingsActions } from 'actions/SettingsActions'
import createReducer from './createReducer'

const initialState = fromJS({
  savingReceiver: false,
  loggedInFacebook: false,
  checkingFacebookLogin: false,
  removingConsent: false,
  storeSettings: undefined,
  allowedTransferFeedbackDomains: []
})

export const reducer = createReducer(initialState, {
  [SettingsActions.SET]: (state, { name, data } ) => state.set(name, fromJS(data)),
  [SettingsActions.START_RECEIVER_SAVING]: state => state.set('savingReceiver', true),
  [SettingsActions.END_RECEIVER_SAVING]: state => state.set('savingReceiver', false),
  [SettingsActions.FACEBOOK_LOGIN]: state => state.set('loggedInFacebook', true),
  [SettingsActions.FACEBOOK_LOGOUT]: state => state.set('loggedInFacebook', false),
  [SettingsActions.START_FACEBOOK_LOGIN_CHECK]: state => state.set('checkingFacebookLogin', true),
  [SettingsActions.END_FACEBOOK_LOGIN_CHECK]: state => state.set('checkingFacebookLogin', false),
  [SettingsActions.START_REMOVING_CONSENT]: state => state.set('removingConsent', true),
  [SettingsActions.END_REMOVING_CONSENT]: state => state.set('removingConsent', false)
});
