import React, { PropTypes } from 'react';

import { TriangledTitle } from 'components/kesko'

import Text from 'containers/Text'
import { CollapsingContainer } from 'containers/Layout'

import FeedbackCustomerInfo from './FeedbackCustomerInfo'

import { Customer } from 'domain'

class CollapsingCustomerInfo extends React.PureComponent {

  constructor(props) {
    super(props)
    this.renderTitle = this.renderTitle.bind(this)
  }

  renderTitle({visible, handleClick}) {
    const titleElement = (
      <Text k="feedback.customer.info" />
    );

    return (
      <TriangledTitle
        titleElement={titleElement}
        toggled={visible}
        onClick={handleClick}
      />
    )
  }

  render() {
    const {customer, collapseId, initiallyVisible} = this.props
    return customer ? (
      <div className="collapsing-customer-info">
        <CollapsingContainer collapseId={collapseId} initiallyVisible={initiallyVisible} title={this.renderTitle} animated={false}>
          <FeedbackCustomerInfo shortForm={false} customer={customer} />
        </CollapsingContainer>
      </div>
    ) : <span />
  }
}

CollapsingCustomerInfo.defaultProps = {
  initiallyVisible: false
};

CollapsingCustomerInfo.propTypes = {
  collapseId: PropTypes.any.isRequired,
  initiallyVisible: PropTypes.bool.isRequired,
  customer: PropTypes.instanceOf(Customer)
};

export default CollapsingCustomerInfo
