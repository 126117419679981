import React, { Component, Children, PropTypes } from 'react'

import { Col } from 'components/grid'
import { Spinner } from 'components/Icons'

class WaitFor extends Component {
  static propTypes = {
    condition: PropTypes.any.isRequired
  };
  static defaultProps = {
    condition: false
  };

  evaluateCondition = () => {
    const condition = this.props.condition
    if (typeof condition === 'function') {
      return condition()
    }
    return !!condition
  };

  render() {
    if (this.evaluateCondition()) {
      return Children.only(this.props.children)
    } else {
      return (
        <Col className="center blue">
          <Spinner className="mt2 mb2" />
        </Col>
      )
    }
  }
}

export default WaitFor
