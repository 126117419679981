import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { TransferFeedbackView } from 'components/Feedback'
import { relativizeTarget } from 'components/Routing/RoutingUtils'
import { initializeTransfer, doTransfer } from 'actions/FeedbackActions'
import { fetchStoreHandlers } from 'actions/GlobalDataActions'

const toList = obj => obj ? obj.toList() : obj

const toActiveList = obj => obj ? obj.filter(o => o.isActive).toList() : obj

class TransferFeedbackRoute extends Component {
  static contextTypes = {
    currentPath: PropTypes.string,
    router: PropTypes.any
  };

  navigateToFeedbackView = () => {
    this.context.router.push(relativizeTarget(this.context.currentPath, '..'))
  };

  navigateAfterTransfer = () => {
    if (this.props.isStoreUser) {
      this.context.router.push(this.props.routeContext.url.toString());
    } else {
      this.navigateToFeedbackView()
    }
  }

  transfer = (fields) => {
    const {commentText, targetType, targetStore, targetHandler, targetChain, targetOther, targetEmail, targetSubject, language} = fields
    return this.props.actions.transferFeedback(commentText, targetType, targetStore, targetHandler, targetChain, targetOther, targetEmail, targetSubject, language).then(this.navigateAfterTransfer)
  };

  render() {
    return (
      <TransferFeedbackView
        {...this.props}
        transfer={this.transfer}
        cancel={this.navigateToFeedbackView}
      />
    )
  }
}

export default connect(
  (state, props) => ({
    feedbackId: parseInt(props.params.feedbackId),
    externalSystemOptions: toList(state.getIn(['globalData', 'externalSystems'])),
    businessUnitOptions: toActiveList(state.getIn(['globalData', 'businessUnits'])),
    handlerOptions: toList(state.getIn(['globalData', 'storeHandlers'])),
    chainUnitOptions: toList(state.getIn(['globalData', 'chainUnits'])),
    isStoreUser: state.getIn(['client', 'storeUser']),
    subjectOptions: toList(state.getIn(['globalData', 'subjects'])),
    transferFeedbackAddresses: toList(state.getIn(['globalData', 'transferFeedbackAddresses']))
  }),
  (dispatch, props) => ({
    actions: bindActionCreators({
      init: initializeTransfer,
      fetchStoreHandlers: (businessUnitId) => dispatch(() => fetchStoreHandlers(businessUnitId)),
      transferFeedback: (commentText, targetType, targetStore, targetHandler, targetChain, targetOther, targetEmail, targetSubject, language) =>
        doTransfer(props.feedbackId, commentText, targetType, targetStore, targetHandler, targetChain, targetOther, targetEmail, targetSubject, language)
    }, dispatch)
  })
)
(TransferFeedbackRoute)
