import React, { PropTypes } from 'react'
import PureComponent from 'react-pure-render/component'
import Icon, { IconSizes } from './Icon'
import KeskoIcons from './KeskoIcons'

class Spinner extends PureComponent {
  render() {
    const { className, ...otherProps } = this.props
    return (
      <Icon icon={KeskoIcons.spinner} className={'anim-spinner3 ' + className} {...otherProps}/>
    )
  }
}
Spinner.defaultProps = {
  className: '',
  size: 'xl'
}
Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(IconSizes)
}
export default Spinner
