import * as Helpers from './Helpers'
import UriHandler from './UriHandler'
import * as wd from './windowAndDocument'
import { Colors } from './Colors'
import createUuid from './uuid'

module.exports = {
  createUuid,
  Colors,
  UriHandler,
  ...Helpers,
  ...wd
};
