import { Map } from 'immutable'

import { BusinessUnitActions } from 'actions/BusinessUnitActions'

import createReducer from './createReducer'

export const reducer = createReducer(Map({}), {

  [BusinessUnitActions.LOAD]: (state, action) => state.setIn(
    [action.payload.businessUnit.id, 'info'],
    action.payload.businessUnit
  )
})
