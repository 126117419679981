import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { FeedbackListFilters } from 'components/Feedback'
import { WaitFor } from 'components/Utils'
import { ContextSelectionHeader } from 'containers/Menu'
import { init, bindFeedbackList, fetchFeedbacks, fetchMoreFeedbacks } from 'actions/FeedbackListActions'
import { toggleFilter, resetFilter } from 'actions/QuickFilterActions'
import { changeContext } from 'actions/ContextActions'
import { Subject, ContextType, ReceiverType } from 'domain'

import { defined } from 'utils'

import RouteUtil from './RouteUtil'
import FeedbackListRoute from './FeedbackListRoute'

class SubjectRoute extends Component {
  static propTypes = {
    activeFilter: PropTypes.string,
    subjectId: PropTypes.string.isRequired,
    subject: PropTypes.instanceOf(Subject),
    feedbackList: PropTypes.instanceOf(Map),
    feedbackListId: PropTypes.string,
    location: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      init: PropTypes.func.isRequired,
      fetchFeedbacks: PropTypes.func.isRequired,
      fetchMoreFeedbacks: PropTypes.func.isRequired,
      toggleFilter: PropTypes.func.isRequired
    })
  };

  componentDidMount() {
    const { subjectId } = this.props
    if (subjectId) {
      this.props.actions.resetQuickFilter(subjectId)
      this.props.actions.bindContext(subjectId)
      this.props.actions.init(subjectId)
    }
  }

  componentWillReceiveProps(newProps) {
    const newSubjectId = newProps.subjectId
    if (newSubjectId && newSubjectId !== this.props.subjectId) {
      this.props.actions.resetQuickFilter(newSubjectId)
      this.props.actions.bindContext(newSubjectId)
      this.props.actions.init(newSubjectId)
    }
  }

  render() {
    const { subject, feedbackList, actions, actions: { toggleFilter }, activeFilter, location } = this.props
    return (
      <WaitFor condition={!!subject && !!feedbackList}>
        { feedbackList && (
          <div className="context-selection-container">
            <ContextSelectionHeader text={subject && subject.name} type='subject' />
            <FeedbackListFilters activeFilter={activeFilter} actions={{ toggleFilter }} />
            <FeedbackListRoute
              feedbackList={feedbackList}
              actions={actions}
              location={location}
              showReceiver={true}
            />
          </div>
        )}
      </WaitFor>
    )
  }
}

const mapStateToProps = (state, { location, params: { subjectId }}) => {
  const subject = state.getIn(['globalData', 'subjects', defined(subjectId, 'SubjectId')])
  const activeFilterListId = state.getIn(['quickFilter', 'feedbackListId'])
  const feedbackListId = state.getIn(['feedbackLists', 'bindings', `subject/${subjectId}`])
  const currentFeedbackListId = activeFilterListId || feedbackListId
  const feedbackList = state.getIn(['feedbackLists', currentFeedbackListId])
  const activeFilter = state.getIn(['quickFilter', 'activeFilter'])

  return {
    activeFilter,
    subjectId,
    subject,
    feedbackListId,
    feedbackList,
    location
  }
}

const mapDispatchToProps = (dispatch, { params: { subjectId }}) => ({
  actions: bindActionCreators({
    init: (subjectId) => dispatch => {
      const initializeList = init({
        subjectId,
        receiverType: ReceiverType.subject
      })
      dispatch(initializeList)
      dispatch(bindFeedbackList(`subject/${subjectId}`, initializeList.feedbackListId))
    },
    fetchFeedbacks,
    fetchMoreFeedbacks,
    toggleFilter: filterName => toggleFilter(filterName, { subjectId, receiverType: ReceiverType.subject }),
    bindContext: (subjectId) => changeContext(ContextType.subject, RouteUtil.subject(subjectId)),
    resetQuickFilter: (subjectId) => resetFilter({ subjectId, receiverType: ReceiverType.subject })
  }, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(SubjectRoute)
