/**
 * Helper to facilitate creation of redux reducers
 * @param initialState initial state for the reducer
 * @param actionHandlers a map of action handlers from action.type => (state, action) => newState
 * @returns {Function}
 */
const createReducer = (initialState, actionHandlers) => {
  return (state = initialState, action) => {
    const handler = actionHandlers[action.type]
    return !!handler ? handler(state, action) : state
  }
}

export default createReducer
