import { fromJS } from 'immutable'

export const isEmpty = value => value === undefined || value === null || value === '';
export const isEmptyArray = arr => isEmpty(arr) || arr.filter((value) => !isEmpty(value)).length <= 0;
const valid = {valid: true}
const invalid = msg => ({valid: false, msg})

export const simpleValidator = (test, msg) => value => test(value) ? valid : invalid(msg)
export const nonRequiredValidator = (test, msg) => value => !value || test(value) ? valid : invalid(msg)

export const required   = (msg = 'required')       => simpleValidator(val => !isEmpty(val), msg)
export const minLength  = (min, msg = 'minLength') => nonRequiredValidator(str => str.length >= min, msg)
export const maxLength  = (max, msg = 'maxLength') => nonRequiredValidator(str => str.length <= max, msg)

// Email validation logic copied from org.hibernate.validator.internal.constraintvalidators.hv.EmailValidator
/* eslint-disable quotes */
const ATOM = "[a-z0-9!#$%&'*+/=?^_`{|}~-]";
const DOMAIN = ATOM + "+(\\." + ATOM + "+)*";
const IP_DOMAIN = "\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\]";
const MAX_LOCAL_PART_LENGTH = 64;
const MAX_DOMAIN_PART_LENGTH = 255;
const CASE_INSENSITIVE = 'i'
/** Regular expression for the local part of an email address (everything before '@') */
const localPattern = new RegExp(
  "^" + ATOM + "+(\\." + ATOM + "+)*$",
  CASE_INSENSITIVE
)

/** Regular expression for the domain part of an email address (everything after '@') */
const domainPattern = new RegExp(
  "^((" + DOMAIN + ")|(" + IP_DOMAIN + "))$",
  CASE_INSENSITIVE
)

/* eslint-enable quotes */
const matchPart = (part, pattern, maxLength) => {
  if (!part) {
    return false
  }
  if (part.length > maxLength) {
    return false
  }
  return pattern.test(part)
}

export const email = (msg = 'email') => nonRequiredValidator(str => {
  const parts = str.split('@')
  if (parts.length > 2) {
    return false
  }
  if ( !matchPart( parts[0], localPattern, MAX_LOCAL_PART_LENGTH ) ) {
    return false;
  }
  return matchPart( parts[1], domainPattern, MAX_DOMAIN_PART_LENGTH );
}, msg)

export const phoneNumberRegex = /^([+][1-9]|0[0-9])([0-9,' ',-]){4,18}$/;

export const arrayHasValues = (msg) => simpleValidator(arr => !isEmptyArray(arr), msg);

/**
 * Create validator based on certain rules. The validator can be passed straight
 * to redux-form
 * @param rules
 *
 * @example
 * <pre>
 * const validator = createValidator({
 *  name: required(),
 *  phone: [required(), minLength(6), maxLength(10)]
 * })
 * </pre>
 */
export const createValidator = rules => data =>
  fromJS(rules)
    .mapEntries(([fieldName, rulesForKey]) => ([
      fieldName,
      fromJS([rulesForKey]).flatten()
        .map(rule => rule(data[fieldName], data))
        .filterNot(a => a.valid)
        .map(a => a.msg)
        .first()
    ]))
    .filter(a => a) // Remove fields with no errors
    .toJS()
