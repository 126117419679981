import React, { Component, PropTypes } from 'react'
import { Map } from 'immutable'

import { FeedbackList, FeedbackListLoadingIndicator, FeedbackListItem } from 'components/Feedback'
import { ScrollToBottomDetector } from 'components/Utils'
import { Text } from 'containers/Text'
import { ScrollTo } from 'components/Utils'
import { UriHandler } from 'utils'
import { OrderBy } from 'domain'

class NothingMoreToShow extends Component {
  render() {
    return (
      <h3 className="no-more-feedbacks">
        <Text k="feedback.list.atBottom" />
      </h3>
    )
  }
}

export class FeedbackListRoute extends Component {
  static defaultProps = {
    ListItemClass: FeedbackListItem,
    showReceiver: false
  };
  static propTypes = {
    ListItemClass: PropTypes.func.isRequired,
    feedbackList: PropTypes.instanceOf(Map).isRequired,
    actions: PropTypes.shape({
      fetchFeedbacks: PropTypes.func.isRequired,
      fetchMoreFeedbacks: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    scrollToFeedback: PropTypes.number,
    showReceiver: PropTypes.bool.isRequired
  };
  static contextTypes = {
    router: PropTypes.any
  };

  constructor(props) {
    super(props)
    this.ListItemClass = ScrollTo(this.shouldScrollTo, this.removeScrollFromUrl)(FeedbackListItem)
  }

  shouldScrollTo = (props) => props.feedback.id === parseInt(this.props.location.query.scrollTo);

  removeScrollFromUrl = () => {
    const newUri = new UriHandler(this.props.location).removeQuery('scrollTo').toString()
    this.context.router.replace(newUri)
  };

  componentDidMount() {
    const feedbackListId = this.props.feedbackList.get('feedbackListId')
    if (feedbackListId) {
      this.props.actions.fetchFeedbacks(feedbackListId)
    }
  }

  componentWillReceiveProps(newProps) {
    const newFeedbackListId = newProps.feedbackList.get('feedbackListId')
    const previousFeedbackListId = this.props.feedbackList.get('feedbackListId')
    if (newFeedbackListId && newFeedbackListId !== previousFeedbackListId) {
      this.props.actions.fetchFeedbacks(newFeedbackListId)
    }
  }

  handleShowMore = () => {
    if (!this.props.feedbackList.get('loading') && this.props.feedbackList.get('hasMore')) {
      this.props.actions.fetchMoreFeedbacks(this.props.feedbackList.get('feedbackListId'))
    }
  };

  render() {
    const {feedbackList, showReceiver} = this.props
    const hasMore = feedbackList.get('hasMore')
    const loading = feedbackList.get('loading')
    const showModified = feedbackList.getIn(['filter', 'orderBy']) === OrderBy.modified
    const feedbackPreviews = feedbackList.get('feedbackPreviews')
    return (
      <ScrollToBottomDetector handleScrolledToBottom={this.handleShowMore} pageBottomThreshold={700}>
        <div>
          <FeedbackList feedbacks={feedbackPreviews} FeedbackListItem={this.ListItemClass} showReceiver={showReceiver} showModified={showModified} />
          <FeedbackListLoadingIndicator loading={loading} />
          {!loading && !hasMore && <NothingMoreToShow />}
        </div>
      </ScrollToBottomDetector>
    )
  }
}

export default FeedbackListRoute
