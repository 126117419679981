/* eslint react/no-danger:0 */
import React, { PropTypes } from 'react';

import classNames from 'classnames'

const IconSizesEnum = {
  s: 's',
  m: 'm',
  l: 'l',
  xl: 'xl',
  xxl: 'xxl',
  unset: ''
};

class Icon extends React.PureComponent {
  render() {
    const {className, size, icon, onClick, ...rest} = this.props;
    const useTag = (<use xlinkHref={`${icon.url()}`} />);
    const classes = classNames([
      className,
      'inline-block',
      'icon',
      `icon-${size}`,
      `icon-${icon.type}`
    ]);

    return (
      <svg {...rest} className={classes} onClick={onClick}>
        {useTag}
      </svg>
    );
  }
}

export const IconPropType = PropTypes.shape({
  url: PropTypes.func.isRequired,
  type: PropTypes.string
});

export const IconSizes = ['s', 'm', 'l', 'xl', 'xxl', ''];

Icon.defaultProps = {
  size: 'm'
};

Icon.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.oneOf(IconSizes),
    PropTypes.oneOf(Object.keys(IconSizesEnum))
  ]),
  icon: IconPropType.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string
};

Icon.sizes = IconSizesEnum;

export default Icon;

