import Serializable from './Serializable'

class ExternalSystem extends Serializable {
  constructor(data) {
    super(data, 'ExternalSystem')
  }

  get id() {
    return this._data.get('id')
  }

  get name() {
    return this._data.get('name')
  }

  get nameFi() {
    return this._data.get('name')
  }

  get nameSv() {
    return this._data.get('nameSv')
  }

  get nameEn() {
    return this._data.get('nameEn')
  }

  localizedName(lang) {
    switch (lang) {
      case 'sv':
        return this.nameSv;
      case 'en':
        return this.nameEn;
      default:
        return this.nameFi;
    }
  }
}

export default ExternalSystem
