import React, { Component, PropTypes } from 'react'

import { Col } from 'components/grid'
import { Navigator } from 'components/Menu'
import FacebookReplyForm from './FacebookReplyForm'

import { Feedback } from 'domain'
import FeedbackCollapsingSections from '../FeedbackCollapsingSections';

class FacebookReplyView extends Component {

  static propTypes = {
    feedback: PropTypes.instanceOf(Feedback),
    actions: PropTypes.shape({
      cancel: PropTypes.func.isRequired,
      sendReplyAndMarkDone: PropTypes.func.isRequired,
      sendReplyAndMarkWaiting: PropTypes.func.isRequired,
      saveDraft: PropTypes.func.isRequired,
      init: PropTypes.func.isRequired,
      cleanup: PropTypes.func.isRequired
    }).isRequired
  };

  componentDidMount() {
    this.props.actions.init()
  }

  componentWillUnmount() {
    this.props.actions.cleanup()
  }

  render() {
    const {feedback, actions: {cancel, sendReplyAndMarkDone, sendReplyAndMarkWaiting, saveDraft}} = this.props
    const message = feedback.responseDraft ? feedback.responseDraft.messageText : ''
    return (
      <section className="facebook-reply-view">
        <Navigator backUrl=".." />
        <Col>
          <hr />
        </Col>

        <FeedbackCollapsingSections
          feedback={feedback}
          messagesVisible
        />

        <Col>
          <hr />
        </Col>

        <FacebookReplyForm
          localStorageKey={feedback.id}
          onCancel={cancel}
          submitAndDone={sendReplyAndMarkDone}
          submitAndWaiting={sendReplyAndMarkWaiting}
          submitAndSaveDraft={saveDraft}
          initialValues={{message}}
        />
      </section>
    )
  }
}

export default FacebookReplyView
