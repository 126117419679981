import React, { Component, PropTypes } from 'react';

import { trace } from 'utils/Log'
import { mapValues } from 'utils'
import { getText } from './utils'

/**
 * Higher-order component to translate the properties.
 * Requires <TextProvider /> supply the context
 */
const TranslateProps = OriginalComponent => {
  class Extended extends Component {
    static contextTypes = {
      translations: PropTypes.object.isRequired
    };

    constructor(props) {
      super(props)
      this.bindOriginal = this.bindOriginal.bind(this)
    }

    bindOriginal(component) {
      this._original = component
    }

    getWrappedInstance() {
      return this._original
    }

    render() {
      const { translate, children, ...props } = this.props
      const translations = this.context.translations;
      const translatedProps = mapValues(translate, k => getText(translations, k));
      trace('TranslateProps for ', OriginalComponent, translatedProps)
      return <OriginalComponent ref={this.bindOriginal} {...props} {...translatedProps}>{children}</OriginalComponent>
    }
  }

  return Extended
}

export default TranslateProps
