import React, { Children, Component, PropTypes } from 'react'

import { Text } from 'containers/Text'
import { createUuid } from 'utils'
import { Icon, KeskoIcons } from 'components/Icons'

class LabeledInput extends Component {
  static propTypes = {
    labelKey: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ]).isRequired,
    className: PropTypes.string
  };

  formInputId = createUuid();

  render() {
    const {labelKey, labelHint, children, className, ...rest} = this.props
    const child = React.cloneElement(Children.only(children), {
      id: this.formInputId
    });
    return (
      <div className={`labeled-input ${className ? className : ''}`} {...rest}>
        <div className="flex bubble-bottom">
          <label htmlFor={this.formInputId}>
            <Text k={labelKey} />
          </label>
          {labelHint && (
            <span className="hint-icon hint--bottom" data-hint={labelHint}>
              <Icon size="l" icon={KeskoIcons.info} className="silver clickable" />
            </span>
          )}
        </div>
        {child}
      </div>
    )
  }
}

export default LabeledInput
