import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { List } from 'immutable'

import { changeContext } from 'actions/ContextActions'
import { initializeReporting, generateResponseSummaryReportExport, generateResponseSummaryKeskoReportExport } from 'actions/ReportActions'
import { ContextType, Permissions } from 'domain'
import { RouteUtil } from 'routes'
import { WaitFor } from 'components/Utils'
import { toJS } from 'utils'
import Company from 'domain/Company'

/**
 * Lazy-loading route for reporting
 */
class ResponseSummaryReportingRoute extends Component {

  static contextTypes = {
    router: PropTypes.any
  };

  componentWillMount() {
    if (!this.props.hasPermission) {
      this.context.router.replace(RouteUtil.home())
    } else {
      require.ensure(['components/Reporting/ResponseSummary/ResponseSummaryReportingView'], require => {
        this.ResponseSummaryReportingView = require('components/Reporting/ResponseSummary/ResponseSummaryReportingView').default
        this.forceUpdate()
      }, 'responseSummaryReport')
    }
  }

  render() {
    const { ResponseSummaryReportingView } = this
    return (
      <WaitFor condition={!!ResponseSummaryReportingView}>
        {ResponseSummaryReportingView && (
          <div>
            <ResponseSummaryReportingView {...this.props} />
          </div>
        )}
      </WaitFor>
    )
  }

}

const toList = obj => obj ? obj.toList() : obj

const toAuthorizedList = obj => obj ? obj.filter(o => o.isAuthorized).toList() : obj

const dataForCompanyUser = state => ({
  user: toJS(state.getIn(['client', 'userInfo'])),
  loading: state.getIn(['reports', 'loading']),
  chainUnits: toAuthorizedList(state.getIn(['globalData', 'chainUnits'])),
  businessUnits: toAuthorizedList(state.getIn(['globalData', 'businessUnits'])),
  reportResult: state.getIn(['reports', 'summaryResult']),
  reportError: state.getIn(['reports', 'responseSummaryError']),
  hasPermission: state.getIn(['client','permissions', Permissions.reporting]),
  possibleChannels: toList(state.getIn(['globalData', 'feedbackChannels'])),
  subjects: toAuthorizedList(state.getIn(['globalData', 'subjects'])),
  topics: state.getIn(['globalData', 'topics']),
  showKeskoSummary: state.getIn(['client', 'authorizedCompanies']).contains(Company.KESKO)})

const dataForStoreUser = (state, reportingBusinessUnits, selectedBusinessUnit) => ({
  user: toJS(state.getIn(['client', 'userInfo'])),
  loading: state.getIn(['reports', 'loading']),
  chainUnits: List(),
  businessUnits: toList(state.getIn(['globalData', 'allowableBusinessUnits'])),
  reportResult: state.getIn(['reports', 'summaryResult']),
  reportError: state.getIn(['reports', 'responseSummaryError']),
  hasPermission: reportingBusinessUnits !== undefined && reportingBusinessUnits.indexOf(selectedBusinessUnit) !== -1,
  possibleChannels: toList(state.getIn(['globalData', 'feedbackChannels'])),
  subjects: toAuthorizedList(state.getIn(['globalData', 'subjects'])),
  topics: state.getIn(['globalData', 'topics']),
  selectedBusinessUnitCode: state.getIn(['client', 'selectedBusinessUnitCode'])
});

export default connect(
  state => {
    const isStore = state.getIn(['client', 'storeUser'])
    const reportingBusinessUnits = state.getIn(['client','permissions', Permissions.reportingBusinessUnits])
    const selectedBusinessUnit = state.getIn(['client', 'selectedBusinessUnitCode'])
    return isStore
      ? dataForStoreUser(state, reportingBusinessUnits, selectedBusinessUnit)
      : dataForCompanyUser(state)
  },
  dispatch => ({
    actions: bindActionCreators({
      bindContext: () => changeContext(ContextType.reporting, RouteUtil.responseSummaryReport()),
      init: () => initializeReporting(),
      generateReport: generateResponseSummaryReportExport,
      loadReport: generateResponseSummaryKeskoReportExport
    }, dispatch)
  })
)(ResponseSummaryReportingRoute)
