import { window, document } from 'utils'

/**
 * window.pageYOffset supported by IE >= 9, Safari > 3
 * window.innerHeight supported by IE >= 9, Safari > 3
 *
 * @param offsetPageBottom number of pixels from the bottom of the viewable area to consider as page bottom. Defaults to 0
 * @returns {boolean} true if scrolled to page bottom, false otherwise
 */
export const isScrolledToBottom = (offsetPageBottom = 0) => {
  const scrollY = window.pageYOffset;
  const windowHeight = window.innerHeight;
  return (windowHeight + scrollY) >= document.body.offsetHeight - offsetPageBottom
};


/**
 * Force scroll to the target node if present.
 * @param {HTMLElement} node the element to scroll to
 */
export const scrollIntoView = node => {
  if (node) {
    if (node.scrollIntoViewIfNeeded) {
      // Should scroll the element to the center of the view
      node.scrollIntoViewIfNeeded(true);
    } else {
      // Fallback for browsers not supporting scrollIntoViewIfNeeded
      node.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }
};

export const scrollToPageTop = () => {
  window.scrollTo(0, 0);
};

const ScrollUtils = {
  isScrolledToBottom,
  scrollIntoView
};
export default ScrollUtils
