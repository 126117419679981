import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux'
import { SingleFeedbackView } from 'components/Feedback'
import { Feedback, Status } from 'domain'

import {
  markAsDone,
  markAsRead,
  reopen,
  reportAmbianceError,
  reportTopicError,
  reportThreat,
  exportFeedbackMessages,
  removeComment
} from '../actions/FeedbackActions'

class SingleFeedbackRoute extends Component {

  static propTypes = {
    feedback: PropTypes.instanceOf(Feedback).isRequired
  };

  markRead(feedback) {
    if (feedback && feedback.status === Status.NEW && !feedback.requiresResponse && !this.props.isReadOnly) {
      this.props.actions.markRead()
    }
  }

  componentDidMount() {
    this.markRead(this.props.feedback)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.feedback.modified.getTime() !== this.props.feedback.modified.getTime()) {
      this.markRead(newProps.feedback)
    }
  }

  render() {
    return (<SingleFeedbackView {...this.props} />)
  }
}

export default connect(
  (state) => ({
    isStoreUser: state.getIn(['client', 'storeUser']),
    isReadOnly: state.getIn(['client', 'readOnly']),
    isAdminUser: state.getIn(['client', 'admin'])
  }),
  (dispatch, props) => ({
    actions: {
      markAsDone: () => dispatch(markAsDone(props.feedbackId)),
      markRead: () => dispatch(markAsRead(props.feedbackId)),
      reopen: () => dispatch(reopen(props.feedbackId)),
      exportMessages: () => dispatch(exportFeedbackMessages(props.feedbackId)),
      reportAmbianceError: () => dispatch(reportAmbianceError(props.feedbackId)),
      reportTopicError: () => dispatch(reportTopicError(props.feedbackId)),
      reportThreat: () => dispatch(reportThreat(props.feedbackId)),
      removeComment: messageId => () => dispatch(removeComment(props.feedbackId, messageId))
    }
  })
)(SingleFeedbackRoute)
