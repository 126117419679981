import React, { PropTypes, Component } from 'react'

import { Col } from 'components/grid'
import Icon from './Icons/Icon';
import KeskoIcons from './Icons/KeskoIcons';
import Text from '../containers/Text/Text';

const Header = ({header}) => (
  <Col className="lg-hide clearfix">
    <header className="clearfix bg-white">
      {header}
    </header>
  </Col>
);

const DismissableErrorMessage = ({errorState, dismissError}) => errorState ? (
  <div className="dismissable-error-message error-block rounded">
    <Text k="error.genericError" />
    <Icon
      icon={KeskoIcons.crossSmall}
      onClick={dismissError}
      size={Icon.sizes.xl}
    />
  </div>
) : null;

const Main = ({main, hasSidebar = true, maintenanceMessage, errorState, dismissError}) => (
  <div className={('md-px3 p1 clearfix' + (hasSidebar ? ' md-sidebar-marginleft' : ''))}>
    <main>
      {maintenanceMessage && (
        <div className="maintenance-message error-block rounded">
          {maintenanceMessage}
        </div>
      )}
      <DismissableErrorMessage errorState={errorState} dismissError={dismissError} />
      {main}
    </main>
  </div>
);

class AppWrapper extends Component {
  static propTypes = {
    sidebarOpen: PropTypes.bool.isRequired,
    header: PropTypes.element,
    sidebar: PropTypes.element,
    main: PropTypes.element.isRequired
  };

  render() {
    const {sidebar, header, main, maintenanceMessage, errorState, dismissError} = this.props;
    return (
      <div>
        <div className="container">
          {sidebar}
          {header && <Header header={header} />}
          <Main
            main={main}
            hasSidebar={!!sidebar}
            maintenanceMessage={maintenanceMessage}
            errorState={errorState}
            dismissError={dismissError}
          />
        </div>
      </div>
    );
  }
}

export default AppWrapper
