import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { UberallReplyView } from 'components/Feedback'
import { RoutingUtils } from 'components/Routing'
import { Feedback } from 'domain'
import { replyWithChannel, saveResponseDraft } from 'actions/FeedbackActions'
import { startResponding, stopResponding } from 'actions/ClientActions'

class ReplyViaUberallRoute extends Component {
  static contextTypes = {
    currentPath: PropTypes.string,
    router: PropTypes.any
  };
  static propTypes = {
    feedback: PropTypes.instanceOf(Feedback).isRequired
  };

  navigateToFeedbackView = () => this.context.router.push(RoutingUtils.relativizeTarget(this.context.currentPath, '..'));
  sendReplyAndMarkDone = reply => this.props.actions.reply(reply, true).then(this.navigateToFeedbackView);
  sendReplyAndMarkWaiting = reply => this.props.actions.reply(reply, false).then(this.navigateToFeedbackView);
  saveDraft = reply => this.props.actions.saveDraft(reply).then(this.navigateToFeedbackView);
  init = () => this.props.actions.startResponding();
  cleanup = () => this.props.actions.stopResponding();

  render() {
    const props = {
      feedback: this.props.feedback,
      actions: {
        sendReplyAndMarkDone: this.sendReplyAndMarkDone,
        sendReplyAndMarkWaiting: this.sendReplyAndMarkWaiting,
        saveDraft: this.saveDraft,
        cancel: this.navigateToFeedbackView,
        init: this.init,
        cleanup: this.cleanup
      }
    }
    return (<UberallReplyView {...props} />)
  }
}

export default connect(
  state => ({
    userName: state.getIn(['client', 'userInfo', 'displayName'])
  }),
  (dispatch, props) => ({
    actions: bindActionCreators({
      reply: (reply, markAsHandled) => dispatch(() => replyWithChannel(props.feedbackId, reply.message, markAsHandled, 'uberall')),
      saveDraft: (reply) => dispatch(() => saveResponseDraft(props.feedbackId, reply.message)),
      startResponding: () => dispatch(() => startResponding(props.feedbackId)),
      stopResponding: () => dispatch(() => stopResponding(props.feedbackId))
    }, dispatch)
  })
)(ReplyViaUberallRoute)
