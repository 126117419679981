
const ContextType = {
  default: 'default',
  allFeedbacks: 'allFeedbacks',
  secondaryFeedbacks: 'secondaryFeedbacks',
  search: 'search',
  businessUnit: 'businessUnit',
  chainUnit: 'chainUnit',
  subject: 'subject',
  reporting: 'reporting'
}

export default ContextType
