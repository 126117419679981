import React, { Component, PropTypes } from 'react';
import { List } from 'immutable'
import { TriangledTitle } from 'components/kesko'

import Text from 'containers/Text'
import { CollapsingContainer } from 'containers/Layout'

import { Message } from 'domain'
import MessagesBlock from './MessagesBlock';

class FeedbackMessages extends Component {

  constructor(props) {
    super(props);
    this.renderTitle = this.renderTitle.bind(this);
  }

  renderTitle({visible, handleClick}) {
    const {messages} = this.props;
    const titleElement = (
      <span>
        <Text k="feedback.messages" />
        <span> ({messages.size}) </span>
      </span>
    );

    return (
      <TriangledTitle
        titleElement={titleElement}
        toggled={visible}
        onClick={handleClick}
      />
    )
  }

  render() {
    const {feedback, collapseId, messages, showEditLinks, initiallyVisible} = this.props;
    return (
      <div className="feedback-messages">
        <CollapsingContainer animated={false} collapseId={collapseId} initiallyVisible={initiallyVisible} title={this.renderTitle}>
          <MessagesBlock feedback={feedback} messages={messages} showEditLinks={showEditLinks}/>
        </CollapsingContainer>
      </div>
    )
  }
}

FeedbackMessages.defaultProps = {
  initiallyVisible: true,
  showEditLinks: true
};

FeedbackMessages.propTypes = {
  collapseId: PropTypes.any.isRequired,
  initiallyVisible: PropTypes.bool.isRequired,
  messages: PropTypes.oneOfType([
    PropTypes.arrayOf(Message),
    PropTypes.instanceOf(List)
  ]).isRequired,
  showEditLinks: PropTypes.bool.isRequired
};

export default FeedbackMessages
