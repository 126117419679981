import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { List } from 'immutable'

import { initializeProfile, saveProfile } from 'actions/ProfileActions'
import { ProfileView } from 'components/Profile'
import { WaitFor } from 'components/Utils'
import { toJS } from 'utils'
import { Company } from 'domain'

class ProfileRoute extends Component {
  render() {
    const { user } = this.props
    return (
      <WaitFor condition={user}>
        {user && <ProfileView {...this.props} />}
      </WaitFor>
    )
  }
}

const toList = obj => obj ? obj.toList() : obj

const toAuthorizedList = obj => obj ? obj.filter(o => o.isAuthorized).toList() : obj

const dataForCompanyUser = state => ({
  user: toJS(state.getIn(['client', 'userInfo'])),
  locale: state.getIn(['i18n', 'locale']),
  isStoreUser: false,
  isReadOnly: state.getIn(['client', 'readOnly']),
  chainUnits: toAuthorizedList(state.getIn(['globalData', 'chainUnits'])),
  businessUnits: toAuthorizedList(state.getIn(['globalData', 'businessUnits'])),
  subjects: toAuthorizedList(state.getIn(['globalData', 'subjects'])),
  showInterestFeedbackCategorySelection: state.getIn(['client', 'authorizedCompanies']).contains(Company.KAUTO)
})

const dataForStoreUser = state => ({
  user: toJS(state.getIn(['client', 'userInfo'])),
  locale: state.getIn(['i18n', 'locale']),
  isStoreUser: true,
  isReadOnly: state.getIn(['client', 'readOnly']),
  chainUnits: List([]),
  allowableBusinessUnits: toList(state.getIn(['globalData', 'allowableBusinessUnits'])),
  showInterestFeedbackCategorySelection: state.getIn(['client', 'kautoStoreUser'])
})

export default connect(
  state => {
    const isStore = state.getIn(['client', 'storeUser'])
    return isStore ? dataForStoreUser(state) : dataForCompanyUser(state)
  },
  dispatch => ({
    actions: bindActionCreators({
      init: () => initializeProfile(),
      saveProfile
    }, dispatch)
  })
)(ProfileRoute)
