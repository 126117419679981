import React, { Component, PropTypes } from 'react'

import { List } from 'immutable'

import FeedbackListItem from './FeedbackListItem'

class FeedbackList extends Component {
  static propTypes = {
    feedbacks: PropTypes.instanceOf(List),
    FeedbackListItem: PropTypes.func.isRequired,
    showReceiver: PropTypes.bool.isRequired,
    showModified: PropTypes.bool
  };

  static defaultProps = {
    feedbacks: List([]),
    FeedbackListItem: FeedbackListItem,
    showReceiver: false,
    showModified: false
  };

  render() {
    const {feedbacks, FeedbackListItem, showReceiver, showModified} = this.props;
    const children = feedbacks.map(fb => (
      <FeedbackListItem
        feedback={fb}
        showReceiver={showReceiver}
        showModified={showModified}
        key={fb.id}
      />
    ));

    return (
      <div className="feedback-list">
        {children.isEmpty() ? null : children}
      </div>
    )
  }
}

export default FeedbackList
