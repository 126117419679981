import React, { Component } from 'react'
import classNames from 'classnames'

const AddErrorDisplay = OriginalComponent => class extends Component {
  render() {
    const { className, touched, invalid } = this.props
    const classes = classNames(
      className,
      { 'is-error': touched && invalid }
    )
    return <OriginalComponent {...this.props} className={classes} />
  }
}

export default AddErrorDisplay
