import React, { PropTypes } from 'react';
import PureComponent from 'react-pure-render/component'

import { MediaObject, Col } from 'components/grid'
import { Icon } from 'components/Icons'

import { TranslateProps } from 'containers/Text'
import KeskoIcons from '../Icons/KeskoIcons';

class ExclamatedNote extends PureComponent {
  static propTypes = {
    note: PropTypes.string.isRequired
  };

  render() {
    return (
      <MediaObject
        object={<Icon icon={KeskoIcons.info} size={Icon.sizes.xl} className="align-middle blue mr1" />}
        content={<Col >{this.props.note.split('\n').map((item, key) => {
          return <span key={key}>{item}<br /></span>
        })}</Col>}
        {...this.props}
      />
    )
  }
}

export default TranslateProps(ExclamatedNote)
