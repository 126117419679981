/* global jsConfig */
import React, { Component } from 'react'
import { reduxForm } from 'redux-form'

import { TextInput, ErrorBlock, LabeledCheckBox, LabeledInput } from 'components/Forms'
import { Spinner } from 'components/Icons'
import { CollapsingContainer } from 'containers/Layout'
import { Text } from 'containers/Text'

import { createValidator, required } from 'utils/Validation'
import Button from '../kesko/common/Button';
import { TriangledTitle } from 'components/kesko'

const isProduction = jsConfig.env === 'prod'

class LoginForm extends Component {

  constructor(props) {
    super(props);
    this.renderTitle = this.renderTitle.bind(this);
  }

  redirectToAadLogin = () => window.location.href = jsConfig.aadLoginUrl + '&state=' + this.props.path

  renderTitle({visible, handleClick}) {
    const titleElement = (
      <Text k="loginPage.alternativeLogin" />
    );
    return (
      <TriangledTitle
        titleElement={titleElement}
        toggled={visible}
        onClick={handleClick}
      />
    )
  }

  render() {
    if (this.props.aadLoginInProgress) {
      return <Spinner className="blue"/>;
    }
    const {fields, fields: {username, password, rememberMe}, handleSubmit, error, submitting, loginError} = this.props
    const otherErrors = [error, loginError && 'loginPage.loginFailed.' + loginError].filter(a => a)
    return (
      <form onSubmit={handleSubmit} className="login-form">

        <Button
          theme={Button.theme.primary}
          translation="loginPage.login"
          onClick={this.redirectToAadLogin}
          disabled={submitting}
          loading={submitting}
          className="login-button mt2 mb3"
          uppercase
        />

        <ErrorBlock
          className="mb3"
          fields={fields}
          otherErrors={otherErrors}
          translatePrefix="loginPage"
        />

        { !isProduction && (
          <CollapsingContainer collapseId={'LoginForm'} initiallyVisible={false} title={this.renderTitle} animated={true}>
            <div className="mb3">
              <LabeledInput labelKey="loginPage.username.placeholder">
                <TextInput
                  className="bg-blue"
                  disabled={submitting}
                  {...username}
                />
              </LabeledInput>
            </div>
  
            <div className="mb3">
              <LabeledInput labelKey="loginPage.password.placeholder">
                <TextInput
                  type="password"
                  disabled={submitting}
                  {...password}
                />
              </LabeledInput>
            </div>
  
            <div className="mb3">
              <LabeledCheckBox
                {...rememberMe}
                label="loginPage.rememberMe"
              />
            </div>
  
            <Button
              type="submit"
              theme={Button.theme.secondary}
              translation="loginPage.login"
              disabled={submitting}
              loading={submitting}
              className="login-button"
              uppercase
            />
          </CollapsingContainer>
        )}

      </form>
    )
  }
}

const ReduxLoginForm = reduxForm({
  form: 'login',
  fields: ['username', 'password', 'rememberMe'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    username: required(),
    password: required()
  })
})(LoginForm)

export default ReduxLoginForm
