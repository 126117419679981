import { connect } from 'react-redux'
import { fromJS, List } from 'immutable'

import { ContextSelectionHeader } from 'components/Menu'
import { RouteUtil } from 'routes'

export default connect(
  state => {
    const isStoreUser = state.getIn(['client','storeUser'])
    if (!isStoreUser) {
      const chainUnitViews = state
        .getIn(['globalData', 'chainUnits'], List())
        .filter(cu => cu.isAuthorized)
        .map(cu => fromJS({ name: cu.name, url: RouteUtil.chainUnit(cu.id), type: 'chain' }))
      const subjects = state
        .getIn(['globalData', 'subjects'], List())
        .filter(s => s.isAuthorized)
        .map(s => fromJS({ name: s.name, url: RouteUtil.subject(s.id), type: 'subject' }))
      const options = state.getIn(['client', 'views']).concat(chainUnitViews, subjects)

      return { options }
    }
    const userViews = state.getIn(['client','views'])
    if (userViews && userViews.size > 0) {
      return { options: userViews }
    }
    return {}
  }
)(ContextSelectionHeader)
