import {isEmpty, email as emailValidator, maxLength} from 'utils/Validation'
import { trimStringsInMap } from 'utils/Helpers';

export const validate = (values, props) => {
  values = trimStringsInMap(values);
  const errors = {};
  const isEmailValid = emailValidator()(values.offerRequestTransferEmail).valid;
  if (!isEmailValid) {
    errors.offerRequestTransferEmail = 'email';
  }
  const isEmailProValid = emailValidator()(values.offerRequestProTransferEmail).valid;
  if (!isEmailValid) {
    errors.offerRequestProTransferEmail = 'email';
  }
  const isAllowedEmailDomain = isEmpty(values.offerRequestTransferEmail) || !isEmailValid
    || validateOfferRequestTransferEmail(values.offerRequestTransferEmail, props);
  if (!isAllowedEmailDomain) {
    errors.offerRequestTransferEmail = 'wrongDomain';
  }
  const isAllowedEmailProDomain = isEmpty(values.offerRequestProTransferEmail) || !isEmailProValid
    || validateOfferRequestTransferEmail(values.offerRequestProTransferEmail, props);
  if (!isAllowedEmailProDomain) {
    errors.offerRequestProTransferEmail = 'wrongDomain';
  }
  if (!maxLength(255)(values.greetingFi).valid) {
    errors.greetingFi = 'maxLength';
  }
  if (!maxLength(255)(values.greetingSv).valid) {
    errors.greetingSv = 'maxLength';
  }
  if (!maxLength(255)(values.greetingEn).valid) {
    errors.greetingEn = 'maxLength';
  }
  return errors;
}

const validateOfferRequestTransferEmail = (transferEmail, props) => {
  const parts = transferEmail.split('@');
  return parts.length === 2 && !!parts[1] && props.allowedTransferFeedbackDomains.includes(parts[1]);
}
