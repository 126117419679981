import React, { Component, PropTypes } from 'react';
import Lightbox from 'react-image-lightbox-with-rotate';

import Text from 'containers/Text'
import TextWithIcon from '../kesko/TextWithIcon';
import KeskoIcons from '../Icons/KeskoIcons';

// Selected image index could be in redux state, move it there is you see any reason to.
/*eslint-disable react/no-set-state */

class AttachmentImageGallery extends Component {

  constructor() {
    super();
    this.state = {
      currentImage: 0,
      isLightboxOpen: false
    };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
  }

  openLightbox(index, event) {
    event.preventDefault();
    this.setState({
      currentImage: index,
      isLightboxOpen: true
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      isLightboxOpen: false
    });
  }

  gotoPrevious() {
    const { images } = this.props
    this.setState({
      currentImage: (this.state.currentImage + images.length - 1) % images.length
    });
  }

  gotoNext() {
    const { images } = this.props
    this.setState({
      currentImage: (this.state.currentImage + 1) % images.length
    });
  }

  handleClickImage() {
    if (this.state.currentImage === this.props.images.length - 1) {
      return;
    }
    this.gotoNext();
  }

  handleImageLinkClick = (index) => (event) => {
    this.openLightbox(index, event)
  }

  renderGallery() {
    if (!this.props.images) {
      return;
    }
    const gallery = this.props.images.map((obj, i) => {
      return (
        <div key={obj.src} className="attachment-text truncate">
          <TextWithIcon
            icon={KeskoIcons.paperclip}
            translation={obj.fileName}
            onClick={this.handleImageLinkClick(i)}
          />
          <span className="filesize">{obj.fileSize}</span>
        </div>
      );
    });

    return (
      <div>
        <h4><Text k="feedback.images" /></h4>
        {gallery}
      </div>
    );
  }

  render() {
    const { images } = this.props
    const { currentImage, isLightboxOpen } = this.state;
    return (
      <div className="section">
        {this.renderGallery()}
        { isLightboxOpen && (
          <Lightbox
            mainSrc={images[currentImage].src}
            nextSrc={images[(currentImage + 1) % images.length].src}
            prevSrc={images[(currentImage + images.length - 1) % images.length].src}
            onCloseRequest={this.closeLightbox}
            onMovePrevRequest={this.gotoPrevious}
            onMoveNextRequest={this.gotoNext}
            imageTitle={images[currentImage].caption}
            imageCaption={currentImage + 1 + ' / ' + images.length}
          />
        )}
      </div>
    );
  }
}

AttachmentImageGallery.propTypes = {
  images: PropTypes.array
};

export default AttachmentImageGallery;
