import React, { Component } from 'react'
import classNames from 'classnames'

class BaseButton extends Component {
  render() {
    const {baseClasses,className,bg,color,...otherProps} = this.props;
    const classes = classNames(
      baseClasses,
      className,
      {
        [`bg-${bg}`]: !!bg,
        [`${color}`]: !!color
      }
    );
    return (
      <button {...otherProps} className={classes}>
        {this.props.children}
      </button>
    );
  }
}

BaseButton.defaultProps = {
  baseClasses: ['btn'],
  bg: undefined,
  color: undefined
};

export default BaseButton
