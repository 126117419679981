import React, { PropTypes } from 'react';
import FeedbackDate from './FeedbackDate';
import { MessageAttachments } from 'components/Attachments';
import { TextFormatter } from 'components/Formatting'
import { Message } from 'domain'

const MessageElementTheme = {
  customer: 'customer',
  response: 'response'
};

const MessageElement = ({message, theme}) => (
  <div className={`message-element ${theme}`}>
    <span className="message-content">
      {message.title ? <h4 className="truncate message-title">{message.title}</h4> : ''}
      <TextFormatter className="message-text" text={message.message} />
      <MessageAttachments message={message}/>
    </span>
    <FeedbackDate className="message-date" date={message.created} full={true} />
  </div>
);

MessageElementTheme.defaultProps = {
  theme: 'customer'
};

MessageElement.propTypes = {
  message: PropTypes.shape(Message).isRequired,
  theme: PropTypes.oneOf(Object.keys(MessageElementTheme))
};

MessageElement.theme = MessageElementTheme;

export default MessageElement;
