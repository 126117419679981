import Serializable from './Serializable'

class Receiver extends Serializable {

  constructor(data) {
    super(data, 'Receiver')
  }

  get receiverName() {
    return this._data.get('receiverName')
  }

  get receiverType() {
    return this._data.get('receiverType')
  }

  get totalCount() {
    return this._data.get('totalCount')
  }

  get notAnsweredCount() {
    return this._data.get('notAnsweredCount')
  }

  get newAndNoResponseRequiredCount() {
    return this._data.get('newAndNoResponseRequiredCount')
  }

  get averageResponseTime() {
    return this._data.get('averageResponseTime')
  }

  get averageReactTime() {
    return this._data.get('averageReactTime')
  }

  get averageHandlingTime() {
    return this._data.get('averageHandlingTime')
  }

  get negatives() {
    return this._data.get('negativeCount')
  }

  get neutrals() {
    return this._data.get('neutralCount')
  }

  get positives() {
    return this._data.get('positiveCount')
  }

  get topicIds() {
    return this._data.get('topicIds')
  }

  toJSON() {
    const json = super.toJSON();
    delete json.handler
    return json
  }
}

export default Receiver
