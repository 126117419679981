import React, { Component, PropTypes } from 'react'
import { Link } from 'react-router'
import { List, fromJS } from 'immutable'

import { Icon } from 'components/Icons'
import { defined } from 'utils'
import { getText } from 'containers/Text/utils'
import { ContextType } from 'domain'
import KeskoIcons from '../Icons/KeskoIcons';
import ContextMenu from '../kesko/ContextMenu';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const SelectableRow = ({url, name, label, onClick}) => (
  <Link to={defined(url, 'ContextSelectionHeader>SelectableRow>Link>url')} onClick={onClick} className="selectable-row">
    <span>
      {defined(label + name, 'ContextSelectionHeader>SelectableRow>Link>name')}
    </span>
  </Link>
);

const optionShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
});

class ContextSelectionHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
    this.menuContent = null;
  }

  static contextTypes = {
    translations: PropTypes.instanceOf(Object)
  };

  componentDidMount() {
    this.menuContent = document.querySelector('.header-context-menu .menu-content');
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
    this.closeMenu();
  }

  openMenu = () => {
    if (fromJS(this.props.options).count() > 0) {
      disableBodyScroll(this.menuContent);
      this.setState({menuOpen: true});
    }
  };

  closeMenu = () => {
    enableBodyScroll(this.menuContent);
    this.setState({menuOpen: false});
  };

  clickOption = () => this.closeMenu();

  render() {
    const {options, text, type} = this.props;
    const hasOptions = !fromJS(options).isEmpty()
    const chainName = type ? text : text.split(/\ (.+)?/) [0];
    const storeName = type ? undefined : text.split(/\ (.+)?/) [1];
    const {translations} = this.context;
    const label = (selectionType) => getText(translations, 'feedback.context.' + selectionType, '');
    const chainNameWithLabel = (type === ContextType.allFeedbacks || type === ContextType.secondaryFeedbacks) ? label(type) : (type ? label(type) : '') + chainName;

    const getOptionName = opt => {
      const type = opt.get('type');
      if (type === ContextType.allFeedbacks || type === ContextType.secondaryFeedbacks) {
        return ''
      }
      return opt.get('name')
    };

    const icon = hasOptions ? (
      <Icon
        icon={!this.state.menuOpen ? KeskoIcons.arrowSDown : KeskoIcons.crossSmall}
        size={Icon.sizes.unset}
      />
    ) : null;

    const generateRows = (options) => fromJS(options).map(option => (
      <SelectableRow
        key={option}
        url={option.get('url')}
        name={getOptionName(option)}
        label={label(option.get('type'))}
        onClick={this.clickOption}
      />
    )).toJS();

    const contextMenu = this.state.menuOpen ? (
      <ContextMenu theme={ContextMenu.theme.header} closeMenu={this.closeMenu} className="header-context-menu">
        {generateRows(options)}
      </ContextMenu>
    ) : null;

    return (
      <div className="context-selection-header">
        <div className="header-row" onClick={!this.state.menuOpen ? this.openMenu : this.closeMenu}>
          <h2>
            {`${chainNameWithLabel} ${storeName ? storeName : ''}`}
          </h2>
          {icon}
        </div>
        {contextMenu}
        <div className={`screen-blur ${this.state.menuOpen ? 'visible' : ''}`} />
      </div>
    );
  }
}

ContextSelectionHeader.defaultProps = {
  options: List([])
};

ContextSelectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(optionShape).isRequired,
    PropTypes.instanceOf(List)
  ]).isRequired
};

export default ContextSelectionHeader
