import Serializable from './Serializable'

class FeedbackEvent extends Serializable {

  constructor(data) {
    super(data, 'FeedbackEvent')
    this._mapTimestamp()
  }

  _mapTimestamp() {
    const created = this._data.get('created');
    const date = new Date(Date.parse(created));
    this._data = this._data.set('created', date)
  }

  get type() {
    return this._data.get('type')
  }

  get created() {
    return this._data.get('created')
  }

  get actor() {
    return this._data.get('actor')
  }

  get target() {
    return this._data.get('target')
  }
}

export default FeedbackEvent