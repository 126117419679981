
export const ReceiverFilterType = {
  chainUnit: 'chainUnit',
  businessUnitInclusive: 'businessUnitInclusive',
  businessUnitExclusive: 'businessUnitExclusive',
  businessUnitIds: 'businessUnitIds',
  subject: 'subject'
}

export default ReceiverFilterType
