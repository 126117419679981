import React, { Component, PropTypes } from 'react';
import classNames from 'classnames'

import { Col } from 'components/grid'
import { Icon, KeskoIcons } from 'components/Icons'

class TriangledTitle extends Component {
  render() {
    const {titleElement, rightSideLink, toggled, onClick, className} = this.props

    const iconClasses = classNames(
      'mr1 align-middle trans-d03',
      {'trans-rotZ-m90': !toggled}
    );

    return (
      <div className={`triangled-title ${className ? className : ''}`}>
        <div onClick={onClick}>
          <span className="h3 blue">
            <Icon
              icon={KeskoIcons.arrowSDown}
              className={iconClasses}
            />
            <span className="align-middle">
            {titleElement}
          </span>
          </span>
        </div>

        {rightSideLink && (
          <Col xs="4" className="right">
            {rightSideLink}
          </Col>
        )}
      </div>
    )
  }
}

TriangledTitle.propTypes = {
  titleElement: PropTypes.node.isRequired,
  rightSideLink: PropTypes.node,
  toggled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default TriangledTitle
