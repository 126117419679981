import {fromJS} from 'immutable'
import createReducer from './createReducer'
import {FeedbackReplyActions} from 'actions/FeedbackReplyActions'

const defaultState = fromJS({
  message: '',
  templateIds: [],
  isInitialization: true
});

export const reducer = createReducer(defaultState, {
  [FeedbackReplyActions.SET_REPLY_FORM_VALUES]: (state, {message, templateIds}) =>
    state.set('message', message)
      .set('templateIds', fromJS(templateIds))
      .set('isInitialization', false),

  [FeedbackReplyActions.RESET_FORM]: () => defaultState
})
