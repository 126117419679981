import { OrderedMap } from 'immutable'
import { getChainUnits, getSubjects, getAllowableBusinessUnits } from './GlobalDataActions'
import {
  fetchFacebookData,
  createFacebookReceiver as clientCreateFacebookReceiver,
  removeFacebookReceiver as clientRemoveFacebookReceiver,
  removeFacebookLinkingConsent as clientRemoveFacebookLinkingConsent,
  logoutFromFacebook as clientLogoutFromFacebook,
  fetchStoreSettings,
  saveStoreSettings as clientSaveStoreSettings,
  fetchResponseTemplates,
  saveResponseTemplate as clientSaveResponseTemplate
} from './ClientActions'
import {fetchAllowedTransferFeedbackDomains} from 'actions/ClientActions'

export const SettingsActions = {
  SET: 'Settings/Set',
  START_RECEIVER_SAVING: 'Settings/StartSaving',
  END_RECEIVER_SAVING: 'Settings/StopSaving',
  FACEBOOK_LOGIN: 'Settings/FacebookLogin',
  FACEBOOK_LOGOUT: 'Settings/FacebookLogout',
  START_FACEBOOK_LOGIN_CHECK: 'Settings/StartFacebookLoginCheck',
  END_FACEBOOK_LOGIN_CHECK: 'Settings/EndFacebookLoginCheck',
  START_REMOVING_CONSENT: 'Settings/StartRemovingConsent',
  END_REMOVING_CONSENT: 'Settings/EndRemovingConsent'
}

export const setSettingsData = (name, data) => ({ type: SettingsActions.SET, name, data })

export const startSaving = () => dispatch => new Promise(resolve => {
  dispatch({ type: SettingsActions.START_RECEIVER_SAVING })
  resolve()
})

export const endSaving = () => dispatch => new Promise(resolve => {
  dispatch({ type: SettingsActions.END_RECEIVER_SAVING })
  resolve()
})

export const facebookLogin = () => dispatch => new Promise(resolve => {
  dispatch({ type: SettingsActions.FACEBOOK_LOGIN })
  resolve()
})

export const facebookLogout = () => dispatch => new Promise(resolve => {
  dispatch({ type: SettingsActions.FACEBOOK_LOGOUT })
  resolve()
})

export const startFacebookLoginCheck = () => dispatch => new Promise(resolve => {
  dispatch({ type: SettingsActions.START_FACEBOOK_LOGIN_CHECK })
  resolve()
})

export const endFacebookLoginCheck = () => dispatch => new Promise(resolve => {
  dispatch({ type: SettingsActions.END_FACEBOOK_LOGIN_CHECK })
  resolve()
})

export const initializeSettings = () => dispatch => new Promise((resolve, reject) => {
  Promise.all([
    dispatch(getChainUnits()),
    dispatch(getSubjects()),
    dispatch(getAllowableBusinessUnits()),
    dispatch(getFacebookData()),
    dispatch(fetchResponseTemplates())
  ]).then(resolve, reject)
})

export const getFacebookData = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const facebookUserId = getState().getIn(['settings', 'facebookUserId'])
  const accessToken = getState().getIn(['settings', 'accessToken'])
  dispatch(fetchFacebookData(facebookUserId, accessToken))
    .then(data => {
      dispatch(setSettingsData('facebookReceivers', new OrderedMap(data.linkedReceivers.map(r => [r.id, r]))))
      dispatch(setSettingsData('facebookPages', new OrderedMap(data.availablePages.map(p => [p.id, p]))))
      dispatch(setSettingsData('facebookUserName', data.userName))
    },
    err => {
      reject(err)
    })
})

export const getStoreSettings = (businessUnitId) => (dispatch) => new Promise((resolve, reject) => {
  Promise.all([
    dispatch(fetchStoreSettings(businessUnitId)),
    dispatch(fetchAllowedTransferFeedbackDomains())
  ]).then(
      data => {
        dispatch(setSettingsData('storeSettings', data[0]))
        dispatch(setSettingsData('allowedTransferFeedbackDomains', data[1]));
      },
      err => reject(err)
  )
});

export const saveStoreSettings = (
  businessUnitId,
  showGreeting,
  showRetailerImage,
  greetings,
  offerRequestTransferEmail,
  offerRequestProTransferEmail
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(
      clientSaveStoreSettings(businessUnitId, showGreeting, showRetailerImage, greetings, offerRequestTransferEmail, offerRequestProTransferEmail)
    ).then(
      () => {
        dispatch(getStoreSettings(businessUnitId))
        resolve()
      },
      err => {
        reject(err)
      },
    )
  })
};

export const getFacebookDataForUser = (facebookUserId, accessToken) => dispatch => new Promise((resolve, reject) => {
  dispatch(fetchFacebookData(facebookUserId, accessToken))
    .then(data => {
      dispatch(setSettingsData('facebookReceivers', new OrderedMap(data.linkedReceivers.map(r => [r.id, r]))))
      dispatch(setSettingsData('facebookPages', new OrderedMap(data.availablePages.map(p => [p.id, p]))))
      dispatch(setSettingsData('facebookUserName', data.userName))
    },
    err => {
      reject(err)
    })
})

export const createFacebookReceiver = (chainUnitId, feedbackSubject, storeId, facebookPage) => (dispatch, getState) => new Promise((resolve, reject) => {
  const facebookUserId = getState().getIn(['settings', 'facebookUserId'])
  const accessToken = getState().getIn(['settings', 'accessToken'])
  dispatch(startSaving())
    .then(() => dispatch(clientCreateFacebookReceiver(accessToken, facebookUserId, chainUnitId, feedbackSubject, storeId, facebookPage.id)))
    .then(
      () => {
        dispatch(getFacebookDataForUser(facebookUserId, accessToken))
      },
      err => {
        dispatch(endSaving())
        reject(err)
      }
    ).then(() => dispatch(endSaving()))
})

export const checkFacebookLogin = facebook => (dispatch, getState) => new Promise((resolve, reject) => {
  if (getState().getIn(['settings', 'loggedInFacebook']) === undefined) {
    facebook.getLoginStatus()
      .then(response => {
        if (response.status === 'connected') {
          dispatch(facebookLogin())
          dispatch(setSettingsData('accessToken', response.authResponse.accessToken))
          dispatch(setSettingsData('facebookUserId', response.authResponse.userID))
          dispatch(getFacebookDataForUser(response.authResponse.userID, response.authResponse.accessToken))
        }
        dispatch(endFacebookLoginCheck())
      },
      err => {
        dispatch(endFacebookLoginCheck())
        reject(err)
      })
  }
})

export const facebookLoginSuccess = response => dispatch => new Promise((resolve, reject) => {
  dispatch(facebookLogin())
  dispatch(setSettingsData('accessToken', response.tokenDetail.accessToken))
  dispatch(setSettingsData('facebookUserId', response.tokenDetail.userID))
  dispatch(getFacebookDataForUser(response.tokenDetail.userID, response.tokenDetail.accessToken)).then(resolve, reject)
})

export const removeFacebookReceiver = receiver => dispatch => new Promise((resolve, reject) => {
  dispatch(clientRemoveFacebookReceiver(receiver.pageId))
    .then(() => {
      dispatch(getFacebookData())
    },
    err => {
      reject(err)
    })
})

export const removeFacebookLinkingConsent = () => dispatch => new Promise((resolve, reject) => {
  dispatch(startRemovingConsent())
    .then(() => dispatch(clientRemoveFacebookLinkingConsent()))
    .then(
      () => {
        dispatch(getFacebookData())
      },
      err => {
        dispatch(endRemovingConsent())
        reject(err)
      }
    ).then(() => dispatch(endRemovingConsent()))
})

export const startRemovingConsent = () => dispatch => new Promise(resolve => {
  dispatch({ type: SettingsActions.START_REMOVING_CONSENT })
  resolve()
})

export const endRemovingConsent = () => dispatch => new Promise(resolve => {
  dispatch({ type: SettingsActions.END_REMOVING_CONSENT })
  resolve()
})

export const logoutFromFacebook = () => (dispatch, getState) => new Promise((resolve, reject) => {
  dispatch(startFacebookLoginCheck())
  const facebookUserId = getState().getIn(['settings', 'facebookUserId'])
  const accessToken = getState().getIn(['settings', 'accessToken'])
  dispatch(clientLogoutFromFacebook(facebookUserId, accessToken))
    .then(() => {
      dispatch(getFacebookDataForUser(null, null))
      dispatch(facebookLogout())
      dispatch(endFacebookLoginCheck())
    },
    err => {
      reject(err)
    })
})

export const saveResponseTemplate = (id, name, template) => dispatch =>
  dispatch(clientSaveResponseTemplate(id, name, template))
    .then(
        () => dispatch(fetchResponseTemplates()),
        () => Promise.reject({ _error: 'template.saveFailed' })
    );
