
import React, { Component } from 'react';

import * as ClientActions from 'actions/ClientActions'
import * as CollapseActions from 'actions/CollapseActions'
import * as DialogActions from 'actions/DialogActions'
import * as FeedbackActions from 'actions/FeedbackActions'
import * as LoginActions from 'actions/LoginActions'

class NoOpTools extends Component {
  render() {
    return <span style={{display: 'none'}}/>
  }
}

const finalCreateDevTools = () => {
  // Disable inclusion of devtools in dist
  if (process.env.NODE_ENV !== 'production') {
    const { createDevTools } = require('redux-devtools');
    const LogMonitor = require('redux-devtools-log-monitor').default;
    const MultipleMonitors = require('redux-devtools-multiple-monitors').default
    const DockMonitor = require('redux-devtools-dock-monitor').default;
    const Dispatcher = require('redux-devtools-dispatch').default

    const actionCreators = {
      ...CollapseActions,
      ...ClientActions,
      ...FeedbackActions,
      ...DialogActions,
      ...LoginActions
    }

    return createDevTools(
      <DockMonitor toggleVisibilityKey='ctrl-j'
                   changePositionKey='ctrl-q'
                   defaultIsVisible={false}>
        <MultipleMonitors>
          <LogMonitor />
          <Dispatcher actionCreators={actionCreators} />
        </MultipleMonitors>
      </DockMonitor>
    );
  } else {
    return NoOpTools
  }
}

export default finalCreateDevTools()
