import { info } from 'utils/Log'
import { push } from 'react-router-redux'
import { RouteUtil } from 'routes'
import { searchFeedbacks } from 'actions/ClientActions'

export const SearchActions = {
  SET_RESULTS: 'Search/SetResults',
  CLEAR_RESULTS: 'Search/ClearResults',

  SET_QUERY: 'Search/SetQuery',
  CLEAR_QUERY: 'Search/ClearQuery'
}

export const setSearchResults = feedbacks => ({
  type: SearchActions.SET_RESULTS,
  feedbacks
})

export const clearSearchResults = () => ({
  type: SearchActions.CLEAR_RESULTS
})

export const setSearchQuery = query => ({
  type: SearchActions.SET_QUERY,
  query
})

export const clearSearchQuery = () => ({
  type: SearchActions.CLEAR_QUERY
})

export const search = (searchString, filter) => dispatch => new Promise((resolve, reject) => {
  info('Searching with string', searchString)
  dispatch(clearSearchResults())
  dispatch(clearSearchQuery())
  dispatch(setSearchQuery({ search: searchString, exclusiveSearchField: filter }))
  dispatch(push(RouteUtil.search(searchString, filter)))
  dispatch(searchFeedbacks(searchString, filter)).then(
    response => {
      dispatch(setSearchResults(response.feedbackPreviews))
      resolve(response.feedbackPreviews)
    },
    reject
  )
})

export const searchAndRedirect = (searchString) => (dispatch, getState) => {
  const filter = getState().getIn(['searchFilter', 'activeFilter'])
  return dispatch(search(searchString, filter)).then(() => dispatch(push(RouteUtil.search(searchString, filter))))
}
