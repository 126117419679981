import React, { Component, PropTypes } from 'react';

import { getText } from './utils'

/**
 * Renders a translated text based on translations stored in redux state
 * and the key. Requires <TextProvider /> supply the context
 * <p>
 * Key can be supplied as dot notation or array, i.e., the following two
 * are equivalent:
 *
 * <Text k="foo.bar" />
 * <Text k={['foo','bar']} />
 */
class Text extends Component {
  render() {
    let text = getText(this.context.translations, this.props.k);

    if (this.props.additions && this.props.additions.length !== 0) {
      this.props.additions.forEach((a, i) => {
        text = text.replace(`{${i}}`, a);
      });
    }

    return (<span {...this.props}>{text}</span>);
  }
}

Text.contextTypes = {
  translations: PropTypes.object.isRequired
};

Text.propTypes = {
  k: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.any)
  ]).isRequired,
  additions: PropTypes.arrayOf(PropTypes.string)
};

export default Text
