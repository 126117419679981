import React, { Component, PropTypes} from 'react'
import { findDOMNode } from 'react-dom'
import { trace } from 'utils/Log'
import { debounce, window } from 'utils'

export const localStoragePrefix = 'Kesko-Hymy-SaveToLocalStorage-'

const SaveToLocalStorage = OriginalComponent => class extends Component {

  static defaultProps = {
    debounceDelay: 1000
  };
  static propTypes = {
    debounceDelay: PropTypes.number.isRequired,
    localStorageKey: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number
    ]).isRequired,
    initValue: PropTypes.string
  };

  constructor(props) {
    super(props)
    this.saveToLocalStorage = debounce(this.saveToLocalStorage.bind(this), props.debounceDelay)
    this.clear = this.clear.bind(this)
  }

  getLocalStorageKey() {
    return localStoragePrefix + this.props.localStorageKey
  }

  saveToLocalStorage(event) {
    if (window.localStorage) {
      const value = event.target.value;
      trace('Saving to local storage', this.getLocalStorageKey(), value)
      window.localStorage.setItem(this.getLocalStorageKey(), value)
    }
  }

  componentDidMount() {
    var value
    if (window.localStorage) {
      value = window.localStorage.getItem(this.getLocalStorageKey())
    }
    if (!value) {
      value = this.props.initValue
    }
    const domNode = findDOMNode(this);
    if (value) {
      domNode.value = value
      const event = document.createEvent('HTMLEvents')
      event.initEvent('input', true, false)
      domNode.dispatchEvent(event)
    }
    if (window.localStorage) {
      domNode.addEventListener('input', this.saveToLocalStorage)
    }
  }

  clear() {
    if (window.localStorage) {
      window.localStorage.removeItem(this.getLocalStorageKey())
      this.saveToLocalStorage.cancel()
    }
  }

  componentWillUnmount() {
    findDOMNode(this).removeEventListener('input', this.saveToLocalStorage)
  }

  render() {
    return (<OriginalComponent {...this.props}>{this.props.children}</OriginalComponent>)
  }

}

export default SaveToLocalStorage
