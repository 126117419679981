import { warn } from 'utils/Log'

export const asText = (text, key) => {
  if (typeof text !== 'string') {
    warn(`Got a non-string as <Text /> for key '${key}': ${text}`)
    return '[Object]'
  }
  return text;
}

export const getText = (translations, key) => {
  const keys = Array.isArray(key) ? key : key.split('.')
  const text = translations.getIn(keys, keys.join('.'))
  return asText(text, key)
}
