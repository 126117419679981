import React, { PropTypes } from 'react';
import { findDOMNode } from 'react/lib/ReactDOM';

const ContextMenuTheme = {
  light: 'light',
  header: 'header'
};

export default class ContextMenu extends React.Component {
  componentDidMount() {
    document.body.addEventListener('click', this.clickOnBody);
    document.body.addEventListener('touchend', this.clickOnBody);
    findDOMNode(this).addEventListener('click', this.stopPropagation);
    findDOMNode(this).addEventListener('touchend', this.stopPropagation);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.clickOnBody);
    document.body.removeEventListener('touchend', this.clickOnBody);
    findDOMNode(this).removeEventListener('click', this.stopPropagation);
    findDOMNode(this).removeEventListener('touchend', this.stopPropagation);
  }

  clickOnBody = (event) => {
    const clickInsideMenu = this.contextMenu.contains(event.target);
    if (!clickInsideMenu) {
      event.stopPropagation();
      event.preventDefault();
      this.props.closeMenu();
    }
  };

  /* eslint-disable react/jsx-no-bind */
  render() {
    const {theme, className} = this.props;
    return (
      <div className={`context-menu ${theme} ${className ? className : ''}`} ref={menu => this.contextMenu = menu}>
        <div className="menu-content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

ContextMenu.defaultProps = {
  theme: 'light',
  closeMenu: () => null
};

ContextMenu.propTypes = {
  theme: PropTypes.oneOf(Object.keys(ContextMenuTheme)),
  closeMenu: PropTypes.func,
  className: PropTypes.string
};

ContextMenu.theme = ContextMenuTheme;
