import { fromJS } from 'immutable'

import { ContextActions } from 'actions/ContextActions'
import { Context, ContextType } from 'domain'
import { UriHandler } from 'utils'
import { RouteUtil } from 'routes'

import createReducer from './createReducer'

export const defaultContext = new Context({
  type: ContextType.default,
  url: new UriHandler(RouteUtil.home())
})

export const reducer = createReducer(defaultContext, {
  [ContextActions.SET]: (state, { context }) => fromJS(context),

  [ContextActions.CLEAR]: () => defaultContext
})
