import React, { PropTypes } from 'react'
import PureComponent from 'react-pure-render/component';
import { RelativeLink } from 'components/Routing'
import { Feedback } from 'domain'
import { RouteUtil } from 'routes'

import StatusAndDateHeader from '../StatusAndDateHeader'
import FeedbackStatusInfo from '../FeedbackStatusInfo'
import FeedbackCustomerInfo from '../FeedbackCustomerInfo'

class FeedbackListItem extends PureComponent {

  static propTypes = {
    feedback: PropTypes.instanceOf(Feedback).isRequired,
    showReceiver: PropTypes.bool.isRequired,
    showModified: PropTypes.bool
  };

  static defaultProps = {
    showReceiver: false
  };

  navigateToSingleFeedback = () => {
    window.history.replaceState(window.history.state, 'Hymy', '#allFeedbacks?scrollTo=' + this.props.feedback.id);
  };

  render() {
    const {feedback, showReceiver, showModified} = this.props;
    const timestamp = showModified ? feedback.modified : feedback.created;
    return (
      <RelativeLink to={RouteUtil.feedback(feedback.id)} onClick={this.navigateToSingleFeedback} className="feedback-list-item">
        <StatusAndDateHeader status={feedback.status} date={timestamp} showChevron={true} />

        <div className="feedback-content">
          <div className="info-block">
            <FeedbackStatusInfo feedback={feedback} />
            <FeedbackCustomerInfo shortForm={true} customer={feedback.customer} />
            {showReceiver && (<span className="feedback-receiver">{feedback.receiverName}</span>)}
          </div>

          <div className="feedback-block">
            {feedback.title && (<h4 className="mt0 mb1 truncate black">{feedback.title}</h4>)}
            {feedback.preview && (<span className="feedback-preview silver break-word">{feedback.preview}</span>)}
          </div>
        </div>
      </RelativeLink>
    )
  }
}

export default FeedbackListItem
