import { isEmpty, email as emailValidator, phoneNumberRegex } from 'utils/Validation'
import { trimStringsInMap } from 'utils/Helpers';

export const validate = (values) => {
  values = trimStringsInMap(values)
  const errors = {}
  const isEmailValid = emailValidator()(values.email).valid
  const isEmailEmpty = isEmpty(values.email)
  const isNameEmpty = isEmpty(values.name)
  const isPhoneEmpty = isEmpty(values.phone);
  const isPhoneValid = phoneNumberRegex.test(values.phone);

  if (!isEmailEmpty && !isEmailValid) {
    errors.email = 'email'
  }

  if (!isEmailEmpty && isNameEmpty) {
    errors.name = 'required'
  }

  if (!isPhoneEmpty && !isPhoneValid) {
    errors.phone = 'format';
  }

  return errors
};

export default validate
