import { fromJS } from 'immutable'
import createReducer from './createReducer'
import { I18nActions } from 'actions/I18nActions'

const defaultState = fromJS({
  locale: 'fi',
  translations: {
    fi: require('data/translations/translations_fi'),
    sv: require('data/translations/translations_sv'),
    en: {}
  }
})

export const reducer = createReducer(defaultState, {
  [I18nActions.CHANGE_LOCALE]: (state, { locale }) => state.set('locale', locale)
})
