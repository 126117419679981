import React from 'react'
import BaseButton from './BaseButton'

class SecondaryButton extends BaseButton {
  render() {
    return (
      <BaseButton {...this.props}/>
    );
  }
}

SecondaryButton.defaultProps = {
  baseClasses: ['btn', 'btn-primary', 'extra-bold', 'fill', 'px1'],
  bg: 'lightblue',
  color: 'blue'
};

export default SecondaryButton
