const Company = {
  KESKO: 'kesko',
  KRAUTA: 'krauta',
  KPLUSSA: 'kplussa',
  INTERSPORT: 'intersport',
  BUDGETSPORT: 'budgetsport',
  KAUTO: 'kauto',
  ONNINEN: 'onninen'
}

export default Company;
