
export const CollapseActions = {
  REGISTER: 'Collapse/Register',
  DEREGISTER: 'Collapse/Deregister',
  OPEN: 'Collapse/Open',
  CLOSE: 'Collapse/Close',
  TOGGLE: 'Collapse/Toggle'
}

/**
 * Register a new collapsing container with an initial state.
 * @param collapseId the id of the container
 * @param state initial state
 */
export const register = (collapseId, state) => ({
  type: CollapseActions.REGISTER,
  collapseId,
  state
})

/**
 * Remove the collapsing container data from state
 * @param collapseId the id of the container
 */
export const deregister = (collapseId) => ({
  type: CollapseActions.DEREGISTER,
  collapseId
})

/**
 * Toggle the state of the collapsing container
 * @param collapseId the id of the container
 */
export const toggle = collapseId => ({
  type: CollapseActions.TOGGLE,
  collapseId
})

/**
 * Force open the collapsing container
 * @param collapseId the id of the container
 */
export const open = collapseId => ({
  type: CollapseActions.OPEN,
  collapseId
})

/**
 * Force close the collapsing container
 * @param collapseId the id of the container
 */
export const close = collapseId => ({
  type: CollapseActions.CLOSE,
  collapseId
})
