import React, { Component } from 'react'

import { Selection } from 'components/Forms'
import { Text } from 'containers/Text'

import { Company, ReceiverType } from 'domain'
import {connect} from 'react-redux'

const possibleReceivers = Object.keys(ReceiverType)
const receiverMapper = option => ({
  k: ['receiverFilter', 'receiverTypes', option],
  id: option,
  value: option
})

class TopLevelReceiverSelection extends Component {

  handleChange = (receiver, onChange) => (event) => {
    onChange(event)
    receiver.onChange(event)
  }

  filterReceivers = () => {
    const { authorizedCompanies } = this.props
    return possibleReceivers.filter(receiver => {
      if (Object.values(Company).includes(receiver)) {
        return authorizedCompanies.contains(receiver) && authorizedCompanies.size > 1
      } else if (receiver === ReceiverType.store || receiver === ReceiverType.chain || receiver === ReceiverType.chainAndStore) {
        return !authorizedCompanies.filterNot(c => c === Company.KPLUSSA).isEmpty()
      } else if (receiver === ReceiverType.subject) {
        return !authorizedCompanies.filterNot(c => c === Company.INTERSPORT).isEmpty()
      } else if (receiver === ReceiverType.subject) {
        return !authorizedCompanies.filterNot(c => c === Company.BUDGETSPORT).isEmpty()
      }
      return true
    })
  }

  render() {
    const {receiver, submitting, onChange, notSelectedLabel} = this.props
    return (
      <div className="top-level-receiver-selection">
        <label htmlFor="feedback-receiver">
          <Text k="reporting.form.fields.receiver.label" />
        </label>
        <Selection
          id="feedback-receiver"
          disabled={submitting}
          options={this.filterReceivers()}
          optionMapper={receiverMapper}
          showNotSelected={true}
          translate={{notSelectedLabel}}
          {...receiver}
          onChange={this.handleChange(receiver, onChange)}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    authorizedCompanies: state.getIn(['client', 'authorizedCompanies'])
  })
)
(TopLevelReceiverSelection);
