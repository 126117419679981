import React, { PropTypes } from 'react';
import { TranslateProps } from 'containers/Text'
import {Icon, KeskoIcons} from '../../Icons';
import FilterableDropdownItem from './FilterableDropdownItem';

class FilterableDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      suggestionsOpen: false,
      highlightedSuggestion: 0,
      filteredItems: []
    };

    this.inputField = null;

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onSuggestionClick = this.onSuggestionClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.focusOnInput = this.focusOnInput.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.initialValue !== this.props.initialValue) {
      this.setState({input: newProps.initialValue});
    }
  }

  onInputChange(event) {
    const input = event.currentTarget.value;
    this.setState({input, filteredItems: this.filterItems(input)});
  }

  onInputFocus() {
    this.setState({suggestionsOpen: true, highlightedSuggestion: 0});
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  onInputBlur() {
    setTimeout(() => { // 0ms timeout moves this to the bottom of the callstack, so that it happens after click event.
      this.setState({suggestionsOpen: false});
    }, 0);
    if (this.props.onBlur) {
      this.props.onBlur(this.state.input);
    }
  }

  focusOnInput() {
    if (this.state.suggestionsOpen) {
      return;
    }
    this.inputField.focus();
  }

  onSuggestionClick(suggestion) {
    this.setState({input: suggestion});
  }

  incrementHighlight() {
    const nextValue = this.state.highlightedSuggestion + 1;
    if (nextValue < this.state.filteredItems.length) {
      this.setState({highlightedSuggestion: nextValue});
    }
  }

  decrementHighlight() {
    const nextValue = this.state.highlightedSuggestion - 1;
    if (nextValue > -1) {
      this.setState({highlightedSuggestion: nextValue});
    }
  }

  filterItems(input) {
    if (input === '') {
      return [];
    }
    return this.props.items.filter(item => item.includes(input));
  }

  handleKeyPress(event) {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        this.incrementHighlight();
        return;
      case 'ArrowUp':
        event.preventDefault();
        this.decrementHighlight();
        return;
      case 'Enter':
        event.preventDefault();
        this.setState({input: this.state.filteredItems[this.state.highlightedSuggestion]}, () => this.inputField.blur());
        return;
    }
  }

  suggestionList = () => {

    const itemSet = this.state.input === '' ? this.props.items : this.state.filteredItems;

    if (!this.state.suggestionsOpen || !itemSet.length) {
      return null;
    }

    const generateList = () => itemSet.map((item, index) => (
      <FilterableDropdownItem
        text={item}
        highlighted={this.state.highlightedSuggestion === index}
        onClick={this.onSuggestionClick}
        key={`suggestion-item-${index}`}
      />
    ));

    return (
      <div className="suggestion-list">
        {generateList()}
      </div>
    )
  };

  /* eslint-disable react/jsx-no-bind */
  render() {
    const {className, disabled, placeholder, name, isValid} = this.props;
    return (
      <div className={`dropdown filterable-dropdown test-filterable-dropdown ${className ? className : ''}`}>
        <div className={`input-container ${isValid ? '' : 'invalid-value' }`}>
          <input
            value={this.state.input}
            type={this.props.type}
            disabled={disabled}
            placeholder={placeholder}
            autoComplete="new-password" // Forces browser auto-suggest off
            onChange={this.onInputChange}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            onKeyDown={this.handleKeyPress}
            className="test-filterable-dropdown-input"
            name={name}
            ref={input => this.inputField = input}
          />
          <Icon
            icon={KeskoIcons.arrowSDown}
            size={Icon.sizes.unset}
            onClick={this.focusOnInput}
          />
        </div>
        {this.suggestionList()}
      </div>
    );
  }
}

FilterableDropdown.defaultProps = {
  type: 'text',
  isValid: true
};

FilterableDropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  initialValue: PropTypes.string,
  isValid: PropTypes.bool
};

export default TranslateProps(FilterableDropdown);
