
const MessageType = {
  feedback: 'feedback',
  response: 'response',
  comment: 'comment',
  commentRequest: 'commentRequest',
  commentResponse: 'commentResponse',
  followUp: 'followUp',
  transferComment: 'transferComment',
  forwardComment: 'forwardComment',
  responseComment: 'responseComment',
  error: 'error'
}

export default MessageType
