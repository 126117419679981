import React, {Component, PropTypes} from 'react'

import {Col} from 'components/grid'
import {Navigator} from 'components/Menu'
import FeedbackReplyForm from './FeedbackReplyForm'
import FeedbackCollapsingSections from '../FeedbackCollapsingSections'
import {fixLineChangesForReplyMessage} from 'utils/fixLineChanges'
import {hasItemsInList} from 'utils'
import {List} from 'immutable'

class FeedbackReplyView extends Component {

  static propTypes = {
    isInitialization: PropTypes.bool,
    initialMessage: PropTypes.string,
    initialTemplateIdss: PropTypes.instanceOf(List),
    actions: PropTypes.shape({
      cancel: PropTypes.func.isRequired,
      sendReplyAndMarkDone: PropTypes.func.isRequired,
      sendReplyAndMarkWaiting: PropTypes.func.isRequired,
      saveDraft: PropTypes.func.isRequired,
      init: PropTypes.func.isRequired,
      cleanup: PropTypes.func.isRequired,
      uploadAttachment: PropTypes.func.isRequired,
      addAttachment: PropTypes.func.isRequired,
      updateAttachment: PropTypes.func.isRequired,
      removeAttachment: PropTypes.func.isRequired,
      removeDraftAttachment: PropTypes.func.isRequired,
      setFormValues: PropTypes.func.isRequired
    }).isRequired
  };

  componentDidMount() {
    this.props.actions.init()
  }

  componentWillUnmount() {
    this.props.actions.cleanup()
  }

  render() {
    const {feedback, attachments, draftAttachments, proposeResponses, actions} = this.props;
    const showResponseProposals = this.getShowResponseProposals(proposeResponses, feedback);
    const initialValues = this.getInitialValues();
    return (
      <section className="feedback-reply-view">
        <Navigator backUrl=".." />
        <Col>
          <hr />
        </Col>
        <FeedbackCollapsingSections
          feedback={feedback}
          messagesVisible
        />
        <Col>
          <hr />
        </Col>
        <FeedbackReplyForm
          feedback={feedback}
          attachments={attachments}
          draftAttachments={draftAttachments}
          localStorageKey={feedback.id}
          actions={actions}
          initialValues={initialValues}
          showResponseProposals={showResponseProposals}
        />
      </section>
    )
  }

  getInitialValues = () => {
    const {
      isInitialization, initialMessage, initialTemplateIds, feedback, draftAttachments
    } = this.props;
    const firstTemplate = this.getFirstTemplate(feedback);
    const draftText = feedback.responseDraft && feedback.responseDraft.messageText;

    const message = this.getMessage(isInitialization, initialMessage, firstTemplate, draftText, feedback);
    const templateIds = this.getTemplateIds(isInitialization, initialTemplateIds, firstTemplate, draftText);
    const attachmentIds = this.getIdsFromDraftAttachments(draftAttachments);

    return {message, templateIds, attachmentIds};
  }

  getFirstTemplate = ({allResponseTemplates}) => {
    return hasItemsInList(allResponseTemplates) ? allResponseTemplates.get(0) : null;
  }

  getShowResponseProposals = (proposeResponses, feedback) => {
    return proposeResponses
      && hasItemsInList(feedback.allResponseTemplates)
      && feedback.allResponseTemplates.some(t => t.isProposal)
  }

  getMessage(isInitialization, initialMessage, firstTemplate, draftText, {greeting, signature}) {
    if (!isInitialization) {
      return initialMessage;
    } else if (draftText) {
      return draftText;
    } else if (firstTemplate) {
      return fixLineChangesForReplyMessage(greeting, [firstTemplate], signature);
    }
    return '';
  }

  getIdsFromDraftAttachments = (draftAttachments) => {
    return draftAttachments ? draftAttachments.map(attachment => attachment.id).toArray() : [];
  }

  getTemplateIds = (isInitialization, initialTemplateIds, firstTemplate, draftText) => {
    if (!isInitialization) {
      return hasItemsInList(initialTemplateIds) ? initialTemplateIds.toArray() : [];
    } else if (!draftText && firstTemplate) {
      return [firstTemplate.toJSON().id];
    }
    return [];
  }
}

export default FeedbackReplyView
