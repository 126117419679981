/* global jsConfig */
import 'isomorphic-fetch'
import {
  BusinessUnit,
  ChainUnit,
  Channel,
  ExternalSystem,
  FacebookData,
  Feedback,
  Handler,
  Subject,
  Topic,
  Receiver,
  RespondingUser,
  ResponseTemplate
} from 'domain'
import { createUuid, merge } from 'utils'
import { trace, warn } from 'utils/Log'
import { UriHandler } from 'utils'
import Session from './Session'
import StoreSettings from '../domain/StoreSettings'

const restRoot = jsConfig.restRoot
const attachmentRoot = jsConfig.attachmentRoot
const attachmentApiSubscriptionKey = jsConfig.attachmentApiSubscriptionKey

const verifyStatus = response => {
  if (response.status >= 200 && response.status <= 300) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error
  }
}

const authTokenName = 'X-Token'
const baseHeaders = {
  Accept: 'application/json'
}
const baseConfig = {
  credentials: 'include'
}
const configGET = () => ({
  ...baseConfig,
  method: 'get',
  headers: {
    ...baseHeaders
  }
})
const configPUT = () => ({
  ...baseConfig,
  method: 'put',
  headers: {
    ...baseHeaders,
    'Content-Type': 'application/json'
  }
})
const configPOST = () => ({
  ...configPUT(),
  method: 'post'
})
const configDELETE = () => ({
  ...baseConfig,
  method: 'delete',
  headers: {
    ...baseHeaders
  }
})

const baseUri = () => new UriHandler(restRoot)
const baseAttachmentUri = () => new UriHandler(attachmentRoot)

class Client {

  //
  // INTERNALS
  //

  addAuthorization(config) {
    const token = Session.get().token
    if (!token) {
      warn('Client: No authorization set when required')
    }
    trace('Client: add authorization', authTokenName, this.authorization)
    config.headers[authTokenName] = token
    return config
  }

  static addBody(config, body) {
    if (body) {
      return Object.assign({ body: JSON.stringify(body) }, config)
    }
    return config
  }

  request({ url, body, config, includeAuthHeader = true, convertToJson = true }) {
    const conf = Client.addBody(config, body)
    return global.fetch(
      url.toString(),
      includeAuthHeader ? this.addAuthorization(conf) : conf
    ).then(verifyStatus, verifyStatus).then(response => {
      if (convertToJson) {
        return response.json()
      }
      return response;
    })
  }

  getJson(url, more = {}) {
    return this.request({ url, ...more, config: configGET() })
  }

  putJson(url, body, more = {}) {
    return this.request({ url, body, ...more, config: configPUT() })
  }

  postJson(url, body, more = {}) {
    return this.request({ url, body, ...more, config: configPOST() })
  }

  deleteJson(url, more = {}) {
    return this.request({ url, ...more, config: configDELETE() })
  }

  //
  // ACTUAL CLIENT METHODS
  //

  getBusinessUnit({ businessUnitId }) {
    return this.getJson(baseUri().segment('businessUnit').segment(businessUnitId))
      .then(json => new BusinessUnit(json))
  }

  getFeedbackPreviews(filter) {
    return this.getJson(
      baseUri().segment('feedback').search({
        maxResults: 30,
        ...filter
      })
    ).then(json => ({
      hasMore: json.hasMore,
      feedbackPreviews: json.feedbacks.map(item => new Feedback(item))
    }))
  }

  getSingleFeedback({ feedbackId }) {
    return this.getJson(baseUri().segment('feedback').segment(feedbackId))
      .then(json => new Feedback(json))
  }

  getExportMessages({ feedbackId }) {
    return this.getJson(baseUri().segment('feedback').segment(feedbackId).segment('exportMessages'))
      .then(({ downloadLink }) => downloadLink)
  }

  getSearchFeedbacks({ searchString, exclusiveSearchField }) {
    return this.getJson(baseUri().segment('feedback').segment('search').segment(searchString).addQuery({ maxResults: 50, exclusiveSearchField }))
      .then(json => ({
        hasMore: json.hasMore,
        feedbackPreviews: json.feedbacks.map(item => new Feedback(item))
      }))
  }

  getExternalSystems() {
    return this.getJson(baseUri().segment('externalSystems'))
      .then(json => json.map(item => new ExternalSystem(item)))
  }

  getFeedbackChannels() {
    return this.getJson(baseUri().segment('channel'))
      .then(json => json.map(item => new Channel(item)))
  }

  getSubjects() {
    return this.getJson(baseUri().segment('subject'))
      .then(json => json.map(item => new Subject(item)))
  }

  getTopics() {
    return this.getJson(baseUri().segment('topics'))
      .then(json => json.map(item => new Topic(item)))
  }

  getBusinessUnits() {
    return this.getJson(baseUri().segment('businessUnit'))
      .then(json => json.map(item => new BusinessUnit(item)))
  }

  getChainUnits() {
    return this.getJson(baseUri().segment('chainUnit'))
      .then(json => json.map(item => new ChainUnit(item)))
  }

  getCompanyHandlers({ companyId }) {
    return this.getJson(baseUri().segment('handlers').segment('company').segment(companyId))
      .then(json => json.map(item => new Handler(item)))
  }

  getReportingHandlers() {
    return this.getJson(baseUri().segment('handlers').segment('reporting'))
      .then(json => json.map(item => new Handler(item)))
  }

  getRespondingUsers({ feedbackId }) {
    return this.getJson(baseUri().segment('feedback').segment(feedbackId).segment('respondingUsers'))
      .then(json => json.map(item => new RespondingUser(item)))
  }

  getTransferFeedbackAddresses() {
    return this.getJson(baseUri().segment('user').segment('transferFeedbackAddresses'))
      .then(json => json.map(item => item))
  }

  getResponseTemplates() {
    return this.getJson(baseUri().segment('templates').segment('responseProposals'))
      .then(json => json.map(item => new ResponseTemplate(item)))
  }

  putResponseTemplate({ id, name, template }) {
    return this.putJson(
      baseUri().segment('templates').segment(id),
      { name, template }
    )
  }

  startResponding({ feedbackId, feedbackVersion }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('startResponding'),
      { feedbackVersion }
    )
  }

  stopResponding({ feedbackId, feedbackVersion }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('stopResponding'),
      { feedbackVersion }
    )
  }

  setCustomerInformationAsObjected({ feedbackId, comment }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('setCustomerInformationAsObjected'),
      { comment }
    )
  }

  /**
   * Saves a new comment for target feedbackId
   * @param feedbackId the id of the comment
   * @param commentText the text of the comment
   * @param attachmentIds UUIDs of comment attachments
   * @param externalMessageId to ensure idempotency
   */
  putComment({ feedbackId, commentText, attachmentIds, externalMessageId = createUuid() }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('comment'),
      { commentText, attachmentIds, externalMessageId }
    )
  }

  /**
   * Update feedback status to handled
   * @param feedbackId
   * @param feedbackVersion
   */
  markFeedbackDone({ feedbackId, feedbackVersion }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('markHandled'),
      { feedbackVersion }
    )
  }

  /**
   * Reopen feedback
   * @param feedbackId
   * @param feedbackVersion
   */
  reopenFeedback({ feedbackId, feedbackVersion }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('reopen'),
      { feedbackVersion }
    )
  }

  /**
   * Mark feedback read
   * @param feedbackId
   * @param feedbackVersion
   * @returns {*}
   */
  markFeedbackRead({ feedbackId, feedbackVersion }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('markRead'),
      { feedbackVersion }
    )
  }

  reportAmbianceError({ feedbackId, feedbackVersion }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('reportAmbianceError'),
      { feedbackVersion }
    )
  }

  reportTopicError({ feedbackId, feedbackVersion }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('reportTopicError'),
      { feedbackVersion }
    )
  }

  reportThreat({ feedbackId, feedbackVersion }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('reportThreat'),
      { feedbackVersion }
    )
  }

  updateFeedback({ feedbackId, status, comment, feedbackVersion }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId),
      { status, comment, feedbackVersion }
    )
  }

  removeMessage({ feedbackId, messageId, feedbackVersion }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('message').segment(messageId).segment('remove'),
      { feedbackVersion }
    )
  }

  putFeedbackHandler({ feedbackId, feedbackVersion, userId, comment }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('handler'),
      { feedbackVersion, userId, comment }
    )
  }

  putResponseDraft({ feedbackId, feedbackVersion, responseText, attachmentIds }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('responseDraft'),
      { feedbackVersion, responseText, attachmentIds }
    )
  }

  putResponse({ feedbackId, feedbackVersion, responseText, attachmentIds, markAsHandled, contactChannel, templateIds, externalMessageId = createUuid()}) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('response'),
      { feedbackVersion, responseText, attachmentIds, markAsHandled, contactChannel, externalMessageId, templateIds }
    )
  }

  putResponseComment({ feedbackId, commentText, markAsHandled, externalMessageId = createUuid() }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('responseComment'),
      { commentText, markAsHandled, externalMessageId }
    )
  }

  putCommentRequest({ feedbackId, commentRequestText, emails, language, externalMessageId = createUuid() }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('commentRequest'),
      { commentRequestText, emails, externalMessageId, language }
    )
  }

  transferFeedback({ feedbackId, feedbackVersion, commentText, targetType, targetStore, targetHandler, targetChain, targetOther, targetEmail, targetSubject, language, externalMessageId = createUuid() }) {
    const chainId = targetChain ? targetChain.id : undefined
    const subjectId = targetSubject ? targetSubject.id : undefined
    const extSysId = targetOther ? targetOther.id : undefined
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('transfer'),
      { feedbackVersion, commentText, targetType, targetStore, targetHandler, targetChain: chainId, targetOther: extSysId, email: targetEmail, targetSubject: subjectId, language, externalMessageId }
    )
  }

  forwardFeedback({ feedbackId, comment, emails, language, externalMessageId = createUuid() }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('forward'),
      { comment, emails, language, externalMessageId }
    )
  }

  changeFeedbackContact({ feedbackId, feedbackVersion, name, phone, email }) {
    return this.putJson(
      baseUri().segment('feedback').segment(feedbackId).segment('customer'),
      { feedbackVersion, name, phone, email }
    )
  }

  postForReport({ filter }) {
    return this.postJson(
      baseUri().segment('reports').segment('feedbackList'),
      filter
    ).then(({ feedbacks, ...rest }) => {
      return {
        ...rest,
        feedbacks: feedbacks.map(fb => new Feedback(fb))
      }
    })
  }

  putForReportExport({ filter }) {
    return this.putJson(
      baseUri().segment('reports').segment('feedbackList').segment('export'),
      filter
    ).then(({ downloadLink }) => downloadLink)
  }

  putForMessagesReport({ filter }) {
    return this.putJson(
      baseUri().segment('reports').segment('feedbackMessages'),
      filter
    ).then(({ feedbacks, ...rest }) => {
      return {
        ...rest,
        feedbacks: feedbacks.map(fb => new Feedback(fb))
      }
    })
  }

  putForMessagesReportExport({ filter }) {
    return this.putJson(
      baseUri().segment('reports').segment('feedbackMessages').segment('export'),
      filter
    ).then(({ downloadLink }) => downloadLink)
  }

  putForSummaryReport({ filter }) {
    return this.putJson(
      baseUri().segment('reports').segment('feedbackSummary'),
      filter
    ).then(({ trendGraphs, ...rest }) => {
      const fieldNames = []
      trendGraphs.series.forEach(({ field }) => {
        fieldNames.push(field)
      })
      const convertedData = []
      trendGraphs.data.forEach(({ date, ...counts }) => {
        var datapoint = merge({ date: new Date(date) }, counts)
        fieldNames.forEach(fieldName => {
          if (datapoint[fieldName] === undefined) {
            datapoint[fieldName] = 0
          }
        })
        convertedData.push(datapoint)
      })
      trendGraphs.data = convertedData
      return merge({ trendGraphs }, rest)
    })
  }

  putForSummaryReportExport({ filter }) {
    return this.putJson(
      baseUri().segment('reports').segment('feedbackSummary').segment('export'),
      filter
    ).then(({ downloadLink }) => downloadLink)
  }

  putForReceiversReport({ filter }) {
    return this.putJson(
      baseUri().segment('reports').segment('receivers'),
      filter
    ).then(({ receiverSummaries, ...rest }) => {
      return {
        ...rest,
        receivers: receiverSummaries.map(rec => new Receiver(rec))
      }
    })
  }

  putForReceiversReportExport({ filter }) {
    return this.putJson(
      baseUri().segment('reports').segment('receivers').segment('export'),
      filter
    ).then(({ downloadLink }) => downloadLink)
  }

  putForResponseSummaryReportExport({ filter }) {
    return this.putJson(
      baseUri().segment('reports').segment('responseSummary').segment('export'),
      filter
    ).then(({ downloadLink }) => downloadLink)
  }

  putForResponseSummaryKeskoReportExport({ filter }) {
    return this.putJson(
      baseUri().segment('reports').segment('responseSummaryKesko').segment('export'),
      filter
    ).then(({ downloadLink }) => downloadLink)
  }

  aadLogin({ authCode }) {
    return this.postJson(
      baseUri().segment('aadLogin'),
      { authCode },
      { includeAuthHeader: false }
    )
  }

  login({ username, password, rememberMe }) {
    return this.postJson(
      baseUri().segment('login'),
      { username, password, rememberMe },
      { includeAuthHeader: false }
    )
  }

  logout() {
    return this.postJson(
      baseUri().segment('logout'),
      {}
    )
  }

  whoami() {
    return this.getJson(baseUri().segment('whoami'))
  }

  putProfile({ profile }) {
    return this.putJson(
      baseUri().segment('user').segment('profile'),
      profile,
      { convertToJson: false }
    )
  }

  sendFeedback({ feedback, responseRequested, externalId = createUuid() }) {
    return this.putJson(
      baseUri().segment('sendFeedback'),
      { feedback, responseRequested, externalId },
      { convertToJson: false }
    )
  }

  getFacebookData({ facebookUserId, accessToken }) {
    return this.getJson(
      baseUri().segment('facebook/data').addQuery({ userId: facebookUserId, accessToken })
    ).then(json => new FacebookData(json))
  }

  getStoreSettings({ businessUnitId }) {
    return this.getJson(
      baseUri().segment('businessUnit')
        .segment(businessUnitId)
        .segment('settings')
    ).then(json => new StoreSettings(json));
  }

  getAllowedTransferFeedbackDomains() {
    return this.getJson(
      baseUri()
        .segment('settings')
        .segment('allowedTransferFeedbackDomains')
    ).then(allowedDomains => allowedDomains);
  }

  saveStoreSettings({ businessUnitId, showGreeting, showRetailerImage, greetings, offerRequestTransferEmail, offerRequestProTransferEmail }) {
    return this.putJson(
      baseUri().segment('businessUnit').segment(businessUnitId).segment('settings'),
      {showGreeting, showRetailerImage, greetings, offerRequestTransferEmail, offerRequestProTransferEmail},
      { convertToJson: false }
    );
  }

  createFacebookReceiver({ accessToken, facebookUserId, chainUnitId, feedbackSubject, storeId, facebookPageId }) {
    return this.putJson(
      baseUri().segment('facebook').segment('receiver'),
      { accessToken, facebookUserId, chainUnitId, businessUnitId: storeId, feedbackSubject, facebookPageId }
    ).then(receiverId => receiverId)
  }

  removeFacebookReceiver({ pageId }) {
    return this.deleteJson(
      baseUri().segment('facebook').segment('receiver').segment(pageId),
      { convertToJson: false }
    )
  }

  removeFacebookLinkingConsent() {
    return this.deleteJson(baseUri().segment('user').segment('facebookLinkingConsent'), { convertToJson: false })
  }

  facebookLogout({ facebookUserId, accessToken }) {
    return this.deleteJson(
      new baseUri().segment('facebook/user').segment(facebookUserId).segment('accessToken').segment(accessToken),
      { converToJson: false }
    )
  }

  uploadAttachment({ formData }) {
    const url = new baseAttachmentUri().segment('attachment/upload')

    const config = {
      method: 'post',
      headers: {
        ...baseHeaders,
        'X-Application-Id': 'hymy-ui',
        'Ocp-Apim-Subscription-Key': attachmentApiSubscriptionKey
      },
      body: formData
    }

    return global.fetch(
      url.toString(),
      config
    ).then(verifyStatus, verifyStatus).then(response => {
      return response.json()
    })
  }
}

export const clientMiddleware = client => store => next => action => {
  if (action.clientMethod) {
    trace('Dispatching client action', action)
    return client[action.clientMethod](action.payload).then(
      data => store.dispatch(action.success(data)),
      error => store.dispatch(action.error(error))
    )
  }
  return next(action)
}

export default Client
