import {hasItems} from 'utils/Helpers'

export const fixLineChanges = text => !!text ? text.split('\\n').join('\n') : '';

export const fixLineChangesForReplyMessage = (greeting, templates, signature) => {
  if (!hasItems(templates)) {
    return '';
  }
  return fixLineChanges(greeting)
    .concat(templates.map(t => fixLineChanges(t.template)).join('\n\n'))
    .concat(fixLineChanges(signature));
}
