import React, { Component, PropTypes } from 'react'

import { Col } from 'components/grid'
import { Navigator } from 'components/Menu'
import { Text } from 'containers/Text'
import { ExclamatedNote } from 'components/kesko'

import ForwardFeedbackForm from './ForwardFeedbackForm'
import FeedbackComments from '../FeedbackComments'
import FeedbackMessages from '../FeedbackMessages'
import FacebookMessageHistory from '../FacebookMessageHistory'
import CollapsingCustomerInfo from '../CollapsingCustomerInfo'
import WaitFor from '../../Utils/common/WaitFor';

class ForwardFeedbackView extends Component {

  static propTypes = {
    forward: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      forward: PropTypes.func.isRequired,
      getUserEmails: PropTypes.func.isRequired
    }).isRequired,
    userEmails: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    userEmails: null
  };

  componentDidMount() {
    this.props.actions.getUserEmails();
  }

  render() {
    const {feedback, cancel, forward, userEmails} = this.props
    return (
      <WaitFor condition={userEmails !== null}>
        <section className="px1">
          <Navigator backUrl=".." />
          <Col>
            <hr />
          </Col>
          <CollapsingCustomerInfo collapseId={`ForwardFeedbackView-${feedback.id}-customerinfo`}
                                  customer={feedback.customer}
                                  initiallyVisible={false}
          />
          <FacebookMessageHistory collapseId={`ForwardFeedbackView-${feedback.id}-facebookHistoryMessages`}
                                  facebookHistoryMessages={feedback.facebookHistoryMessages}
                                  initiallyVisible={false}
          />
          <FeedbackMessages collapseId={`ForwardFeedbackView-${feedback.id}-messages`}
                            messages={feedback.messages}
                            showEditLinks={false}
                            initiallyVisible={false}
          />
          <FeedbackComments collapseId={`ForwardFeedbackView-${feedback.id}-comments`}
                            comments={feedback.comments}
                            showEditLinks={false}
                            showNote={false}
                            initiallyVisible={false}
          />
          <Col>
            <hr />
          </Col>

          <Col><h2 className="blue mt3 mb3"><Text k="feedback.forward.title" /></h2></Col>

          <ExclamatedNote
            translate={{note: 'feedback.forward.note'}}
            className="mb2"
          />

          <ForwardFeedbackForm
            forward={forward}
            onCancel={cancel}
            localStorageKey={`${feedback.id}-forward`}
            userEmails={userEmails}
          />

        </section>
      </WaitFor>
    )
  }
}

export default ForwardFeedbackView
