import React, { PropTypes } from 'react';
import {FilterableDropdown, ErrorBlock} from 'components/Forms'
import {KeskoIcons, Icon} from '../Icons';

export default class SingleEmailForm extends React.Component {
  /* eslint-disable react/jsx-no-bind */
  render() {
    const {index, userEmails, initialValue, isValid, onBlur, deleteForm} = this.props
    const deleteOnClick = () => {
      return deleteForm(index)
    }
    const onFormBlur = (value) => {
      onBlur(value, index);
    }
    return (
      <div>
        {!isValid && (
          <div className="clearfix mb2">
            <ErrorBlock submitError={'feedback.requestComment.emailFormat'}/>
          </div>
        )}
        <div className="email-row" key={`email-row-${index}`}>
          <FilterableDropdown
            items={userEmails}
            translate={{placeholder: 'feedback.requestComment.fields.emails.placeholder'}}
            onBlur={(value) => onFormBlur(value, index)}
            initialValue={initialValue}
            className='multiple-emails-input'
            type="email"
            key={`email-input-${index}`}
            isValid={isValid}
          />
          <Icon
            icon={KeskoIcons.trash}
            size={Icon.sizes.l}
            onClick={deleteOnClick}
            className="delete-row-button"
          />
        </div>
      </div>
    )
  }
}

SingleEmailForm.propTypes = {
  index: PropTypes.number.isRequired,
  isValid: PropTypes.bool,
  userEmails: PropTypes.arrayOf(PropTypes.string),
  initialValue: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  deleteForm: PropTypes.func.isRequired
}

