module.exports = {
  state: {
    NEW: 'Ny',
    WAITING: 'Oavslutad',
    DONE: 'Behandlad'
  },
  formButtons: {
    save: 'Spara',
    cancel: 'Avbryt'
  },
  proposal: {
    template: {
      label: 'Svarsförslagen',
      note: 'Obs! Svarsassistenten är inte ofelbar, så kom ihåg att kontrollera svarsförslaget och korrigera eventuella brister i svaret. Om du vill, kan du välja något annat svarsunderlag i rullgardinsmenyn nedan. I svaret infogas automatiskt efter ditt meddelande en logo och en länk, via vilken kunden vid behov kan fortsätta hanteringen av ärendet.'
    },
    nameSuffix: ' (Svarsassistentens förslaget)'
  },
  feedback: {
    responseNeeded: 'Kunden väntar på svar', // !DONE and requiresResponse
    responseSent: 'Kunden har svarats', // DONE and answered
    responseNotSent: 'Kunden har inte svarats', // DONE, but not answered
    messageSent: 'Meddelandet har skickats till kunden',
    messageReceived: 'Kunden har skickat ett meddelande',
    transferred: 'Har vidarebefordrats',
    handler: 'Hanterare',
    context: {
      chain: 'Kedja: ',
      subject: 'Ämne: ',
      allFeedbacks: 'Egna intresseområden',
      secondaryFeedbacks: 'Sekundära intresseområden',
      businessUnit: ''
    },
    transcript: {
      success: 'Maskinöversatt röstmeddelande',
      pending: 'Tusan, textöversättningen som datorn gav är inte klar än. Lyssna på röstmeddelandet nedan.',
      failedError: 'Åhå, maskinöversättningen misslyckades. Lyssna på röstmeddelandet nedan.',
      failedEmpty: 'Åhå, datorn anser att röstmeddelandet inte innehåller något tal. Lyssna på röstmeddelandet nedan.',
      failedQuality: 'Åhå, maskinöversättningens kvalitet uppfyllde inte kraven. Lyssna på röstmeddelandet nedan.'
    },
    customer: {
      removed: 'Kundens uppgifter har avlägsnats',
      anonymous: 'Anonym respons',
      name: 'Kund',
      companyName: 'Företag',
      otherCustomerId: 'Andra specificerande uppgifter',
      phone: 'Telefon',
      email: 'E-post',
      streetAddress: 'Gatuadress',
      postalCode: 'Postnummer',
      postName: 'Postanstalt',
      preferredContactChannel: 'Önskat kontaktsätt',
      channels: {
        phone: 'Telefon',
        email: 'E-post',
        uberall: 'Uberall',
        facebook: 'Facebook privat meddelande'
      },
      info: 'Kontaktuppgifter'
    },
    state: 'Status',
    methodOfContact: 'Önskat sätt att ta kontakt',
    messageHistory: 'Meddelandehistorik',
    messages: 'Meddelandekedja',
    images: 'Bildbilagor',
    audios: 'Röstmeddelanden',
    files: 'Övriga bilagor',
    virusScanningStatus: {
      ok: '',
      notNeeded: '',
      fail: 'virusskanningen blockerade filen eller filtypen stöds inte',
      pending: 'virusskanning pågår',
      notUploaded: 'virusskanning pågår'
    },
    meta: {
      id: 'Identifikation',
      category: {
        title: 'Kategori'
      },
      receiverName: 'Mottagare',
      facebookReceiverName: {
        title: 'Facebook-sida'
      },
      ambiance: {
        title: 'Responsens helhetston',
        HIGHLY_POSITIVE: 'Mycket positiv',
        POSITIVE: 'Positiv',
        NEUTRAL: 'Neutral',
        NEGATIVE: 'Negativ',
        HIGHLY_NEGATIVE: 'Mycket negativ'
      },
      topicsPos: 'Positiva samtalsämnen',
      topicsNeu: 'Neutrala samtalsämnen',
      topicsNeg: 'Negativa samtalsämnen',
      area: {
        title: 'Kedjans område'
      },
      channel: {
        title: 'Responskälla'
      },
      purchaseDate: {
        title: 'Datum för butiksbesöket'
      },
      deviceModel: {
        title: 'Använd anordning'
      },
      applicationVersion: {
        title: 'Applikationsversion'
      },
      plussaCardNumber: {
        title: 'Plussa-kortets nummer'
      },
      tempPlussaCardNumber: {
        title: 'Numret på det temporära Plussa-kortet'
      },
      plussaPersonId: {
        title: 'Plussa-kundnummer'
      },
      b2cAccountId: {
        title: 'K-Tunnus ID'
      },
      nps: {
        title: 'Vitsord för kundpulsen'
      },
      rating: {
        title: 'Bedömning'
      },
      orderNumber: {
        title: 'Beställningsnummer'
      },
      originalBusinessUnit: {
        title: 'Butik'
      },
      orderExperienceSentiment: {
        title: 'Beställningserfarenhetens ton'
      },
      carRegistrationNumber: {
        title: 'Registernummer'
      },
      carBrand: {
        title: 'Brand'
      },
      clientBrowser: {
        title: 'Webbläsare'
      },
      clientLocation: {
        title: 'Webbsida som responsen skickades från'
      },
      clientReferrer: {
        title: 'Webbsida man kom från för att ge respons'
      },
      clientScreenSize: {
        title: 'Skärmstorlek (bredd x höjd)'
      },
      clientWindowSize: {
        title: 'Fönstrets storlek (bredd x höjd)'
      },
      handlingTime: {
        title: 'Behandlingstid'
      },
      reportAmbianceError: {
        link: 'Meddela om fel ton för responsen',
        reported: 'Ett meddelande om fel ton för responsen har gjorts.',
        confirmTitle: 'Meddela om fel ton',
        confirmBody: 'Responsens ton som helhet bildas automatiskt utifrån responsens verbala innehåll. Kundpulsens vitsord (0−10) påverkar inte analysen. Den automatiska klassificeringen av tonen som helhet träffar rätt i ca 85 % av fallen.\n\nÄr responsens ton som helhet felaktig?'
      },
      reportTopicError: {
        link: 'Meddela om fel samtalsämne för responsen',
        reported: 'Ett meddelande om fel samtalsämne för responsen har gjorts.',
        confirmTitle: 'Meddela om fel samtalsämne',
        confirmBody: 'Responsens samtalsämnen bildas automatiskt utifrån responsens verbala innehåll. Även om analysens tillförlitlighet är ca 95 %, kan fel smyga sig in.\n\nÄr responsens samtalsämnen felaktiga?'
      },
      reportThreat: {
        link: 'Rapportera responsens innehåll som hotfullt',
        reported: 'Responsens innehåll har rapporterats som hotfullt.',
        confirmTitle: 'Rapportera innehåll som hotfullt',
        confirmBody: 'Är responsens innehåll hotfullt?'
      }
    },
    events: 'Händelselogg',
    event: {
      response_attachment_scan_failed: 'Bilagan kunde inte fogas till svaret, svaret har inte skickats till kunden',
      feedback_received: 'Responsen är mottagen: {target}',
      feedback_opened: 'Responsen är öppnad',
      etuma_analysis_ready: 'Responsen är analyserad i Lumoa',
      feedback_ready: 'Responsen är klar för behandling',
      email_response_to_customer: 'Kunden har fått svar till adressen {target}',
      phone_response_to_customer: 'Kunden har kontaktats på nummer {target}',
      uberall_response_to_customer: 'Kunden har besvarats med ett Uberall-meddelande via Hymy',
      uberall_response_to_customer_ua: 'Kunden har besvarats med ett Uberall-meddelande via Uberall',
      facebook_response_to_customer_fb: 'Kunden har fått svar som Facebookmeddelande via Facebookchatten',
      facebook_response_to_customer_hymy: 'Kunden har fått svar som Facebookmeddelande via Hymy',
      follow_up_received: 'Uppföljningsmeddelande mottaget',
      follow_up_received_feedback_reopened: 'Uppföljningsmeddelande mottaget, responsen har öppnats på nytt',
      comment_added: 'Intern kommentar tillagd',
      comment_removed: 'Intern kommentar raderad',
      marked_as_handled: 'Responsen är markerad som behandlad',
      marked_as_handled_automatically: 'Responsen har automatiskt märkts som hanterad',
      feedback_reopened: 'Responsen har öppnats på nytt',
      feedback_reassigned: 'Responsen handläggare är ändrad: {target}',
      change_receiver: 'Responsens mottagare är ändrad: {target}',
      comment_request_sent: 'Kommentarsförfrågan är sänd: {target}',
      comment_response_received: 'Svar till kommentarsförfrågan är mottaget.',
      transfer_feedback: 'Responsen är förflyttad för behandling: {target}',
      forward_feedback: 'Responsen har vidarebefordrats som informationsärende: {target}',
      report_analysis_error: 'Fel i Etuma-analysen är rapporterat',
      report_ambiance_error: 'Ett fel i Etuma-analysens ton har rapporterats',
      report_topic_error: 'Ett fel i Etuma-analysens samtalsämnen har rapporterats',
      change_customer_contact: 'Kundens kontaktuppgiftar har ändrats',
      sent_copy_to_customer: ' En kopia av meddelandet har sänts till kundens adress {target}',
      threat_reported: 'Responsens innehåll har rapporterats som hotfullt',
      facebook_connection_disabled: 'Facebooklänkning till sidan {target} har avbrutits',
      facebook_connection_enabled: 'Facebooklänkning till sidan {target} har skapats',
      facebook_responding_disabled: 'Svar till responsen har förhindrats, ny respons: {target}',
      error: 'Fel vid sändning av meddelande: {target}',
      error_feedback_reopened: 'Fel vid sändning av meddelande, responsen har öppnats på nytt: {target}',
      response_draft_saved: 'Utkast till svar har sparats',
      customer_info_removed: 'Kundens kontaktuppgifter är borttagna',
      customer_info_usage_limited: 'Användning av kundens uppgifter är begränsad',
      customer_info_usage_objected: 'Användning av kundens uppgifter är otillåten',
      hymy_user_info_removed: 'Hymy-användarnas uppgifter har avlägsnats',
      feedback_archived: 'Responsen är arkiverad',
      audio_transcribed: 'Röstmeddelandet omvandlat till text',
      audio_transcribed_failed: 'Det gick inte att omvandla röstmeddelandet till text',
      response_proposal_added: 'Det har kommit ett svarsförslag från svarsassistenten',
      attachments_removed: 'Responsens filer har avlägsnats',
      surveypal_invitation_scheduled: 'Surveypal inbjudan tidsinställad: {target}',
      surveypal_invitation_sent: 'Surveypal inbjudan skickad: {target}',
      surveypal_invitation_skipped: 'Surveypal unbjudan var inte skickad',
      email_notifications_sent: 'E-postmeddelanden har skickats till hanterarna',
      push_message_notifications_sent: 'Push message-meddelanden har skickats till hanterarna',
      elisa_desk_notification_sent: 'Ett meddelande har skickats till Elisa Desk'
    },
    transfer: {
      link: 'Vidarebefordra för hantering',
      title: 'Vidarebefordra för hantering',
      fields: {
        target: {
          none: 'Välj objekt *',
          required: 'Välj objekt'
        },
        commentText: {
          placeholder: 'Kommentar *',
          required: 'Skriv in en kommentar'
        },
        targetType: {
          required: 'Välj objekt *',
          label: 'Objekt *',
          store: 'Butik',
          chain: 'Kedja',
          other: 'Annan',
          email: 'E-postadress',
          subject: 'Ämne',
          emailList: 'Tidigare använd e-postadress'
        },
        targetOther: {
          required: 'Välj objekt *'
        },
        targetStore: {
          required: 'Välj butik *'
        },
        targetChain: {
          required: 'Välj kedja *'
        },
        targetEmail: {
          placeholder: 'Skriv in eller välj e-postadress *',
          email: 'Kontrollera e-postadressen',
          required: 'Skriv in e-postadressen'
        },
        targetEmailList: {
          required: 'Välj e-postadress *'
        },
        targetSubject: {
          required: 'Välj ämne *'
        },
        targetHandler: {
          notSelected: 'Ingen hanterare'
        },
        language: 'E-postspråk'
      },
      save: 'Vidarebefordra',
      cancel: 'Avbryt',
      saveFailed: 'Responsen kunde inte vidarebefordras',
      note: 'Observera, att utöver responsen förmedlas med meddelandet även kundens kontaktuppgifter och länken till eventuella bilagor. Överför inte respons som innehåller personuppgifter utanför K-gruppen! Ifall kundens uppgifter behöver förmedlas, ska du först be kunden om samtycke. Om du vill att det tas kontakt med dig om hur ärendet framskrider, ska du be om det skilt i kommentaren. Lägg till dina kontaktuppgifter om det behövs.'
    },
    comments: {
      title: 'Interna kommentarer',
      note: 'Kommentarerna används endast internt och kunden kan inte se dem',
      goToCommenting: 'Kommentera',
      addComment: 'Kommentera responsen',
      save: 'Spara',
      cancel: 'Avbryt',
      saveFailed: 'Kommentaren kunde inte sparas',
      commentRequest: 'Begäran om kommentar',
      commentResponse: 'Svar på begäran om kommentar',
      transferComment: 'Vidarebefordrad',
      forwardComment: 'Har förmedlats till adresserna',
      responseComment: 'Samtal till kunden:',
      addAttachment: 'Bifoga en fil',
      attachmentHint: 'Största tillåtna storlek på en fil är 10 megabyte. Ett meddelande kan innehålla högst 5 bifogade filer.',
      error: {
        genericError: 'Ett fel inträffade i hanteringen av responsen',
        commentRequestDeliveryFailed: 'Begäran om kommentarer kunde inte skickas',
        responseDeliveryFailed: 'Svaret kunde inte skickas',
        transferCommentDeliveryFailed: 'Responsen kunde inte vidarebefordras',
        forwardCommentDeliveryFailed: 'Responsen kunde inte skickas',
        copyOfFeedbackDeliveryFailed: 'Det gick inte att skicka en kopia på responsen',
        followUpDeliveryFailed: 'Det gick inte att skicka en kopia på uppföljningsmeddelanden',
        assignmentDeliveryFailed: 'Meddelandet kunde inte skickas till den nya hanteraren'
      },
      removeComment: {
        link: 'Avlägsna',
        confirmTitle: 'Avlägsna kommentaren',
        confirmBody: 'Vill du säkert avlägsna kommentaren?'
      },
      fields: {
        comment: {
          placeholder: 'Kommentar *',
          required: 'Skriv in en kommentar'
        }
      },
      attachment: {
        format: 'Filen du bifogar är för stor. Största tillåtna storlek på en fil är 10 megabyte.',
        uploading: 'Vänligen vänta tills laddningen av de bifogade filerna är klar',
        uploadError: 'Laddningen misslyckades',
        max: 'Högst 5 filer tillåts, var god och avlägsna filer.'
      }
    },
    reply: {
      goToReply: 'Svara',
      replyWithEmail: 'Svara kunden per e-post',
      note: 'Svaret sänds direkt till kunden. I svaret infogas automatiskt efter ditt meddelande en logo och en länk, via vilken kunden vid behov kan fortsätta hanteringen av ärendet.',
      replyViaUberall: 'Besvara kunden med ett Uberall-meddelande',
      replyViaFacebook: 'Svara kunden per Facebook privat meddelande',
      noteFacebook: 'Svaret sänds direkt till kunden. Till meddelandet fogas ingen automatisk hälsning eller underskrift.',
      noteUberall: 'Svaret skickas via Uberall-tjänsten. Ingen automatisk hälsning eller underskrift tilläggs meddelandet.',
      saveAndDone: 'Skicka svaret, hanterat',
      saveAndWaiting: 'Skicka svaret, oavslutat',
      saveDraft: 'Spara utkastet, oavslutat',
      cancel: 'Avbryt',
      saveFailed: 'Svaret kunde inte skickas',
      saveDraftFailed: 'Utkastet kunde inte sparas',
      templates: 'Svarsbottnarna',
      confirmClearResponseTitle: 'Töm svaret',
      confirmClearResponseBody: 'Vill du säkert tömma svaret?',
      fields: {
        message: {
          placeholder: 'Svaret sänds direkt till kunden. I svaret infogas automatiskt efter ditt meddelande en logo och en länk, via vilken kunden vid behov kan fortsätta hanteringen av ärendet. *',
          label: 'Svaret *',
          required: 'Skriv in svaret',
          maxLength: 'Svaret får bestå av högst 50 000 tecken'
        },
        template: {
          placeholder: 'Välj svarsbotten om du så önskar',
          clear: 'Töm svaret'
        }
      },
      selection: {
        title: 'Svara kunden',
        replyByEmail: 'Per e-post',
        replyByPhone: 'Per telefon',
        replyViaUberall: 'Med ett Uberall-meddelande',
        replyViaFacebook: 'Per Facebook privat meddelande',
        preferredContactMethod: 'Önskat kontaktsätt',
        cancel: 'Avbryt'
      },
      responding: {
        note: 'Obs!',
        startedResponding: 'började svara'
      },
      attachment: {
        format: 'Filen du bifogar är för stor. Största tillåtna storlek på en fil är 10 megabyte.',
        uploading: 'Vänligen vänta tills laddningen av de bifogade filerna är klar',
        uploadError: 'Laddningen misslyckades',
        max: 'Högst 5 filer tillåts, var god och avlägsna filer.'
      }
    },
    requestComment: {
      link: 'Be om en kommentar per e-post',
      title: 'Be om en kommentar per e-post',
      note: 'Den ursprungliga responsen förmedlas inte med begäran om kommentar. Kopiera vid behov in den i fältet där en kommentar begärs, men försäkra dig om att kundens personuppgifter inte överförs till någon utanför K-gruppen. Ifall kundens uppgifter behöver förmedlas tillsammans med begäran om kommentar, ska du först be kunden om samtycke. I meddelandet som skickats står responsens identifikation, med vilken användare av Hymy kan söka responsen i systemet. Kom ihåg att vid behov skriva in en egen hälsning och underskrift. Uppge åtminstone en e-postadress, som du vill skicka en begäran om kommentar till.',
      fields: {
        emails: {
          placeholder: 'E-postadresser',
          label: 'E-postadresser *',
          required: 'Skriv in minst en e-postadress'
        },
        commentText: {
          placeholder: 'Begäran om kommentar *',
          required: 'Skriv in en begäran om kommentar',
          maxLength: 'Begäran om kommentar får bestå av högst 50 000 tecken'
        }
      },
      saveFailed: 'Begäran om kommentar kunde inte skickas',
      emailFormat: 'Kontrollera e-postadressen',
      save: 'Skicka',
      cancel: 'Avbryt',
      language: 'Språk som kommentar begärs på',
      addReceiver: 'Lägg till en mottagare'
    },
    list: {
      loadingMore: 'Responser laddas...',
      atBottom: 'Inga fler responser :)'
    },
    markAsDone: {
      link: 'Markera som hanterad',
      dialog: {
        title: 'Markera som hanterad',
        body: 'Är hanteringen av responsen klar?'
      }
    },
    reopen: {
      link: 'Öppna',
      dialog: {
        title: 'Öppna den hanterade responsen',
        body: 'Behöver responsen fler åtgärder?'
      }
    },
    changeHandler: {
      link: 'Byt hanterare',
      title: 'Byt hanterare',
      none: 'Ingen hanterare',
      handlerId: {
        required: 'Välj hanterare'
      },
      comment: {
        placeholder: 'Kommentar'
      },
      saveFailed: 'Bytet av hanterare misslyckades'
    },
    filter: {
      NEW: 'Ny',
      WAITING: 'Oavslutad',
      requiresResponse: 'Väntar på svar',
      myFeedback: 'Min responser',
      myWaiting: 'Min oavslutade'
    },
    forward: {
      link: 'Förmedla till e-posten för kännedom',
      title: 'Förmedla till e-posten för kännedom',
      save: 'Förmedla',
      cancel: 'Avbryt',
      saveFailed: 'Responsen kunde inte förmedlas',
      fields: {
        comment: {
          placeholder: 'Kommentar *',
          required: 'Skriv in en kommentar'
        },
        emails: {
          placeholder: 'E-postadress *',
          required: 'Skriv in minst en e-postadress'
        },
        language: 'E-postspråk'
      },
      note: 'Med den här funktionen kan du för kännedom förmedla responsen och svaren som kommit på den. Observera, att kundens uppgifter förmedlas till de adresser du har valt. Skriv in minst en e-postadress.'
    },
    changeContact: {
      link: 'Ändra kundens kontaktuppgifter',
      title: 'Kundens kontaktuppgifter',
      save: 'Spara',
      cancel: 'Avbryt',
      saveFailed: 'Kontaktuppgifterna kunde inte sparas',
      fields: {
        name: {
          placeholder: 'Namn',
          required: 'Skriv in ett namn'
        },
        phone: {
          placeholder: 'Telefon',
          format: 'Var god och kontrollera telefonnumret'
        },
        email: {
          placeholder: 'E-post',
          email: 'Kontrollera e-postadressen'
        }
      },
      noteChanging: 'Kom ihåg att vid behov skicka kunden ett nytt svar till den korrigerade e-postadressen.',
      noteAdding: 'Om du sätter till kundens kontaktuppgifter i responsen, blir responsen en respons som kräver svar och kunden ska svaras via Hymy.'
    },
    replyByPhone: {
      replyByPhone: 'Svara kunden per telefon',
      recordingNote: 'Berätta för kunden redan i början av samtalet, att du bandar samtalet!',
      note: 'Ring kunden med vald anordning. Registrera en kommentar om samtalet.',
      saveAndDone: 'Ringt, hanterat',
      saveAndWaiting: 'Ringt, oavslutat',
      cancel: 'Avbryt',
      saveFailed: 'Svaret kunde inte sparas',
      fields: {
        comment: {
          placeholder: 'Kommentar *',
          required: 'Skriv in en kommentar',
          maxLength: 'Kommentar får bestå av högst 50 000 tecken'
        }
      }
    },
    adminTools: {
      link: 'Admin-användarens tilläggsfunktioner',
      title: 'Admin-användarens tilläggsfunktioner',
      save: 'Godkänn',
      cancel: 'Avbryt',
      saveFailed: 'Det gick inte att utföra åtgärden',
      usage_limited: {
        label: 'Begränsa användningen av kunduppgifterna',
        note: 'Kundens uppgifter sparas, men de behandlas inte för andra än eventuella juridiska ändamål.'
      },
      usage_objected: {
        label: 'Kunden motsätter sig användning av kunduppgifterna',
        note: 'Kunden vill inte att hens personliga uppgifter används i t.ex. direktmarknadsföring, profilering eller rapporter.',
        toBeObjected: 'Användningen av följande kunduppgifter kommer att begränsas:'
      },
      archived: {
        label: 'Arkivera responsen',
        note: 'Arkiverad respons syns inte längre i användargränssnittet eller i rapporter.'
      },
      fields: {
        status: {
          placeholder: 'Välj åtgärd *'
        },
        comment: {
          placeholder: 'Kommentar'
        }
      }
    },
    exportMessages: {
      link: 'Ladda meddelanden'
    },
    category: {
      feedback: 'Respons',
      productWish: 'Produktönskemål',
      offerRequest: 'Offertbegäran',
      newSales: 'Respons till försäljningen',
      afterSales: 'Respons till eftermarknadsföringen',
      sales: 'Försäljningen',
      reclamation: 'Reklamation',
      webStoreBusinessUnit:"Nätbutik"
    },
    usageObjected: {
      note: 'Kunden har begränsat användningen av sina uppgifter. Det är förbjudet att vidareförmedla respons med kunduppgifter utanför det här registret (t.ex. till en varuleverantör, köpmän eller ett annat affärsområde).'
    }
  },
  back: 'Tillbaka',
  loginPage: {
    title: 'Hymy :)',
    login: 'Logga in',
    alternativeLogin: 'Alternativt inloggningssätt',
    loginFailed: {
      default: 'Fel användarnamn eller lösenord',
      credentialsNotFound: 'Fel användarnamn eller lösenord',
      accountFreezed: 'För många inloggningsförsök. Användarnamnet har låsts för en timme.',
      missingBusinessUnits: 'Ditt användarnamn saknar gällande behörighet att använda Hymy. Om du är matköpman, ska du kontakta handelns servicecentral. Om du är K-Rautaköpman, ska du kontakta Tietos kundsupport. Om du är butiksanställd, får du behörigheterna av köpmannen. Anställda på K-Citymarkets bruksvaruavdelning får användaridentifikationerna av den egna enhetens assistent.',
      missingAuthority: 'Ditt användarnamn saknar gällande behörighet att använda Hymy. Om du är matköpman, ska du kontakta handelns servicecentral. Om du är K-Rautaköpman, ska du kontakta Tietos kundsupport. Om du är butiksanställd, får du behörigheterna av köpmannen. Anställda på K-Citymarkets bruksvaruavdelning får användaridentifikationerna av den egna enhetens assistent.',
      authenticationFailed: 'Inloggning misslyckades'
    },
    username: {
      placeholder: 'Användarnamn *',
      required: 'Skriv in användarnamn'
    },
    password: {
      placeholder: 'Lösenord *',
      required: 'Skriv in lösenordet'
    },
    rememberMe: 'Kom ihåg mig'
  },
  customerReply: {
    expired: {
      title: 'Responsen är föråldrad',
      body: 'Tack för ert förtroende för Kesko. Ni kan ge ny respons via våra webbsidor'
    },
    messageTitle: 'Du kan fortsätta behandlingen av saken genom att skriva nedanför ditt meddelande',
    previousMessages: 'Tidigare meddelanden i anslutning till ärendet',
    fields: {
      message: {
        placeholder: 'Meddelande *',
        required: 'Skriv meddelande'
      }
    },
    thanks: {
      title: 'Ge oss respons',
      body: 'Tack för ditt meddelande!'
    },
    send: 'Skicka',
    sendMeACopy: 'Jag vill ha en kopio av meddelandet till min e-post'
  },
  actions: {
    title: 'Andra funktioner',
    save: 'Spara',
    cancel: 'Avbryt'
  },
  sidebar: {
    title: 'Hymy :)',
    reports: 'Rapporter',
    logout: 'Logga ut',
    search: 'Sök i responserna',
    myProfile: 'Egen profil',
    feedbacks: 'Responser',
    summaryReport: 'Översikt (beskrivare)',
    feedbackListReport: 'Inkomna responser',
    feedbackMessagesReport: 'Responser och meddelandekedja',
    receiversReport: 'Översikt (tabell)',
    responseSummaryReport: 'Översikt (svarstider)',
    addNewFeedback: 'Lägg till ny respons',
    giveFeedback: 'Ge respons på Hymy',
    settings: 'Inställningar',
    support: 'Anvisningar för Hymy'
  },
  defaults: {
    select: {
      noSelection: 'Inget har valts'
    }
  },
  search: {
    title: 'Sök responser',
    note: 'Textsökningen söker sökord i kundmeddelanden och i interna meddelanden. Textsökningen hittar även kundernas namn, identifikationer på responser och samtalsämnen.',
    execute: 'Sök',
    cancel: 'Avbryt',
    backToHome: 'Till ingångssidan',
    sidebar: {
      search: {
        placeholder: 'Sök...'
      }
    },
    fields: {
      search: {
        placeholder: 'Textsökning',
        required: 'Skriv in minst 3 tecken',
        minLength: 'Skriv in minst 3 tecken'
      }
    },
    filter: {
      customer: 'Kund',
      handler: 'Hanterare',
      store: 'Butik'
    }
  },
  searchResults: {
    title: 'Sökresultat',
    empty: 'Din sökning gav inga resultat',
    showingResultsForPre: 'Resultaten för sökordet',
    showingResultsForPost: ' visas',
    backToSearch: 'Specificera sökningen'
  },
  yes: 'Ja',
  no: 'Nej',
  loading: 'Laddar...',
  remove: 'Avlägsna',
  profile: {
    title: 'Egen profil',
    firstVisit: {
      description: 'Hej! Välkommen att använda Hymy-systemet.',
      moreDescription: 'Fyll i dina uppgifter nedan och tryck på "Spara".'
    },
    language: {
      title: 'Systemspråk'
    },
    hello: {
      title: 'Hälsning som används på svarsbottnen',
      preview: 'Förhandsvisning av hälsningen',
      placeholder: 'Du kan skriva önskad hälsning här. Om du lämnar hälsningen tom, ser du standardhälsningen i förhandsvisningen. Standardhälsningens språk väljs automatiskt på basis av svarsbottnens språk.'
    },
    signature: {
      title: 'Underskriften som används på svarsubottnen',
      preview: 'Förhandsvisning av underskriften',
      placeholder: 'Du kan skriva den underskrift du önskar här. Om du lämnar underskriften tom, ser du standardunderskriften i förhandsvisningen. Standardunderskriftens språk väljs automatiskt på basis av svarsbottnens språk.'
    },
    subscriptions: {
      title: 'Meddelanden från systemet',
      email: 'E-post:',
      fields: {
        sendNotificationsStore: 'Skicka mig alltid e-post när en ny respons som kräver svar inkommer på Hymy',
        sendNotificationsNoResponseStore: 'Skicka mig alltid ett e-postmeddelande, när det kommer en ny respons till Hymy som inte kräver ett svar',
        sendNotificationsKesko: 'Skicka mig alltid e-post när en ny respons på mina intresseområden som kräver svar inkommer på Hymy',
        sendNotificationsNoResponseKesko: 'Skicka mig alltid ett e-postmeddelande, när det kommer en ny respons på mina intresseområden som inte kräver ett svar',
        sendSummaries: 'Skicka mig tre gånger i veckan (mån, ons, fre) en sammanställning per e-post över obehandlade responser som kräver svar och som hör till mina intresseområden',
        sendPushMessages: 'Skicka ett meddelande till de enheter du har valt, då en ny respons som kräver ett svar inkommer på Hymy (Webbläsaren ber om tillåtelse att presentera meddelanden, om inga behörigheter har inställts)'
      }
    },
    responseProposals: {
      title: 'Vastausapulainen',
      fields: {
        proposeResponses: 'Visa det svarsförslag som svarsassistenten har valt när det svaras på respons'
      }
    },
    excludes: {
      title: 'Skicka mig inte e-postmeddelanden från de här butikerna',
      notSelected: 'välj butiker'
    },
    interests: {
      enableSecondaryInterests: 'Jag har också sekundära intresseområden',
      title: 'Egna intresseområden',
      note: 'De egna intresseområdena avgänsar automatiskt responserna som syns på responslistan, meddelandena du får och sökningarna i rapporteringen.Du kan ändå göra rapportering och sökning i vilken kedja, vilket område eller vilken responskanal som helst. Du kan uppdatera dina egna intresseområden när som helst.',
      categoryTitle: 'Kategori',
      feedbackCategoryNote: 'Du kan avgränsa responsen och meddelandena om nya responser som visas på responslistan genom att välja den kategori du är intresserad av.',
      allFeedbackCategories: 'Alla',
      title2: 'Sekundära intresseområden',
      note2: 'De sekundära intresseområdena avgränsar automatiskt responserna som syns på responslistan och meddelandena du får.'
    },
    saveFailed: 'Profil kunde inte sparas',
    save: 'Spara',
    cancel: 'Avbryt',
    fields: {
      signature: {
        maxLength: 'Underskriften bör vara kortare än 256 tecken'
      },
      greeting: {
        maxLength: 'Hälsningen bör vara kortare än 300 tecken'
      }
    },
    pushNotifications: {
      title: 'Meddelanden från systemet',
      userAgent: 'Tillagda enheter',
      thisDevice: 'Den här enheten',
      enableOnThisDevice: 'Tillåt meddelanden på den här enheten',
      confirmRemovePushMessageTokenTitle: 'Vill du säkert blockera meddelanden från den valda webbläsaren eller enheten?',
      confirmRemovePushMessageTokenBody: 'Det här stänger av meddelanden till den valda enheten eller webbläsaren. Du kan tillåta meddelanden senare på nytt.'
    }
  },
  settings: {
    title: 'Inställningar',
    facebook: {
      title: 'Länkning av privata meddelanden på Facebook-sidorna till Hymy',
      login: 'Logga in på Facebook',
      loggedIn: 'Du har loggat in på Facebook med namnet:',
      loggingIn: 'Du loggas in på Facebook...',
      logout: 'Avlägsna länkningen till ditt Facebook-konto',
      confirmLogoutTitleStart: 'Vill du säkert ta bort länkningarna till ditt Facebook-konto "',
      confirmLogoutTitleEnd: '"?',
      confirmLogoutBody: 'Det här avlägsnar alla länkningar till Facebook-sidor som har gjorts med de här inloggningsuppgifterna. När en länkning med inloggningsuppgifterna tas bort, avlägsnas inte meddelanden som redan har sparats på Hymy.',
      loggingOut: 'Länkningar avlägsnas ...',
      targetType: {
        label: 'Välj mottagare, till vilken meddelanden länkas i Hymy:',
        notSelected: 'Välj mottagare',
        chain: 'Kedja: ',
        subject: 'Ämne: '
      },
      page: {
        label: 'Välj den Facebook-sida, vars privata meddelanden du vill länka:',
        notSelected: 'Välj Facebook-sida'
      },
      receivers: {
        title: 'Länkade facebook-sidor',
        labels: {
          pageName: 'Länkad sida',
          userName: 'Länkningen är gjord av',
          chainOrSubjectName: 'Mottagare'
        },
        add: 'Länka till Hymy',
        remove: 'Avlägsna länkningen'
      },
      allowLinking: 'Jag samtycker till att privata meddelanden från Facebook-sidor som jag administrerar söks och sparas på Hymy, och att svar som skrivits på Hymy skickas till Facebook. Jag förstår, att meddelanden som redan har sparats på Hymy inte avlägsnas, om jag senare avlägsnar Facebook-sidan eller tar bort länkningen till Facebook-kontot.',
      confirmRemoveConsentTitle: 'Vill du säkert återkalla ditt godkännande?',
      confirmRemoveConsentBody: 'Om godkännandet återtas, avlägsnas alla länkningar till Facebook-sidor som du har lagt in på Hymy. Meddelanden som redan har sparats på Hymy raderas inte. Vill du säkert återkalla ditt godkännande?',
      confirmRemoveReceiverTitleStart: 'Vill du ta bort länkningen till Facebook-sidan "',
      confirmRemoveReceiverTitleEnd: '"?',
      confirmRemoveReceiverBody: 'När en länkning till sidan tas bort, raderas inte meddelanden som redan har sparats på Hymy.'
    },
    store: {
      title: 'Butiksspecifika inställningar',
      select: 'Välj butik',
      noStoreSelected: 'Ingen vald butik',
      showGreeting: 'Visa butikens hälsning på responsblanketten',
      showRetailerImage: 'Visa köpmannens bild på responsblanketten',
      preview: 'Förhandsvisning',
      greetingTitle: 'Hälsningstext som visas på responsblanketten',
      greetingFi: {
        title: 'På finska',
        maxLength: 'Hälsningen får bestå av högst 256 tecken'
      },
      greetingSv: {
        title: 'På svenska',
        maxLength: 'Hälsningen får bestå av högst 256 tecken'
      },
      greetingEn: {
        title: 'På engelska',
        maxLength: 'Hälsningen får bestå av högst 256 tecken'
      },
      offerRequestTransferEmail: {
        title: 'Vidarebefordra offertbegäran till butikens e-postadress',
        email: 'Kontrollera e-postadressen',
        wrongDomain: 'E-postadress är inte tillåten'
      },
      offerRequestProTransferEmail: {
        title: 'Vidarebefordra K-Rauta PRO offertbegäran till butikens e-postadress',
        email: 'Kontrollera e-postadressen',
        wrongDomain: 'E-postadress är inte tillåten'
      }
    },
    responseTemplates: {
      title: 'Svarsbottnar',
      description: 'På den här sidan kan du bearbeta svarsbottnarna du använder. Ta i beaktande, att svarsbottnarna inte är personliga, utan även andra som använder Hymy kan se ändringarna du har gjort. Bearbeta svarsbottnarna med eftertanke, speciellt när du benämner dem.',
      select: 'Välj svarsbotten',
      categoriesTitle: 'Svarsbottnens kategorier',
      template: {
        title: 'Svarsbottnens text',
        required: 'Svarsbottnen kan inte vara tom',
        maxLength: 'Svarsbottnen får innehålla högst 50 000 tecken'
      },
      name: {
        title: 'Svarsbottnens namn',
        required: 'Svarsbottnens namn kan inte vara tomt',
        maxLength: 'Svarsbottnens namn kan vara högst 100 tecken långt'
      }
    }
  },
  receiverFilter: {
    receiverTypes: {
      all: 'Alla',
      notSelected: 'Ej vald',
      chainAndStore: 'Kedjor och butiker',
      chain: 'Endast kedjor',
      store: 'Endast butiker',
      subject: 'Ämnen',
      kesko: 'Endast Kesko PT',
      krauta: 'Endast K-Rauta',
      kplussa: 'Endast K-Plussa',
      intersport: 'Endast Intersport',
      kauto: 'Endast K-Auto',
      onninen: 'Endast Onninen',
      budgetsport: 'Endast Budget Sport'
    },
    filterRows: {
      receiverType: {
        label: 'Avgränsning',
        notSelected: 'Välj avgränsning',
        chainUnit: 'Kedja',
        businessUnitInclusive: 'Butiker',
        businessUnitExclusive: 'Men ej butiker',
        businessUnitIds: 'Numren på butikernas verksamhetsställen',
        subject: 'Ämne'
      },
      chainUnit: {
        label: 'Kedja',
        notSelected: 'Välj kedja'
      },
      chainUnitArea: {
        label: 'Område',
        notSelected: 'Alla områden'
      },
      businessUnit: {
        label: 'Butikens namn eller handelsenhetens nummer',
        notSelected: 'Skriv in butikerna',
        placeholder: 'Butik'
      },
      businessUnitIds: {
        label: 'Numren på butikernas verksamhetsställen',
        placeholder: 'Avskilj verksamhetsställenas nummer med ett mellanslag'
      },
      subject: {
        label: 'Ämne',
        notSelected: 'Alla ämnena'
      }
    },
    addReceiverFilter: 'Inför en preciserande avgränsning'
  },
  reporting: {
    title: 'Responsrapport',
    messages: {
      title: 'Rapport om responser och meddelandekedja'
    },
    summary: {
      title: 'Översikt (beskrivare)',
      pies: {
        status: {
          title: 'Responsens status'
        },
        sentiment: {
          title: 'Responsens ton',
          POSITIVE: 'Positiv',
          NEUTRAL: 'Neutral',
          NEGATIVE: 'Negativ'
        },
        requiresResponse: {
          title: 'Ska besvaras',
          yes: 'Ja',
          no: 'Nej'
        }
      },
      trend: {
        title: 'Responsantal',
        types: {
          all: 'Alla',
          other: 'Annan'
        }
      },
      topics: {
        title: 'Samtalsämnen',
        heading: {
          negative: 'neg',
          neutral: 'neu',
          positive: 'pos'
        },
        unknownTopic: 'Icke definierad samtalsämne'
      },
      total: 'Alla responser',
      responseAverage: 'Medeltal för svarstiderna',
      reactAverage: 'Medeltal för reaktionstiderna'
    },
    form: {
      fields: {
        receiver: {
          label: 'Mottagare av responsen'
        },
        channel: {
          label: 'Responskälla',
          placeholder: '',
          notSelected: 'Allt'
        },
        dateRange: {
          label: 'Tidsintervall',
          placeholder: 'Välj tidsintervall',
          clear: 'Töm'
        },
        status: {
          label: 'Status',
          placeholder: '',
          notSelected: 'Allt'
        },
        requiresResponse: {
          label: 'Svar har begärts',
          notSelected: 'Allt',
          options: {
            true: 'Ja',
            false: 'Nej'
          }
        },
        sentiment: {
          label: 'Responsens ton',
          notSelected: 'Allt',
          placeholder: '',
          options: {
            HIGHLY_POSITIVE: 'Mycket positiv',
            POSITIVE: 'Positiv',
            NEUTRAL: 'Neutral',
            NEGATIVE: 'Negativ',
            HIGHLY_NEGATIVE: 'Mycket negativ'
          }
        },
        topic: {
          label: 'Samtalsämne',
          placeholder: '',
          notSelected: 'Ingen avgränsning'
        },
        handler: {
          label: 'Hanterare',
          placeholder: '',
          notSelected: 'Ingen avgränsning'
        },
        searchString: {
          label: 'Sökord',
          placeholder: 'Skilj åt sökorden med mellanslag. Du kan också söka med delar av ord.',
          hint: 'Sökningen ersätter mellanrum mellan två ord med AND, så att alla ord måste finnas i sökresultatet.\u000ADu kan begränsa sökningen med orden OR och NOT.\u000ATill exempel "sko OR stövel" visar de meddelanden som innehåller antingen ordet "sko" eller ordet "stövel".\u000ASökningen "bank NOT kort" visar resultat i vilka ordet "bank" finns med, men inte ordet "kort".'
        },
        feedbackIds: {
          label: 'Responsens identifikation',
          placeholder: 'Skilj åt identifikationen vid behov med mellanslag'
        },
        store: {
          label: 'Butik',
          notSelected: 'Ingen avgränsning'
        }
      },
      load: 'Ladda',
      submit: 'Sök'
    },
    responseSummary: {
      title: 'Översikt (svarstider)',
      loadKeskoSummary: 'Ladda Kesko-sammandrag',
      loadSelectedSummary: 'Ladda valt sammandrag'
    },
    results: {
      paging: {
        showingResults: 'Resultaten visas',
        noResults: 'Inga resultat',
        next: 'Följande »',
        previous: '« Föregående'
      },
      labels: {
        date: 'Datum',
        status: 'Status',
        requiresResponse: 'Ska besvaras',
        handler: 'Hanterare',
        receiver: 'Mottagare',
        feedback: 'Respons',
        analysisTopics: 'Samtalsämnen',
        totalCount: 'Responser sammanlagt st.',
        notAnsweredCount: 'Väntar på svar st.',
        newAndNoResponseRequiredCount: 'Nya, som inte besvaras st.',
        negatives: 'Negativa st.',
        neutrals: 'Neutrala st.',
        positives: 'Positiva st.',
        averageReactTime: 'Reaktionstid (medeltal, hh:mm)',
        averageResponseTime: 'Svarstid (medeltal, hh:mm)',
        averageHandlingTime: 'Behandlingstid (medeltal, hh:mm)',
        id: 'Identifikation',
        messages: 'Kundmeddelanden',
        comments: 'Interna meddelanden'
      },
      notes: {
        averageReactTime: 'Medeltal för hur länge det tar från att respons som kräver svar har inkommit till Hymy tills någon funktion vidtas t.ex. byte av hanterare, kommentar, förmedling eller svar på responsen. Ingen reaktionstid införs enbart för att responsen öppnas.',
        averageResponseTime: 'Medeltal för hur länge det tar från att respons som kräver svar har inkommit till Hymy tills kunden besvaras första gången (Skicka svar/Ringt, oavslutat eller Skicka svar/Ringt, behandlat).',
        averageHandlingTime: 'Genomsnittlig tid från att responsen inkom på Hymy tills den har märkts som hanterad.'
      },
      requiresResponse: {
        true: 'Ja',
        false: 'Nej'
      }
    },
    receivers: {
      title: 'Översikt (tabell)',
      types: {
        all: 'Alla',
        other: 'Annan'
      }
    },
    error: 'Det skedde ett fel i sökningen av rapporten. Kontrollera sökvillkoren för rapporten eller ta kontakt med Hymys support (hymy.support@kesko.fi).'
  },
  error: {
    notFound: {
      title: 'Sidan kunde inte hittas',
      text: 'Sidan du söker kunde tyvärr inte hittas eller du saknar behörigheter till den.',
      goBack: 'Tillbaka'
    },
    genericError: 'Åhå, ett fel inträffade. Var god och kontrollera din internetanslutning, uppdatera sidan och försök på nytt. Ta kontakt med Hymys support, om problemet upprepas.'
  },
  language: {
    fi: 'Finska',
    sv: 'Svenska'
  },
  tokenInput: {
    clearAllText: 'Radera',
    clearValueText: 'Radera',
    noResultsText: 'Inga resultat'
  },
  giveFeedback: {
    title: 'Ge respons på Hymy',
    note: 'Med den här blanketten kan du skicka ris, rosor och utvecklingsförslag om Hymy. Till meddelandet fogas automatisk responsgivarens namn och e-postadress. Uppge responsens identifikation, om din respons gäller en särskild respons.',
    save: 'Skicka',
    cancel: 'Avbryt',
    saveFailed: 'Responsen kunde inte skickas',
    fields: {
      feedback: {
        placeholder: 'Respons *',
        required: 'Skriv responsen'
      },
      responseRequested: {
        label: 'Jag önskar få svar på min respons'
      }
    }
  },
  support: {
    title: 'Anvisningar för Hymy',
    store: 'Butik',
    kesko: 'Kesko',
    storeLinks: {
      hymyKnet: {
        linkText: 'Hymys bruksanvisning på K-netti',
        url: 'https://keskogroup.sharepoint.com/sites/k-netti-ohjeet/SitePages/Hymyn-j%C3%A4rjestelm%C3%A4ohje.aspx'
      },
      dreambrokerKcm: {
        linkText: 'Hymys hjälpvideor för KCM KT',
        url: 'https://www.dreambroker.com/channel/ccwiza6u'
      },
      kcmMyk: {
        beforeLinkText: 'Hymys bruksanvisning finns i ',
        linkText: 'KCM KT MyK',
        afterLinkText: ' Tavaratalotoiminnot > Ohjeet > Kaupan työohjeet > HYMY-palautejärjestelmä',
        url: 'https://keskogroup.sharepoint.com/sites/myk-kcm/tavaratalotoiminnot/ohjeet/kaupantyoohjeet/Pages/default.aspx'
      },
      kcmMykHymy: {
        linkText: 'Hymys bruksanvisning för KCM KT',
        url: 'https://keskogroup.sharepoint.com/sites/myk-kcm/tavaratalotoiminnot/asiakkaanaani/Hymy-palautej%c3%a4rjestelm%c3%a4/Documents/Hymy%20ohje%20kauppa.pdf'
      }
    },
    keskoLinks: {
      dreamBroker: {
        linkText: 'Hymys hjälpvideor',
        url: 'https://www.dreambroker.com/channel/esbxab00'
      },
      myk: {
        linkText: 'Hymys sida på MyK',
        url: 'https://keskogroup.sharepoint.com/sites/myk-fi/yhteisetkaytannot/branditjamarkkinointi/asiakkaanaani/Pages/Hymy-asiakaspalautej%C3%A4rjestelma.aspx'
      },
      keskoSharepoint: {
        linkText: 'Hymys bruksanvisning för keskoanställda',
        url: 'https://keskogroup.sharepoint.com/:b:/r/teams/PT-Laatuohjelma/Yleiset%20ja%20taustaaineistot/Hymy%20ohje%20keskolainen.pdf?csf=1&e=82FK5y'
      },
      storeSharepoint: {
        linkText: 'Hymys bruksanvisning för butikerna',
        url: 'https://keskogroup.sharepoint.com/:b:/r/teams/PT-Laatuohjelma/Yleiset%20ja%20taustaaineistot/Hymy%20ohje%20kauppa.pdf?csf=1&e=TSBvl1'
      }
    }
  },
  datePicker: {
    range: {
      noLimit: 'Ingen avgränsning',
      previousWeek: 'Föregående vecka',
      previousMonth: 'Föregående månad',
      previousYear: 'Föregående år',
      last14days: 'De senaste 14 dagarna',
      last30days: 'De senaste 30 dagarna',
      currentYear: 'Innevarande år',
      custom: 'Fritt tidsinternvall'
    }
  }
}
