import { clear, init } from './FeedbackListActions'
import { Status, OrderBy } from 'domain'
import { defined } from 'utils'

export const QuickFilterActions = {
  SET: 'QuickFilter/Set',
  CLEAR: 'QuickFilter/Clear'
}

export const setFilter = (filter, feedbackListId, activeFilter) => ({
  type: QuickFilterActions.SET,
  filter: defined(filter, 'filter'),
  feedbackListId: defined(feedbackListId, 'feedbackListId'),
  activeFilter: defined(activeFilter, 'activeFilter')
})

export const deduceActiveFilterName = (filter) => defined(
  (filter.requiresResponse && 'requiresResponse') || (filter.status && filter.assignedToUserId && 'myWaiting') || (filter.assignedToUserId && 'myFeedback') || filter.status,
  'activeFilter should be requiresResponse, myWaiting, myFeedback or status'
)

export const filterNameToFilter = (filterName, userId) => {
  if (filterName === 'requiresResponse') {
    return {
      requiresResponse: true,
      status: [ Status.NEW, Status.WAITING ],
      orderBy: OrderBy.modified
    }
  } else if (filterName === 'myFeedback') {
    return {
      assignedToUserId: userId,
      ignoreProfile: true
    }
  } else if (filterName === 'myWaiting') {
    return {
      assignedToUserId: userId,
      ignoreProfile: true,
      status: [Status.WAITING],
      orderBy: OrderBy.modified
    }
  } else {
    return { status: filterName, orderBy: filterName === 'WAITING' ? OrderBy.modified : OrderBy.created }
  }
}

export const applyFilter = (filter) => (dispatch) => {
  const initFeedbackListAction = init(filter)
  const { feedbackListId } = initFeedbackListAction
  const activeFilter = deduceActiveFilterName(filter)
  dispatch(initFeedbackListAction)
  dispatch(setFilter(filter, feedbackListId, activeFilter))
}

export const clearFilter = () => (dispatch, getState) => {
  const feedbackListId = getState().getIn(['quickFilter', 'feedbackListId'])
  if (feedbackListId) {
    dispatch(clear(feedbackListId))
  }
  dispatch({ type: QuickFilterActions.CLEAR })
}

export const toggleFilter = (filterName, additionalFilter = {}) => (dispatch, getState) => {
  const currentFilter = getState().getIn(['quickFilter', 'activeFilter'])
  if (currentFilter) {
    dispatch(clearFilter())
  }
  if (currentFilter !== filterName) {
    const userId = getState().getIn(['client', 'userInfo', 'userId'])
    const filter = filterNameToFilter(filterName, userId)
    dispatch(applyFilter({
      ...filter,
      ...additionalFilter
    }))
  }
}

/**
 * If there is a set quick filter active, reset it for the given additionalFilter
 * Useful for, e.g., when navigating to a different context when the filter is set.
 */
export const resetFilter = (additionalFilter) => (dispatch, getState) => {
  const state = getState()
  const currentFilter = state.getIn(['quickFilter', 'activeFilter'])
  const activeFeedbackListId = getState().getIn(['quickFilter', 'feedbackListId'])
  if (currentFilter) {
    const userId = getState().getIn(['client', 'userInfo', 'userId'])
    const filter = filterNameToFilter(currentFilter, userId)
    dispatch(applyFilter({
      ...filter,
      ...additionalFilter
    }))
    dispatch(clear(activeFeedbackListId))
  }
}
