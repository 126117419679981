import Serializable from './Serializable'

class ResponseDraft extends Serializable {
  constructor(data) {
    super(data, 'ResponseDraft')
  }

  get messageText() {
    return this._data.get('messageText')
  }

  get modified() {
    return this._data.get('modified')
  }

  get userName() {
    return this._data.get('userName')
  }

  get attachments() {
    return this._data.get('attachments')
  }

}

export default ResponseDraft
