import { init, addFeedbacks, bindFeedbackList as bindList, startLoading, endLoading } from './FeedbackListActions'
import { fetchBusinessUnit } from './ClientActions'
import { defined } from 'utils'
import { ReceiverType } from 'domain'

export const BusinessUnitActions = {
  LOAD: 'BusinessUnit/Info/LoadedFromServer'
}

export const loadBusinessUnit = (businessUnit) => ({
  type: BusinessUnitActions.LOAD,
  payload: { businessUnit }
})

export const bindFeedbackList = (businessUnitId, feedbackListId) => dispatch => {
  dispatch(bindList(`businessUnit/${businessUnitId}`, feedbackListId))
}

const initFeedbackListIfNeeded = businessUnitId => (dispatch, getState) => new Promise(resolve => {
  const feedbackListId = getState().getIn(['feedbackLists', 'bindings', `businessUnit/${businessUnitId}`])
  if (feedbackListId) {
    resolve(feedbackListId)
  } else {
    const initFeedbackList = init({
      businessUnitId,
      receiverType: ReceiverType.store
    })
    dispatch(initFeedbackList)
    dispatch(bindFeedbackList(businessUnitId, initFeedbackList.feedbackListId))
    resolve(initFeedbackList.feedbackListId)
  }
})

export const getOrFetchBusinessUnit = businessUnitId => (dispatch, getState) => new Promise((resolve, reject) => {
  const businessUnit = getState().getIn(['businessUnits', defined(businessUnitId, 'getOrFetchBusinessUnit > businessUnitId'), 'info'])
  if (businessUnit) {
    resolve(businessUnit)
  } else {
    dispatch(fetchBusinessUnit(businessUnitId)).then(resolve, reject)
  }
})

/**
 * @param businessUnit
 */
export const loadAndInitializeBusinessUnit = (businessUnit) => (dispatch) => {
  dispatch(loadBusinessUnit(businessUnit))
  dispatch(initFeedbackListIfNeeded(businessUnit.id))
}

export const triggerStartLoading = businessUnitId => dispatch =>
  dispatch(initFeedbackListIfNeeded(businessUnitId))
    .then(feedbackListId => dispatch(startLoading(feedbackListId)))

export const triggerEndLoading = businessUnitId => dispatch =>
  dispatch(initFeedbackListIfNeeded(businessUnitId))
    .then(feedbackListId => dispatch(endLoading(feedbackListId)))

export const loadBusinessUnitFeedbacks = (businessUnitId, feedbacks, hasMore) => (dispatch) =>
  dispatch(initFeedbackListIfNeeded(businessUnitId))
    .then(feedbackListId => dispatch(addFeedbacks(feedbackListId, feedbacks, hasMore)))
