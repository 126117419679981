import React from 'react'
import { BaseButton } from 'components/kesko/common'

class OutlineButton extends BaseButton {
  render() {
    return (
      <BaseButton {...this.props}/>
    );
  }
}

OutlineButton.defaultProps = {
  baseClasses: ['btn', 'btn-outline', 'extra-bold'],
  color: 'black'
};

export default OutlineButton
