import React, { PropTypes } from 'react'
import Text from '../../containers/Text/Text';

export default class ToggleButton extends React.Component {
  render() {
    const {translation, toggled, onClick, className} = this.props;
    return (
      <div className={`toggle-button ${toggled ? 'toggled' : ''} ${className ? className : ''}`} onClick={onClick}>
        <Text k={translation} />
      </div>
    );
  }
}

ToggleButton.propTypes = {
  translation: PropTypes.string.isRequired,
  toggled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};
