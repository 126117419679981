import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux'
import { hashHistory } from 'react-router'

import rootReducer from 'reducers'

import Log from 'utils/Log'

import Client, { clientMiddleware } from 'service/Client'
import Session from 'service/Session'
import { updateToken } from 'actions/LoginActions'

const noop = a => a

const maybeAddDevTools = () => {
  if (process.env.NODE_ENV !== 'production') {
    Log.debug('ADDING THE DEV TOOLS!')
    const DevTools = require('containers/DevTools').default;
    return DevTools.instrument()
  }
  return noop;
}

const loggingMiddleware = () => next => action => {
  Log.trace(action.type)
  return next(action)
}

const createStoreWithOptions = ({client, history}) => compose(
  applyMiddleware(
    loggingMiddleware,
    clientMiddleware(client),
    thunk,
    routerMiddleware(history)
  ),
  maybeAddDevTools()
)(createStore)


export default function configureStore(initialState, options = {client: new Client(), history: hashHistory}) {
  const store = createStoreWithOptions(options)(rootReducer, initialState)

  Session.create(
    () => store.getState().getIn(['client', 'token']),
    token => store.dispatch(updateToken(token)),
    () => store.getState().getIn(['client', 'userInfo'])
  ).refreshIfTokenNotSet()

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default;
      store.replaceReducer(nextReducer)
    })
  }

  return store
}
