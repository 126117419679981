import React, { Component, PropTypes } from 'react';

import { getText } from './utils'

/**
 * Higher-order component to translate the content.
 * Will override the children
 * Requires <TextProvider /> supply the context
 */
const TranslateContent = OriginalComponent => {
  class Extended extends Component {
    static contextTypes = {
      translations: PropTypes.object.isRequired
    };

    static propTypes = {
      translateContent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
      ]).isRequired
    };

    constructor(props) {
      super(props)
      this.bindOriginal = this.bindOriginal.bind(this)
    }

    bindOriginal(component) {
      this._original = component
    }

    getWrappedInstance() {
      return this._original
    }

    render() {
      const { translateContent, ...props } = this.props
      if (this.props.children && React.children.count(this.props.children) > 0) {
        throw 'TranslateContent added to a component with children: ' + OriginalComponent
      }
      const translations = this.context.translations;
      return (
        <OriginalComponent ref={this.bindOriginal} {...props}>
          {getText(translations, translateContent)}
        </OriginalComponent>
      )
    }
  }

  return Extended
}

export default TranslateContent
