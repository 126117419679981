
const Sentiment = {
  HIGHLY_POSITIVE: 'HIGHLY_POSITIVE',
  POSITIVE: 'POSITIVE',
  NEUTRAL: 'NEUTRAL',
  NEGATIVE: 'NEGATIVE',
  HIGHLY_NEGATIVE: 'HIGHLY_NEGATIVE'
}

export default Sentiment
