import { List } from 'immutable'

import Serializable from './Serializable'
import Handler from './Handler'

class BusinessUnit extends Serializable {
  constructor(data) {
    super(data, 'BusinessUnit')
    this._chainId = this._data.get('chainId');
    this._chainName = this._data.get('marketingName').split(/\ (.+)?/) [0]
    this._storeName = this._data.get('marketingName').split(/\ (.+)?/) [1]
    this._handlers = this._data.get('handlers', List())
      .map(h => new Handler(h))
      .sortBy(h => h.name)
  }

  get id() {
    return this._data.get('businessUnitId')
  }

  get name() {
    return this._data.get('marketingName')
  }

  get chainName() {
    return this._chainName
  }

  get chainId() {
    return this._chainId;
  }

  get storeName() {
    return this._storeName
  }

  get handlers() {
    return this._handlers
  }

  get isAuthorized() {
    return this._data.get('isAuthorized')
  }

  get isActive() {
    return this._data.get('isActive')
  }
}

export default BusinessUnit
