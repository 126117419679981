import React  from 'react'
import PureComponent from 'react-pure-render/component'

import { TextInput } from 'components/Forms/common'

/**
 * Renders the correct search keyboard on iOS devices
 * with the correct "Search" button shown.
 *
 * Requires wrapper: <form action="."></form>
 */
class SearchInput extends PureComponent {
  render() {
    const { style, ...rest } = this.props
    return (
      <TextInput
        {...rest}
        type="search"
        style={{ ...style, boxSizing: 'border-box', WebkitAppearance: 'none' }}
      />
    )
  }
}

export default SearchInput
