import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { Header } from 'components/Menu'
import { openMenu } from 'actions/MenuActions'
import { searchAndRedirect } from 'actions/SearchActions'
import { RouteUtil } from 'routes'

export default connect(() => ({}), dispatch => ({
  openMenu: () => dispatch(openMenu()),
  search: (query) => dispatch(searchAndRedirect(query.search)),
  toHomePage: () => dispatch(push(RouteUtil.home()))
}))(Header)
