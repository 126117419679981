import { fromJS } from 'immutable'
import { MenuActions } from 'actions/MenuActions'
import createReducer from './createReducer'

const initialState = fromJS({})

export const reducer = createReducer(initialState, {
  [MenuActions.OPEN]:  (state) => state.set('open', true),
  [MenuActions.CLOSE]: (state) => state.set('open', false)
})
