import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form'

import { Col } from 'components/grid'
import { ExclamatedNote, TextWithHeading } from 'components/kesko'
import { Text } from 'containers/Text'
import { Navigator } from 'components/Menu'
import { Feedback } from 'domain'
import { ErrorBlock, TokenInput, TextArea, LabeledInput } from 'components/Forms'
import { createValidator, maxLength } from 'utils/Validation'
import SaveToLocalStorage from 'components/Forms/SaveToLocalStorage'

import FeedbackComments from './FeedbackComments'
import FeedbackMessages from './FeedbackMessages'
import FacebookMessageHistory from './FacebookMessageHistory'
import CollapsingCustomerInfo from './CollapsingCustomerInfo'
import Button from '../kesko/common/Button';

const SavingTextArea = SaveToLocalStorage(TextArea)

const statusToOption = status => ({
  k: ['feedback', 'adminTools', status, 'label'],
  id: status,
  value: status
})

class BaseAdminToolsForm extends Component {

  static propTypes = {
    possibleStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
    initialValues: PropTypes.shape({
      status: PropTypes.string,
      comment: PropTypes.string
    })
  };

  static defaultProps = {
    updateStatus: () => {
    }
  }

  bindTextArea = (area) => {
    this._savingTextArea = area
  };

  forgetStoredCommentIfSuccessful = (cb) => {
    return function (event) {
      const promise = cb(event)
      promise.then(this._savingTextArea.clear)
      return promise
    }.bind(this)
  }

  render() {
    const {fields, fields: {status, comment}, handleSubmit, onCancel, possibleStatuses, objectedError, submitting, localStorageKey, customer} = this.props

    return (
      <form className="basic-form">

        <ErrorBlock fields={fields} submitError={objectedError} className="mb2" translatePrefix="feedback.adminTools.objectedError" />

        <Col className="mb2">
          <TokenInput
            disabled={submitting}
            options={possibleStatuses}
            multiple={false}
            showNotSelected={false}
            optionMapper={statusToOption}
            translate={{
              notSelectedLabel: 'feedback.adminTools.fields.status.placeholder'
            }}
            {...status}
          />
        </Col>

        {!!status.value && (
          <Col className="mb2">
            <ExclamatedNote translate={{note: 'feedback.adminTools.' + status.value + '.note'}} />
          </Col>
        )}

        {status.value == 'usage_objected' && customer && (
          <Col className="mb2">
            <span>
              <h3 className="blue mt1 mb1"><Text k="feedback.adminTools.usage_objected.toBeObjected" /></h3>
              <TextWithHeading
                heading="feedback.customer.email"
                body={customer.email}
              />
              <TextWithHeading
                heading="feedback.customer.phone"
                body={customer.phone}
              />
              </span>
          </Col>
        )}

        <Col className="mb2">
          <LabeledInput labelKey="feedback.adminTools.fields.comment.placeholder">
            <SavingTextArea
              ref={this.bindTextArea}
              rows="5"
              disabled={submitting}
              localStorageKey={localStorageKey}
              {...comment}
            />
          </LabeledInput>
        </Col>

        <div className="form-buttons-group">
          <Button
            onClick={onCancel}
            translation="feedback.adminTools.cancel"
            disabled={submitting}
            theme={Button.theme.cancel}
          />
          <Button
            onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(this.props.updateStatus))}
            translation="feedback.adminTools.save"
            type="submit"
            disabled={submitting || !status.value}
            theme={Button.theme.primary}
          />
        </div>

      </form>
    )
  }
}

export const AdminToolsForm = reduxForm({
  form: 'adminTools',
  fields: ['status', 'comment'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    comment: [maxLength(255)]
  })
})(BaseAdminToolsForm)

class AdminToolsView extends Component {

  static propTypes = {
    updateStatus: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    feedback: PropTypes.instanceOf(Feedback).isRequired,
    possibleActions: PropTypes.arrayOf(PropTypes.string)
  }

  render() {
    const {updateStatus, cancel, feedback, possibleStatuses} = this.props
    return (
      <section className="px1">
        <Navigator backUrl=".." />
        <Col>
          <hr />
        </Col>
        <CollapsingCustomerInfo collapseId={`AdminToolsView-${feedback.id}-customerinfo`}
                                customer={feedback.customer}
                                initiallyVisible={false}
        />
        <FacebookMessageHistory collapseId={`AdminToolsView-${feedback.id}-facebookHistoryMessages`}
                                facebookHistoryMessages={feedback.facebookHistoryMessages}
                                initiallyVisible={false}
        />
        <FeedbackMessages collapseId={`AdminToolsView-${feedback.id}-messages`}
                          messages={feedback.messages}
                          showEditLinks={false}
                          initiallyVisible={false}
        />
        <FeedbackComments collapseId={`AdminToolsView-${feedback.id}-comments`}
                          comments={feedback.comments}
                          showEditLinks={false}
                          showNote={false}
                          initiallyVisible={false}
        />
        <Col>
          <hr />
        </Col>

        <Col><h2 className="blue mt3 mb3"><Text k="feedback.adminTools.title" /></h2></Col>

        <AdminToolsForm
          possibleStatuses={possibleStatuses}
          updateStatus={updateStatus}
          onCancel={cancel}
          customer={feedback.customer}
          localStorageKey={`${feedback.id}-adminTools`}
        />

      </section>
    )
  }
}

export default AdminToolsView
