import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { FeedbackListFilters } from 'components/Feedback'
import { fetchFeedbacks, fetchMoreFeedbacks } from 'actions/FeedbackListActions'
import { toggleFilter, resetFilter } from 'actions/QuickFilterActions'
import { changeContext } from 'actions/ContextActions'
import { ContextType } from 'domain'
import { WaitFor } from 'components/Utils'

import RouteUtil from './RouteUtil'
import FeedbackListRoute from './FeedbackListRoute'

export class BusinessUnitRoute extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      fetchFeedbacks: PropTypes.func.isRequired,
      fetchMoreFeedbacks: PropTypes.func.isRequired,
      toggleFilter: PropTypes.func.isRequired
    })
  };

  componentDidMount() {
    this.props.actions.bindContext(this.props.businessUnitId)
    this.props.actions.resetQuickFilter(this.props.businessUnitId)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.businessUnitId && newProps.businessUnitId !== this.props.businessUnitId) {
      this.props.actions.bindContext(newProps.businessUnitId)
      this.props.actions.resetQuickFilter(newProps.businessUnitId)
    }
  }

  render() {
    const { feedbackList, actions, actions: { toggleFilter }, activeFilter, location } = this.props
    return (
      <WaitFor condition={!!feedbackList}>
        <div>
          <FeedbackListFilters activeFilter={activeFilter} actions={{ toggleFilter }} />
          <FeedbackListRoute
            feedbackList={feedbackList}
            actions={actions}
            location={location}
          />
        </div>
      </WaitFor>
    )
  }
}


const EMPTY_MAP = Map({})
function mapStateToProps(state, props) {
  const businessUnitId = props.params.businessUnitId

  const activeFilterListId = state.getIn(['quickFilter', 'feedbackListId'])
  const feedbackListId = activeFilterListId || state.getIn(['feedbackLists', 'bindings', `businessUnit/${businessUnitId}`])
  const feedbackList = state.getIn(['feedbackLists', feedbackListId], EMPTY_MAP)
  const activeFilter = state.getIn(['quickFilter', 'activeFilter'])

  return {
    activeFilter,
    businessUnitId,
    feedbackListId,
    feedbackList,
    location: props.location
  };
}

const mapDispatchToProps = (dispatch, props) => ({
  actions: bindActionCreators({
    fetchFeedbacks,
    fetchMoreFeedbacks,
    toggleFilter: filterName => toggleFilter(filterName, { businessUnitId: props.params.businessUnitId }),
    bindContext: (businessUnitId) => businessUnitId && changeContext(ContextType.businessUnit, RouteUtil.businessUnit(businessUnitId)),
    resetQuickFilter: (businessUnitId) => resetFilter({ businessUnitId })
  }, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(BusinessUnitRoute)
