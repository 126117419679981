import Serializable from './Serializable'

class ChainUnitArea extends Serializable {
  constructor(data) {
    super(data, 'ChainUnitArea')
  }

  get id() {
    return this._data.get('chainUnitAreaId')
  }

  get name() {
    return this._data.get('displayName')
  }
}

export default ChainUnitArea
