import React from 'react'
import BaseButton from './BaseButton'

class PrimaryButton extends BaseButton {
  render() {
    return (
      <BaseButton {...this.props}/>
    );
  }
}

PrimaryButton.defaultProps = {
  baseClasses: ['btn', 'btn-primary', 'extra-bold', 'fill', 'px1'],
  bg: 'orange',
  color: 'white'
};

export default PrimaryButton
