import { fromJS } from 'immutable'

import createReducer from './createReducer'
import { DialogActions } from 'actions/DialogActions'

export const reducer = createReducer(fromJS({ }), {
  [DialogActions.OPEN]: (state, { dialogId }) => state.setIn([dialogId, 'open'], true),
  [DialogActions.CLOSE]: (state, { dialogId }) => state.setIn([dialogId, 'open'], false),
  [DialogActions.DESTROY]: (state, { dialogId }) => state.delete(dialogId)
})
