const DateRangeType = {
  previousWeek: 'previousWeek',
  previousMonth: 'previousMonth',
  previousYear: 'previousYear',
  last14days: 'last14days',
  last30days: 'last30days',
  currentYear: 'currentYear',
  custom: 'custom'
}
export default DateRangeType
