import { uploadAttachment as clientUploadAttachment } from './ClientActions'

export const AttachmentActions = {
  ADD: 'attachment/add',
  UPDATE: 'attachment/update',
  REMOVE: 'attachment/remove',
  CLEAR: 'attachment/clear'
}

export const addAttachment = file => dispatch => dispatch({
  type: AttachmentActions.ADD,
  file
})

export const updateAttachment = file => dispatch => dispatch({
  type: AttachmentActions.UPDATE,
  file
})

export const removeAttachment = file => dispatch => dispatch({
  type: AttachmentActions.REMOVE,
  file
})

export const clearAttachments = () => dispatch => dispatch({
  type: AttachmentActions.CLEAR
})

export const uploadAttachment = formData => dispatch =>
  dispatch(clientUploadAttachment(formData))
