import React, { Component, PropTypes } from 'react'
import { List } from 'immutable'

import { Col } from 'components/grid'
import { TriangledTitle } from 'components/kesko'

import Text from 'containers/Text'
import { CollapsingContainer } from 'containers/Layout'

import { Message } from 'domain'
import MessagesBlock from './MessagesBlock'

class FacebookMessageHistory extends Component {

  constructor(props) {
    super(props)
    this.renderTitle = this.renderTitle.bind(this)
  }

  renderTitle({ visible, handleClick }) {
    const { facebookHistoryMessages } = this.props
    const titleElement = (
      <span>
        <Text k="feedback.messageHistory"/>
        <span> ({facebookHistoryMessages.size})</span>
      </span>
    )
    return (
      <TriangledTitle
        titleElement={titleElement}
        rightSideLink={false}
        toggled={visible}
        onClick={handleClick}
      />
    )
  }

  render() {
    const { collapseId, facebookHistoryMessages, initiallyVisible } = this.props
    return facebookHistoryMessages && facebookHistoryMessages.size > 0 && (
      <Col>
        <CollapsingContainer collapseId={collapseId} initiallyVisible={initiallyVisible} title={this.renderTitle}>
          <MessagesBlock messages={facebookHistoryMessages} />
        </CollapsingContainer>
      </Col>
    )
  }
}

FacebookMessageHistory.defaultProps = {
  initiallyVisible: false
}

FacebookMessageHistory.propTypes = {
  collapseId: PropTypes.any.isRequired,
  initiallyVisible: PropTypes.bool.isRequired,
  facebookHistoryMessages: PropTypes.oneOfType([
    PropTypes.arrayOf(Message),
    PropTypes.instanceOf(List)
  ]).isRequired
}

export default FacebookMessageHistory
