import React, { Component, PropTypes } from 'react'

import { List } from 'immutable'
import { getText } from 'containers/Text/utils'
import TextWithIcon from 'components/kesko/TextWithIcon';
import KeskoIcons from 'components/Icons/KeskoIcons';
import { TranslateChildrenProps } from 'containers/Text'
import { Confirmation } from 'containers/Dialog'

class FacebookReceiverRow extends Component {

  static propTypes = {
    receiver: PropTypes.object.isRequired,
    chainUnits: PropTypes.instanceOf(List).isRequired,
    subjects: PropTypes.instanceOf(List).isRequired,
    stores: PropTypes.instanceOf(List).isRequired,
    actions: PropTypes.shape({
      removeFacebookReceiver: PropTypes.func.isRequired
    }).isRequired
  }

  static contextTypes = {
    locale: PropTypes.string,
    translations: PropTypes.instanceOf(Object)
  }

  bindRemoveReceiverConfirmation = (dialog) => this._removeReceiverConfirmation = dialog;

  openRemoveReceiverConfirmation = () => {
    this._removeReceiverConfirmation.open()
  };

  render() {
    const {receiver, chainUnits, subjects, stores, actions: {removeFacebookReceiver}} = this.props

    const removeReceiver = () => {
      removeFacebookReceiver(receiver)
    }

    const chainOrSubjectPrefix = (type) => {
      return getText(this.context.translations, 'settings.facebook.targetType.' + type, '')
    }

    const receiverName = receiver => {
      if (receiver.chainUnitId && receiver.chainUnitId !== '') {
        return chainOrSubjectPrefix('chain') + chainUnits.find(cu => cu.id === receiver.chainUnitId).name
      } else if (receiver.subjectId && receiver.subjectId !== '') {
        return chainOrSubjectPrefix('subject') + subjects.find(s => s.id === receiver.subjectId).localizedName(this.context.locale)
      } else {
        return stores.find(s => s.id === receiver.storeId).name
      }
    }

    const removeReceiverConfirmationTitle = receiver => {
      return getText(this.context.translations, 'settings.facebook.confirmRemoveReceiverTitleStart') + receiver.pageName +
        getText(this.context.translations, 'settings.facebook.confirmRemoveReceiverTitleEnd')
    };

    return (
      <tr className="mb2 silver">
        <td>
          {receiver.pageName}
        </td>
        <td>
          {receiverName(receiver)}
        </td>
        <td>
          {receiver.userName}
        </td>
        <td>
          <TextWithIcon
            className="remove-facebook-receiver"
            onClick={this.openRemoveReceiverConfirmation}
            translation="settings.facebook.receivers.remove"
            icon={KeskoIcons.trash}
          />
          <TranslateChildrenProps>
            <Confirmation
              translate={{
                body: 'settings.facebook.confirmRemoveReceiverBody',
                yesText: 'yes',
                noText: 'no'
              }}
              title={removeReceiverConfirmationTitle(receiver)}
              ref={this.bindRemoveReceiverConfirmation}
              onConfirm={removeReceiver}
            />
          </TranslateChildrenProps>
        </td>
      </tr>
    )
  }
}

export default FacebookReceiverRow
