import React, { PropTypes } from 'react';
import Icon from '../Icons/Icon';
import Text from '../../containers/Text';

const TextWithIconTheme = {
  kesko: 'kesko'
};

const getIcon = (icon) => icon ? (
  <Icon icon={icon} size={Icon.sizes.unset} />
) : null;

const TextWithIcon = ({translation, icon, theme, className, onClick}) => (
  <div className={`text-with-icon ${theme} ${onClick ? 'clickable' : ''} ${className ? className : ''}`} onClick={onClick}>
    {getIcon(icon)}
    <Text k={translation} />
  </div>
);

TextWithIcon.defaultProps = {
  theme: 'kesko'
};

TextWithIcon.propTypes = {
  translation: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.object,
  theme: PropTypes.oneOf(Object.keys(TextWithIconTheme)),
  className: PropTypes.string
};

TextWithIcon.themes = TextWithIconTheme;

export default TextWithIcon;
