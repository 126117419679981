import React, { Component, PropTypes } from 'react';

import { createUuid } from 'utils'
import Dialog from './Dialog'
import Button from '../../components/kesko/common/Button';

class Confirmation extends Component {
  constructor(props) {
    super(props)
    this.uuid = createUuid()
    this.bindDialog = this.bindDialog.bind(this)
    this.confirm = this.confirm.bind(this)
    this.cancel = this.cancel.bind(this)
    this.isOpen = this.isOpen.bind(this)
  }

  bindDialog(dialog) {
    // Since connected to the Redux store, we need to get the original instance
    this._dialog = dialog && dialog.getWrappedInstance()
  }

  open() {
    this._dialog.open()
  }

  isOpen() {
    return this._dialog.isOpen()
  }

  close() {
    this._dialog.close()
  }

  confirm() {
    this.props.onConfirm()
    this.close()
  }

  cancel() {
    this.props.onCancel()
    this.close()
  }

  render() {
    const {title, body, yesText, noText, ...props} = this.props;

    return (
      <Dialog dialogId={this.uuid} {...props} ref={this.bindDialog} titleText={title} bodyText={body}>
        <Button
          translation={yesText}
          onClick={this.confirm}
          theme={Button.theme.primary}
          uppercase
        />
        <Button
          translation={noText}
          onClick={this.cancel}
          theme={Button.theme.cancel}
          uppercase
        />
      </Dialog>
    )
  }
}

Confirmation.defaultProps = {
  title: '',
  body: '',
  yesText: '',
  noText: '',
  onConfirm: () => {
  },
  onCancel: () => {
  }
}
Confirmation.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  yesText: PropTypes.node.isRequired,
  noText: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default Confirmation
