import Serializable from './Serializable'

class Handler extends Serializable {
  constructor(data) {
    super(data, 'Handler')
  }

  get userId() {
    return this._data.get('userId')
  }

  get name() {
    return this._data.get('userName')
  }

  get email() {
    return this._data.get('email')
  }
}

export default Handler
