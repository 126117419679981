/* global console, jsConfig */
/* eslint no-console:0 */

const noop = function() {}
const noopConsole = {
  log: noop,
  info: noop,
  debug: noop,
  error: noop,
  warn: noop
}

const getConsole = () => {
  if (!console) {
    return noopConsole
  }
  return console
}

const cs = getConsole()

const callIfEnabled = function(kind) {
  return function() {
    if ((!jsConfig.log || !!jsConfig.log[kind]) && cs[kind]) {
      cs[kind].apply(cs, arguments)
    }
  }
}

export const debug   = callIfEnabled('debug')
export const error   = callIfEnabled('error')
export const info    = callIfEnabled('info')
export const log     = callIfEnabled('log')
export const trace   = callIfEnabled('trace')
export const warn    = callIfEnabled('warn')

const Log = {
  debug, error, info, log, trace, warn
}
export default Log
