import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { ContextSelectionHeader } from 'containers/Menu'
import { WaitFor } from 'components/Utils'
import { BusinessUnit } from 'domain'

import { fetchBusinessUnit } from 'actions/ClientActions'
import { changeBusinessUnit } from 'actions/LoginActions'
import { show404Page } from 'actions/ErrorActions'

import { noop } from 'utils'

class BusinessUnitRouteWrapper extends Component {
  static propTypes = {
    businessUnitId: PropTypes.string.isRequired,
    businessUnit: PropTypes.instanceOf(BusinessUnit)
  };

  componentDidMount() {
    this.props.actions.fetchBusinessUnit(this.props.businessUnitId)
      .then(noop, this.props.actions.show404Page)
    this.props.actions.changeBusinessUnit(this.props.businessUnitId);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.businessUnitId !== this.props.businessUnitId) {
      this.props.actions.changeBusinessUnit(newProps.businessUnitId);
      this.props.actions.fetchBusinessUnit(newProps.businessUnitId)
        .then(noop, this.props.actions.show404Page)
    }
  }

  render() {
    const { businessUnit } = this.props
    return (
      <WaitFor condition={!!businessUnit}>
        <div className="context-selection-container">
          <ContextSelectionHeader text={businessUnit && businessUnit.name} />
          {this.props.children}
        </div>
      </WaitFor>
    )
  }
}

const mapStateToProps = (state, props) => {
  const businessUnitId = props.params.businessUnitId;
  return {
    businessUnitId,
    businessUnit: state.getIn(['businessUnits', businessUnitId, 'info'])
  }
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchBusinessUnit,
    show404Page,
    changeBusinessUnit
  }, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(BusinessUnitRouteWrapper)
