/* globals window, document */

/**
 * Export the global window and document objects to
 * allow easier mocking in tests
 */
module.exports = {
  window,
  document
}
