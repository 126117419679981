import { createUuid, defined } from 'utils'
import { fetchPreviews } from 'actions/ClientActions'
import { OrderBy } from 'domain'

export const FeedbackListActions = {
  INIT: 'FeedbackList/Init',
  CLEAR: 'FeedbackList/Clear',
  BIND_LIST: 'FeedbackList/Bind',

  ADD_FEEDBACKS: 'FeedbackList/AddFeedbacks',

  START_LOADING: 'FeedbackList/Loading/Start',
  END_LOADING: 'FeedbackList/Loading/End'
}

export const init = (filter, feedbackListId = createUuid()) => ({
  type: FeedbackListActions.INIT,
  feedbackListId,
  filter: defined(filter, 'Filter')
})

export const clear = feedbackListId => ({
  type: FeedbackListActions.CLEAR,
  feedbackListId
})

export const startLoading = (feedbackListId) => ({
  type: FeedbackListActions.START_LOADING,
  feedbackListId
})

export const endLoading = (feedbackListId) => ({
  type: FeedbackListActions.END_LOADING,
  feedbackListId
})

export const addFeedbacks = (feedbackListId, feedbacks, hasMore) => ({
  type: FeedbackListActions.ADD_FEEDBACKS,
  feedbackListId,
  feedbacks,
  hasMore
})

export const bindFeedbackList = (path, feedbackListId) => ({
  type: FeedbackListActions.BIND_LIST,
  path,
  feedbackListId
})

export const fetchFeedbacks = feedbackListId => (dispatch, getState) => new Promise((resolve, reject) => {
  defined(feedbackListId, 'FeedbackListActions#fetchFeedbacks: feedbackListId')
  const filter = defined(getState().getIn(['feedbackLists', feedbackListId, 'filter']), 'FeedbackListActions#fetchFeedbacks: feedback list filter').toJS()
  dispatch(fetchPreviews({ feedbackListId, filter })).then(resolve, reject)
})

export const fetchMoreFeedbacks = feedbackListId => (dispatch, getState) => new Promise((resolve, reject) => {
  defined(feedbackListId, 'FeedbackListActions#fetchMoreFeedbacks: feedbackListId')
  const feedbackList = defined(getState().getIn(['feedbackLists', feedbackListId]), 'FeedbackListActions#fetchMoreFeedbacks: feedback list')
  const filter = feedbackList.get('filter').toJS()
  const lastFeedback = feedbackList.get('lastFeedback')
  const lastFeedbackId = lastFeedback && lastFeedback.id
  const orderBy = filter.orderBy == OrderBy.modified ? OrderBy.modified : OrderBy.created
  const lastDate = lastFeedback && lastFeedback[orderBy].getTime()
  dispatch(fetchPreviews({ feedbackListId, filter, lastFeedbackId, lastDate })).then(resolve, reject)
})
