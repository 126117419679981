import React, { Component, PropTypes } from 'react'

import { Text } from 'containers/Text'
import { ExclamatedNote } from 'components/kesko'

import GiveFeedbackForm from './GiveFeedbackForm'

class GiveFeedbackView extends Component {

  static propTypes = {
    giveFeedback: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      giveFeedback: PropTypes.func.isRequired
    }).isRequired
  };

  render() {
    const { cancel, giveFeedback } = this.props
    return (
      <section className="px1">

        <h1>
          <Text k="giveFeedback.title" />
        </h1>

        <ExclamatedNote
          translate={{ note: 'giveFeedback.note' }}
          className="mb2"
        />

        <GiveFeedbackForm onSubmit={giveFeedback} onCancel={cancel} />

      </section>
    )
  }
}

export default GiveFeedbackView
