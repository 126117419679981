const keskoicon = (id) => ({
  type: 'keskoicon',
  url: () => process.env.NODE_ENV === 'test' ? id : require(`images/svg/icons/${id}.svg`)
});

const KeskoIcons = {
  keskoLogo: keskoicon('icon-kesko-logo'),
  arrowLDown: keskoicon('icon-arrow-l-down'),
  arrowLLeft: keskoicon('icon-arrow-l-left'),
  arrowLUp: keskoicon('icon-arrow-l-up'),
  arrowLRight: keskoicon('icon-arrow-l-right'),
  arrowSDown: keskoicon('icon-arrow-s-down'),
  arrowSLeft: keskoicon('icon-arrow-s-left'),
  arrowSUp: keskoicon('icon-arrow-s-up'),
  arrowSRight: keskoicon('icon-arrow-s-right'),
  plus: keskoicon('icon-plus'),
  menu: keskoicon('icon-menu'),
  cross: keskoicon('icon-cross'),
  profile: keskoicon('icon-profile'),
  settings: keskoicon('icon-settings'),
  idea: keskoicon('icon-idea'),
  faq: keskoicon('icon-FAQ'),
  exit: keskoicon('icon-exit'),
  feedback: keskoicon('icon-feedback'),
  spyglass: keskoicon('icon-spyglass'),
  crossSmall: keskoicon('icon-crossSmall'),
  mail: keskoicon('icon-mail'),
  tick: keskoicon('icon-tick'),
  phone: keskoicon('icon-phone'),
  chat: keskoicon('icon-chat'),
  trash: keskoicon('icon-trash'),
  paperclip: keskoicon('icon-paperclip'),
  comment: keskoicon('icon-comment'),
  reply: keskoicon('icon-reply'),
  tag: keskoicon('icon-tag'),
  info: keskoicon('icon-info'),
  download: keskoicon('icon-download'),
  robot: keskoicon('icon-robot'),
  minus: keskoicon('icon-minus'),
  exclamation: keskoicon('icon-exclamation'),
  happy: keskoicon('icon-happy'),
  happyInactive: keskoicon('icon-happy-inactive'),
  unhappy: keskoicon('icon-unhappy'),
  unhappyInactive: keskoicon('icon-unhappy-inactive'),
  spinner: keskoicon('icon-spinner'),
  facebook: keskoicon('icon-facebook'),
  send: keskoicon('icon-send')
};

export default KeskoIcons;
