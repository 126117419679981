import React, { PropTypes } from 'react'

import { LabeledRadio } from 'components/Forms/common'
import { Text } from 'containers/Text'
import { Locale } from 'domain'

const LanguageSelection = ({language, titleKey}) => (
  <div className="language-selection">
    <h3 className="language-selection-title">
      <Text k={titleKey} />
    </h3>
    <div>
      <LabeledRadio
        {...language}
        name="lang"
        checked={language.value === Locale.fi}
        value={Locale.fi}
        label="language.fi"
      />
    </div>
    <div>
      <LabeledRadio
        {...language}
        name="lang"
        checked={language.value === Locale.sv}
        value={Locale.sv}
        label="language.sv"
      />
    </div>
  </div>
);

LanguageSelection.propTypes = {
  titleKey: PropTypes.string,
  language: PropTypes.any
};

export default LanguageSelection
