import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'

import { Col } from 'components/grid'
import { ErrorBlock, TextInput, LabeledInput } from 'components/Forms'

import { validate as validateContact } from 'components/Utils/Validation/Contact'
import Button from 'components/kesko/common/Button';


class ChangeContactForm extends Component {

  static propTypes = {
    initialValues: PropTypes.shape({
      name: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string
    })
  };

  render() {
    const {handleSubmit, error, onCancel, fields, fields: {name, phone, email}, submitting, invalid} = this.props
    return (
      <form onSubmit={handleSubmit} className="basic-form change-contact-form" noValidate>
        <Col className="clearfix mb2">
          <ErrorBlock
            fields={fields}
            submitError={error}
            translatePrefix="feedback.changeContact.fields" />
        </Col>
        <Col className="mb2">
          <LabeledInput labelKey="feedback.changeContact.fields.name.placeholder">
            <TextInput
              disabled={submitting}
              maxLength={100}
              {...name}
            />
          </LabeledInput>
        </Col>
        <Col className="mb2">
          <LabeledInput labelKey="feedback.changeContact.fields.phone.placeholder">
            <TextInput
              disabled={submitting}
              maxLength={20}
              {...phone}
            />
          </LabeledInput>
        </Col>
        <Col className="mb2">
          <LabeledInput labelKey="feedback.changeContact.fields.email.placeholder">
            <TextInput
              disabled={submitting}
              {...email}
            />
          </LabeledInput>
        </Col>


        <div className="form-buttons-group">
          <Button
            onClick={onCancel}
            translation="feedback.changeContact.cancel"
            disabled={submitting}
            theme={Button.theme.cancel}
          />
          <Button
            translation="feedback.changeContact.save"
            type="submit"
            disabled={submitting || invalid}
            theme={Button.theme.primary}
          />
        </div>

      </form>
    )
  }
}

const ReduxChangeContactForm = reduxForm({
  form: 'changeContact',
  fields: ['name', 'phone', 'email'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: (values) => validateContact(values)
})(ChangeContactForm)
ReduxChangeContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default ReduxChangeContactForm
