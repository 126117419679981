import React, { PropTypes } from 'react'
import PureComponent from 'react-pure-render/component'

import { TextWithHeading } from 'components/kesko'

import { Customer } from 'domain'

class FeedbackCustomerInfo extends PureComponent {
  static propTypes = {
    customer: PropTypes.instanceOf(Customer),
    shortForm: PropTypes.bool.isRequired,
    showPhoneLink: PropTypes.bool.isRequired
  };

  static defaultProps = {
    shortForm: false,
    showPhoneLink: false,
    showPhone: true,
    showEmail: true,
    showPreferredContactChannel: true
  };

  getCustomerInfoText = (customer) => {
    if (customer.removed) {
      return 'feedback.customer.removed';
    } else if (!customer.name) {
      return 'feedback.customer.anonymous';
    }
    return customer.name;
  };

  renderTelephoneNumber = (phoneNumber) => (<a href={'tel:' + phoneNumber}>{phoneNumber}</a>);

  render() {
    const {customer, shortForm, showPreferredContactChannel, showEmail, showPhone, showPhoneLink} = this.props
    return customer ? (
      <div className="feedback-customer-info" {...this.props}>
        {shortForm && customer.name && (
          <TextWithHeading
            heading="feedback.customer.name"
            body={customer.name}
          />
        )}
        {!shortForm && (
          <TextWithHeading
            heading="feedback.customer.name"
            body={this.getCustomerInfoText(customer)}
          />
        )}
        {!shortForm && (
          <span>
            {customer.companyName && (
              <TextWithHeading
                heading="feedback.customer.companyName"
                body={customer.companyName}
              />
            )}
            {customer.otherCustomerId && (
              <TextWithHeading
                heading="feedback.customer.otherCustomerId"
                body={customer.otherCustomerId}
              />
            )}
            {customer.preferredContactChannel && showPreferredContactChannel && (
              <TextWithHeading
                heading="feedback.customer.preferredContactChannel"
                body={`feedback.customer.channels.${customer.preferredContactChannel}`}
              />
            )}
            {customer.phone && showPhone && (
              <TextWithHeading
                heading="feedback.customer.phone"
                body={!showPhoneLink ? customer.phone : null}
                bodyElement={showPhoneLink ? this.renderTelephoneNumber(customer.phone) : null}
              />
            )}
            {customer.email && showEmail && (
              <TextWithHeading
                heading="feedback.customer.email"
                body={customer.email}
              />
            )}
            {customer.streetAddress && (
              <TextWithHeading
                heading="feedback.customer.streetAddress"
                body={customer.streetAddress}
              />
            )}
            {customer.postalCode && (
              <TextWithHeading
                heading="feedback.customer.postalCode"
                body={customer.postalCode}
              />
            )}
            {customer.postName && (
              <TextWithHeading
                heading="feedback.customer.postName"
                body={customer.postName}
              />
            )}
          </span>
        )}
      </div>
    ) : <span />
  }
}

export default FeedbackCustomerInfo
