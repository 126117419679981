import React, { PropTypes } from 'react'
import PureComponent from 'react-pure-render/component'


class BlueHeading extends PureComponent {
  render() {
    return (
      <div className={`flex flex-center border-box center bg-blue white mb3 px3 py2 ${this.props.className}`}>
        <div className="flex-auto">
          <h1 className="m0">{this.props.text}</h1>
        </div>
      </div>
    )
  }
}

BlueHeading.defaultProps = {
  className: ''
};

BlueHeading.propTypes = {
  text: PropTypes.node,
  className: PropTypes.string
};

export default BlueHeading
