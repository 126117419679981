import { combineReducers } from 'redux-immutablejs';
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import { fromJS } from 'immutable'

import { reducer as attachments } from './attachmentReducer'
import { reducer as businessUnits } from './businessUnitsReducer'
import { reducer as client } from './clientReducer'
import { reducer as collapses } from './collapseReducer'
import { reducer as context } from './contextReducer'
import { reducer as dialog } from './dialogReducer'
import { reducer as feedbackLists } from './feedbackListReducer'
import { reducer as feedbacks } from './feedbacksReducer'
import { reducer as globalData } from './globalDataReducer'
import { reducer as i18n } from './i18nReducer'
import { reducer as menu } from './menuReducer'
import { reducer as quickFilter } from './quickFilterReducer'
import { reducer as reports } from './reportsReducer'
import { reducer as search } from './searchReducer'
import { reducer as searchFilter } from './searchFilterReducer'
import { reducer as settings } from './settingsReducer'
import { reducer as feedbackReply } from './feedbackReplyReducer'

/**
 * Converts immutableJS-based state to redux router compatible version
 */
export const immutableConverter = reducer => {
  return function (state, action) {
    const oldState = state ? state.toJS() : undefined;
    const newState = reducer(oldState, action);
    // check for equal reference. If reducer doesn't modify, state neither should this converter
    if (oldState === newState) {
      return state;
    }
    return fromJS(newState);
  };
}

const reducers = {
  attachments,
  businessUnits,
  client,
  collapses,
  context,
  dialog,
  feedbackLists,
  feedbacks,
  form: immutableConverter(formReducer),
  globalData,
  i18n,
  menu,
  quickFilter,
  feedbackReply,
  reports,
  /*
   NOTE: we store the routerReducer's state as non-immutable to avoid .toJS() call
   creating new objects and causing repeated re-renders. This is also taken into
   account in syncHistoryWithStore (see src/index.js)
  */
  routing: routerReducer,
  search,
  searchFilter,
  settings
}
export default combineReducers(reducers)
