import { List } from 'immutable'
import Serializable from './Serializable'
import FacebookReceiver from './FacebookReceiver'
import FacebookPage from './FacebookPage'

class FacebookData extends Serializable {

  constructor(data) {
    super(data, 'FacebookData')
    this._mapLinkedReceivers()
    this._mapAvailablePages()
  }

  _mapLinkedReceivers() {
    this._data = this._data.updateIn(
        ['linkedReceivers'],
        List(),
        list => list.map(receiver => new FacebookReceiver(receiver))
      )
  }

  _mapAvailablePages() {
    this._data = this._data.updateIn(
        ['availablePages'],
        List(),
        list => list.map(page => new FacebookPage(page))
      )
  }

  get linkedReceivers() {
    return this._data.get('linkedReceivers')
  }

  get availablePages() {
    return this._data.get('availablePages')
  }

  get userName() {
    return this._data.get('userName')
  }
}

export default FacebookData
