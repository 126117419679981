import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'

class Pill extends Component {
  render() {
    const {bg, className, baseClasses, color, ...otherProps} = this.props
    const classes = classNames(
      ...baseClasses,
      {
        [`bg-${bg}`]: true,
        [`${color}`]: !!color
      },
      className
    )
    return (
      <span {...otherProps} className={classes}>
        {this.props.children}
      </span>
    )
  }
}

Pill.propTypes = {
  bg: PropTypes.string.isRequired,
  baseClasses: PropTypes.arrayOf(PropTypes.string),
  color: PropTypes.string
}

Pill.defaultProps = {
  baseClasses: ['pill','bold','px1','truncate','border-box'],
  bg: 'lightblue'
}

export default Pill
