import {
  markFeedbackDone,
  fetchSingleFeedback,
  fetchSingleFeedbackCustom403ErrorShowFeedbacks,
  changeFeedbackHandler,
  markFeedbackRead,
  saveResponseDraft as saveDraft,
  sendResponse,
  removeMessage,
  reopenFeedback,
  transferFeedback,
  addResponseComment,
  forwardFeedback,
  changeFeedbackContact as clientChangeFeedbackContact,
  reportFeedbackAmbianceError,
  reportFeedbackTopicError,
  reportFeedbackThreat,
  updateFeedbackStatus,
  sendFeedback as clientSendFeedback,
  exportFeedbackMessages as clientExportFeedbackMessages,
  fetchTransferFeedbackAddresses,
  setFeedbackCustomerInformationAsObjected
} from './ClientActions'
import { getOrFetchBusinessUnit } from './BusinessUnitActions'
import { clearAttachments } from './AttachmentActions'
import {
  getCompanyHandlers,
  getChainUnits,
  getBusinessUnits,
  getSubjects,
  getExternalSystems
} from './GlobalDataActions'

export const FeedbackActions = {
  LOAD: 'Feedback/LoadedFromServer',
  REMOVE_FROM_LISTS: 'Feedback/RemoveFromLists',

  MARK_AS_DONE: 'Feedback/MarkAsDone',

  SET_POSSIBLE_HANDLERS: 'Feedback/SetPossibleHandlers',

  EXPORT_FEEDBACK_MESSAGES: 'Feedback/ExportMessages',
  START_LOADING: 'Feedback/Loading/Start',
  END_LOADING: 'Feedback/Loading/End'
}

export const loadSingleFeedback = (feedback) => ({
  type: FeedbackActions.LOAD,
  payload: { feedback }
})

export const removeFeedbackFromLists = (feedbackId) => ({
  type: FeedbackActions.REMOVE_FROM_LISTS,
  payload: { feedbackId }
})

export const setPossibleHandlers = (feedbackId, possibleHandlers) => ({
  type: FeedbackActions.SET_POSSIBLE_HANDLERS,
  feedbackId,
  possibleHandlers
})

export const markAsDone = feedbackId => dispatch =>
  dispatch(markFeedbackDone(feedbackId)).then(() => dispatch(fetchSingleFeedback(feedbackId)))

export const markAsRead = feedbackId => dispatch => dispatch(markFeedbackRead(feedbackId))

export const reportAmbianceError = feedbackId => dispatch =>
  dispatch(reportFeedbackAmbianceError(feedbackId)).then(() => dispatch(fetchSingleFeedback(feedbackId)))

export const reportTopicError = feedbackId => dispatch =>
  dispatch(reportFeedbackTopicError(feedbackId)).then(() => dispatch(fetchSingleFeedback(feedbackId)))

export const setCustomerInformationAsObjected = (feedbackId, comment) => dispatch =>
  dispatch(setFeedbackCustomerInformationAsObjected(feedbackId, comment)).then(() => dispatch(fetchSingleFeedback(feedbackId)))

export const reportThreat = feedbackId => dispatch =>
  dispatch(reportFeedbackThreat(feedbackId)).then(() => dispatch(fetchSingleFeedback(feedbackId)))

export const updateStatus = (feedbackId, status, comment) => dispatch =>
  dispatch(updateFeedbackStatus(feedbackId, status, comment)).then(() => dispatch(removeFeedbackFromLists(feedbackId)))

export const removeComment = (feedbackId, messageId) => dispatch =>
  dispatch(removeMessage(feedbackId, messageId)).then(() => dispatch(fetchSingleFeedback(feedbackId)))

export const reopen = feedbackId => dispatch =>
  dispatch(reopenFeedback(feedbackId)).then(() => dispatch(fetchSingleFeedback(feedbackId)))

export const changeHandler = (feedbackId, handlerId, comment) => dispatch =>
  dispatch(changeFeedbackHandler(feedbackId, handlerId, comment)).then(
    () => dispatch(fetchSingleFeedback(feedbackId)),
    () => Promise.reject({ _error: 'feedback.changeHandler.saveFailed' })
  )

export const fetchPossibleHandlers = (feedback) => (dispatch) => {
  const businessUnitId = feedback.businessUnitId
  if (businessUnitId === undefined) {
    return dispatch(getCompanyHandlers(feedback.companyId)).then(handlers => {
      dispatch(setPossibleHandlers(feedback.id, handlers))
    })
  }
  return dispatch(getOrFetchBusinessUnit(businessUnitId)).then(businessUnit => {
    dispatch(setPossibleHandlers(feedback.id, businessUnit.handlers))
  })
}

export const initializeTransfer = () => dispatch => new Promise((resolve, reject) => {
  const chainUnitsPromise = dispatch(getChainUnits())
  const businessUnitsPromise = dispatch(getBusinessUnits())
  const externalSystemsPromise = dispatch(getExternalSystems())
  const subjectsPromise = dispatch(getSubjects())
  const addressesPromise = dispatch(fetchTransferFeedbackAddresses())
  Promise.all([chainUnitsPromise, businessUnitsPromise, externalSystemsPromise, subjectsPromise, addressesPromise]).then(resolve, reject)
})

export const doTransfer = (feedbackId, commentText, targetType, targetStore, targetHandler, targetChain, targetOther, targetEmail, targetSubject, language) => dispatch =>
  dispatch(transferFeedback(feedbackId, commentText, targetType, targetStore, targetHandler, targetChain, targetOther, targetEmail, targetSubject, language)).then(
    () => dispatch(fetchSingleFeedback(feedbackId, fetchSingleFeedbackCustom403ErrorShowFeedbacks(removeFeedbackFromLists(feedbackId)))),
    () => Promise.reject({ _error: 'feedback.transfer.saveFailed' })
  )

export const forwardFeedbackToEmail = (feedbackId, comment, emails, language) => dispatch =>
  dispatch(forwardFeedback(feedbackId, comment, emails, language)).then(
    () => dispatch(fetchSingleFeedback(feedbackId)),
    () => Promise.reject({ _error: 'feedback.forward.saveFailed' })
  )

export const changeFeedbackContact = (feedbackId, name, phone, email) => dispatch =>
  dispatch(clientChangeFeedbackContact(feedbackId, name, phone, email)).then(
    () => dispatch(fetchSingleFeedback(feedbackId)),
    () => Promise.reject({ _error: 'feedback.changeContact.saveFailed' })
  )

export const saveResponseDraft = (feedbackId, message, attachmentIds) => dispatch =>
  dispatch(saveDraft(feedbackId, message, attachmentIds)).then(
    () => dispatch(fetchSingleFeedback(feedbackId)),
    () => Promise.reject({ _error: 'feedback.reply.saveDraftFailed' })
  )

export const reply = (feedbackId, message, attachmentIds, markAsHandled, templateIds) => dispatch => {
  return dispatch(sendResponse(feedbackId, message, attachmentIds, markAsHandled, 'email', templateIds))
    .then(() => {
      dispatch(clearAttachments())
      dispatch(fetchSingleFeedback(feedbackId))
    }, () => Promise.reject({_error: 'feedback.reply.saveFailed'}));
}

export const replyByPhone = (feedbackId, comment, markAsHandled) => dispatch =>
  dispatch(addResponseComment(feedbackId, comment, markAsHandled)).then(
    () => dispatch(fetchSingleFeedback(feedbackId)),
    () => Promise.reject({ _error: 'feedback.replyByPhone.saveFailed' })
  )

export const replyWithChannel = (feedbackId, message, markAsHandled, channel) => dispatch =>
  dispatch(sendResponse(feedbackId, message, [], markAsHandled, channel)).then(
    () => dispatch(fetchSingleFeedback(feedbackId)),
    () => Promise.reject({ _error: 'feedback.reply.saveFailed' })
  )

export const sendFeedback = (feedback, responseRequested) => dispatch =>
  dispatch(clientSendFeedback(feedback, responseRequested)).then(
    () => {},
    () => Promise.reject({ _error: 'giveFeedback.saveFailed' })
  )

export const exportFeedbackMessages = feedbackId => dispatch => new Promise((resolve, reject) => {
  dispatch({ type: FeedbackActions.EXPORT_FEEDBACK_MESSAGES, feedbackId })
  dispatch(startLoading())
  dispatch(clientExportFeedbackMessages(feedbackId)).then(
    downloadLink => {
      dispatch(endLoading())
      window.open(downloadLink, '_blank')
      resolve(downloadLink)
    },
    err => {
      dispatch(endLoading())
      reject(err)
    }
  )
})

export const startLoading = () => dispatch => new Promise(resolve => {
  dispatch({ type: FeedbackActions.START_LOADING })
  resolve()
})

export const endLoading = () => dispatch => new Promise(resolve => {
  dispatch({ type: FeedbackActions.END_LOADING })
  resolve()
})
