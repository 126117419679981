import { fromJS } from 'immutable'
import { defined, hashCode } from 'utils'

class Serializable {

  constructor(data, type) {
    defined(data, 'domain object data')
    this._data = fromJS(data instanceof Serializable ? data._data : data)
    this._type = defined(type, 'domain object type')
    this._hash = hashCode(this._type + ':' + JSON.stringify(this._data.toJS()))
  }

  /**
   * Deep compares the data, use sparingly!
   */
  equals(other) {
    // Quick win - object comparison
    return (this === other) || (this._hash === other._hash)
  }

  toJSON() {
    return this._data.toJSON()
  }
}

export default Serializable;
