import { fromJS } from 'immutable'

import createReducer from './createReducer'

import { QuickFilterActions } from 'actions/QuickFilterActions'

const EMPTY = fromJS({});
export const reducer = createReducer(EMPTY, {

  [QuickFilterActions.SET]: (state, { filter, activeFilter, feedbackListId }) => fromJS({
    active: true,
    activeFilter,
    filter,
    feedbackListId
  }),

  [QuickFilterActions.CLEAR]: () => EMPTY
})
