import React, {Component, PropTypes} from 'react'
import {reduxForm} from 'redux-form'
import Button from 'components/kesko/common/Button'
import ErrorBlock from 'components/Forms/common/ErrorBlock'
import Col from 'components/grid/Col'
import {TextArea, TextInput} from 'components/Forms/common'
import LabeledCheckBox from 'components/Forms/common/LabeledCheckBox'
import { validate as validateSettings } from 'components/Utils/Validation/StoreSettings'

import Text from 'containers/Text'

class StoreSettingsForm extends Component {

  static propTypes = {
    retailerName: PropTypes.string,
    defaultGreetingFi: PropTypes.string,
    defaultGreetingSv: PropTypes.string,
    defaultGreetingEn: PropTypes.string,
    saveStoreSettings: PropTypes.func.isRequired,
    offerRequestTransferEmail: PropTypes.string,
    offerRequestProTransferEmail: PropTypes.string,
    enableOfferRequestTransferEmail: PropTypes.bool,
    disableGreetings: PropTypes.bool,
    allowedTransferFeedbackDomains: PropTypes.array
  };

  populatePlaceholders = (greeting, defaultGreeting) => {
    const {retailerName} = this.props;
    let text = greeting.value;
    if (!text || text === '') {
      text = defaultGreeting;
    }
    text = text.replace('${retailerName}', retailerName);
    return (
      <Col>{text.split('\n').map((item, key) => {
        return <div className="silver break-word" key={key}>{item}<br /></div>
      })}
      </Col>
    )
  }

  saveSettings = (data) => {
    const {saveStoreSettings} = this.props;
    const greetings = [
      {language: 'fi', content: data.greetingFi},
      {language: 'sv', content: data.greetingSv},
      {language: 'en', content: data.greetingEn}
    ];
    return saveStoreSettings(data.businessUnitId, data.showGreeting, data.showRetailerImage, greetings, data.offerRequestTransferEmail,
      data.offerRequestProTransferEmail);
  }

  resetSettings = () => {
    const {
      showGreeting, showRetailerImage, greetingFi, greetingSv, greetingEn, offerRequestTransferEmail, offerRequestProTransferEmail
    } = this.props.fields;
    [showGreeting, showRetailerImage, greetingFi, greetingSv, greetingEn, offerRequestTransferEmail, offerRequestProTransferEmail]
      .forEach(fieldValue => fieldValue.onChange(fieldValue.initialValue));
  }

  onTransferEmailChange = ({target}) => {
    this.props.fields.offerRequestTransferEmail.onChange(target.value);
  }

  onTransferEmailProChange = ({target}) => {
    this.props.fields.offerRequestProTransferEmail.onChange(target.value);
  }

  render() {
    const {
      error, submitting, pristine, defaultGreetingFi, defaultGreetingSv, defaultGreetingEn,
      handleSubmit, enableOfferRequestTransferEmail, disableGreetings,
      fields: {
        showGreeting, showRetailerImage, greetingFi, greetingSv, greetingEn, offerRequestTransferEmail, offerRequestProTransferEmail
      }
    } = this.props;

    return (
      <form className="basic-form" action="." noValidate="novalidate" onSubmit={handleSubmit(this.saveSettings)}>

        {!disableGreetings && (
        <LabeledCheckBox
          {...showGreeting}
          disabled={submitting}
          label="settings.store.showGreeting"
        />
        )}

        {!!showGreeting.value && (
          <div>
            <LabeledCheckBox
              {...showRetailerImage}
              disabled={submitting}
              label="settings.store.showRetailerImage"
            />

            <h3>
              <Text k="settings.store.greetingTitle"/>
            </h3>

            {this.getGreetingField('greetingFi', greetingFi, defaultGreetingFi)}
            {this.getGreetingField('greetingSv', greetingSv, defaultGreetingSv)}
            {this.getGreetingField('greetingEn', greetingEn, defaultGreetingEn)}

          </div>
        )}

        {!!enableOfferRequestTransferEmail && (
          <Col className="mb2">
            <ErrorBlock
              fields={{offerRequestTransferEmail}}
              submitError={error}
              className="mb2 mt2"
              translatePrefix="settings.store"
            />

            <h3>
              <Text k="settings.store.offerRequestTransferEmail.title"/>
            </h3>

            <TextInput
              {...offerRequestTransferEmail}
              disabled={submitting}
              onChange={this.onTransferEmailChange}
            />

            <ErrorBlock
              fields={{offerRequestProTransferEmail}}
              submitError={error}
              className="mb2 mt2"
              translatePrefix="settings.store"
            />

            <h3>
              <Text k="settings.store.offerRequestProTransferEmail.title"/>
            </h3>

            <TextInput
              {...offerRequestProTransferEmail}
              disabled={submitting}
              onChange={this.onTransferEmailProChange}
            />
          </Col>
        )}
        <div className="form-buttons-group">
          <Button
            translation="formButtons.save"
            type="submit"
            disabled={submitting || pristine}
            theme={Button.theme.primary}
            loading={submitting}
          />
          <Button
            onClick={this.resetSettings}
            translation="formButtons.cancel"
            disabled={submitting}
            theme={Button.theme.cancel}
          />
        </div>
      </form>
    )
  }

  getGreetingField(titleKey, localizedGreeting, defaultLocalizedGreeting) {
    const {submitting, error} = this.props;
    return (
      <Col>
        <ErrorBlock
          fields={{[titleKey] : {...localizedGreeting}}}
          submitError={error}
          className="mt2"
          translatePrefix="settings.store"
        />
        <div className="sm-flex">
          <div className="flex-none xs-col-12 sm-col-6">
            <h3>
              <Text k={`settings.store.${titleKey}.title`}/>
            </h3>
            <TextArea
              rows="3"
              disabled={submitting}
              {...localizedGreeting}
            />
          </div>
          <div className="flex-auto ml2 xs-col-12 sm-col-6">
            <h3>
              <Text k="settings.store.preview" />
            </h3>
            {this.populatePlaceholders(localizedGreeting, defaultLocalizedGreeting)}
          </div>
        </div>
      </Col>
    );
  }
}

export default reduxForm({
  form: 'storeSettings',
  fields: [
    'businessUnitId',
    'showGreeting',
    'showRetailerImage',
    'greetingFi',
    'greetingSv',
    'greetingEn',
    'offerRequestTransferEmail',
    'offerRequestProTransferEmail'
  ],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: (values, props) => validateSettings(values, props)
})(StoreSettingsForm);
