import React, { Component, PropTypes } from 'react'

import { Pill } from 'components/kesko'
import Text from 'containers/Text'

import Status from 'domain/Status'
import statusToColor from './StatusColors'

class StatusPill extends Component {
  render() {
    return (
      <Pill {...this.props} bg={statusToColor[this.props.status]} color="black">
        <Text k={['state', this.props.status]} />
      </Pill>
    );
  }
}

StatusPill.propTypes = {
  status: PropTypes.oneOf(Object.keys(Status)).isRequired
}

export default StatusPill
