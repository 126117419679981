import React, { PropTypes } from 'react'
import PureComponent from 'react-pure-render/component'
import classNames from 'classnames'

import AddErrorDisplay from 'components/Forms/common/AddErrorDisplay'
import { overrideOnBlur } from 'components/Forms/common/FormUtils'
import { TranslateProps } from 'containers/Text'

class TextInput extends PureComponent {
  render() {
    const {type, name, className, ...props} = this.props
    const classes = classNames(
      className,
      'field',
      'fill'
    )
    return (
      <input
        className={classes}
        type={type}
        name={name}
        {...overrideOnBlur(props)}
      />
    )
  }
}
TextInput.defaultProps = {
  type: 'text'
}
TextInput.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default TranslateProps(AddErrorDisplay(TextInput))
