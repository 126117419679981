import React, { Component, PropTypes } from 'react';

import Col from './Col'

/**
 * Shows media object on the left side and content vertically centered
 *
 * @example
 * <MediaObject object={<Icon ... />} content={<h1></h2>} />
 */
class MediaObject extends Component {
  render() {
    return (
      <Col {...this.props}>
        <div className="flex flex-center justify-around">
          <div className="flex-none">
            {this.props.object}
          </div>
          { this.props.content && <div className="flex-auto">
            {this.props.content}
          </div> }
        </div>
      </Col>
    )
  }
}
MediaObject.propTypes = {
  object: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired
}

export default MediaObject
