import Session from 'service/Session'
import { aadLogin as clientAadLogin, login as clientLogin, whoami as clientWhoami, logout as clientLogout } from 'actions/ClientActions'
import { getChainUnits, getSubjects } from 'actions/GlobalDataActions'
import { changeLocale } from 'actions/I18nActions'
import { window } from 'utils'

export const LoginActions = {
  LOGIN: 'Login/Init',
  LOGOUT: 'Login/DoLogout',

  SUCCESS: 'Login/Successful',
  ERROR: 'Login/Error',

  UPDATE_TOKEN: 'Login/UpdateToken',
  UPDATE_USER_INFO: 'Login/UpdateUserInfo',

  WHOAMI_FAILED: 'Login/WhoamiFailed',
  AUTH_FAILED: 'Login/AuthFailed',

  CHANGE_BUSINESS_UNIT: 'Login/ChangeBusinessUnit',

  AAD_LOGIN_IN_PROGRESS: 'Login/AadLoginInProgress'
}

export const updateToken = token => ({
  type: LoginActions.UPDATE_TOKEN,
  token
})

export const updateUserInfo = userInfo => ({
  type: LoginActions.UPDATE_USER_INFO,
  userInfo
})

export const loginSuccessful = (token) => dispatch => {
  dispatch({ type: LoginActions.SUCCESS })
  dispatch(updateToken(token))
  Session.get().token = token
}

export const loginError = errorCode => ({
  type: LoginActions.ERROR,
  errorCode
})

export const aadLoginSuccessful = (token) => dispatch => {
  dispatch(aadLoginInProgress(false));
  dispatch(loginSuccessful(token));
}

export const aadLoginError = errorCode => dispatch => {
  dispatch(aadLoginInProgress(false));
  dispatch(loginError(errorCode));
}

export const aadLoginInProgress = isInProgress => ({
  type: LoginActions.AAD_LOGIN_IN_PROGRESS,
  isInProgress
});

export const whoamiFailed = () => dispatch => {
  Session.get().token = undefined
  dispatch({ type: LoginActions.WHOAMI_FAILED })
}

export const authError = () => dispatch => {
  Session.get().token = undefined
  dispatch({ type: LoginActions.AUTH_FAILED })
}

export const logout = () => dispatch => {
  dispatch(clientLogout())
  Session.get().token = undefined
  dispatch({ type: LoginActions.LOGOUT })
  if (!window.location.origin) {
    window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
  }
  window.location = window.location.origin + window.location.pathname
}

export const aadLogin = (authCode) => dispatch => {
  dispatch(aadLoginInProgress(true));
  return dispatch(clientAadLogin(authCode))
}

export const login = (username, password, rememberMe) => dispatch => dispatch(clientLogin(username, password, rememberMe))

export const whoami = () => dispatch => new Promise((resolve, reject) => dispatch(getChainUnits())
  .then(() => dispatch(getSubjects()))
  .then(() => dispatch(clientWhoami()))
  .then(userInfo => {
    if (userInfo.profile && userInfo.profile.language) {
      dispatch(changeLocale(userInfo.profile.language))
    }
    return dispatch(updateUserInfo(userInfo))
  })
  .then(resolve, reject)
)

export const changeBusinessUnit = (newBusinessUnitCode) => (dispatch, getState) => {
  const oldBusinessUnitCode = getState().getIn(['client', 'selectedBusinessUnitCode'])
  if (oldBusinessUnitCode !== newBusinessUnitCode) {
    dispatch({
      type: LoginActions.CHANGE_BUSINESS_UNIT,
      businessUnitCode: newBusinessUnitCode
    })
  }
}
