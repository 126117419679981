import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ExposeWrapped } from 'containers/Utils'
import { open, close, destroy } from 'actions/DialogActions'
import { BlueHeading } from 'components/kesko'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Text from '../Text/Text';
import TextFormatter from '../../components/Formatting/TextFormatter';

export class Dialog extends React.PureComponent {

  componentWillUnmount() {
    clearAllBodyScrollLocks();
    this.props.actions.destroy(this.props.dialogId);
  }

  open = () => {
    disableBodyScroll();
    this.props.actions.open(this.props.dialogId);
  };

  close = () => {
    enableBodyScroll();
    this.props.actions.close(this.props.dialogId);
  };

  isOpen = () => this.props.open;

  render() {
    const {titleText, bodyText} = this.props;

    const body = bodyText ? (
      <TextFormatter text={bodyText} />
    ) : null;

    return this.props.open && (
      <div className="dialog-wrapper">
        <div className="dialog">
          <BlueHeading text={<Text k={titleText} />} className="dialog-heading" />
          {body}
          <div className="buttons-section">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

Dialog.propTypes = {
  dialogId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    open: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired
  }).isRequired,
  titleText: PropTypes.string.isRequired,
  bodyText: PropTypes.string
};

const mapStateToProps = (state, props) => ({
  open: state.getIn(['dialog', props.dialogId, 'open'], false)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({open, close, destroy}, dispatch)
});

export default ExposeWrapped(['open', 'close', 'isOpen'])(
  connect(mapStateToProps, mapDispatchToProps, undefined, {withRef: true})(Dialog)
)
