import Serializable from './Serializable'

class RespondingUser extends Serializable {

  constructor(data) {
    super(data, 'RespondingUser')
    this._mapStarted()
  }

  _mapStarted() {
    const started = this._data.get('started');
    if (!!started) {
      const date = new Date(Date.parse(started));
      this._data = this._data.set('started', date)
    }
  }

  get name() {
    return this._data.get('name')
  }

  get started() {
    return this._data.get('started')
  }

}

export default RespondingUser
