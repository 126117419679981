
export const MenuActions = {
  OPEN: 'Menu/Open',
  CLOSE: 'Menu/Close'
}

export const openMenu = () => ({
  type: MenuActions.OPEN
})

export const closeMenu = () => ({
  type: MenuActions.CLOSE
})
