/**
 * A helper HOC to expose the functions of the underlying component.
 * The connect function from react-redux must be called with { withRef: true }
 * to expose the getWrappedInstance() method
 *
 * @example
 * class OriginalComponent extends Component {
 *   myFn() {
 *     // Do something
 *   }
 *   render() { ... }
 * }
 *
 * const Connected = connect(mapStateToProps, mapDispatchToProps, undefined, { withRef: true })(OriginalComponent)
 * const Exposed = ExposeWrapper(['myFn'])(Connected)
 *
 * class Container extends Component {
 *   render() {
  *    return (
  *     <div>
  *       <Connected ref="connected" />
  *       <Exposed ref="exposed" />
  *     </div>
  *    )
  *  }
 * }
 *
 * const container = ReactDOM.render(..)
 * container.connected.myFn // undefined
 * container.exposed.myFn // function() { ... }
 *
 * @param methods
 * @constructor
 */
const ExposeWrapped = methods => Original => class extends Original {
  constructor(props, context) {
    super(props, context)
    methods.forEach(m => {
      const fn = function() {
        return this.getWrappedInstance()[m](arguments)
      }
      this[m] = fn.bind(this)
    })
  }
}

export default ExposeWrapped
