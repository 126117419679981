import React, { PropTypes } from 'react'
import PureComponent from 'react-pure-render/component'

function zeroed(val) {
  return parseInt(val) < 10 ? '0' + val : '' + val;
}

function hours(date) {
  const h = date.getHours()
  const m = zeroed(date.getMinutes())
  return `${h}:${m}`
}

function onlyDate(date) {
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();
  return `${d}.${m}.${y}`
}

function isToday(d) {
  return onlyDate(new Date()) === onlyDate(d);
}

/**
 * Displays date as d.M.yyyy if date is not today.
 * Otherwise, displays the time as H:mm
 */
class FeedbackDate extends PureComponent {
  static getDateString(date, full) {
    if (!date) {
      return ''
    } else if (full) {
      return onlyDate(date) + ' ' + hours(date)
    } else {
      return isToday(date) ? hours(date) : onlyDate(date);
    }
  }

  render() {
    const {full, date, ...otherProps} = this.props
    return (<span className="feedback-date" {...otherProps}>{FeedbackDate.getDateString(date, full)}</span>)
  }
}

FeedbackDate.propTypes = {
  full: PropTypes.bool.isRequired,
  date: PropTypes.instanceOf(Date)
}
FeedbackDate.defaultProps = {
  full: false
}

export default FeedbackDate
