import React from 'react'

import { KeskoIcons } from 'components/Icons'
import { Col } from 'components/grid'
import TextWithIcon from '../kesko/TextWithIcon';

const AttachmentUpload = ({handleFile}) => {
  return (
    <div className="attachment-upload">
      <Col className="file-input flex">
        <input
          value="" multiple
          id="file-input"
          type="file"
          onChange={handleFile}
          className="mt3 hide"
        />

        <div className="flex flex-center">
          <label
            htmlFor="file-input"
            className="flex flex-center clickable">
            <TextWithIcon
              className="add-attachment"
              translation="feedback.comments.addAttachment"
              icon={KeskoIcons.plus}
            />
          </label>
        </div>
      </Col>
    </div>
  );
}

export default AttachmentUpload
