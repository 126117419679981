import React, {Component} from 'react'

import AttachmentFileGallery from './AttachmentFileGallery'
import AttachmentImageGallery from './AttachmentImageGallery'
import AttachmentAudioGallery from './AttachmentAudioGallery'
import {hasItems} from 'utils'

class MessageAttachments extends Component {
  mapAttachmentsOfType = (attachment) => ({
    src: attachment.get('url'),
    fileName: attachment.get('fileName'),
    fileSize: attachment.get('fileSize'),
    mediaType: attachment.get('mediaType'),
    caption: attachment.get('fileName') + ' (' + attachment.get('fileSize') + ')',
    virusScanned: attachment.get('virusScanned'),
    audioTranscript: attachment.get('audioTranscript'),
    audioTranscriptStatus: attachment.get('audioTranscriptStatus')
  });

  render() {
    const {
      message: {attachmentAudios, attachmentImages, attachmentFiles}
    } = this.props;
    const audioSrcs = attachmentAudios && attachmentAudios.map((attachment) => {
      return this.mapAttachmentsOfType(attachment)
    }).toArray()
    const imageSrcs = attachmentImages && attachmentImages.map((attachment) => {
      return this.mapAttachmentsOfType(attachment)
    }).toArray()
    const fileSrcs = attachmentFiles && attachmentFiles.map((attachment) => {
      return this.mapAttachmentsOfType(attachment)
    }).toArray()

    return (
      <div>
        {hasItems(audioSrcs) && <AttachmentAudioGallery audios={audioSrcs}/>}
        {hasItems(imageSrcs) && <AttachmentImageGallery images={imageSrcs}/>}
        {hasItems(fileSrcs) && <AttachmentFileGallery files={fileSrcs}/>}
      </div>
    )
  }
}

export default MessageAttachments
