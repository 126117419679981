import React, { Component, PropTypes } from 'react'
import { ToggleButton } from 'components/kesko'

class FilterButton extends Component {

  static propTypes = {
    toggleFilter: PropTypes.func.isRequired,
    isActive: PropTypes.func.isRequired
  };

  toggleFilter = filter => () => this.props.toggleFilter(filter);

  render() {
    const {filter, isActive} = this.props;
    return (
      <ToggleButton
        toggled={isActive(filter)}
        onClick={this.toggleFilter(filter)}
        translation={`search.filter.${filter}`}
      />
    )
  }
}

class QuickSearchFilters extends Component {
  static propTypes = {
    activeFilter: PropTypes.string,
    toggleFilter: PropTypes.func.isRequired
  };
  static defaultProps = {
    statistics: {}
  };

  isActive = filter => this.props.activeFilter === filter;

  render() {
    const buttonProps = {
      isActive: this.isActive,
      toggleFilter: this.props.toggleFilter
    };

    return (
      <div className="quick-search-filters">
        <FilterButton filter="customer" {...buttonProps} />
        <FilterButton filter="handler" {...buttonProps} />
        <FilterButton filter="store" {...buttonProps} />
      </div>
    )
  }
}

export default QuickSearchFilters;
