import React, { Component, PropTypes, Children} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Context, Feedback } from 'domain'
import { fetchSingleFeedback } from 'actions/ClientActions'
import { show404Page } from 'actions/ErrorActions'
import { WaitFor } from 'components/Utils'
import { ContextSelectionHeader } from 'containers/Menu'
import { noop } from 'utils'
import { trace } from 'utils/Log'

class FeedbackRouteWrapper extends Component {
  static defaultProps = {};
  static propTypes = {
    feedbackId: PropTypes.number.isRequired,
    feedback: PropTypes.instanceOf(Feedback),
    routeContext: PropTypes.instanceOf(Context).isRequired
  };

  componentDidMount() {
    this.props.actions.fetchSingleFeedback(this.props.feedbackId)
      .then(noop, this.props.actions.show404Page)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.feedbackId !== this.props.feedbackId) {
      this.props.actions.fetchSingleFeedback(newProps.feedbackId)
        .then(noop, this.props.actions.show404Page)
    }
  }

  render() {
    const { feedback, feedbackId, routeContext } = this.props
    trace('FeedbackRouteWrapper: adding feedback to child props', feedback)
    const children = Children.map(this.props.children, child => React.cloneElement(child, {
      feedback,
      feedbackId,
      routeContext
    }))
    return (
      <WaitFor condition={!!feedback}>
        <div className="context-selection-container">
          <ContextSelectionHeader text={feedback ? feedback.receiverName : ''} />
          {children}
        </div>
      </WaitFor>
    )
  }
}


const mapStateToProps = (state, props) => {
  const feedbackId = parseInt(props.params.feedbackId);
  return {
    feedbackId,
    feedback: state.getIn(['feedbacks', feedbackId]),
    routeContext: state.get('context')
  };
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchSingleFeedback, show404Page }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackRouteWrapper);
