import { clear, init } from './FeedbackListActions'
import { search } from './SearchActions'
import { defined } from 'utils'

export const SearchFilterActions = {
  SET: 'SearchFilter/Set',
  CLEAR: 'SearchFilter/Clear'
}

export const setFilter = (filter, feedbackListId, activeFilter) => ({
  type: SearchFilterActions.SET,
  filter: defined(filter, 'filter'),
  feedbackListId: defined(feedbackListId, 'feedbackListId'),
  activeFilter: defined(activeFilter, 'activeFilter')
})

export const applyFilter = (filter) => (dispatch) => {
  const initFeedbackListAction = init(filter)
  const { feedbackListId } = initFeedbackListAction
  dispatch(initFeedbackListAction)
  dispatch(setFilter(filter, feedbackListId, filter))
}

export const clearFilter = () => (dispatch, getState) => {
  const feedbackListId = getState().getIn(['searchFilter', 'feedbackListId'])
  if (feedbackListId) {
    dispatch(clear(feedbackListId))
  }
  dispatch({ type: SearchFilterActions.CLEAR })
}

export const toggleFilter = (filterName) => (dispatch, getState) => {
  const query = getState().getIn(['search', 'query', 'search'])
  const currentFilter = getState().getIn(['searchFilter', 'activeFilter'])
  if (currentFilter) {
    dispatch(clearFilter())
  }
  if (currentFilter === filterName) {
    dispatch(search(query))
  } else {
    dispatch(applyFilter(filterName))
    dispatch(search(query, filterName))
  }
}

/**
 * If there is a set quick filter active, reset it for the given additionalFilter
 * Useful for, e.g., when navigating to a different context when the filter is set.
 */
export const resetFilter = () => (dispatch, getState) => {
  const state = getState()
  const currentFilter = state.getIn(['searchFilter', 'activeFilter'])
  const activeFeedbackListId = getState().getIn(['searchFilter', 'feedbackListId'])
  if (currentFilter) {
    dispatch(applyFilter(currentFilter))
    dispatch(clear(activeFeedbackListId))
  }
}
