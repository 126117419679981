import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'

import { Col } from 'components/grid'
import { ErrorBlock, TextArea, LanguageSelection, LabeledInput } from 'components/Forms'
import SaveToLocalStorage from 'components/Forms/SaveToLocalStorage'

import { createValidator, required, minLength, maxLength, arrayHasValues } from 'utils/Validation'
import MultipleEmailsForm from '../MultipleEmailsForm';
import Button from 'components/kesko/common/Button';

const SavingTextArea = SaveToLocalStorage(TextArea)

class RequestCommentForm extends Component {

  static defaultProps = {
    sendRequest: () => {
    }
  }

  bindTextArea = (area) => {
    this._savingTextArea = area
  };

  forgetStoredCommentIfSuccessful = (cb) => {
    return function (event) {
      const promise = cb(event)
      promise.then(this._savingTextArea.clear)
      return promise
    }.bind(this)
  }

  render() {
    const {handleSubmit, error, onCancel, fields, userEmails, fields: {emails, commentText, language}, submitting, localStorageKey} = this.props
    return (
      <form className="basic-form request-comment-form" noValidate>

        <Col className="clearfix mb2">
          <ErrorBlock
            fields={fields}
            submitError={error}
            translatePrefix="feedback.requestComment.fields"
          />
        </Col>

        <Col className="mb1">
          <LabeledInput labelKey="feedback.requestComment.fields.emails.label">
            <MultipleEmailsForm
              userEmails={userEmails}
              onChange={emails.onChange}
            />
          </LabeledInput>
        </Col>

        <Col className="mb2">
          <LabeledInput labelKey="feedback.requestComment.fields.commentText.placeholder">
            <SavingTextArea
              ref={this.bindTextArea}
              rows="5"
              disabled={submitting}
              localStorageKey={localStorageKey}
              {...commentText}
            />
          </LabeledInput>
        </Col>

        <Col className="mb2">
          <LanguageSelection
            language={language}
            titleKey="feedback.requestComment.language"
          />
        </Col>

        <div className="form-buttons-group">
          <Button
            onClick={onCancel}
            translation="feedback.requestComment.cancel"
            disabled={submitting}
            theme={Button.theme.cancel}
          />
          <Button
            onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(this.props.sendRequest))}
            translation="feedback.requestComment.save"
            type="submit"
            disabled={submitting}
            theme={Button.theme.primary}
          />
        </div>

      </form>
    )
  }
}

const mapStateToProps = () => ({
  initialValues: {
    language: 'fi'
  }
});

const ReduxRequestCommentForm = reduxForm({
  form: 'requestComment',
  fields: ['emails', 'commentText', 'language'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    emails: [arrayHasValues('required'), maxLength(1000)],
    commentText: [required(), maxLength(50000)],
    language: [required(), minLength(2), maxLength(2)]
  })
}, mapStateToProps)(RequestCommentForm)
ReduxRequestCommentForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  userEmails: PropTypes.arrayOf(PropTypes.string)
}

export default ReduxRequestCommentForm
