import React from 'react';
import PureComponent from 'react-pure-render/component'

import { ExclamatedNote } from 'components/kesko'

class FacebookReplyNote extends PureComponent {
  render() {
    return (
      <ExclamatedNote translate={{note: 'feedback.reply.noteFacebook' }} />
    )
  }
}

export default FacebookReplyNote
