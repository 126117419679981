import { flatten } from 'utils'
import { ReceiverFilterType } from 'domain'

export const formModelToProfile = ({
  language,
  receiver,
  receiver2,
  sendNotifications,
  sendNotificationsNoResponse,
  sendSummaries,
  signature,
  greeting,
  proposeResponses,
  secondaryInterestsEnabled,
  excludeBusinessUnits = [],
  restrictions = [],
  restrictions2 = [],
  sendPushMessages,
  pushMessageTokens = [],
  interestFeedbackCategory
  }) => {
  const profile = {
    language,
    sendNotifications,
    sendNotificationsNoResponse,
    sendSummaries: sendSummaries !== undefined ? sendSummaries : true,
    excludeNotificationsBuIds: undefined,
    interests: undefined,
    secondaryInterests: undefined,
    signature,
    greeting,
    proposeResponses,
    sendPushMessages,
    pushMessageTokens,
    interestFeedbackCategory
  }

  if (excludeBusinessUnits !== undefined || excludeBusinessUnits.length > 0) {
    profile.excludeNotificationsBuIds = []
    excludeBusinessUnits.forEach(bu => profile.excludeNotificationsBuIds.push(bu.id))
  }

  if (!!pushMessageTokens && pushMessageTokens.length > 0) {
    profile.pushMessageTokens = pushMessageTokens;
  }

  if (receiver !== undefined || restrictions.length > 0) {
    profile.interests = {
      interestReceiverType: receiver,
      chainInterests: [],
      businessUnitInterests: [],
      subjects: []
    }

    restrictions
      .filter(r => r.receiverType === ReceiverFilterType.chainUnit && r.chainUnit)
      .forEach(r => profile.interests.chainInterests.push({
        chainId: r.chainUnit.id,
        chainAreaIds: r.chainUnitArea && r.chainUnitArea.map(area => area.id)
      }))

    flatten(restrictions.filter(r => r.receiverType === ReceiverFilterType.businessUnitInclusive && r.businessUnits)
      .map(r => r.businessUnits))
      .forEach(bu => profile.interests.businessUnitInterests.push({ businessUnitId: bu.id, exclusion: false }))

    flatten(restrictions.filter(r => r.receiverType === ReceiverFilterType.businessUnitIds && r.businessUnitIds)
      .map(r => r.businessUnitIds.split(' ')))
      .forEach(buId => profile.interests.businessUnitInterests.push({ businessUnitId: buId, exclusion: false }))

    flatten(restrictions.filter(r => r.receiverType === ReceiverFilterType.businessUnitExclusive && r.businessUnits)
      .map(r => r.businessUnits))
      .forEach(bu => profile.interests.businessUnitInterests.push({ businessUnitId: bu.id, exclusion: true }))

    flatten(restrictions.filter(r => r.receiverType === ReceiverFilterType.subject && r.subject)
      .map(r => r.subject))
      .forEach(su => profile.interests.subjects.push(su.id))
  }

  if (secondaryInterestsEnabled) {
    if (receiver2 === undefined) {
      receiver2 = 'all'
    }
    profile.secondaryInterests = {
      interestReceiverType: receiver2,
      chainInterests: [],
      businessUnitInterests: [],
      subjects: []
    }

    restrictions2
      .filter(r => r.receiverType === ReceiverFilterType.chainUnit && r.chainUnit)
      .forEach(r => profile.secondaryInterests.chainInterests.push({
        chainId: r.chainUnit.id,
        chainAreaIds: r.chainUnitArea && r.chainUnitArea.map(area => area.id)
      }))

    flatten(restrictions2.filter(r => r.receiverType === ReceiverFilterType.businessUnitInclusive && r.businessUnits)
      .map(r => r.businessUnits))
      .forEach(bu => profile.secondaryInterests.businessUnitInterests.push({ businessUnitId: bu.id, exclusion: false }))

    flatten(restrictions2.filter(r => r.receiverType === ReceiverFilterType.businessUnitIds && r.businessUnitIds)
      .map(r => r.businessUnitIds.split(' ')))
      .forEach(buId => profile.secondaryInterests.businessUnitInterests.push({ businessUnitId: buId, exclusion: false }))

    flatten(restrictions2.filter(r => r.receiverType === ReceiverFilterType.businessUnitExclusive && r.businessUnits)
      .map(r => r.businessUnits))
      .forEach(bu => profile.secondaryInterests.businessUnitInterests.push({ businessUnitId: bu.id, exclusion: true }))

    flatten(restrictions2.filter(r => r.receiverType === ReceiverFilterType.subject && r.subject)
      .map(r => r.subject))
      .forEach(su => profile.secondaryInterests.subjects.push(su.id))
  }

  return profile
}

const mapBusinessUnitToTokenInput = (b, businessUnits) => {
  const businessUnit = businessUnits.find(bu => bu.id == b.businessUnitId)
  return {
    id: businessUnit.id,
    name: businessUnit.name,
    label: businessUnit.id + ' - ' + businessUnit.name
  }
}

const mapChainAreasToTokenInput = (chainAreaIds, chainAreas) => {
  const tokenInputChainAreas = []
  chainAreas
    .filter(area => chainAreaIds.indexOf(area.id) !== -1)
    .forEach(area => {
      tokenInputChainAreas.push({
        id: area.id,
        name: area.name,
        label: area.name,
        value: area
      })
    })
  return tokenInputChainAreas;
}

const mapSubjectToTokenInput = (subjectId, subjects) => {
  const subject = subjects.find(su => su.id == subjectId)
  return {
    id: subject.id,
    name: subject.name,
    label: subject.name,
    value: subject
  }
}

export const profileToFormModel = (profile, chainUnits, businessUnits, subjects) => {
  const formModel = {
    receiver: undefined,
    receiver2: undefined,
    restrictions: [],
    restrictions2: [],
    secondaryInterestsEnabled: false,
    sendNotifications: true,
    sendNotificationsNoResponse: false,
    sendSummaries: true,
    excludedBusinessUnits: [],
    proposeResponses: false,
    sendPushMessages: false,
    pushMessageTokens: [],
    interestFeedbackCategory: undefined
  }

  if (profile) {
    formModel.sendNotifications = profile.sendNotifications
    formModel.sendNotificationsNoResponse = profile.sendNotificationsNoResponse
    formModel.sendSummaries = profile.sendSummaries
    formModel.proposeResponses = profile.proposeResponses
    formModel.sendPushMessages = profile.sendPushMessages
    formModel.pushMessageTokens = profile.pushMessageTokens
    if (profile.interestFeedbackCategory) {
      formModel.interestFeedbackCategory = profile.interestFeedbackCategory
    }
    if (profile.signature) {
      formModel.signature = profile.signature
    }
    if (profile.greeting) {
      formModel.greeting = profile.greeting
    }
  }

  const pushToRestrictions = (c, restrictions) => {
    const chainUnit = chainUnits.find(cu => cu.id == c.chainId)
    const chainUnitArea = chainUnit.areas && c.chainAreaIds && mapChainAreasToTokenInput(c.chainAreaIds, chainUnit.areas)
    restrictions.push({
      receiverType: ReceiverFilterType.chainUnit,
      chainUnit,
      chainUnitArea
    });
  };

  if (chainUnits && businessUnits && subjects && profile && profile.interests) {
    formModel.receiver = profile.interests.interestReceiverType

    if (profile.interests.chainInterests) {
      profile.interests.chainInterests.forEach(c => pushToRestrictions(c, formModel.restrictions));
    }

    const includedBusinessUnits = []
    const excludedBusinessUnits = []
    if (profile.interests.businessUnitInterests) {
      profile.interests.businessUnitInterests
        .forEach(b => b.exclusion ?
          excludedBusinessUnits.push(mapBusinessUnitToTokenInput(b, businessUnits)) :
          includedBusinessUnits.push(mapBusinessUnitToTokenInput(b, businessUnits)))
    }
    if (includedBusinessUnits.length > 0) {
      formModel.restrictions.push({ receiverType: ReceiverFilterType.businessUnitInclusive, businessUnits: includedBusinessUnits })
    }
    if (excludedBusinessUnits.length > 0) {
      formModel.restrictions.push({ receiverType: ReceiverFilterType.businessUnitExclusive, businessUnits: excludedBusinessUnits })
    }

    const includedSubjects = []
    if (profile.interests.subjects) {
      profile.interests.subjects
        .forEach(subjectId => includedSubjects.push(mapSubjectToTokenInput(subjectId, subjects)))
    }
    if (includedSubjects.length > 0) {
      formModel.restrictions.push({ receiverType: ReceiverFilterType.subject, subject: includedSubjects })
    }
  }
  if (chainUnits && businessUnits && subjects && profile && profile.secondaryInterests) {
    formModel.secondaryInterestsEnabled = true
    formModel.receiver2 = profile.secondaryInterests.interestReceiverType

    if (profile.secondaryInterests.chainInterests) {
      profile.secondaryInterests.chainInterests.forEach(c => pushToRestrictions(c, formModel.restrictions2));
    }

    const includedBusinessUnits = []
    const excludedBusinessUnits = []
    if (profile.secondaryInterests.businessUnitInterests) {
      profile.secondaryInterests.businessUnitInterests
        .forEach(b => b.exclusion ?
          excludedBusinessUnits.push(mapBusinessUnitToTokenInput(b, businessUnits)) :
          includedBusinessUnits.push(mapBusinessUnitToTokenInput(b, businessUnits)))
    }
    if (includedBusinessUnits.length > 0) {
      formModel.restrictions2.push({ receiverType: ReceiverFilterType.businessUnitInclusive, businessUnits: includedBusinessUnits })
    }
    if (excludedBusinessUnits.length > 0) {
      formModel.restrictions2.push({ receiverType: ReceiverFilterType.businessUnitExclusive, businessUnits: excludedBusinessUnits })
    }

    const includedSubjects = []
    if (profile.secondaryInterests.subjects) {
      profile.secondaryInterests.subjects
        .forEach(subjectId => includedSubjects.push(mapSubjectToTokenInput(subjectId, subjects)))
    }
    if (includedSubjects.length > 0) {
      formModel.restrictions2.push({ receiverType: ReceiverFilterType.subject, subject: includedSubjects })
    }
  }
  return formModel
}
