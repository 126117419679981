import { fromJS, List } from 'immutable'
import createReducer from './createReducer'

import { FeedbackActions } from 'actions/FeedbackActions'
import { FeedbackListActions } from 'actions/FeedbackListActions'
import { defined } from 'utils'
import { OrderBy } from 'domain'

const initialState = fromJS({
  bindings: {}
})

const defineOrder = order => {
  return order === OrderBy.modified ? byModifiedDateAndIdDesc : byCreatedDateAndIdDesc
}

const byCreatedDateAndIdDesc = (fb1, fb2) => {
  const dateDiff = fb2.created - fb1.created
  if (dateDiff == 0) {
    return fb2.id - fb1.id
  }
  return dateDiff;
}

const byModifiedDateAndIdDesc = (fb1, fb2) => {
  const dateDiff = fb2.modified - fb1.modified
  if (dateDiff == 0) {
    return fb2.id - fb1.id
  }
  return dateDiff;
}

/**
 * @param feedbacks a immutable.List of domain/Feedback
 */
const updateFeedbackPreviews = (feedbacks, orderBy) => (list) => {
  const newIds = feedbacks.map(fb => fb.id).toJS()
  const byId = acceptedIds => fb => acceptedIds.indexOf(fb.id) !== -1;
  return list
    .filterNot(byId(newIds)) // Remove the values to be updated
    .concat(feedbacks) // Add the new values
    .sort(defineOrder(orderBy)) // Sort by created or modified date and id descending
}

const ifNotRemoved = (state, feedbackListId, otherwise) => state.getIn([feedbackListId, 'removed']) ? state : otherwise()

export const reducer = createReducer(initialState, {
  [FeedbackListActions.INIT]: (state, { filter, feedbackListId }) => state.set(feedbackListId, fromJS({
    feedbackListId,
    filter,
    hasMore: true,
    feedbackPreviews: []
  })),
  /**
   * Remove a feedback list. We set the removed flag to be distinct
   * on whether a list is removed or not initialized at all, which is
   * a programming error when ADD_FEEDBACKS is called. If the list is
   * removed, when a fetch is in progress, the ADD_FEEDBACKS might get
   * triggered for removed list, in which case we just ignore the update.
   */
  [FeedbackListActions.CLEAR]: (state, { feedbackListId }) => state.set(feedbackListId, fromJS({ removed: true })),


  /**
   * Set loading flag => true
   */
  [FeedbackListActions.START_LOADING]: (state, { feedbackListId }) => ifNotRemoved(
    state, feedbackListId, () => state.setIn([feedbackListId, 'loading'], true)
  ),
  [FeedbackListActions.END_LOADING]: (state, { feedbackListId }) => ifNotRemoved(
    state, feedbackListId, () => state.setIn([feedbackListId, 'loading'], false),
  ),
  [FeedbackListActions.BIND_LIST]: (state, { path, feedbackListId }) => state.setIn(['bindings', path], feedbackListId),
  /**
   * Merge new feedbacks with the previous ones. Will sort the list in created date descending order.
   * Updates existing feedbacks with new values and leaves others untouched.
   * @param feedbackListId the id of the feedback list
   * @param feedbacks the new previews
   * @param hasMore whether or not the list is complete
   */
  [FeedbackListActions.ADD_FEEDBACKS]: (state, { feedbackListId, feedbacks, hasMore }) => ifNotRemoved(
    state,
    feedbackListId,
    () => {
      defined(feedbackListId, 'feedbackListReducer>ADD_FEEDBACKS>FeedbackListId')
      defined(feedbacks, 'feedbackListReducer>ADD_FEEDBACKS>feedbacks')
      defined(hasMore, 'feedbackListReducer>ADD_FEEDBACKS>hasMore')
      const orderBy = state.getIn([feedbackListId, 'filter', 'orderBy']);
      const updated = defined(state.get(feedbackListId), 'FeedbackList with id '+feedbackListId+' not defined. Have you called INIT?')
        .set('hasMore', hasMore)
        .update(
          'feedbackPreviews',
          List([]),
          updateFeedbackPreviews(fromJS(feedbacks), orderBy)
        )
      const updatedFeedbacks = updated.get('feedbackPreviews')
      const lastFeedback = updatedFeedbacks.isEmpty() ? undefined : updatedFeedbacks.last();
      return state.set(feedbackListId, updated.set('lastFeedback', lastFeedback))
    }
  ),

  /**
   * When full feedback update is received we also update the preview of the feedback
   * in each of the feedback lists
   */
  [FeedbackActions.LOAD]: (state, action) => {
    const feedback = action.payload.feedback
    const feedbackId = feedback.id
    // Update the feedback in each of the feedback lists
    return state.map(feedbackList => {
      const idx = feedbackList.get('feedbackPreviews', List()).findIndex(fb => fb.id === feedbackId);
      return idx === -1 ? feedbackList : feedbackList.setIn(['feedbackPreviews', idx], feedback)
    })
  },

  /**
   * Remove feedback from all lists, when feedback can no longer be shown (e.g. after transfer)
   */
  [FeedbackActions.REMOVE_FROM_LISTS]: (state, action) => {
    const feedbackId = action.payload.feedbackId
    // Update the feedback in each of the feedback lists
    return state.map(feedbackList => {
      const idx = feedbackList.get('feedbackPreviews', List()).findIndex(fb => fb.id === feedbackId);
      return idx === -1 ? feedbackList : feedbackList.deleteIn(['feedbackPreviews', idx])
    })
  }

})
