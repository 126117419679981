import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'

import { MediaObject } from 'components/grid'
import { Icon } from 'components/Icons'
import { SearchInput } from 'components/Forms'

import { createValidator, required } from 'utils/Validation'
import KeskoIcons from '../Icons/KeskoIcons';

class SearchIcon extends Component {
  render() {
    return (
      <Icon
        icon={KeskoIcons.spyglass}
        size={Icon.sizes.xl}
        className="orange"
        {...this.props}
      />
    )
  }
}

class QuickSearchForm extends Component {
  static propTypes = {
    autoFocusInput: PropTypes.bool.isRequired
  };
  static defaultProps = {
    autoFocusInput: false
  };

  render() {
    // Somewhy redux-form warns about the readonly prop so we exclude it
    const {handleSubmit, fields: {search}, submitting, autoFocusInput, readonly, ...rest} = this.props // eslint-disable-line no-unused-vars
    return (
      <form action="." onSubmit={handleSubmit} {...rest} className="quick-search-form">

        <MediaObject
          object={<SearchIcon onClick={handleSubmit} />}
          content={(
            <SearchInput
              ref={this.bindInput}
              autoFocus={autoFocusInput}
              className="search-field"
              disabled={submitting}
              maxLength={255}
              translate={{placeholder: 'search.sidebar.search.placeholder'}}
              {...search}
            />
          )}
        />

        <input type="submit" className="quick-search-submit"/>
      </form>
    )
  }
}

export const QUICK_SEARCH_FORM_NAME = 'quickSearch'
export default reduxForm({
  form: QUICK_SEARCH_FORM_NAME,
  fields: ['search'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    search: [required()]
  })
})(QuickSearchForm)
