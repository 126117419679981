import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'

import { RequestCommentView } from 'components/Feedback'
import { relativizeTarget } from 'components/Routing/RoutingUtils'
import { triggerSendCommentRequest } from 'actions/CommentActions'
import { Feedback } from 'domain'
import { fetchTransferFeedbackAddresses } from '../actions/ClientActions';
import { toJS } from '../utils/Helpers';

class RequestCommentRoute extends Component {
  static contextTypes = {
    currentPath: PropTypes.string,
    router: PropTypes.any
  };
  static propTypes = {
    feedback: PropTypes.instanceOf(Feedback).isRequired,
    userEmails: PropTypes.arrayOf(PropTypes.string)
  };

  navigateToFeedbackView = () => this.context.router.push(relativizeTarget(this.context.currentPath, '..'));

  sendCommentRequest = (receiverEmail, commentText, language) => this.props.actions.sendRequest(receiverEmail, commentText, language).then(this.navigateToFeedbackView);

  render() {
    const actions = {
      sendRequest: this.sendCommentRequest,
      cancel: this.navigateToFeedbackView,
      getUserEmails: this.props.actions.getUserEmails
    };

    return (
      <RequestCommentView
        {...this.props}
        actions={actions}
      />
    )
  }
}

const mapStateToProps = state => ({
  userEmails: toJS(state.getIn(['globalData', 'transferFeedbackAddresses']))
});

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    getUserEmails: () => dispatch(fetchTransferFeedbackAddresses()),
    sendRequest: (receiverEmail, commentText, language) => dispatch(triggerSendCommentRequest(props.feedbackId, commentText, receiverEmail, language))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestCommentRoute)
