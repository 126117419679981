import React, { Component, PropTypes } from 'react'

import { Col } from 'components/grid'
import { Navigator } from 'components/Menu'
import { Text } from 'containers/Text'
import { ExclamatedNote } from 'components/kesko'

import ChangeContactForm from './ChangeContactForm'

class ChangeContactView extends Component {

  static propTypes = {
    changeContact: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      changeContact: PropTypes.func.isRequired
    }).isRequired
  };

  render() {
    const { feedback: {customer}, cancel, changeContact } = this.props;
    const { name, phone, email } = customer || {};
    let exclamatedNoteKey = `feedback.changeContact.${!!name ? 'noteChanging' : 'noteAdding'}`;
    return (
      <section className="px1">
        <Navigator backUrl=".." />
        <Col className="mb2"><hr /></Col>

        <h2 className="blue">
          <Text k="feedback.changeContact.title" />
        </h2>

        <ExclamatedNote translate={{ note: exclamatedNoteKey }}
                        className="mb2"
        />

        <ChangeContactForm initialValues={{ name, phone, email }}
                           onSubmit={changeContact}
                           onCancel={cancel}
        />

      </section>
    )
  }
}

export default ChangeContactView
