import React from 'react'

import { Col } from 'components/grid'

const SearchRouteWrapper = (props) => (
  <Col className="clearfix p1">
    {props.children}
  </Col>
);

export default SearchRouteWrapper
