const ChainId = {
  kcitymarket: 'kcitymarket',
  ksupermarket: 'ksupermarket',
  kmarket: 'kmarket',
  kmkesko: 'kmkesko',
  nokm: 'nokm',
  krauta: 'krauta',
  intersport: 'intersport',
  budgetsport: 'budgetsport',
  onninen: 'onninen',
  kcaara: 'kcaara'
};

export default ChainId;
