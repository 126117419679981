import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'

import { ChangeFeedbackHandlerView } from 'components/Feedback'
import { RoutingUtils } from 'components/Routing'
import { WaitFor } from 'components/Utils'
import { Handler, Feedback } from 'domain'
import { toJS } from 'utils'

import { fetchPossibleHandlers, changeHandler } from 'actions/FeedbackActions'

class ChangeHandlerRoute extends Component {
  static contextTypes = {
    currentPath: PropTypes.string,
    router: PropTypes.any
  };
  static propTypes = {
    possibleHandlers: PropTypes.arrayOf(PropTypes.instanceOf(Handler)),
    feedback: PropTypes.instanceOf(Feedback)
  };

  navigateToFeedbackView = () => this.context.router.push(RoutingUtils.relativizeTarget(this.context.currentPath, '..'));
  saveHandler = (handlerId, comment) => this.props.actions.saveHandler(handlerId, comment).then(this.navigateToFeedbackView);
  feedbackAndHandlerDefined = () => this.props.feedback && this.props.possibleHandlers;

  componentDidMount() {
    if (this.props.feedback) {
      this.props.actions.fetchPossibleHandlers(this.props.feedback)
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.feedback && newProps.feedback !== this.props.feedback) {
      this.props.actions.fetchPossibleHandlers(newProps.feedback)
    }
  }

  render() {
    const actions = {
      saveHandler: this.saveHandler,
      cancel: this.navigateToFeedbackView
    }
    return (
      <WaitFor condition={this.feedbackAndHandlerDefined}>
        <ChangeFeedbackHandlerView {...this.props} actions={actions}/>
      </WaitFor>
    )
  }
}

const mapStateToProps = (state, props) => {
  const feedback = state.getIn(['feedbacks', props.feedbackId])
  const possibleHandlers = toJS(state.getIn(['feedbacks', 'possibleHandlers', props.feedbackId]))
  return {
    possibleHandlers,
    feedback
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    fetchPossibleHandlers: (feedback) => dispatch(fetchPossibleHandlers(feedback)),
    saveHandler: (handlerId, comment) => dispatch(changeHandler(props.feedbackId, handlerId, comment ))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeHandlerRoute)
