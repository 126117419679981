import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'

import { Col } from 'components/grid'
import { ErrorBlock, TextArea, LanguageSelection, LabeledInput } from 'components/Forms'
import SaveToLocalStorage from 'components/Forms/SaveToLocalStorage'

import { createValidator, required, minLength, maxLength, arrayHasValues } from 'utils/Validation'
import MultipleEmailsForm from '../MultipleEmailsForm';
import Button from '../../kesko/common/Button';

const SavingTextArea = SaveToLocalStorage(TextArea)

class ForwardFeedbackForm extends Component {

  static contextTypes = {
    locale: PropTypes.string
  };

  static propTypes = {
    initialValues: PropTypes.shape({
      emails: PropTypes.string,
      language: PropTypes.string
    }),
    userEmails: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    forward: () => {
    }
  }

  bindTextArea = (area) => {
    this._savingTextArea = area
  };

  forgetStoredCommentIfSuccessful = (cb) => {
    return function (event) {
      const promise = cb(event)
      promise.then(this._savingTextArea.clear)
      return promise
    }.bind(this)
  }

  render() {
    const {handleSubmit, error, onCancel, fields, fields: {emails, language, comment}, submitting, localStorageKey, userEmails} = this.props
    return (
      <form className="basic-form forward-feedback-form" noValidate>
        <Col className="clearfix mb2">
          <ErrorBlock
            fields={fields}
            submitError={error}
            translatePrefix="feedback.forward.fields" />
        </Col>
        <Col className="mb2">
          <LabeledInput labelKey="feedback.forward.fields.emails.placeholder">
            <MultipleEmailsForm
              userEmails={userEmails}
              onChange={emails.onChange}
            />
          </LabeledInput>
        </Col>
        <Col className="mb2">
          <LanguageSelection
            language={language}
            titleKey="feedback.forward.fields.language"
          />
        </Col>
        <Col className="mb2">
          <LabeledInput labelKey="feedback.forward.fields.comment.placeholder">
            <SavingTextArea
              ref={this.bindTextArea}
              rows="5"
              disabled={submitting}
              localStorageKey={localStorageKey}
              {...comment}
            />
          </LabeledInput>
        </Col>

        <div className="form-buttons-group">
          <Button
            onClick={onCancel}
            translation="feedback.forward.cancel"
            disabled={submitting}
            theme={Button.theme.cancel}
          />
          <Button
            onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(this.props.forward))}
            translation="feedback.forward.save"
            type="submit"
            disabled={submitting}
            theme={Button.theme.primary}
          />
        </div>

      </form>
    )
  }
}

const mapStateToProps = () => ({
  initialValues: {
    language: 'fi'
  }
})

const ReduxForwardFeedbackForm = reduxForm({
  form: 'forward',
  fields: ['emails', 'comment', 'language'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    emails: [arrayHasValues('required'), maxLength(1000)],
    comment: [required(), maxLength(50000)],
    language: [minLength(2), maxLength(2)]
  })
}, mapStateToProps)(ForwardFeedbackForm)
ReduxForwardFeedbackForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func.isRequired
}

export default ReduxForwardFeedbackForm
