import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { info, warn } from 'utils/Log'

export const requestPermissionForNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    return await messaging.getToken();
  } catch (error) {
    warn(error);
    return false;
  }
}

export const getPermissionToken = async () => {
  const messaging = firebase.messaging();
  return await messaging.getToken();
}

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    firebase.initializeApp({
      messagingSenderId: '154579998288'
    });
    navigator.serviceWorker
      .register('./sw.js', {scope: '.'})
      .then(function(registration) {
        info('Registration successful, scope is:', registration.scope);
        firebase.messaging().useServiceWorker(registration);
      });
  }
};
