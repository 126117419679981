import { fromJS, List } from 'immutable'
import createReducer from './createReducer'
import { AttachmentActions } from 'actions/AttachmentActions'
import { DraftAttachmentActions } from 'actions/DraftAttachmentActions'

const defaultState = fromJS({
  attachments: [],
  draftAttachments: []
})

export const reducer = createReducer(defaultState, {
  [AttachmentActions.ADD]: (state, action) => {
    const old = state.get('attachments')
    return state.set('attachments', old.push(action.file))
  },
  [AttachmentActions.UPDATE]: (state, action) => {
    const old = state.get('attachments')
    return state.set('attachments', old.push(action.file).pop())
  },
  [AttachmentActions.REMOVE]: (state, action) => {
    const old = state.get('attachments')
    return state.set('attachments', old.filter(item => item !== action.file))
  },
  [AttachmentActions.CLEAR]: state => {
    return state.set('attachments', List([]))
  },
  [DraftAttachmentActions.INIT]: (state, action) => {
    let attachments = fromJS([])
    if (action.feedback.responseDraft && action.feedback.responseDraft.attachments) {
      action.feedback.responseDraft.attachments.forEach((attachment) => {
        if (!attachment.removed) {
          attachments = attachments.push({
            id: attachment.get('attachmentId'),
            src: attachment.get('downloadLink'),
            name: attachment.get('fileName'),
            fileSize: attachment.get('sizeInBytes'),
            caption: attachment.get('fileName') + ' (' + attachment.get('sizeInBytes') + ')',
            virusScanned: attachment.get('virusScanned')
          })
        }
      })
    }
    return state.set('draftAttachments', attachments)
  },
  [DraftAttachmentActions.REMOVE]: (state, action) => {
    const old = state.get('draftAttachments')
    return state.set('draftAttachments', old.filter(item => item !== action.file))
  }
})
