import React, { PropTypes } from 'react'
import PureComponent from 'react-pure-render/component'

class CommaSeparated extends PureComponent {
  static propTypes = {
    values: PropTypes.arrayOf(PropTypes.string).isRequired
  };
  static defaultProps = {
    values: []
  };

  render() {
    return (<span>{this.props.values.join(', ')}</span>)
  }
}

export default CommaSeparated
