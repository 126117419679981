import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'

import { Col } from 'components/grid'
import { ErrorBlock, TextArea, LabeledCheckBox, LabeledInput } from 'components/Forms'

import { createValidator, required, maxLength } from 'utils/Validation'
import Button from '../kesko/common/Button';

class GiveFeedbackForm extends Component {

  render() {
    const {handleSubmit, error, onCancel, fields, fields: {feedback, responseRequested}, submitting} = this.props
    return (
      <form className="basic-form" onSubmit={handleSubmit} noValidate>
        <Col className="clearfix mb2">
          <ErrorBlock
            fields={fields}
            submitError={error}
            translatePrefix="giveFeedback.fields" />
        </Col>
        <Col className="mb2">
          <LabeledInput labelKey="giveFeedback.fields.feedback.placeholder">
            <TextArea
              rows="5"
              disabled={submitting}
              {...feedback}
            />
          </LabeledInput>
        </Col>
        <Col className="mb2">
          <LabeledCheckBox
            {...responseRequested}
            label="giveFeedback.fields.responseRequested.label"
          />
        </Col>

        <div className="form-buttons-group">
          <Button
            onClick={onCancel}
            translation="giveFeedback.cancel"
            disabled={submitting}
            theme={Button.theme.cancel}
          />
          <Button
            translation="giveFeedback.save"
            type="submit"
            disabled={submitting}
            theme={Button.theme.primary}
          />
        </div>

      </form>
    )
  }
}

const ReduxGiveFeedbackForm = reduxForm({
  form: 'giveFeedback',
  fields: ['feedback', 'responseRequested'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    feedback: [required(), maxLength(50000)]
  })
}, () => {
})(GiveFeedbackForm)
ReduxGiveFeedbackForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default ReduxGiveFeedbackForm
