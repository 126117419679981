import common from 'components/kesko/common'
import { default as BlueHeading } from './BlueHeading'
import { default as ExclamatedNote } from './ExclamatedNote'
import { default as HeadingLine } from './HeadingLine'
import { default as LinkButton } from './LinkButton'
import { default as OutlineButton } from './OutlineButton'
import { default as Pill } from './Pill'
import { default as ToggleButton } from './ToggleButton'
import { default as TriangledTitle } from './TriangledTitle'
import { default as TextWithIcon } from './TextWithIcon';
import { default as Button } from './common/Button';
import { default as TextWithHeading } from './TextWithHeading';
import { default as ContextMenu } from './ContextMenu';

module.exports = {
  ...common,
  BlueHeading,
  ExclamatedNote,
  HeadingLine,
  LinkButton,
  OutlineButton,
  Pill,
  ToggleButton,
  TriangledTitle,
  TextWithIcon,
  Button,
  TextWithHeading,
  ContextMenu
};
