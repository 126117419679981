import { ReceiverType, ReceiverFilterType } from 'domain'

export const possibleReceiverFiltersByReceiver = (receiver) => {
  switch (receiver.value) {
    case ReceiverType.chainAndStore:
      return [ReceiverFilterType.chainUnit, ReceiverFilterType.businessUnitInclusive, ReceiverFilterType.businessUnitExclusive]
    case ReceiverType.chain:
      return [ReceiverFilterType.chainUnit]
    case ReceiverType.store:
      return [ReceiverFilterType.chainUnit, ReceiverFilterType.businessUnitInclusive, ReceiverFilterType.businessUnitExclusive, ReceiverFilterType.businessUnitIds]
    case ReceiverType.subject:
      return [ReceiverFilterType.subject]
    default:
      return []
  }
}
