/**
 * Overrides the redux-form's onBlur default functionality
 * by only setting the touched flag when the field is dirty
 * @param field
 * @returns {{onBlur: onBlur}}
 */
export const overrideOnBlur = field => {
  const { onBlur, ...everythingExceptOnBlur } = field // eslint-disable-line no-unused-vars
  return field.dirty ? field : everythingExceptOnBlur
}

export const overrideDragAndDropEvents = field => {
  const { onDragStart, onDrop, ...everythingExceptDragAndDrop } = field // eslint-disable-line no-unused-vars
  return everythingExceptDragAndDrop
}
