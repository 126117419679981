import React, { Component, PropTypes, Children, cloneElement } from 'react';

import { Col } from 'components/grid'
import { Collapse } from 'containers/Layout'

class DrawerMenu extends Component {
  constructor(props) {
    super(props)
    this.bindCollapse = this.bindCollapse.bind(this)
    this.toggle = this.toggle.bind(this)
    this.isOpen = this.isOpen.bind(this)
  }

  bindCollapse(collapse) {
    this._collapse = collapse
  }

  toggle() {
    this._collapse.toggle()
  }

  isOpen() {
    return this._collapse.isOpen()
  }

  render() {
    const children = Children.toArray(this.props.children)
    const links = Children.map(children, child => (
      <div className="underline">
        <hr/>
        {cloneElement(child, { className: 'block' })}
      </div>
    ))

    return (
      <Col className="clearfix">
        <Collapse collapseId={this.props.collapseId}
                  ref={this.bindCollapse}
                  initiallyVisible={false}
                  deregisterOnUnmount={true}>
          {links}
        </Collapse>
      </Col>
    )
  }
}
DrawerMenu.propTypes = {
  collapseId: PropTypes.any.isRequired
}

export default DrawerMenu
