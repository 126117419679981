import { fromJS, OrderedMap } from 'immutable'
import {
  fetchChainUnits,
  fetchBusinessUnits,
  fetchCompanyHandlers,
  fetchReportingHandlers,
  fetchFeedbackChannels,
  fetchSubjects,
  fetchTopics,
  fetchExternalSystems
} from 'actions/ClientActions'
import { getOrFetchBusinessUnit } from 'actions/BusinessUnitActions'
import { defined } from 'utils'
export const GlobalDataActions = {
  SET: 'GlobalData/Set'
}

export const setGlobalData = (name, data) => ({ type: GlobalDataActions.SET, name, data })

/**
 * Fetches all available feedback channels
 */
export const getFeedbackChannels = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const feedbackChannels = getState().getIn(['globalData', 'feedbackChannels'])
  if (feedbackChannels) {
    resolve(feedbackChannels.toJS())
  } else {
    dispatch(fetchFeedbackChannels())
      .then(data => {
        const sorted = fromJS(data).sortBy(channel => channel.id).toJS()
        dispatch(setGlobalData('feedbackChannels', new OrderedMap(sorted.map(s => [s.id, s]))))
        return sorted
      })
      .then(resolve, reject)
  }
})

/**
 * Fetches all available subjects
 */
export const getSubjects = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const subjects = getState().getIn(['globalData', 'subjects'])
  if (subjects) {
    resolve(subjects.toJS())
  } else {
    dispatch(fetchSubjects())
      .then(data => {
        dispatch(setGlobalData('subjects', new OrderedMap(data.map(s => [s.id, s]))))
        return data
      })
      .then(resolve, reject)
  }
})

/**
 * Fetches all available external systems
 */
export const getExternalSystems = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const externalSystems = getState().getIn(['globalData', 'externalSystems'])
  if (externalSystems) {
    resolve(externalSystems.toJS())
  } else {
    dispatch(fetchExternalSystems())
      .then(data => {
        const sorted = fromJS(data).sortBy(externalSystem => externalSystem.id).toJS()
        dispatch(setGlobalData('externalSystems', new OrderedMap(sorted.map(s => [s.id, s]))))
        return sorted
      })
      .then(resolve, reject)
  }
})

/**
 * Fetches all available topics
 */
export const getTopics = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const topics = getState().getIn(['globalData', 'topics'])
  if (topics) {
    resolve(topics.toJS())
  } else {
    dispatch(fetchTopics())
      .then(data => {
        const sorted = fromJS(data).sortBy(topics => topics.id).toJS()
        dispatch(setGlobalData('topics', new OrderedMap(sorted.map(s => [s.id, s]))))
        return sorted
      })
      .then(resolve, reject)
  }
})

/**
 * Fetches all available chain units
 */
export const getChainUnits = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const chainUnits = getState().getIn(['globalData', 'chainUnits'])
  if (chainUnits) {
    resolve(chainUnits.toJS())
  } else {
    dispatch(fetchChainUnits())
      .then(data => {
        dispatch(setGlobalData('chainUnits', new OrderedMap(data.map(s => [s.id, s]))))
        return data
      })
      .then(resolve, reject)
  }
})

/**
 * Fetches all available business units
 */
export const getBusinessUnits = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const businessUnits = getState().getIn(['globalData', 'businessUnits'])
  if (businessUnits) {
    resolve(businessUnits.toList().toJS())
  } else {
    dispatch(fetchBusinessUnits())
      .then(data => {
        const sorted = fromJS(data).sortBy(chainUnit => chainUnit.name).toJS()
        dispatch(setGlobalData('businessUnits', new OrderedMap(sorted.map(s => [s.id, s]))))
        return sorted
      })
      .then(resolve, reject)
  }
})

export const getAllowableBusinessUnits = () => (dispatch, getState) => dispatch(getBusinessUnits())
  .then(list => {
    const isStoreUser = defined(getState().getIn(['client','storeUser']), 'client>storeUser should be defined, is the user logged in?')
    if (!isStoreUser) {
      dispatch(setGlobalData('allowableBusinessUnits', new OrderedMap(list.map(s => [s.id, s]))))
      return list
    }
    const allowedBusinessUnitIds = getState().getIn(['client','businessUnits'])
    const filtered = list.filter(bu => allowedBusinessUnitIds.contains(bu.id))
    dispatch(setGlobalData('allowableBusinessUnits', new OrderedMap(filtered.map(s => [s.id, s]))))
    return filtered
  })

export const getCompanyHandlers = companyId => (dispatch, getState) => new Promise((resolve, reject) => {
  const handlers = getState().getIn(['globalData', companyId + 'Handlers'])
  if (handlers) {
    resolve(handlers.toJS())
  } else {
    dispatch(fetchCompanyHandlers(companyId))
      .then(data => {
        const sorted = fromJS(data).sortBy(handler => handler.name).toJS()
        dispatch(setGlobalData(companyId + 'Handlers', sorted))
        return sorted
      }).then(resolve, reject)
  }
})

export const fetchStoreHandlers = businessUnitId => dispatch => new Promise((resolve, reject) => {
  dispatch(getOrFetchBusinessUnit(businessUnitId))
    .then(businessUnit => {
      dispatch(setGlobalData('storeHandlers', businessUnit.handlers))
      return businessUnit.handlers
    }).then(resolve, reject)
})

export const getReportingHandlers = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const handlers = getState().getIn(['globalData', 'reportingHandlers'])
  if (handlers) {
    resolve(handlers.toJS())
  } else {
    dispatch(fetchReportingHandlers())
      .then(data => {
        const sorted = fromJS(data).sortBy(handler => handler.name).toJS()
        dispatch(setGlobalData('reportingHandlers', new OrderedMap(sorted.map(h => [h.userId, h]))))
        return sorted
      }).then(resolve, reject)
  }
})
