import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'

import { Col } from 'components/grid'
import {
  ErrorBlock,
  Selection,
  TextArea,
  TokenInput,
  LanguageSelection,
  LabeledInput,
  FilterableDropdown
} from 'components/Forms'
import { WaitFor } from 'components/Utils'
import { List } from 'immutable'
import SaveToLocalStorage from 'components/Forms/SaveToLocalStorage'

import { createValidator, required, minLength, maxLength, email, isEmpty} from 'utils/Validation'
import Button from '../../kesko/common/Button';
import {businessUnitMapper, chainUnitMapper, subjectMapper, externalSystemMapper} from 'utils/OptionMapper'

const SavingTextArea = SaveToLocalStorage(TextArea)

const targetTypeToOption = targetType => ({
  k: ['feedback', 'transfer', 'fields', 'targetType', targetType],
  id: targetType,
  value: targetType
})

const handlerName = handler => handler.name + ' (' + handler.userId + (!!handler.email ? ', ' + handler.email : '') + ')'

const handlerMapper = handler => ({
  label: handlerName(handler),
  id: handler.userId,
  value: handler.userId
})

class TransferFeedbackForm extends Component {

  static contextTypes = {
    locale: PropTypes.string
  };

  static propTypes = {
    externalSystemOptions: PropTypes.instanceOf(List).isRequired,
    usageObjected: PropTypes.bool.isRequired,
    businessUnitOptions: PropTypes.instanceOf(List).isRequired,
    handlerOptions: PropTypes.instanceOf(List).isRequired,
    chainUnitOptions: PropTypes.instanceOf(List).isRequired,
    subjectOptions: PropTypes.instanceOf(List).isRequired,
    transferFeedbackAddresses: PropTypes.instanceOf(List).isRequired,
    fetchStoreHandlers: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      targetType: PropTypes.string,
      targetStore: PropTypes.string,
      targetHandler: PropTypes.string,
      targetChain: PropTypes.string,
      targetOther: PropTypes.string,
      targetEmail: PropTypes.string,
      targetSubject: PropTypes.string,
      language: PropTypes.string
    })
  };

  static defaultProps = {
    transfer: () => {
    }
  }

  bindTextArea = (area) => {
    this._savingTextArea = area
  };

  forgetStoredCommentIfSuccessful = (cb) => {
    return function (event) {
      const promise = cb(event)
      promise.then(this._savingTextArea.clear)
      return promise
    }.bind(this)
  }

  componentWillReceiveProps(newProps) {
    const newStore = newProps.fields.targetStore.value
    if (newStore && this.props.fields.targetStore.value !== newStore) {
      this.props.fetchStoreHandlers(newStore)
    }
  }

  render() {
    const {
      handleSubmit, error, onCancel, isStoreUser, submitting, usageObjected,
      fields, fields: {targetType, targetStore, targetHandler, targetChain, targetOther, targetEmail, targetSubject, language, commentText},
      externalSystemOptions, businessUnitOptions, handlerOptions, chainUnitOptions, subjectOptions, transferFeedbackAddresses, localStorageKey
    } = this.props
    let targetTypes
    if (usageObjected) {
      targetTypes = ['store', 'chain', 'subject', 'other']
    } else {
      targetTypes = ['store', 'chain', 'subject', 'other', 'email']
    }

    return (
      <form className="basic-form transfer-feedback-form" noValidate>
        <Col className="clearfix mb2">
          <ErrorBlock
            fields={fields}
            submitError={error}
            translatePrefix="feedback.transfer.fields" />
        </Col>

        <Col className="mb2">
          <LabeledInput labelKey="feedback.transfer.fields.targetType.label">
            <Selection
              disabled={submitting}
              showNotSelected={false}
              optionMapper={targetTypeToOption}
              options={targetTypes}
              translate={{notSelectedLabel: 'feedback.transfer.fields.targetType.required'}}
              {...targetType}
            />
          </LabeledInput>
        </Col>

        {targetType.value === 'other' && (
          <Col className="mb2">
            <Selection
              disabled={submitting}
              optionMapper={externalSystemMapper(this.context.locale)}
              options={externalSystemOptions.toJS()}
              translate={{notSelectedLabel: 'feedback.transfer.fields.targetOther.required'}}
              {...targetOther}
            />
          </Col>
        )}

        {targetType.value === 'chain' && (
          <Col className="mb2">
            <Selection
              disabled={submitting}
              showNotSelected={false}
              optionMapper={chainUnitMapper(true)}
              options={chainUnitOptions.toJS()}
              translate={{notSelectedLabel: 'feedback.transfer.fields.targetChain.required'}}
              {...targetChain}
            />
          </Col>
        )}

        {targetType.value === 'store' && (
          <Col className="mb2">
            <TokenInput
              disabled={submitting}
              optionMapper={businessUnitMapper}
              options={businessUnitOptions}
              multiple={false}
              translate={{
                placeholder: 'feedback.transfer.fields.targetStore.required',
                notSelectedLabel: 'feedback.transfer.fields.targetStore.required'
              }}
              {...targetStore}
            />
          </Col>
        )}

        {!isStoreUser && targetType.value === 'store' && targetStore.value && (
          <WaitFor condition={handlerOptions}>
            {handlerOptions && (
              <Col className="mb2">
                <Selection
                  disabled={submitting}
                  showNotSelected={true}
                  optionMapper={handlerMapper}
                  options={handlerOptions.toJS()}
                  translate={{notSelectedLabel: 'feedback.transfer.fields.targetHandler.notSelected'}}
                  {...targetHandler}
                />
              </Col>
            )}
          </WaitFor>
        )}

        {(targetType.value === 'email' && !usageObjected) && (
          <div>
            <Col className="mb2">
              <FilterableDropdown
                translate={{
                  placeholder: 'feedback.transfer.fields.targetEmail.placeholder'
                }}
                items={transferFeedbackAddresses.toJS()}
                disabled={submitting}
                type="email"
                {...targetEmail}
              />
            </Col>
            <Col className="mb2">
              <LanguageSelection
                language={language}
                titleKey="feedback.transfer.fields.language"
              />
            </Col>
          </div>
        )}

        {targetType.value === 'subject' && (
          <Col className="mb2">
            <Selection
              disabled={submitting}
              optionMapper={subjectMapper(this.context.locale, true)}
              options={subjectOptions.toJS()}
              translate={{notSelectedLabel: 'feedback.transfer.fields.targetSubject.required'}}
              {...targetSubject}
            />
          </Col>
        )}

        <Col className="mb2">
          <LabeledInput labelKey="feedback.transfer.fields.commentText.placeholder">
            <SavingTextArea
              ref={this.bindTextArea}
              rows="5"
              disabled={submitting}
              localStorageKey={localStorageKey}
              {...commentText}
            />
          </LabeledInput>
        </Col>

        <div className="form-buttons-group">
          <Button
            onClick={onCancel}
            translation="feedback.transfer.cancel"
            disabled={submitting}
            theme={Button.theme.cancel}
          />
          <Button
            onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(this.props.transfer))}
            translation="feedback.transfer.save"
            type="submit"
            disabled={submitting}
            theme={Button.theme.primary}
          />
        </div>
      </form>
    )
  }
}

const mapStateToProps = () => ({
  initialValues: {
    language: 'fi'
  }
})

const validateEmail = (value, props) => {
  if (props.targetType === 'email' && isEmpty(value)) {
    return {msg: 'required'};
  }
  if (!email()(value).valid) {
    return {msg: 'email'};
  }
  return {};
}

const ReduxTransferFeedbackForm = reduxForm({
  form: 'transfer',
  fields: ['targetType', 'targetStore', 'targetHandler', 'targetChain', 'targetOther', 'targetEmail', 'targetSubject', 'commentText', 'language'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    targetEmail: [(value, props) => validateEmail(value, props)],
    targetType: required(),
    commentText: [required(), maxLength(50000)],
    language: [minLength(2), maxLength(2)]
  })
}, mapStateToProps)(TransferFeedbackForm)
ReduxTransferFeedbackForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func.isRequired
}

export default ReduxTransferFeedbackForm
