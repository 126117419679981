import React, { PropTypes } from 'react';
import KeskoIcons from '../Icons/KeskoIcons';
import TextWithIcon from '../kesko/TextWithIcon';
import {email as emailValidator} from 'utils/Validation'
import SingleEmailForm from './SingleEmailForm'

export default class MultipleEmailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailForms: [''],
      invalidEmailForms: []
    };

    this.addNewForm = this.addNewForm.bind(this);
    this.onFormBlur = this.onFormBlur.bind(this);
    this.deleteForm = this.deleteForm.bind(this);
  }

  triggerFormOnChange() {
    // trigger change-event on field to update redux state
    this.props.onChange(this.state.emailForms.filter(email => email !== ''));
    const invalidEmails = this.state.emailForms.filter(email => {
      return email && !emailValidator()(email).valid
    });
    this.setState({invalidEmailForms: invalidEmails})
  }

  addNewForm() {
    const newForms = [...this.state.emailForms];
    newForms.push('');
    this.setState({emailForms: newForms});
  }

  deleteForm(index) {
    const newForms = [...this.state.emailForms];
    this.setState({emailForms: newForms.filter((form, i) => index !== i)}, () => this.triggerFormOnChange());
  }

  onFormBlur(value, formIndex) {
    const newForms = [...this.state.emailForms];
    newForms[formIndex] = value;
    this.setState({emailForms: newForms}, () => this.triggerFormOnChange());
  }

  /* eslint-disable react/jsx-no-bind */
  // no way to do this properly with the rule enabled
  createForms = () => this.state.emailForms.map((email, index) => (
      <SingleEmailForm
        key={index}
        index={index}
        userEmails={this.props.userEmails}
        initialValue={this.state.emailForms.find((form, i) => index === i)}
        onBlur={this.onFormBlur}
        deleteForm={this.deleteForm}
        isValid={this.state.invalidEmailForms.indexOf(email) < 0}
      />
    )
  );

  render() {
    return (
      <div className="multiple-emails-form">
        {this.createForms()}
        <TextWithIcon
          translation="feedback.requestComment.addReceiver"
          icon={KeskoIcons.plus}
          onClick={this.addNewForm}
          theme={TextWithIcon.themes.kesko}
          className="add-new-row-button"
        />
      </div>
    )
  }
}

MultipleEmailsForm.propTypes = {
  userEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired
};
