import React, { Component } from 'react';
import filesize from 'filesize'

import { Icon, KeskoIcons, Spinner } from 'components/Icons'
import { Text } from 'containers/Text'
import TextWithIcon from '../kesko/TextWithIcon'

class Attachment extends Component {
  render() {
    const {file, removeFile, isDraft} = this.props;

    const remove = () => {
      removeFile(file)
    };

    const fileSizeValue = filesize(isDraft ? file.fileSize : file.size)

    return (
      <div className="attachment">

        {file.uploading && (
          <span className="h6 flex-none">
            <Spinner size="xl" className="blue" />
          </span>
        )}

        {(isDraft || file.uploaded) && (
          <Icon
            size={Icon.sizes.xl}
            icon={KeskoIcons.tick}
            className="green"
          />
        )}

        {!file.uploading && file.error && (
          <Icon
            size={Icon.sizes.xl}
            icon={KeskoIcons.exclamation}
            className="orange"
          />
        )}

        <Text k={file.name} className="flex-auto truncate filename" />
        <Text k={fileSizeValue} className="flex-none filesize" />

        <TextWithIcon
          onClick={remove}
          translation="remove"
          icon={KeskoIcons.trash}
          className="delete-attachment-button"
          style={{'visibility': (file.uploaded || file.error) ? '' : 'hidden'}}
        />
      </div>
    );
  }
}

export default Attachment
