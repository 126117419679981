import React, { Component, PropTypes } from 'react'
import { findDOMNode } from 'react-dom'
import { scrollIntoView, scrollToPageTop } from './ScrollUtils'

const ScrollTo = (shouldScroll, scrolled = () => {
}, scrollToTop = false) => OriginalComponent => class extends Component {

  static defaultProps = {
    scrollTimeout: 300
  };
  static propTypes = {
    scrollTimeout: PropTypes.number.isRequired
  };

  getScrollFunction = () => {
    if (!scrollToTop) {
      return scrollIntoView(findDOMNode(this));
    } else {
      return scrollToPageTop();
    }
  };

  componentDidMount() {
    if (shouldScroll(this.props)) {
      setTimeout(() => {
        this.getScrollFunction();
        scrolled()
      }, this.props.scrollTimeout)
    }
  }

  render() {
    const {children, ...props} = this.props
    return (<OriginalComponent {...props}>{children}</OriginalComponent>)
  }
}

export default ScrollTo
