import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form'

import { Col } from 'components/grid'
import { ExclamatedNote } from 'components/kesko'
import { TextArea, LabeledInput } from 'components/Forms'
import { overrideDragAndDropEvents } from 'components/Forms/common/FormUtils'
import SaveToLocalStorage from 'components/Forms/SaveToLocalStorage'

import { Text } from 'containers/Text'
import { ErrorBlock } from 'components/Forms'
import { createValidator, maxLength, required } from 'utils/Validation'

import FeedbackCustomerInfo from '../FeedbackCustomerInfo'
import Button from 'components/kesko/common/Button';

const SavingTextArea = SaveToLocalStorage(TextArea)

class FeedbackReplyForm extends Component {

  bindTextArea = (area) => {
    this._savingTextArea = area
  };

  forgetStoredCommentIfSuccessful = (cb) => {
    return function (event) {
      const promise = cb(event)
      promise.then(this._savingTextArea.clear)
      return promise
    }.bind(this)
  }

  onDrop = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  render() {
    const {feedback, localStorageKey, fields, fields: {comment}, submitting, handleSubmit, onCancel, error} = this.props

    return (
      <div className="feedback-reply-form">
        <h2>
          <Text k="feedback.replyByPhone.replyByPhone" />
        </h2>

        <Col className="mb2">
          <ExclamatedNote translate={{note: 'feedback.replyByPhone.note'}} />
        </Col>

        <Col className="mb2">
          <FeedbackCustomerInfo
            customer={feedback.customer}
            showPhoneLink={true}
            showEmail={false}
            showPreferredContactChannel={false}
          />
        </Col>

        <Col className="clearfix">
          <ErrorBlock
            fields={fields}
            submitError={error}
            translatePrefix="feedback.replyByPhone.fields"
            className="mb2"
          />
        </Col>

        <form>
          <Col className="mb2">
            <LabeledInput labelKey="feedback.replyByPhone.fields.comment.placeholder">
              <SavingTextArea
                ref={this.bindTextArea}
                rows="5"
                disabled={submitting}
                onDrop={this.onDrop}
                localStorageKey={localStorageKey}
                {...overrideDragAndDropEvents(comment)}
              />
            </LabeledInput>
          </Col>

          <div className="reply-buttons">
            <Button
              onClick={onCancel}
              disabled={submitting}
              translation="feedback.replyByPhone.cancel"
              theme={Button.theme.cancel}
            />
            <Button
              onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(this.props.submitAndWaiting))}
              disabled={submitting}
              translation="feedback.replyByPhone.saveAndWaiting"
              theme={Button.theme.state_pending}
            />
            <Button
              onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(this.props.submitAndDone))}
              disabled={submitting}
              translation="feedback.replyByPhone.saveAndDone"
              theme={Button.theme.state_complete}
            />
          </div>

        </form>
      </div>
    )
  }
}

FeedbackReplyForm.propTypes = {
  localStorageKey: PropTypes.any.isRequired
}
FeedbackReplyForm.defaultProps = {
  submitAndDone: () => {
  },
  submitAndWaiting: () => {
  }
}

const ReduxFeedbackReplyForm = reduxForm({
  form: 'replyToFeedback',
  fields: ['comment'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    comment: [required(), maxLength(50000)]
  })
})(FeedbackReplyForm)

export default ReduxFeedbackReplyForm
