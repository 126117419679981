import React, { Component } from 'react'

import { Col } from 'components/grid'
import { LinkButton, BlueHeading } from 'components/kesko'
import { Text } from 'containers/Text'

class Error404Route extends Component {
  render() {
    return (
      <Col className="clearfix p1">
        <BlueHeading text={<Text k="error.notFound.title" />}/>

        <p>
          <Text k="error.notFound.text" />
        </p>

        <p>
          <a href="javascript:history.back()">
            <LinkButton>
              <Text k="error.notFound.goBack" />
            </LinkButton>
          </a>
        </p>
      </Col>
    )
  }
}

export default Error404Route
