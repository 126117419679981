import React, { Component, PropTypes } from 'react'

import { ToggleButton } from 'components/kesko'
import { Status } from 'domain'

class FilterButton extends Component {

  static propTypes = {
    toggleFilter: PropTypes.func.isRequired
  };

  toggleFilter = filter => () => this.props.toggleFilter(filter);

  render() {
    const {filter, isActive} = this.props;
    return (
      <ToggleButton
        toggled={isActive(filter)}
        onClick={this.toggleFilter(filter)}
        translation={`feedback.filter.${filter}`}
        className="filter-button"
      />
    )
  }
}

class FeedbackListFilters extends Component {
  static propTypes = {
    activeFilter: PropTypes.string,
    statistics: PropTypes.shape({
      [Status.NEW]: PropTypes.number,
      [Status.WAITING]: PropTypes.number,
      requiresResponse: PropTypes.number,
      myFeedback: PropTypes.number,
      myWaiting: PropTypes.number
    }),
    actions: PropTypes.shape({
      toggleFilter: PropTypes.func.isRequired
    }).isRequired
  };
  static defaultProps = {
    statistics: {}
  };

  isActive = filter => this.props.activeFilter === filter;

  render() {
    const {isReadOnly} = this.props
    const buttonProps = {
      statistics: this.props.statistics,
      isActive: this.isActive,
      toggleFilter: this.props.actions.toggleFilter
    };

    return (
      <div className="feedback-list-filters">
        <FilterButton filter="requiresResponse" {...buttonProps} />
        <FilterButton filter={Status.NEW} {...buttonProps} />
        <FilterButton filter={Status.WAITING} {...buttonProps} />
        {!isReadOnly && <FilterButton filter="myFeedback" {...buttonProps} />}
        {!isReadOnly && <FilterButton filter="myWaiting" {...buttonProps} />}
      </div>
    )
  }
}

export default FeedbackListFilters
