import React, {Component, PropTypes} from 'react'
import { List } from 'immutable'

import { Col } from 'components/grid'
import { ErrorBlock } from 'components/Forms'
import ExclamatedNote from 'components/kesko/ExclamatedNote'
import { tagInvalidFile } from 'components/Utils/File'
import Attachment from './Attachment'
import AttachmentUpload from './AttachmentUpload'

class Attachments extends Component {

  static propTypes = {
    attachmentIds: PropTypes.array.isRequired,
    attachments: PropTypes.instanceOf(List).isRequired,
    draftAttachments: PropTypes.instanceOf(List),
    translatePrefix: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      uploadAttachment: PropTypes.func.isRequired,
      addAttachment: PropTypes.func.isRequired,
      updateAttachment: PropTypes.func.isRequired,
      removeAttachment: PropTypes.func.isRequired,
      removeDraftAttachment: PropTypes.func
    }).isRequired
  }

  constructor(props) {
    super(props)
    this.handleFile = this.handleFile.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  handleFile(event) {
    event.preventDefault();

    return [...event.target.files]
      .map(tagInvalidFile)
      .map(this.uploadFile)
  }

  uploadFile(file) {
    const {
      attachmentIds,
      actions: {updateAttachment, addAttachment, uploadAttachment}
    } = this.props

    const updateAttachmentWithFile = updateAttachment.bind(this, file)
    const markFileAsFailed = () => {
      file.error = 'uploadError'
      file.uploading = false
    }
    const markFileAsUploaded = resp => {
      file.id = resp.attachmentId
      file.uploading = false
      file.uploaded = true
      file.error = null
      attachmentIds.addField(file.id)
    }
    if (file.error) {
      addAttachment(file)
      return;
    }
    const data = new FormData()
    data.append('file', file)
    file.uploading = true
    addAttachment(file)

    uploadAttachment(data)
      .then(markFileAsUploaded)
      .catch(markFileAsFailed)
      .then(updateAttachmentWithFile)

    return file
  }

  render() {
    const {
      attachmentIds, attachments, draftAttachments, translatePrefix,
      actions: {removeAttachment, removeDraftAttachment}
    } = this.props

    const attachmentWithError = !!attachments ? attachments.find(file => file.error) : null

    const attachment = {
      name: 'attachment',
      dirty: true,
      touched: true,
      valid: false,
      invalid: true,
      error: attachmentWithError ? attachmentWithError.error : ''
    }

    const maxAttachments = attachmentIds.length > 5

    const removeFile = file => {
      const attachment = attachmentIds.find(item => item.value === file.id)

      if (attachment && attachmentIds.indexOf(attachment) > -1) {
        attachmentIds.removeField(attachmentIds.indexOf(attachment))
      }
      if (draftAttachments && draftAttachments.find(draft => {
        return draft.id === file.id
      })) {
        removeDraftAttachment(file)
      } else {
        removeAttachment(file)
      }
    }

    if (maxAttachments) {
      attachment.error = 'max'
    }
    const attachmentCount = attachments ? attachments.count() : 0
    const draftAttachmentCount = draftAttachments ? draftAttachments.count() : 0

    return (
      <div className="attachments">
        {(!!attachmentWithError || maxAttachments) && (
          <Col className='clearfix mt1 mb2'>
            <ErrorBlock fields={{attachment}} translatePrefix={translatePrefix} />
          </Col>
        )}

        <ExclamatedNote
          translate={{note: 'feedback.comments.attachmentHint'}}
          className="attachments-hint"
        />

        {(attachmentCount > 0 || draftAttachmentCount > 0) && (
          <div className='attachment-list fit'>
            {attachmentCount > 0 && attachments.map((file, idx) =>
              <Attachment file={file} removeFile={removeFile} key={idx} />
            )}
            {draftAttachmentCount > 0 && draftAttachments.map((file, idx) =>
              <Attachment file={file} removeFile={removeFile} key={'draft' + idx} isDraft={true} />
            )}
          </div>
        )}

        <AttachmentUpload handleFile={this.handleFile} />
      </div>
    )
  }
}

export default Attachments
