import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'

import Icon, { IconSizes, IconPropType } from 'components/Icons/Icon'

class HeadingLine extends Component {

  static propTypes = {
    icon: IconPropType,
    iconSize: PropTypes.oneOf(IconSizes),
    color: PropTypes.string,
    heading: PropTypes.node,
    text: PropTypes.node,
    truncate: PropTypes.bool.isRequired,
    tooltip: PropTypes.bool.isRequired
  };

  static defaultProps = {
    color: 'silver',
    truncate: true,
    tooltip: false
  };

  icon = () => (<Icon icon={this.props.icon} size={this.props.iconSize} className="align-middle mr1" />);

  headingBlock = () => (
    <span className="mr1 black">
      {!!this.props.icon ? this.icon() : undefined}
      <span className="bold no-user-select">{this.props.heading}</span>
    </span>
  );

  render() {
    const { className, color, truncate, tooltip, ...rest} = this.props
    const classes = classNames(
      className,
      color,
      { 'truncate': truncate }
    )
    return (
      <div {...rest} className={classes}>
        {this.headingBlock()}
        { tooltip && <span title={this.props.text}>{this.props.text}</span> }
        { !tooltip && <span>{this.props.text}</span> }
      </div>
    );
  }
}


export default HeadingLine
