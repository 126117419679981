import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form'

import { Col } from 'components/grid'
import { Attachments } from 'components/Attachments'
import { ErrorBlock, TextArea, LabeledInput } from 'components/Forms'
import { overrideDragAndDropEvents } from 'components/Forms/common/FormUtils'
import SaveToLocalStorage from 'components/Forms/SaveToLocalStorage'

import { createValidator, required } from 'utils/Validation'
import { Text } from 'containers/Text'

import FeedbackCommentsNote from '../FeedbackCommentsNote'
import Button from 'components/kesko/common/Button';

const SavingTextArea = SaveToLocalStorage(TextArea)

class CommentForm extends Component {

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  }

  static defaultProps = {
    saveComment: () => {
    }
  }

  bindTextArea = (area) => {
    this._savingTextArea = area
  }

  forgetStoredCommentIfSuccessful = (cb) => {
    return function (event) {
      const promise = cb(event)
      promise.then(this._savingTextArea.clear)
      return promise
    }.bind(this)
  }

  onDrop = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  render() {
    const {fields: {comment, attachmentIds}, handleSubmit, onCancel, submitting, error, localStorageKey, attachments} = this.props
    const errorFields = {comment}

    return (
      <div className="basic-form feedback-comment-form">
        <h2 className="blue">
          <Text k="feedback.comments.addComment" />
        </h2>

        <Col className="clearfix mb2">
          <FeedbackCommentsNote />
        </Col>

        <form>

          <Col className="clearfix mb2">
            <ErrorBlock fields={errorFields} submitError={error} translatePrefix="feedback.comments.fields" />
          </Col>

          <Col className="mb2">
            <LabeledInput labelKey="feedback.comments.fields.comment.placeholder">
              <SavingTextArea
                ref={this.bindTextArea}
                rows="5"
                disabled={submitting}
                onDrop={this.onDrop}
                localStorageKey={localStorageKey}
                {...overrideDragAndDropEvents(comment)}
              />
            </LabeledInput>

            <Attachments
              attachments={attachments}
              attachmentIds={attachmentIds}
              translatePrefix="feedback.comments"
              actions={this.props.actions}
            />
          </Col>


          <div className="form-buttons-group">
            <Button
              onClick={onCancel}
              translation="feedback.comments.cancel"
              disabled={submitting}
              theme={Button.theme.cancel}
            />
            <Button
              onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(this.props.saveComment))}
              translation="feedback.comments.save"
              type="submit"
              disabled={submitting}
              theme={Button.theme.primary}
            />
          </div>

        </form>
      </div>
    )
  }
}

export default reduxForm({
  form: 'addCommentForm',
  fields: ['comment', 'attachmentIds[]'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    comment: [required()]
  })
})(CommentForm)
