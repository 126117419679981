import Serializable from './Serializable'
import {fixLineChanges} from 'utils/fixLineChanges'

class ResponseTemplate extends Serializable {

  constructor(data) {
    super(data, 'ResponseTemplate')
    this._mapTemplate()
    this._mapCategories()
  }

  _mapTemplate() {
    const template = this._data.get('template');
    if (!!template) {
      this._template = fixLineChanges(template);
    }
  }

  _mapCategories() {
    const categories = this._data.get('categories');
    if (!!categories) {
      this._categories = categories.join(', ');
    }
  }

  get id() {
    return this._data.get('id')
  }

  get name() {
    return this._data.get('name')
  }

  get template() {
    return this._template
  }

  get categories() {
    return this._categories
  }

  get isProposal() {
    return this._data.get('isProposal')
  }

}

export default ResponseTemplate
