import React, { PropTypes } from 'react'

import AddErrorDisplay from 'components/Forms/common/AddErrorDisplay'
import { overrideOnBlur } from 'components/Forms/common/FormUtils'
import { TranslateProps } from 'containers/Text'
import Text from '../../../containers/Text/Text';

const LabeledRadio = ({label, showRadio, ...props}) => {
  const radioLabel = (typeof label) === 'string' ? <Text k={label} className="radio-label" /> : label;

  return (
    <label className="labeled-radio no-user-select radio clickable mb1 mt1">
      <input
        type="radio"
        {...overrideOnBlur(props)}
      />
      {showRadio && props.checked && <span className="radio--checked" />}
      {showRadio && !props.checked && <span className="radio--not-checked" />}
      {radioLabel}
    </label>
  )
};

LabeledRadio.defaultProps = {
  showRadio: true
};

LabeledRadio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  showRadio: PropTypes.bool,
  name: PropTypes.string
};

export default TranslateProps(AddErrorDisplay(LabeledRadio))
