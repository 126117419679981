import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { FeedbackListFilters } from 'components/Feedback'
import { WaitFor } from 'components/Utils'
import { ContextSelectionHeader } from 'containers/Menu'
import { init, bindFeedbackList, fetchFeedbacks, fetchMoreFeedbacks } from 'actions/FeedbackListActions'
import { toggleFilter, resetFilter } from 'actions/QuickFilterActions'
import { changeContext } from 'actions/ContextActions'
import { ChainUnit, ContextType, ReceiverType } from 'domain'

import { defined } from 'utils'

import RouteUtil from './RouteUtil'
import FeedbackListRoute from './FeedbackListRoute'

class ChainUnitRoute extends Component {
  static propTypes = {
    activeFilter: PropTypes.string,
    chainUnitId: PropTypes.string.isRequired,
    chainUnit: PropTypes.instanceOf(ChainUnit),
    feedbackList: PropTypes.instanceOf(Map),
    feedbackListId: PropTypes.string,
    location: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      init: PropTypes.func.isRequired,
      fetchFeedbacks: PropTypes.func.isRequired,
      fetchMoreFeedbacks: PropTypes.func.isRequired,
      toggleFilter: PropTypes.func.isRequired
    })
  };

  componentDidMount() {
    const { chainUnitId } = this.props
    if (chainUnitId) {
      this.props.actions.resetQuickFilter(chainUnitId)
      this.props.actions.bindContext(chainUnitId)
      this.props.actions.init(chainUnitId)
    }
  }

  componentWillReceiveProps(newProps) {
    const newChainUnitId = newProps.chainUnitId
    if (newChainUnitId && newChainUnitId !== this.props.chainUnitId) {
      this.props.actions.resetQuickFilter(newChainUnitId)
      this.props.actions.bindContext(newChainUnitId)
      this.props.actions.init(newChainUnitId)
    }
  }

  render() {
    const { chainUnit, feedbackList, actions, actions: { toggleFilter }, activeFilter, location } = this.props
    return (
      <WaitFor condition={!!chainUnit && !!feedbackList}>
        { feedbackList && (
          <div className="context-selection-container">
            <ContextSelectionHeader text={chainUnit && chainUnit.name} type='chain' />
            <FeedbackListFilters activeFilter={activeFilter} actions={{ toggleFilter }} />
            <FeedbackListRoute
              feedbackList={feedbackList}
              actions={actions}
              location={location}
              showReceiver={true}
            />
          </div>
        )}
      </WaitFor>
    )
  }
}

const mapStateToProps = (state, { location, params: { chainUnitId }}) => {
  const chainUnit = state.getIn(['globalData', 'chainUnits', defined(chainUnitId, 'ChainUnitId')])
  const activeFilterListId = state.getIn(['quickFilter', 'feedbackListId'])
  const feedbackListId = state.getIn(['feedbackLists', 'bindings', `chainUnit/${chainUnitId}`])
  const currentFeedbackListId = activeFilterListId || feedbackListId
  const feedbackList = state.getIn(['feedbackLists', currentFeedbackListId])
  const activeFilter = state.getIn(['quickFilter', 'activeFilter'])

  return {
    activeFilter,
    chainUnitId,
    chainUnit,
    feedbackListId,
    feedbackList,
    location
  }
}

const mapDispatchToProps = (dispatch, { params: { chainUnitId }}) => ({
  actions: bindActionCreators({
    init: (chainUnitId) => dispatch => {
      const initializeList = init({
        chainUnitId,
        receiverType: ReceiverType.chainAndStore
      })
      dispatch(initializeList)
      dispatch(bindFeedbackList(`chainUnit/${chainUnitId}`, initializeList.feedbackListId))
    },
    fetchFeedbacks,
    fetchMoreFeedbacks,
    toggleFilter: filterName => toggleFilter(filterName, { chainUnitId, receiverType: ReceiverType.chainAndStore }),
    bindContext: (chainUnitId) => changeContext(ContextType.chainUnit, RouteUtil.chainUnit(chainUnitId)),
    resetQuickFilter: (chainUnitId) => resetFilter({ chainUnitId, receiverType: ReceiverType.chainAndStore })
  }, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(ChainUnitRoute)
