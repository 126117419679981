import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'

import { WaitFor } from 'components/Utils'
import { search } from 'actions/SearchActions'
import { changeContext } from 'actions/ContextActions'
import { toggleFilter } from 'actions/SearchFilterActions'
import { SearchResultsView } from 'components/Search'
import { FeedbackListItem } from 'components/Feedback'
import { ScrollTo } from 'components/Utils'
import { UriHandler } from 'utils'

import { ContextType } from 'domain'

class SearchResultRoute extends Component {
  static contextTypes = {
    router: PropTypes.any
  };
  componentWillMount() {
    // If search is found in url but not in state
    if (!this.props.searchString) {
      this.props.actions.doSearch(this.props.location.query.search, this.props.location.query.filter)
    }
    this.props.actions.bindContext(this.props.location)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.location.query.search !== this.props.location.query.search || newProps.location.query.filter !== this.props.location.query.filter) {
      this.props.actions.bindContext(newProps.location)
    }
  }

  constructor(props) {
    super(props)
    this.ListItemClass = ScrollTo(this.shouldScrollTo, this.removeScrollFromUrl)(FeedbackListItem)
  }

  shouldScrollTo = (props) => props.feedback.id === parseInt(this.props.location.query.scrollTo);

  removeScrollFromUrl = () => {
    const newUri = new UriHandler(this.props.location).removeQuery('scrollTo').toString()
    this.context.router.replace(newUri)
  };

  render() {
    const isReady = !!this.props.searchString && !!this.props.feedbacks
    return (
      <WaitFor condition={isReady}>
        {isReady && <SearchResultsView FeedbackListItem={this.ListItemClass} {...this.props} />}
      </WaitFor>
    )
  }
}

export default connect((state) => ({
  searchString: state.getIn(['search', 'query', 'search']),
  activeFilter: state.getIn(['searchFilter', 'activeFilter']),
  feedbacks: state.getIn(['search', 'results', 'feedbacks'])
}), (dispatch) => ({
  actions: {
    doSearch: (searchString, filter) => dispatch(search(searchString, filter)),
    bindContext: (location) => dispatch(changeContext(ContextType.search, location)),
    toggleFilter: (filter) => dispatch(toggleFilter(filter))
  }
}))(SearchResultRoute)
