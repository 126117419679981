const RouteUtil = {
  allFeedbacks: () => '/allFeedbacks',
  secondaryFeedbacks: () => '/secondaryFeedbacks',
  businessUnit: (businessUnitId) => `/businessUnit/${businessUnitId}`,
  chainUnit: (chainUnitId) => `/chainUnit/${chainUnitId}`,
  subject: (subjectId) => `/subject/${subjectId}`,
  feedback: (feedbackId) => `/feedback/${feedbackId}`,
  home: () => '/',
  profile: () => '/profile',
  feedbackListReport: () => '/reporting/feedbackList',
  feedbackMessagesReport: () => '/reporting/feedbackMessages',
  summaryReport: () => '/reporting/summary',
  receiversReport: () => '/reporting/receivers',
  responseSummaryReport: () => '/reporting/responseSummary',
  search: (search, filter) => '/search/results/?search=' + encodeURIComponent(search) + (!!filter ? '&filter=' + encodeURIComponent(filter) : ''),
  hymyFeedback: () => '/giveFeedback',
  settings: () => '/settings',
  supportView: () => '/support'
}

export default RouteUtil
