import { whoami } from './LoginActions'
import { getChainUnits, getAllowableBusinessUnits, getSubjects } from './GlobalDataActions'
import { saveProfile as clientSaveProfile } from './ClientActions'
import { changeLocale } from './I18nActions'
import { init as initializeFeedbackList, bindFeedbackList } from './FeedbackListActions'

export const initializeProfile = () => dispatch => new Promise((resolve, reject) => {
  const chainUnitsPromise = dispatch(getChainUnits())
  const businessUnitsPromise = dispatch(getAllowableBusinessUnits())
  const subjectsPromise = dispatch(getSubjects())
  Promise.all([chainUnitsPromise, businessUnitsPromise, subjectsPromise]).then(resolve, reject)
})

export const saveProfile = profile => (dispatch, getState) =>
  dispatch(clientSaveProfile(profile, getState().getIn(['client', 'userInfo', 'userId'])))
    .then(
        () => dispatch(changeLocale(profile.language)),
        () => Promise.reject({ _error: 'profile.saveFailed' }))
    .then(() => dispatch(whoami()))
    // TODO: see todo in AllFeedbackRoute for the following events
    .then(() => dispatch(initializeFeedbackList({})))
    .then(event => dispatch(bindFeedbackList('allFeedbacks', event.feedbackListId)));
