import React, { PropTypes } from 'react';
import Text from '../../containers/Text';

const canSeeLinks = (userInfo, type) => {
  if (userInfo.isAdminUser) {
    return true;
  } else if (type === 'store' && userInfo.isKeskoStoreUser) {
    return true;
  }
  return (type === 'kesko' && userInfo.isKeskoCompanyUser);
};

const generateLinks = (links, userInfo, type) => {
  if (!canSeeLinks(userInfo, type)) {
    return null;
  }

  const header = userInfo.isAdminUser ? (
    <h2 className="blue">
      <Text k={`support.${type}`} />
    </h2>
  ) : null;

  const linksBlock = links.valueSeq().map((link, key) => {
    const beforeLinkText = link.get('beforeLinkText') ? <Text k={link.get('beforeLinkText')}/> : '';
    const afterLinkText = link.get('afterLinkText') ? <Text k={link.get('afterLinkText')}/> : '';
    return (
      <span className="mb1" key={`support-link-${key}`}>{beforeLinkText}<a href={link.get('url')} target="_blank">
        <Text k={link.get('linkText')}/>
      </a>{afterLinkText}</span>
    );
  });
  return (
    <section id={`test-section-${type}`}>
      {header}
      <div className="inline-flex flex-column">
        {linksBlock}
      </div>
    </section>
  )
};

const SupportView = ({userInfo, translations}) => (
  <div>
    <h1 className="blue">
      <Text k="support.title" />
    </h1>
    {generateLinks(translations.get('support').get('storeLinks'), userInfo, 'store')}
    {generateLinks(translations.get('support').get('keskoLinks'), userInfo, 'kesko')}
  </div>
);

SupportView.propTypes = {
  userInfo: PropTypes.shape({
    isKeskoStoreUser: PropTypes.bool.isRequired,
    isKeskoCompanyUser: PropTypes.bool.isRequired,
    isAdminUser: PropTypes.bool.isRequired
  }).isRequired
};

export default SupportView;
