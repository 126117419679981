import { connect } from 'react-redux'

import AppWrapper from 'components/AppWrapper'
import { dismissError } from '../actions/ClientActions';

export default connect(
  state => ({
    sidebarOpen: state.getIn(['menu', 'open'], false),
    maintenanceMessage: state.getIn(['client', 'maintenanceMessage']),
    errorState: state.getIn(['client', 'errorState'])
  }),
  dispatch => ({
    dismissError: () => dispatch(dismissError())
  })
)(AppWrapper)
