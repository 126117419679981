import React, { Component, PropTypes } from 'react'
import { List, Map } from 'immutable'

import AddErrorDisplay from 'components/Forms/common/AddErrorDisplay'
import { TranslateProps } from 'containers/Text'
import { getText } from 'containers/Text/utils'
import { defined, toJS } from 'utils'
import {defaultOptionMapper} from 'utils/OptionMapper'

const verifyMappedOption = option => {
  defined(option, 'option')
  defined(option.id, 'id of option ' + JSON.stringify(option))
  defined(option.name, 'name of option ' + JSON.stringify(option))
  defined(option.value, 'value of option ' + JSON.stringify(option))
  return option
}

class TokenInput extends Component {
  static propTypes = {
    options: PropTypes.oneOfType([
      PropTypes.instanceOf(List),
      PropTypes.array
    ]).isRequired,
    optionMapper: PropTypes.func.isRequired,
    multiple: PropTypes.bool.isRequired,
    clearable: PropTypes.bool
  };
  static defaultProps = {
    optionMapper: defaultOptionMapper,
    /**
     * Select single or multiple options.
     * If multiple is selected, onChange will be called with an array
     */
    multiple: true,
    clearable: true
  };
  static contextTypes = {
    translations: PropTypes.instanceOf(Map)
  };

  componentWillMount() {
    require.ensure(['react-select'], require => {
      this.Select = require('react-select')
      this.forceUpdate()
    }, 'tokeninput')
  }

  translateOption = option => {
    if (option.k) {
      option.name = getText(this.context.translations, option.k)
    }
    return option
  };

  handleChange = (value) => this.props.onChange(value);

  mappedOptions = () => {
    const {options, optionMapper} = this.props
    const mapped = options.map(optionMapper).map(this.translateOption).map(verifyMappedOption);
    return new List(mapped);
  };

  get defaultTranslations() {
    if (!this._defaultTranslations) {
      this._defaultTranslations = toJS(this.context.translations.get('tokenInput'));
    }
    return this._defaultTranslations;
  }

  render() {
    const {
      clearAllText, clearValueText, disabled, multiple, noResultsText, notSelectedLabel, onChange, value, clearable
    } = this.props;
    const {Select} = this
    if (Select) {
      return (
        <Select
          className="fill"
          options={this.mappedOptions().map(opt => ({...opt, label: opt.name})).toJS()}
          placeholder={notSelectedLabel}
          disabled={disabled}
          clearable={clearable}
          deleteRemoves={clearable}
          backspaceRemoves={clearable}
          onChange={onChange}
          multi={multiple}
          value={value}
          clearValueText={clearValueText || this.defaultTranslations.clearValueText}
          clearAllText={clearAllText || this.defaultTranslations.clearAllText}
          noResultsText={noResultsText || this.defaultTranslations.noResultsText}
        />
      )
    }
    return (<span />)
  }
}

const EnhancedComponent = AddErrorDisplay(TranslateProps(TokenInput))
export default EnhancedComponent
