import React, { Component, PropTypes } from 'react'

import { Col } from 'components/grid'
import { Navigator } from 'components/Menu'
import { ExclamatedNote } from 'components/kesko'
import { Text } from 'containers/Text'
import { Feedback } from 'domain'
import { WaitFor } from 'components/Utils'

import RequestCommentForm from './RequestCommentForm'
import FeedbackCollapsingSections from '../FeedbackCollapsingSections';

class RequestCommentView extends Component {

  static propTypes = {
    feedback: PropTypes.instanceOf(Feedback).isRequired,
    actions: PropTypes.shape({
      getUserEmails: PropTypes.func.isRequired,
      sendRequest: PropTypes.func.isRequired,
      cancel: PropTypes.func.isRequired
    }).isRequired,
    userEmails: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    userEmails: null
  };

  handleSubmit = ({emails, commentText, language}) => this.props.actions.sendRequest(emails, commentText, language);

  componentDidMount() {
    this.props.actions.getUserEmails();
  }

  render() {
    const {feedback, actions: {cancel}, userEmails} = this.props
    return (
      <WaitFor condition={userEmails !== null}>
        <section className="request-comment-view">
          <Navigator backUrl=".." />
          <Col>
            <hr />
          </Col>
          <FeedbackCollapsingSections
            feedback={feedback}
          />
          <Col>
            <hr />
          </Col>

          <Col><h2 className="blue mt3 mb3"><Text k="feedback.requestComment.title" /></h2></Col>

          <ExclamatedNote
            translate={{note: 'feedback.requestComment.note'}}
            className="mb2"
          />

          <RequestCommentForm
            sendRequest={this.handleSubmit}
            onCancel={cancel}
            localStorageKey={`${feedback.id}-requestComment`}
            userEmails={userEmails}
          />

        </section>
      </WaitFor>
    )
  }
}

export default RequestCommentView
