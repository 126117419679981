export const DraftAttachmentActions = {
  INIT: 'draftAttachment/init',
  REMOVE: 'draftAttachment/remove'
}

export const initializeDraftAttachments = feedback => dispatch => dispatch({
  type: DraftAttachmentActions.INIT,
  feedback: feedback
})

export const removeDraftAttachment = file => dispatch => dispatch({
  type: DraftAttachmentActions.REMOVE,
  file: file
})
