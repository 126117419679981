import { info } from 'utils/Log'
import { getChainUnits, getAllowableBusinessUnits, getFeedbackChannels, getSubjects, getTopics, getReportingHandlers } from './GlobalDataActions'
import {
  generateReport as clientGenerateReport,
  generateReportExport as clientGenerateReportExport,
  generateMessagesReport as clientGenerateMessagesReport,
  generateMessagesReportExport as clientGenerateMessagesReportExport,
  generateSummaryReport as clientGenerateSummaryReport,
  generateSummaryReportExport as clientGenerateSummaryReportExport,
  generateReceiversReport as clientGenerateReceiversReport,
  generateReceiversReportExport as clientGenerateReceiversReportExport,
  generateResponseSummaryReportExport as clientGenerateResponseSummaryReportExport,
  generateResponseSummaryKeskoReportExport as clientGenerateResponseSummaryKeskoReportExport
} from './ClientActions'

export const ReportActions = {
  GENERATE: 'Report/Generate',
  GENERATE_EXPORT: 'Report/GenerateExport',
  GENERATE_MESSAGES: 'Report/GenerateMessages',
  GENERATE_MESSAGES_EXPORT: 'Report/GenerateMessagesExport',
  GENERATE_SUMMARY: 'Report/GenerateSummary',
  GENERATE_SUMMARY_EXPORT: 'Report/GenerateSummaryExport',
  GENERATE_RECEIVERS: 'Report/GenerateReceivers',
  GENERATE_RECEIVERS_EXPORT: 'Report/GenerateReceiversExport',
  GENERATE_RESPONSE_SUMMARY_EXPORT: 'Report/GenerateResponseSummaryExport',
  GENERATE_RESPONSE_SUMMARY_KESKO_EXPORT: 'Report/GenerateResponseSummaryKeskoExport',
  SAVE: 'Report/Save',
  SAVE_MESSAGES: 'Report/SaveMessages',
  SAVE_SUMMARY: 'Report/SaveSummary',
  SAVE_RECEIVERS: 'Report/SaveReceivers',
  REPORT_ERROR: 'Report/ReportError',
  MESSAGES_ERROR: 'Report/MessagesError',
  SUMMARY_ERROR: 'Report/SummaryError',
  RECEIVERS_ERROR: 'Report/ReceiversError',
  RESPONSE_SUMMARY_ERROR: 'Report/ResponseSummaryError',
  CLEAR: 'Report/Clear',
  INIT: 'Report/Init',

  NEXT_PAGE: 'Report/NextPage',
  PREV_PAGE: 'Report/PrevPage',

  START_LOADING: 'Report/Loading/Start',
  END_LOADING: 'Report/Loading/End'
}

const PAGE_SIZE = 25;
const getPage = (filter, page, pageSize = PAGE_SIZE) => dispatch => new Promise((resolve, reject) => {
  info('Generating report for filter', filter)
  dispatch(clientGenerateReport({
    ...filter,
    page,
    pageSize
  })).then(resolve,reject)
})

export const saveReportPage = report => ({
  type: ReportActions.SAVE,
  report
})

export const reportError = err => ({
  type: ReportActions.REPORT_ERROR,
  err
})

const getAndSavePage = (filter, page, pageSize) => dispatch => new Promise((resolve, reject) => {
  dispatch(startLoading())
  dispatch(getPage(filter, page, pageSize)).then(
    report => {
      dispatch(saveReportPage(report))
      dispatch(endLoading())
      resolve(report)
    },
    err => {
      dispatch(reportError(err))
      dispatch(endLoading())
      reject(err)
    }
  )
})

export const generateReport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.GENERATE, filter })
  dispatch(getAndSavePage(filter, 0)).then(resolve, reject)
})

export const nextPage = () => (dispatch, getState) => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.NEXT_PAGE })
  const filter = getState().getIn(['reports','filter']).toJS()
  const page = getState().getIn(['reports','result','page'])
  const pageSize = getState().getIn(['reports','result','pageSize'])
  dispatch(getAndSavePage(filter, page + 1, pageSize)).then(resolve, reject)
})

export const previousPage = () => (dispatch, getState) => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.PREV_PAGE })
  const filter = getState().getIn(['reports','filter']).toJS()
  const page = getState().getIn(['reports','result','page'])
  const pageSize = getState().getIn(['reports','result','pageSize'])
  dispatch(getAndSavePage(filter, page - 1, pageSize)).then(resolve, reject)
})

export const generateReportExport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.GENERATE_EXPORT, filter })
  info('Generating report export for filter', filter)
  dispatch(startLoading())
  dispatch(clientGenerateReportExport(filter)).then(
    downloadLink => {
      dispatch(endLoading())
      window.open(downloadLink, '_blank')
      resolve(downloadLink)
    },
    err => {
      dispatch(reportError(err))
      dispatch(endLoading())
      reject(err)
    }
  )
})

const MESSAGES_PAGE_SIZE = 10;
const getMessagesPage = (filter, page, pageSize = MESSAGES_PAGE_SIZE) => dispatch => new Promise((resolve, reject) => {
  info('Generating messages report for filter', filter)
  dispatch(clientGenerateMessagesReport({
    ...filter,
    page,
    pageSize
  })).then(resolve,reject)
})

export const saveMessagesReportPage = report => ({
  type: ReportActions.SAVE_MESSAGES,
  report
})

export const messagesError = err => ({
  type: ReportActions.MESSAGES_ERROR,
  err
})

const getAndSaveMessagesPage = (filter, page, pageSize) => dispatch => new Promise((resolve, reject) => {
  dispatch(startLoading())
  dispatch(getMessagesPage(filter, page, pageSize)).then(
    report => {
      dispatch(saveMessagesReportPage(report))
      dispatch(endLoading())
      resolve(report)
    },
    err => {
      dispatch(messagesError(err))
      dispatch(endLoading())
      reject(err)
    }
  )
})

export const generateMessagesReport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.GENERATE_MESSAGES, filter })
  dispatch(getAndSaveMessagesPage(filter, 0)).then(resolve, reject)
})

export const nextMessagesPage = () => (dispatch, getState) => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.NEXT_PAGE })
  const filter = getState().getIn(['reports','filter']).toJS()
  const page = getState().getIn(['reports','messagesResult','page'])
  const pageSize = getState().getIn(['reports','messagesResult','pageSize'])
  dispatch(getAndSaveMessagesPage(filter, page + 1, pageSize)).then(resolve, reject)
})

export const previousMessagesPage = () => (dispatch, getState) => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.PREV_PAGE })
  const filter = getState().getIn(['reports','filter']).toJS()
  const page = getState().getIn(['reports','messagesResult','page'])
  const pageSize = getState().getIn(['reports','messagesResult','pageSize'])
  dispatch(getAndSaveMessagesPage(filter, page - 1, pageSize)).then(resolve, reject)
})

export const generateMessagesReportExport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.GENERATE_MESSAGES_EXPORT, filter })
  info('Generating messages report export for filter', filter)
  dispatch(startLoading())
  dispatch(clientGenerateMessagesReportExport(filter)).then(
    downloadLink => {
      dispatch(endLoading())
      window.open(downloadLink, '_blank')
      resolve(downloadLink)
    },
    err => {
      dispatch(messagesError(err))
      dispatch(endLoading())
      reject(err)
    }
  )
})

export const saveSummaryReport = summary => ({
  type: ReportActions.SAVE_SUMMARY,
  summary
})

export const summaryError = err => ({
  type: ReportActions.SUMMARY_ERROR,
  err
})

const getReportSummary = (filter) => dispatch => new Promise((resolve, reject) => {
  info('Generating summary report for filter', filter)
  dispatch(clientGenerateSummaryReport({
    ...filter
  })).then(resolve,reject)
})

export const generateSummaryReport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.GENERATE_SUMMARY, filter })
  dispatch(startLoading())
  dispatch(getReportSummary(filter)).then(
    summary => {
      dispatch(saveSummaryReport(summary))
      dispatch(endLoading())
      resolve(summary)
    },
    err => {
      dispatch(summaryError(err))
      dispatch(endLoading())
      reject(err)
    }
  )
})

export const generateSummaryReportExport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.GENERATE_SUMMARY_EXPORT, filter })
  info('Generating summary report export for filter', filter)
  dispatch(startLoading())
  dispatch(clientGenerateSummaryReportExport(filter)).then(
    downloadLink => {
      dispatch(endLoading())
      window.open(downloadLink, '_blank')
      resolve(downloadLink)
    },
    err => {
      dispatch(summaryError(err))
      dispatch(endLoading())
      reject(err)
    }
  )
})

export const saveReceiversReport = receivers => ({
  type: ReportActions.SAVE_RECEIVERS,
  receivers
})

export const receiversError = err => ({
  type: ReportActions.RECEIVERS_ERROR,
  err
})

const getReportReceivers = (filter) => dispatch => new Promise((resolve, reject) => {
  info('Generating receivers report for filter', filter)
  dispatch(clientGenerateReceiversReport({
    ...filter
  })).then(resolve,reject)
})

export const generateReceiversReport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.GENERATE_RECEIVERS, filter })
  dispatch(startLoading())
  dispatch(getReportReceivers(filter)).then(
    receivers => {
      dispatch(saveReceiversReport(receivers))
      dispatch(endLoading())
      resolve(receivers)
    },
    err => {
      dispatch(receiversError(err))
      dispatch(endLoading())
      reject(err)
    }
  )
})

export const generateReceiversReportExport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.GENERATE_RECEIVERS_EXPORT, filter })
  info('Generating receivers report export for filter', filter)
  dispatch(startLoading())
  dispatch(clientGenerateReceiversReportExport(filter)).then(
    downloadLink => {
      dispatch(endLoading())
      window.open(downloadLink, '_blank')
      resolve(downloadLink)
    },
    err => {
      dispatch(receiversError(err))
      dispatch(endLoading())
      reject(err)
    }
  )
})

export const responseSummaryError = err => ({
  type: ReportActions.RESPONSE_SUMMARY_ERROR,
  err
})

export const generateResponseSummaryReportExport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.GENERATE_RESPONSE_SUMMARY_EXPORT, filter })
  info('Generating feedback response summary report export for filter', filter)
  dispatch(startLoading())
  dispatch(clientGenerateResponseSummaryReportExport(filter)).then(
    downloadLink => {
      dispatch(endLoading())
      window.open(downloadLink, '_blank')
      resolve(downloadLink)
    },
    err => {
      dispatch(responseSummaryError(err))
      dispatch(endLoading())
      reject(err)
    }
  )
})

export const generateResponseSummaryKeskoReportExport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({ type: ReportActions.GENERATE_RESPONSE_SUMMARY_KESKO_EXPORT, filter })
  info('Generating feedback response summary Kesko report export for filter', filter)
  dispatch(startLoading())
  dispatch(clientGenerateResponseSummaryKeskoReportExport(filter)).then(
    downloadLink => {
      dispatch(endLoading())
      window.open(downloadLink, '_blank')
      resolve(downloadLink)
    },
    err => {
      dispatch(responseSummaryError(err))
      dispatch(endLoading())
      reject(err)
    }
  )
})

export const startLoading = () => dispatch => new Promise(resolve => {
  dispatch({ type: ReportActions.START_LOADING })
  resolve()
})

export const endLoading = () => dispatch => new Promise(resolve => {
  dispatch({ type: ReportActions.END_LOADING })
  resolve()
})

export const initializeReporting = () => dispatch => new Promise((resolve, reject) => {
  const chainUnitsPromise = dispatch(getChainUnits())
  const businessUnitsPromise = dispatch(getAllowableBusinessUnits())
  const feedbackChannelsPromise = dispatch(getFeedbackChannels())
  const subjectsPromise = dispatch(getSubjects())
  const topicsPromise = dispatch(getTopics())
  const handlersPromise = dispatch(getReportingHandlers())
  Promise.all([chainUnitsPromise, businessUnitsPromise, feedbackChannelsPromise, subjectsPromise, topicsPromise, handlersPromise]).then(resolve, reject)
})

export const clearReport = () => ({ type: ReportActions.CLEAR })
