import Serializable from './Serializable';
import {List} from 'immutable'

export default class StoreSettings extends Serializable {
  constructor(data) {
    super(data, 'StoreSettings');
    this._mapGreetings();
  }

  _mapGreetings() {
    this._data = this._data.updateIn(
      ['greetings'],
      List(),
      list => list.map(greeting => new Greeting(greeting))
    );
    this._greetings = this._data.get('greetings', List())
  }

  get greetingFi() {
    const index = this._greetings.findIndex(i => i.language === 'fi');
    return this._greetings.get(index);
  }

  get greetingSv() {
    const index = this._greetings.findIndex(i => i.language === 'sv');
    return this._greetings.get(index);
  }

  get greetingEn() {
    const index = this._greetings.findIndex(i => i.language === 'en');
    return this._greetings.get(index);
  }

  get showGreeting() {
    return this._data.get('showGreeting');
  }

  get showRetailerImage() {
    return this._data.get('showRetailerImage');
  }

  get retailerName() {
    return this._data.get('retailerName');
  }

  get retailerImageUrl() {
    return this._data.get('retailerImageUrl');
  }

  get offerRequestTransferEmail() {
    return this._data.get('offerRequestTransferEmail');
  }

  get offerRequestProTransferEmail() {
    return this._data.get('offerRequestProTransferEmail');
  }
}

export class Greeting extends Serializable {
  constructor(data) {
    super(data, 'Greeting');
  }

  get language() {
    return this._data.get('language');
  }

  get content() {
    return this._data.get('content');
  }

  get defaultContent() {
    return this._data.get('defaultContent');
  }
}
