import { Map, fromJS } from 'immutable'
import createReducer from './createReducer'
import { FeedbackActions } from 'actions/FeedbackActions'

export const reducer = createReducer(Map({}), {

  [FeedbackActions.LOAD]: (state, action) => state.set(action.payload.feedback.id, action.payload.feedback),

  [FeedbackActions.SET_POSSIBLE_HANDLERS]: (state, { feedbackId, possibleHandlers }) => state
    .setIn(['possibleHandlers', feedbackId], fromJS(possibleHandlers).sortBy(handler => handler.name))

})
