import React, { PropTypes } from 'react';
import Icon from '../Icons/Icon';
import Text from '../../containers/Text/Text';

const iconBlock = icon => icon ? (
  <Icon icon={icon} size={Icon.sizes.xl} />
) : null;

const headerBlock = heading => heading ? (
  <Text
    k={heading}
    className="heading-text"
  />
) : null;

const bodyBlock = body => body ? (
  <Text
    k={body}
    className="body-text"
  />
) : null;

const bodyAsElement = bodyElement => bodyElement ? (
  <div className="body-element">
    {bodyElement}
  </div>
) : null;

const TextWithHeading = ({icon, heading, body, bodyElement, showTooltip, className}) => (
  <span className={`text-with-heading ${!icon ? 'no-icon' : ''} ${className ? className : ''}`} title={showTooltip ? body : null}>
    <span className="icon-and-heading">
      {iconBlock(icon)}
      {headerBlock(heading)}
    </span>
    {bodyBlock(body)}
    {bodyAsElement(bodyElement)}
  </span>
);

TextWithHeading.propTypes = {
  icon: PropTypes.object,
  heading: PropTypes.string,
  body: PropTypes.string,
  bodyElement: PropTypes.element,
  showTooltip: PropTypes.bool,
  className: PropTypes.string
};

export default TextWithHeading;
