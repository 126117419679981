export const defaultOptionMapper = option => ({
  id: option,
  label: option,
  value: option
});

export const storeMapper = store => ({
  label: store.name,
  id: store.id,
  value: store
});

export const businessUnitMapper = bu => ({
  name: `${bu.id} - ${bu.name}`,
  id: bu.id,
  value: bu.id
});

export const chainUnitMapper = showCompanyName => cu => ({
  label: showCompanyName ? cu.companyDisplayName + ': ' + cu.name : cu.name,
  id: cu.id,
  value: cu
});

export const chainUnitAreaMapper = ca => ({
  name: ca.name,
  id: ca.id,
  value: ca
});

export const subjectMapper = (lang, showCompanyName) => su => ({
  name: showCompanyName ? su.companyDisplayName + ': ' + su.localizedName(lang) : su.localizedName(lang),
  label: showCompanyName ? su.companyDisplayName + ': ' + su.localizedName(lang) : su.localizedName(lang),
  id: su.id,
  value: su
});

export const externalSystemMapper = lang => es => ({
  label: es.localizedName(lang),
  id: es.id,
  value: es
});

export const templateMapper = template => ({
  label: template.name + ' (' + template.id + ')',
  id: '' + template.id,
  value: template
});
