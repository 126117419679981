import { Children, Component, PropTypes } from 'react'
import { throttle } from 'utils'

import { trace } from 'utils/Log'

import { isScrolledToBottom } from './ScrollUtils'

class ScrollToBottomDetector extends Component {
  constructor(props) {
    super(props)
    this.handleScroll = throttle(this.handleScroll.bind(this), props.throttleDelay)
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const scrolledToBottom = isScrolledToBottom(this.props.pageBottomThreshold);
    trace('ScrollToBottomDetector: throttled handle scroll');
    if (scrolledToBottom) {
      trace('ScrollToBottomDetector: scrolled to bottom of the page')
      this.props.handleScrolledToBottom()
    }
  }

  render() {
    return Children.only(this.props.children)
  }
}
ScrollToBottomDetector.defaultProps = {
  throttleDelay: 100,

  /**
   * The threshold (pixels) from page bottom where the event should be triggered
   */
  pageBottomThreshold: 0
}
ScrollToBottomDetector.propTypes = {
  throttleDelay: PropTypes.number.isRequired,
  pageBottomThreshold: PropTypes.number.isRequired,
  handleScrolledToBottom: PropTypes.func.isRequired
}

export default ScrollToBottomDetector
