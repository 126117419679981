/* global jsConfig, window */
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { closeMenu } from 'actions/MenuActions'
import { logout } from 'actions/LoginActions'
import { searchAndRedirect } from 'actions/SearchActions'
import { SideBar } from 'components/Menu'
import { RouteUtil } from 'routes'
import { toJS } from 'utils'
import { Company } from 'domain'

const addFeedbackUrl = (state) => {
  const buId = state.getIn(['client', 'selectedBusinessUnitCode'])
  if (buId) {
    const company = state.getIn(['client', 'selectedBusinessUnitCompany'])
    if (company === Company.KESKO) {
      return jsConfig.addFeedbackUrl + '?paluuOsoite=' + window.location.href + '&kauppa=' + buId
    } else if (company === Company.KRAUTA) {
      return jsConfig.addKrautaFeedbackUrl + '?paluuOsoite=' + window.location.href + '&kauppa=' + buId
    } else if (company === Company.ONNINEN) {
      return jsConfig.addOnninenFeedbackUrl + '?paluuOsoite=' + window.location.href + '&kauppa=' + buId
    } else if (company === Company.INTERSPORT) {
      return 'https://www.intersport.fi/fi/ota-yhteytta-asiakaspalveluun/?unitid=' + buId
    } else if (company === Company.BUDGETSPORT) {
      return 'https://www.budgetsport.fi/asiakaspalvelu/ota-yhteytta/' //Forwarding to spesific store possible, but not using normal buId
    } else if (company === Company.KAUTO) {
      return 'https://www.kesko.fi/asiakas/palaute/palaute-autokauppaan/'
    }
  } else {
    const authorizedCompanies = state.getIn(['client', 'authorizedCompanies'])
    if (authorizedCompanies.contains(Company.KESKO)) {
      return jsConfig.addFeedbackUrl + '?paluuOsoite=' + window.location.href
    } else if (authorizedCompanies.contains(Company.KRAUTA)) {
      return jsConfig.addKrautaFeedbackUrl + '?paluuOsoite=' + window.location.href
    } else if (authorizedCompanies.contains(Company.ONNINEN)) {
      return jsConfig.addOnninenFeedbackUrl + '?paluuOsoite=' + window.location.href
    } else if (authorizedCompanies.contains(Company.KPLUSSA)) {
      return jsConfig.addFeedbackUrl + '?aihe=plussa&paluuOsoite=' + window.location.href
    } else if (authorizedCompanies.contains(Company.INTERSPORT)) {
      return 'https://www.intersport.fi/fi/ota-yhteytta-asiakaspalveluun/'
    } else if (authorizedCompanies.contains(Company.BUDGETSPORT)) {
      return 'https://www.budgetsport.fi/asiakaspalvelu/ota-yhteytta/'
    } else if (authorizedCompanies.contains(Company.KAUTO)) {
      return 'https://www.kesko.fi/asiakas/palaute/palaute-autokauppaan/'
    }
  }
  return null
}

export default connect(state => ({
  open: state.getIn(['menu', 'open'], false),
  userName: state.getIn(['client', 'userInfo', 'displayName']),
  userEmail: state.getIn(['client', 'userInfo', 'profile', 'email']),
  showFacebookSettings: state.getIn(['client', 'userInfo', 'profile', 'showFacebookSettings']),
  showResponseTemplates: state.getIn(['client', 'showResponseTemplates']),
  permissions: toJS(state.getIn(['client', 'permissions'])),
  addFeedbackUrl: addFeedbackUrl(state),
  selectedBusinessUnit: state.getIn(['client', 'selectedBusinessUnitCode']),
  showSupportLink: state.getIn(['client', 'keskoStoreUser']) || state.getIn(['client', 'authorizedCompanies']).contains(Company.KESKO)
}), dispatch => ({
  actions: {
    close: () => dispatch(closeMenu()),
    logout: () => dispatch(logout()),
    search: (query) => dispatch(searchAndRedirect(query.search)),
    toHomePage: () => dispatch(push(RouteUtil.home()))
  }
}))(SideBar)
