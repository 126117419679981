import React, { PropTypes } from 'react';
import { fromJS, List } from 'immutable'

import { TextFormatter } from 'components/Formatting'
import { RelativeLink } from 'components/Routing'
import { TriangledTitle } from 'components/kesko'

import FeedbackCommentsNote from './FeedbackCommentsNote'
import FeedbackDate from './FeedbackDate'
import { MessageAttachments } from 'components/Attachments'
import { Text, TranslateChildrenProps } from 'containers/Text'
import { CollapsingContainer } from 'containers/Layout'
import { Confirmation } from 'containers/Dialog'

import { Message, MessageType } from 'domain'
import { KeskoIcons } from 'components/Icons'
import { Button, TextWithIcon, TextWithHeading } from 'components/kesko';
import {fixLineChanges} from 'utils/fixLineChanges'

const AddCommentLink = ({classNames}) => (
  <div className={classNames}>
    <RelativeLink to="addComment">
      <Button
        translation="feedback.comments.goToCommenting"
        theme={Button.theme.secondary}
        iconLeft={KeskoIcons.comment}
      />
    </RelativeLink>
  </div>
);

const CommentingTitle = ({comments, toggled, handleClick}) => {
  const titleElement = (
    <span>
        <Text k="feedback.comments.title" />
        <span> ({comments.size})</span>
      </span>
  );

  return (
    <div className="flex flex-justify">
      <TriangledTitle
        titleElement={titleElement}
        toggled={toggled}
        onClick={handleClick}
      />
    </div>
  )
};

const CommentRequestTitle = ({receiverEmail}) => (
  <p className="silver">
    <Text k="feedback.comments.commentRequest" />
    <span> {receiverEmail}:</span>
  </p>
);

const CommentResponseTitle = () => (
  <p className="silver">
    <Text k="feedback.comments.commentResponse" />
  </p>
);

const TransferCommentTitle = ({target, isEmptyMessage}) => (
  <p className="silver">
    <Text k="feedback.comments.transferComment" />
    <span> {target}{isEmptyMessage ? '' : ':'}</span>
  </p>
);

const ForwardCommentTitle = ({target}) => (
  <p className="silver">
    <Text k="feedback.comments.forwardComment" />
    <span> {target}:</span>
  </p>
);

const ResponseCommentTitle = () => (
  <p className="silver">
    <Text k="feedback.comments.responseComment" />
  </p>
);

const ErrorTitle = ({errorType}) => {
  const errorTypeKey = errorType ? 'feedback.comments.error.' + errorType : 'feedback.comments.error.genericError';
  return (
    <TextWithHeading
      icon={KeskoIcons.exclamation}
      heading={errorTypeKey}
      className="error-title"
    />
  );
};

export class SingleComment extends React.PureComponent {

  bindRemoveCommentConfirmation = (dialog) => this._removeCommentConfirmation = dialog;

  openRemoveCommentConfirmation = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this._removeCommentConfirmation.open()
  };

  render() {
    const {
      msg, removeComment = () => {
      }, showEditLinks
    } = this.props;

    const deleteCommentButton = showEditLinks && msg.isRemovable ? (
      <TextWithIcon
        onClick={this.openRemoveCommentConfirmation}
        translation="feedback.comments.removeComment.link"
        icon={KeskoIcons.trash}
        className="delete-comment-button"
      />
    ) : null;

    const commentTitle = (msg.messageType !== MessageType.error) ? (
      <TextWithHeading
        icon={KeskoIcons.profile}
        heading={msg.author}
        className="comment-author"
      />
    ) : (
      <ErrorTitle className="comment-message" errorType={msg.message}/>
    );

    const classNames = (msg.messageType === MessageType.error) ? 'single-comment error' : 'single-comment';
    const isEmptyMessage = msg.length == 0

    return (
      <div className={classNames}>
        <div className="comment-header-row">
          {commentTitle}
          <FeedbackDate className="comment-date" date={msg.created} full={true} />
        </div>
        <div>
          {msg.messageType === MessageType.commentRequest && <CommentRequestTitle receiverEmail={msg.receiverEmail} />}
          {msg.messageType === MessageType.commentResponse && <CommentResponseTitle />}
          {msg.messageType === MessageType.transferComment && <TransferCommentTitle target={msg.receiverEmail} isEmptyMessage={isEmptyMessage}/>}
          {msg.messageType === MessageType.forwardComment && <ForwardCommentTitle target={msg.receiverEmail} />}
          {msg.messageType === MessageType.responseComment && <ResponseCommentTitle />}
          {msg.messageType !== MessageType.error && !isEmptyMessage && <TextFormatter className="comment-message" text={fixLineChanges(msg.message)} />}
          {msg.messageType === MessageType.error && <TextFormatter className="comment-author" text={msg.author} title={msg.fullMessage} />}
        </div>
        <div>
          <MessageAttachments message={msg} />
        </div>

        {deleteCommentButton}

        <TranslateChildrenProps>
          <Confirmation
            translate={{
              title: 'feedback.comments.removeComment.confirmTitle',
              body: 'feedback.comments.removeComment.confirmBody',
              yesText: 'yes',
              noText: 'no'
            }}
            ref={this.bindRemoveCommentConfirmation}
            onConfirm={removeComment(msg.id)}
          />
        </TranslateChildrenProps>
      </div>
    )
  }
}

const CommentBody = ({comments, showEditLinks, removeComment}) => (
  <div>
    <div>
      <div className="col col-6 sm-col-8">
        <FeedbackCommentsNote />
      </div>
      <div className="col col-6 sm-col-4">
        {showEditLinks && <AddCommentLink classNames="add-comment-button top" />}
      </div>
    </div>
    {comments.map((msg, k) => <SingleComment key={k} msg={msg} removeComment={removeComment} showEditLinks={showEditLinks} />).toJS()}
    {showEditLinks && comments.size > 0 && <AddCommentLink classNames="add-comment-button"/>}
  </div>
);

export default class FeedbackComments extends React.Component {
  constructor(props) {
    super(props);
    this.renderTitle = this.renderTitle.bind(this)
  }

  renderTitle({visible, handleClick}) {
    const {comments, showEditLinks} = this.props;
    const titleProps = {
      comments,
      showEditLinks,
      handleClick,
      toggled: visible
    };
    return (<CommentingTitle {...titleProps} />)
  }

  render() {
    const {comments, collapseId, showEditLinks, initiallyVisible, removeComment} = this.props;

    const bodyProps = {
      showEditLinks,
      comments: fromJS(comments),
      removeComment
    };
    const containerProps = {
      collapseId,
      initiallyVisible,
      title: this.renderTitle
    };

    return (
      <div className="feedback-comments">
        <CollapsingContainer {...containerProps} animated={false}>
          <CommentBody {...bodyProps} />
        </CollapsingContainer>
      </div>
    )
  }
}

FeedbackComments.defaultProps = {
  initiallyVisible: true,
  showEditLinks: true
};

FeedbackComments.propTypes = {
  initiallyVisible: PropTypes.bool.isRequired,
  comments: PropTypes.oneOfType([
    PropTypes.arrayOf(Message),
    PropTypes.instanceOf(List)
  ]),
  collapseId: PropTypes.any.isRequired,
  showEditLinks: PropTypes.bool.isRequired
};
