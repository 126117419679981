/* global jsConfig */
import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import FacebookProvider, { Login } from 'react-facebook'
import { List } from 'immutable'

import { Selection, LabeledCheckBox } from 'components/Forms'
import { Text, TranslateChildrenProps } from 'containers/Text'
import { getText } from 'containers/Text/utils'
import { Confirmation } from 'containers/Dialog'

import FacebookReceiverSection from './FacebookReceiverSection'
import Button from 'components/kesko/common/Button'
import TextWithIcon from 'components/kesko/TextWithIcon'
import KeskoIcons from 'components/Icons/KeskoIcons'
import {storeMapper} from 'utils/OptionMapper'

const facebookAppId = jsConfig.facebookAppId

class SettingsFacebookSection extends Component {

  static contextTypes = {
    locale: PropTypes.string,
    translations: PropTypes.instanceOf(Object)
  };

  static propTypes = {
    chainUnits: PropTypes.instanceOf(List).isRequired,
    subjects: PropTypes.instanceOf(List).isRequired,
    stores: PropTypes.instanceOf(List).isRequired,
    savingReceiver: PropTypes.bool.isRequired,
    checkingFacebookLogin: PropTypes.bool.isRequired,
    loggedInFacebook: PropTypes.bool.isRequired,
    facebookPages: PropTypes.instanceOf(List),
    facebookReceivers: PropTypes.instanceOf(List),
    isStoreUser: PropTypes.bool.isRequired,
    removingConsent: PropTypes.bool,
    actions: PropTypes.shape({
      createFacebookReceiver: PropTypes.func.isRequired,
      checkFacebookLogin: PropTypes.func.isRequired,
      facebookLoginSuccess: PropTypes.func.isRequired,
      removeFacebookReceiver: PropTypes.func.isRequired,
      removeFacebookLinkingConsent: PropTypes.func.isRequired,
      logoutFromFacebook: PropTypes.func.isRequired
    }).isRequired
  };

  static defaultProps = {
    facebookReceivers: List([])
  }

  bindLogoutConfirmation = (dialog) => this._logoutConfirmation = dialog;

  openLogoutFromFacebookConfirmation = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this._logoutConfirmation.open()
  };

  bindRemoveConsentConfirmation = (dialog) => this._removeConsentConfirmation = dialog;

  openRemoveConsentConfirmation = () => {
    this._removeConsentConfirmation.open()
  };

  onAllowLinkingChange = (event) => {
    if (!!this.props.fields.allowLinking.value) {
      this.openRemoveConsentConfirmation()
    } else {
      event.target.checked = true
      this.props.fields.allowLinking.onChange(event)
    }
  };

  consentRemoved = () => {
    this.props.actions.removeFacebookLinkingConsent()
    this.props.fields.allowLinking.value = false
    this.props.fields.allowLinking.onChange()
  }

  render() {
    const {
      chainUnits, subjects, stores, savingReceiver, checkingFacebookLogin, loggedInFacebook,
      facebookPages, facebookReceivers, facebookUserName, isStoreUser, removingConsent,
      fields: {
        target, targetStore, facebookPage, allowLinking
      },
      actions: {
        createFacebookReceiver, checkFacebookLogin, facebookLoginSuccess, removeFacebookReceiver, logoutFromFacebook
      }
    } = this.props

    const chainOrSubjectMapper = lang => chainOrSubject => ({
      label: chainOrSubjectLabel(chainOrSubject, lang),
      id: chainOrSubject.id,
      value: chainOrSubject
    })

    const pageMapper = p => ({
      label: p.name,
      id: p.id,
      value: p
    })

    const chainOrSubjectPrefix = (type) => {
      return getText(this.context.translations, 'settings.facebook.targetType.' + type, '')
    }

    const chainOrSubjectLabel = (chainOrSubject, lang) => {
      if (chainOrSubject._type === 'Subject') {
        return chainOrSubjectPrefix('subject') + chainOrSubject.localizedName(lang)
      } else {
        return chainOrSubjectPrefix('chain') + chainOrSubject.name
      }
    }

    const saveFacebookReceiver = () => {
      let chainUnitId = target.value._type === 'ChainUnit' && target.value ? target.value.id : undefined;
      let feedbackSubject = target.value._type === 'Subject' && target.value ? target.value.id : undefined;
      let storeId = targetStore.value ? targetStore.value.id : undefined;
      createFacebookReceiver(chainUnitId, feedbackSubject, storeId, facebookPage.value);
      facebookPage.value = '';
      facebookPage.onChange();
      target.value = '';
      target.onChange();
      targetStore.value = '';
      targetStore.onChange();
    };

    const loginButton = ({onClick}) => {
      if (checkingFacebookLogin) {
        return (
          <TextWithIcon
            className="facebook-logging-in"
            translation="settings.facebook.login"
            icon={KeskoIcons.spinner}
          />
        );
      } else if (!loggedInFacebook) {
        return (
          <TextWithIcon
            onClick={onClick}
            translation="settings.facebook.login"
            className="facebook-login"
            icon={KeskoIcons.facebook}
          />
        );
      }
    };

    const facebookReceiverSection = () => {
      if (!facebookReceivers || facebookReceivers.isEmpty()) {
        return;
      }

      return (
        <div className="facebook-receivers">
          <FacebookReceiverSection
            receivers={facebookReceivers}
            chainUnits={chainUnits}
            subjects={subjects}
            stores={stores}
            actions={{removeFacebookReceiver}}
          />
        </div>
      );
    };

    const logoutOrLoggingOut = checkingFacebookLogin ? (
      <TextWithIcon
        className="facebook-logging-out"
        translation="settings.facebook.loggingOut"
        icon={KeskoIcons.spinner}
      />
    ) : (
      <TextWithIcon
        className="facebook-logout"
        onClick={this.openLogoutFromFacebookConfirmation}
        translation="settings.facebook.logout"
        icon={KeskoIcons.trash}
      />
    );

    const loginSection = () => {
      if (!loggedInFacebook) {
        return;
      }
      if (facebookUserName) {
        return (
          <div className="facebook-status">
            <Text k="settings.facebook.loggedIn"/>
            <span className="facebook-username">{facebookUserName}</span>
            {logoutOrLoggingOut}
          </div>
        );
      }
      return (
        <TextWithIcon
          className="facebook-logging-in"
          translation="settings.facebook.loggingIn"
          icon={KeskoIcons.spinner}
        />
      );
    };

    const logoutConfirmationTitle = () => {
      return getText(this.context.translations, 'settings.facebook.confirmLogoutTitleStart') + facebookUserName +
        getText(this.context.translations, 'settings.facebook.confirmLogoutTitleEnd')
    };

    return (
      <div className="facebook-section">
        {((facebookPages && !facebookPages.isEmpty()) || (facebookReceivers || !facebookReceivers.isEmpty())) && (
          <div className="facebook-section-title">
            <h2>
              <Text k="settings.facebook.title" />
            </h2>
          </div>
        )}
        {(!loggedInFacebook || !facebookPages) && (
          <FacebookProvider appId={facebookAppId}>
            <Login
              scope="pages_show_list,read_page_mailboxes,manage_pages"
              onReady={checkFacebookLogin}
              onResponse={facebookLoginSuccess}
              render={loginButton}
            />
          </FacebookProvider>
        )}
        {loginSection()}
        <div>
          {loggedInFacebook && facebookPages && !facebookPages.isEmpty() && (
            <div className="facebook-consent">
              <LabeledCheckBox
                {...allowLinking}
                label="settings.facebook.allowLinking"
                onChange={this.onAllowLinkingChange}
                disabled={removingConsent}
              />
            </div>
          )}
          {allowLinking.value && loggedInFacebook && facebookPages && !facebookPages.isEmpty() && (
            <div className="mt1">
              <label htmlFor="facebook-page">
                <Text k="settings.facebook.page.label" />
              </label>
              <Selection
                id="facebook-page"
                className="mt1 mb1"
                disabled={savingReceiver || checkingFacebookLogin}
                options={facebookPages.toJS()}
                optionMapper={pageMapper}
                showNotSelected={false}
                translate={{notSelectedLabel: 'settings.facebook.page.notSelected'}}
                {...facebookPage}
              />
            </div>
          )}
          {allowLinking.value && loggedInFacebook && facebookPage.value !== '' && !isStoreUser && (
            <div className="mt1 mb2">
              <label>
                <Text k="settings.facebook.targetType.label" />
              </label>
              <Selection
                id="page-target-type"
                className="mt1"
                disabled={savingReceiver}
                options={chainUnits.concat(subjects).toJS()}
                optionMapper={chainOrSubjectMapper(this.context.locale)}
                showNotSelected={false}
                translate={{notSelectedLabel: 'settings.facebook.targetType.notSelected'}}
                {...target}
              />
            </div>
          )}
          {allowLinking.value && loggedInFacebook && facebookPage.value !== '' && isStoreUser && (
            <div className="mt1 mb2">
              <label>
                <Text k="settings.facebook.targetType.label" />
              </label>
              <Selection
                id="page-target-type"
                className="mt1"
                disabled={savingReceiver}
                options={stores.toJS()}
                optionMapper={storeMapper}
                showNotSelected={false}
                translate={{notSelectedLabel: 'settings.facebook.targetType.notSelected'}}
                {...targetStore}
              />
            </div>
          )}
          {allowLinking.value && loggedInFacebook && facebookPage.value !== '' && (target.value !== '' || targetStore.value) && (
            <div className="mt3 mb2">
              <Button
                onClick={saveFacebookReceiver}
                loading={savingReceiver}
                disabled={savingReceiver || checkingFacebookLogin}
                translation="settings.facebook.receivers.add"
                uppercase
              />
            </div>
          )}
        </div>
        {facebookReceiverSection()}
        <TranslateChildrenProps>
          <Confirmation
            translate={{
              body: 'settings.facebook.confirmLogoutBody',
              yesText: 'yes',
              noText: 'no'
            }}
            title={logoutConfirmationTitle()}
            ref={this.bindLogoutConfirmation}
            onConfirm={logoutFromFacebook}
          />
        </TranslateChildrenProps>
        <TranslateChildrenProps>
          <Confirmation
            translate={{
              title: 'settings.facebook.confirmRemoveConsentTitle',
              body: 'settings.facebook.confirmRemoveConsentBody',
              yesText: 'yes',
              noText: 'no'
            }}
            ref={this.bindRemoveConsentConfirmation}
            onConfirm={this.consentRemoved}
          />
        </TranslateChildrenProps>
      </div>
    )
  }
}

export default reduxForm({
  form: 'settingsForm',
  fields: [
    'target',
    'targetStore',
    'facebookPage',
    'allowLinking'
  ],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS()
})(SettingsFacebookSection)
