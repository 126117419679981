import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { FeedbackListFilters } from 'components/Feedback'
import { WaitFor } from 'components/Utils'
import { ContextSelectionHeader } from 'containers/Menu'
import { init, bindFeedbackList, fetchFeedbacks, fetchMoreFeedbacks } from 'actions/FeedbackListActions'
import { toggleFilter, resetFilter } from 'actions/QuickFilterActions'
import { changeContext } from 'actions/ContextActions'
import { ContextType } from 'domain'
import { mainViewName } from 'data/globals'

import RouteUtil from './RouteUtil'
import FeedbackListRoute from './FeedbackListRoute'

class AllFeedbacksRoute extends Component {
  static propTypes = {
    feedbackList: PropTypes.instanceOf(Map),
    feedbackListId: PropTypes.string,
    location: PropTypes.object.isRequired,
    activeFilter: PropTypes.string,
    actions: PropTypes.shape({
      init: PropTypes.func.isRequired,
      fetchFeedbacks: PropTypes.func.isRequired,
      fetchMoreFeedbacks: PropTypes.func.isRequired,
      toggleFilter: PropTypes.func.isRequired
    })
  };

  componentDidMount() {
    this.props.actions.bindContext()
    this.props.actions.resetQuickFilter()
    if (!this.props.feedbackListId) {
      this.props.actions.init()
    }
    this.interval = setInterval(() => this.props.actions.refreshFeedbacks(this.props.feedbackListId), 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const { feedbackList, actions, actions: { toggleFilter }, activeFilter, location, isReadOnly } = this.props
    return (
      <WaitFor condition={!!feedbackList}>
        { feedbackList && (
          <div className="context-selection-container">
            <ContextSelectionHeader text={mainViewName} type={ContextType.allFeedbacks}/>
            <FeedbackListFilters activeFilter={activeFilter} actions={{ toggleFilter }} isReadOnly={isReadOnly} />
            <FeedbackListRoute
              feedbackList={feedbackList}
              actions={actions}
              location={location}
              showReceiver={true}
            />
          </div>
        )}
      </WaitFor>
    )
  }
}

const BINDING_KEY = 'allFeedbacks'
const mapStateToProps = (state, { location }) => {
  const activeFilterListId = state.getIn(['quickFilter', 'feedbackListId'])
  const feedbackListId = state.getIn(['feedbackLists', 'bindings', BINDING_KEY])
  const currentFeedbackListId = activeFilterListId || feedbackListId
  const feedbackList = state.getIn(['feedbackLists', currentFeedbackListId])
  const activeFilter = state.getIn(['quickFilter', 'activeFilter'])
  const isReadOnly = state.getIn(['client','readOnly'])

  return {
    activeFilter,
    feedbackListId,
    feedbackList,
    location,
    isReadOnly
  }
}

const EMPTY_FILTER = {}
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    // TODO: the same init is run after profile update -> extract to common action
    init: () => dispatch => {
      const initializeList = init(EMPTY_FILTER)
      dispatch(initializeList)
      dispatch(bindFeedbackList(BINDING_KEY, initializeList.feedbackListId))
    },
    fetchFeedbacks,
    fetchMoreFeedbacks,
    toggleFilter,
    resetQuickFilter: () => resetFilter(EMPTY_FILTER),
    bindContext: () => changeContext(ContextType.allFeedbacks, RouteUtil.allFeedbacks()),
    refreshFeedbacks: (feedbackListId) => dispatch => dispatch(fetchFeedbacks(feedbackListId))
  }, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(AllFeedbacksRoute)
