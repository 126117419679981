import React, {Component, PropTypes} from 'react'
import {getPermissionToken, requestPermissionForNotifications} from '../../../pushNotifications'
import {LabeledCheckBox} from 'components/Forms/common'
import Col from 'components/grid/Col'
import Text from 'containers/Text'
import {getText} from 'containers/Text/utils'

import KeskoIcons from 'components/Icons/KeskoIcons'
import TextWithIcon from 'components/kesko/TextWithIcon'
import TranslateChildrenProps from 'containers/Text/TranslateChildrenProps'
import Confirmation from 'containers/Dialog/Confirmation'
import FeedbackDate from 'components/Feedback/FeedbackDate'

class PushMessageTokenForm extends Component {
  state = {
    permissionToken: null
  }

  /*eslint-disable react/no-did-mount-set-state */
  async componentDidMount() {
    const permissionToken = await getPermissionToken();
    if (permissionToken) {
      this.setState({permissionToken});
    }
  }

  handleRemoveDevice = (index) => {
    const {fields: {pushMessageTokens}} = this.props;
    if (!!pushMessageTokens[index]) {
      pushMessageTokens.removeField(index)
    }
  }

  addDeviceToken = (token) => {
    this.props.fields.pushMessageTokens.addField({
      registrationToken: token,
      userAgent: navigator ? navigator.userAgent : ''
    });
  }

  render() {
    const {
      submitting,
      fields: {
        sendPushMessages, pushMessageTokens
      }
    } = this.props;

    const handleAddDeviceToken = async () => {
      const token = await requestPermissionForNotifications();
      if (token) {
        this.addDeviceToken(token);
        this.setState({permissionToken: token});
      }
    }
    const enabledOnThisDevice = pushMessageTokens.filter(currentTokens => currentTokens.registrationToken.value === this.state.permissionToken).length;

    return (
      <div>
        <Col>
          <h3>
            <Text k="profile.pushNotifications.title" />
          </h3>
        </Col>
        <Col className="mb2">
          <LabeledCheckBox
            {...sendPushMessages}
            label="profile.subscriptions.fields.sendPushMessages"
          />
          {!!sendPushMessages.value && !enabledOnThisDevice && (
            <TextWithIcon
              className="push-message-tokens-add-device"
              translation="profile.pushNotifications.enableOnThisDevice"
              icon={KeskoIcons.plus}
              onClick={handleAddDeviceToken}
            />
          )}

          {!!sendPushMessages.value && !!pushMessageTokens.length && (
            <div className="push-message-tokens-section">
              <div className="desktop-view">
                <table className="table-light">
                  <thead>
                  <tr>
                    <th>
                      <Text k="profile.pushNotifications.userAgent"/>
                    </th>
                    <th/>
                    <th/>
                  </tr>
                  </thead>
                  <tbody>
                  {pushMessageTokens.map((device, idx) => (
                    <PushMessageTokenRow
                      device={device}
                      key={idx}
                      idx={idx}
                      submitting={submitting}
                      handleRemoveDevice={this.handleRemoveDevice}
                      tokenForThisDevice={this.state.permissionToken}
                    />
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Col>
      </div>
    )
  }
}

export default PushMessageTokenForm;

class PushMessageTokenRow extends Component {
  static contextTypes = {
    locale: PropTypes.string,
    translations: PropTypes.instanceOf(Object)
  }

  bindRemoveTokenConfirmation = (dialog) => this._removeTokenConfirmation = dialog;
  openRemoveTokenConfirmation = () => {
    this._removeTokenConfirmation.open();
  }

  renderDate = (created) => {
    const date = new Date(Date.parse(created));
    return (
      <FeedbackDate date={date}/>
    )
  }

  render() {
    const {
      device: {
        registrationToken, userAgent, created
      },
      idx, submitting, handleRemoveDevice, tokenForThisDevice
    } = this.props

    const handleRemove = () => handleRemoveDevice(idx);
    return (
      <tr key={idx}>
        <td>
          {(!!tokenForThisDevice && registrationToken.value === tokenForThisDevice) ? (
            <Text k={'profile.pushNotifications.thisDevice'}/>
          ) : (
            <Text k={userAgent.value} />
          )}
        </td>
        <td>
          {!!created.value && this.renderDate(created.value)}
        </td>
        <td>
          <TextWithIcon className="remove-push-message-token"
                        disabled={submitting}
                        onClick={this.openRemoveTokenConfirmation}
                        translation="remove"
                        icon={KeskoIcons.trash}
          />
          <TranslateChildrenProps>
            <Confirmation title={getText(this.context.translations, 'profile.pushNotifications.confirmRemovePushMessageTokenTitle')}
                          translate={{
                            body: 'profile.pushNotifications.confirmRemovePushMessageTokenBody',
                            yesText: 'yes',
                            noText: 'no'
                          }}
                          ref={this.bindRemoveTokenConfirmation}
                          onConfirm={handleRemove}
            />
          </TranslateChildrenProps>
        </td>
      </tr>
    )
  }
}
