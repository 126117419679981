import React, { PropTypes } from 'react'
import classNames from 'classnames'

import AddErrorDisplay from 'components/Forms/common/AddErrorDisplay'
import { Icon, KeskoIcons } from 'components/Icons'
import { overrideOnBlur } from 'components/Forms/common/FormUtils'
import { TranslateProps } from 'containers/Text'
import Text from 'containers/Text/Text';

const LabeledCheckBox = ({label, ...props}) => (
  <label className="no-user-select checkbox flex flex-center clickable">
    <input
      type="checkbox"
      {...overrideOnBlur(props)}
    />
    {props.checked && (
      <span className={classNames(['checkbox--checked', {'checkbox--is-disabled': props.disabled}])}>
        <Icon
          className="checkbox--icon"
          icon={KeskoIcons.tick}
          size={Icon.sizes.unset}
        />
      </span>
    )}
    {!props.checked && (
      <span className={classNames(['checkbox--not-checked', {'checkbox--is-disabled': props.disabled}])}>
        <Icon
          className="checkbox--hidden-icon"
          icon={KeskoIcons.tick}
          size={Icon.sizes.unset}
        />
      </span>
    )}
    <Text
      k={label}
      className={classNames(['checkbox--label', {'checkbox--label-is-disabled': props.disabled}
      ])}
    />
  </label>
);

LabeledCheckBox.propTypes = {
  label: PropTypes.string.isRequired
};

export default TranslateProps(AddErrorDisplay(LabeledCheckBox))
