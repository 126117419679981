import React, { Component, PropTypes } from 'react';

import Text from 'containers/Text'
import TextWithIcon from '../kesko/TextWithIcon';
import KeskoIcons from '../Icons/KeskoIcons';

class AttachmentFileGallery extends Component {

  renderGallery() {
    const {files} = this.props
    if (!files) {
      return;
    }
    const gallery = files.map((obj, i) => {
      const scanningStatus = 'feedback.virusScanningStatus.' + obj.virusScanned
      const isSafe = obj.virusScanned === 'ok' || obj.virusScanned === 'notNeeded'
      return (
        <div key={obj.src} className="clearfix truncate">
          {isSafe && (
            <div className="attachment-text">
              <a href={obj.src} key={i}>
                <TextWithIcon
                  icon={KeskoIcons.paperclip}
                  translation={obj.fileName}
                />
              </a>
              <span className="filesize">{obj.fileSize}</span>
            </div>
          )}
          {!isSafe && (
            <div className="attachment-text">
              <TextWithIcon
                className="unsafe-attachment"
                icon={KeskoIcons.paperclip}
                translation={obj.fileName}
              />
              <span className="mr1 filesize">{obj.fileSize}</span>
              (<Text k={scanningStatus} />)
            </div>
          )}
        </div>
      );
    });
    return (
      <div>
        <h4><Text k="feedback.files" /></h4>
        {gallery}
      </div>
    );
  }

  render() {
    return (
      <div className="section">
        {this.renderGallery()}
      </div>
    );
  }
}

AttachmentFileGallery.propTypes = {
  files: PropTypes.array
};

export default AttachmentFileGallery;
