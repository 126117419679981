import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { FeedbackCommentView } from 'components/Feedback'
import { RoutingUtils } from 'components/Routing'
import { Feedback } from 'domain'

import { triggerSaveComment } from 'actions/CommentActions'
import { addAttachment, updateAttachment, removeAttachment, uploadAttachment, clearAttachments } from 'actions/AttachmentActions'

class AddCommentRoute extends Component {
  static contextTypes = {
    currentPath: PropTypes.string,
    router: PropTypes.any
  }

  static propTypes = {
    feedbackId: PropTypes.number.isRequired,
    feedback: PropTypes.instanceOf(Feedback).isRequired
  }

  navigateToFeedbackView = () => {
    this.context.router.push(RoutingUtils.relativizeTarget(this.context.currentPath, '..'))
  }

  saveComment = (commentText, attachmentIds) => {
    return this.props.actions.saveComment(commentText, attachmentIds).then(this.navigateToFeedbackView)
  }

  cancel = () => {
    this.props.actions.clearAttachments()
    this.navigateToFeedbackView()
  }

  render() {
    return (
      <FeedbackCommentView
        {...this.props}
        saveComment={this.saveComment}
        cancel={this.cancel}
      />
    )
  }
}

const mapStateToProps = (state, props) => ({
  feedback: props.feedback,
  attachments: state.getIn(['attachments', 'attachments'])
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: bindActionCreators({
    saveComment: (commentText, attachmentIds) => triggerSaveComment(props.feedbackId, commentText, attachmentIds),
    clearAttachments: () => dispatch(clearAttachments()),
    addAttachment,
    updateAttachment,
    removeAttachment,
    uploadAttachment
  }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AddCommentRoute)