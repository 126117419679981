/* global jsConfig */
import React, { Component, PropTypes } from 'react'
import { List } from 'immutable'

import { Text } from 'containers/Text'
import { RouteUtil } from 'routes'
import { WaitFor } from 'components/Utils'

import ProfileForm from './ProfileForm'
import { profileToFormModel } from './utils'

class ProfileView extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      init: PropTypes.func.isRequired,
      saveProfile: PropTypes.func.isRequired
    }).isRequired,
    locale: PropTypes.string.isRequired,
    user: PropTypes.any.isRequired,
    isStoreUser: PropTypes.bool.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
    businessUnits: PropTypes.instanceOf(List),
    chainUnits: PropTypes.instanceOf(List),
    subjects: PropTypes.instanceOf(List),
    allowableBusinessUnits: PropTypes.instanceOf(List)
  };

  componentDidMount() {
    this.props.actions.init()
  }

  static contextTypes = {
    router: PropTypes.any
  };

  saveProfileAndReturn = (profile) => {
    return this.props.actions.saveProfile(profile).then(() => {
      this.context.router.push(RouteUtil.home());
    });
  }

  handleCancel = () => {
    this.context.router.push(RouteUtil.home());
  }

  render() {
    const {
      user, locale, businessUnits, chainUnits, subjects, isStoreUser, isReadOnly, allowableBusinessUnits, showInterestFeedbackCategorySelection,
      user: { profile }
    } = this.props
    const {
      receiver, receiver2, restrictions, restrictions2, sendNotifications, sendNotificationsNoResponse, sendSummaries, excludeBusinessUnits, signature,
      greeting, secondaryInterestsEnabled, proposeResponses, sendPushMessages, pushMessageTokens, interestFeedbackCategory
    } = profileToFormModel(profile, chainUnits, businessUnits, subjects)
    const showPushMessageForm = jsConfig && !jsConfig.firebaseMessagingDisabled;
    return (
      <WaitFor condition={isStoreUser || subjects && chainUnits && businessUnits}>
        <div className="profile-view">
          <h1>
            <Text k="profile.title" />
          </h1>
          {!profile && (
            <div className="border rounded pt2 px2 silver bg-lightblue">
              <p><Text k="profile.firstVisit.description" /></p>
              <p><Text k="profile.firstVisit.moreDescription" /></p>
            </div>
          )}
          <ProfileForm
            initialValues={{
              language: locale,
              signature,
              greeting,
              receiver,
              receiver2,
              restrictions,
              restrictions2,
              sendNotifications,
              sendNotificationsNoResponse,
              sendSummaries,
              secondaryInterestsEnabled,
              excludeBusinessUnits,
              proposeResponses,
              sendPushMessages,
              pushMessageTokens,
              interestFeedbackCategory
            }}
            showCancel={!!profile}
            businessUnits={businessUnits}
            isStoreUser={isStoreUser}
            isReadOnly={isReadOnly}
            chainUnits={chainUnits}
            subjects={subjects}
            allowableBusinessUnits={allowableBusinessUnits}
            saveProfileAndReturn={this.saveProfileAndReturn}
            onCancel={this.handleCancel}
            user={user}
            showPushMessageForm={showPushMessageForm}
            showInterestFeedbackCategorySelection={showInterestFeedbackCategorySelection}
            actions={this.props.actions}
          />
        </div>
      </WaitFor>
    )
  }
}

export default ProfileView
