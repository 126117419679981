import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {
  checkFacebookLogin,
  createFacebookReceiver,
  facebookLoginSuccess,
  initializeSettings,
  logoutFromFacebook,
  removeFacebookLinkingConsent,
  removeFacebookReceiver,
  getStoreSettings,
  saveStoreSettings,
  saveResponseTemplate
} from 'actions/SettingsActions'

import {SettingsView} from '../components/Settings'
import {toJS} from 'utils'

class SettingsRoute extends Component {
  render() {
    return (
      <SettingsView {...this.props} />
    )
  }
}

const toList = obj => obj ? obj.toList() : obj

const toAuthorizedList = obj => obj ? obj.filter(o => o.isAuthorized).toList() : obj

const mapStateToProps = state => ({
  isStoreUser: state.getIn(['client', 'storeUser']),
  chainUnits: toAuthorizedList(state.getIn(['globalData', 'chainUnits'])),
  subjects: toAuthorizedList(state.getIn(['globalData', 'subjects'])),
  stores: toList(state.getIn(['globalData', 'allowableBusinessUnits'])),
  permissions: toJS(state.getIn(['client', 'permissions'])),
  facebookPages: toList(state.getIn(['settings', 'facebookPages'])),
  facebookReceivers: toList(state.getIn(['settings', 'facebookReceivers'])),
  savingReceiver: state.getIn(['settings', 'savingReceiver']),
  checkingFacebookLogin: state.getIn(['settings', 'checkingFacebookLogin']),
  loggedInFacebook: state.getIn(['settings', 'loggedInFacebook']),
  facebookUserName: state.getIn(['settings', 'facebookUserName']),
  showFacebookSettings: state.getIn(['client', 'userInfo', 'profile', 'showFacebookSettings']),
  allowFacebookLinking: state.getIn(['client', 'userInfo', 'profile', 'allowFacebookLinking']),
  removingConsent: state.getIn(['settings', 'removingConsent']),
  storeSettings: state.getIn(['settings', 'storeSettings']),
  allowedTransferFeedbackDomains: toList(state.getIn(['settings', 'allowedTransferFeedbackDomains'])),
  showResponseTemplates: state.getIn(['client', 'showResponseTemplates']),
  responseTemplates: toList(state.getIn(['globalData', 'responseTemplates']))
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    init: initializeSettings,
    changeStore: (businessUnitId) => getStoreSettings(businessUnitId),
    saveStoreSettings: (businessUnitId, showGreeting, showRetailerImage, greetings, offerRequestTransferEmail, offerRequestProTransferEmail) => {
      return saveStoreSettings(businessUnitId, showGreeting, showRetailerImage, greetings, offerRequestTransferEmail, offerRequestProTransferEmail);
    },
    createFacebookReceiver,
    checkFacebookLogin,
    facebookLoginSuccess,
    removeFacebookReceiver,
    removeFacebookLinkingConsent,
    logoutFromFacebook,
    saveResponseTemplate
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsRoute)
