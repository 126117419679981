import URI from 'urijs'

class UriHandler {
  constructor(uri) {
    if (!uri) {
      this._uri = URI('').normalize()
    } else if (typeof uri.pathname === 'string') {
      // React-router location
      this._uri = URI(uri.pathname).search(uri.query || {}).normalize()
    } else {
      // URI.js or string
      this._uri = URI(uri).normalize()
    }
  }

  /**
   * Set the path of the uri
   */
  path(path) {
    return new UriHandler(this._uri.pathname(''+path))
  }

  /**
   * Append a path segment
   * @param segment
   * @returns {UriHandler} new uri handler
   */
  segment(segment) {
    return new UriHandler(this._uri.segment(''+segment))
  }

  /**
   * Set the query parameter map
   * @param query
   * @returns {UriHandler} new uri handler
   */
  query(query) {
    return new UriHandler(this._uri.query(query))
  }

  addQuery(query) {
    return new UriHandler(this._uri.addQuery(query))
  }

  removeQuery(key) {
    return new UriHandler(this._uri.removeQuery(key))
  }

  setQuery(query) {
    return new UriHandler(this._uri.setQuery(query))
  }

  search(search) {
    return this.query(search)
  }

  addSearch(search) {
    return this.addQuery(search)
  }

  toString() {
    return this._uri.toString()
  }
}

export default UriHandler
