import React, { PropTypes } from 'react';
import { List } from 'immutable'

import { Feedback, Message, MessageType } from 'domain'
import MessageElement from './MessageElement'
import ReplyToFeedbackLink from './ReplyToFeedbackLink'

const MessagesBlock = ({messages, showEditLinks, feedback}) => {
  const messageElements = messages.map((msg, k) => (
    <MessageElement
      message={msg}
      theme={
        msg.messageType === MessageType.response ?
          MessageElement.theme.response
          : MessageElement.theme.customer
      }
      key={k}
    />
  ));

  const isReplyButtonVisible = feedback && messages.size !== 0 && showEditLinks
  const replyToFeedBackTop = isReplyButtonVisible ? (
    <ReplyToFeedbackLink feedback={feedback} classNames="top" />
  ) : null;
  const replyToFeedBackBottom = isReplyButtonVisible ? (
    <ReplyToFeedbackLink feedback={feedback} />
  ) : null;
  return (
    <div className="messages-block">
      {replyToFeedBackTop}
      {messageElements}
      {replyToFeedBackBottom}
    </div>
  )
};

MessagesBlock.defaultProps = {
  showEditLinks: false
};
MessagesBlock.propTypes = {
  messages: PropTypes.oneOfType([
    PropTypes.arrayOf(Message),
    PropTypes.instanceOf(List)
  ]).isRequired,
  showEditLinks: PropTypes.bool,
  feedback: PropTypes.shape(Feedback)
};

export default MessagesBlock;
