import React, { PropTypes } from 'react';

const FilterableDropdownItem = ({text, highlighted, onClick}) => {
  const onItemClick = () => onClick(text);

  return (
    <span className={`suggestion-item ${highlighted ? 'highlighted' : ''}`} onMouseDown={onItemClick}>
      {text}
    </span>
  );
};

FilterableDropdownItem.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  highlighted: PropTypes.bool
};

export default FilterableDropdownItem;
