
export const Colors = {
  green: '#4CA02A',
  red: '#9B2242',
  blue: '#062b60',
  orange: '#FF6900',
  yellow: '#ffee80',
  darkgray: '#25282A',
  gray: '#d7dbdb',
  lime: '#b4d8a6',
  lightred: '#e6c8d0',
  lightblue: '#c0c7d0',
  middlegray: '#707372'
}
