import React, { Component } from 'react'
import classNames from 'classnames'

import { TranslateProps } from 'containers/Text'
import AddErrorDisplay from 'components/Forms/common/AddErrorDisplay'
import { overrideOnBlur } from 'components/Forms/common/FormUtils'

const selectFactory = React.createFactory('select')
const BaseSelect = props => selectFactory(props)

class Select extends Component {
  render() {
    const {name, className, ...props} = this.props
    const classes = classNames(
      'field',
      'fill',
      className
    )
    return (
      <BaseSelect
        className={classes}
        name={name}
        {...overrideOnBlur(props)}>
        {this.props.children}
      </BaseSelect>
    )
  }
}

export default TranslateProps(AddErrorDisplay(Select))
