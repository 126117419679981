import React, { Component, PropTypes } from 'react'
import { UriHandler } from 'utils'

/**
 * A higher-order component to expose the currentPath to the.
 * Must be added to the routed component,
 * Example:
 *
 * <pre>&lt;Route component={AddContextPath(Component)}} /&gt;</pre>
 * @param OriginalComponent
 * @constructor
 */
const AddPathContext = OriginalComponent => class extends Component {

  static childContextTypes = {
    currentPath: PropTypes.string,
    currentUri: PropTypes.instanceOf(UriHandler)
  };

  getChildContext() {
    return {
      currentPath: this.props.location.pathname,
      currentUri: new UriHandler(this.props.location)
    }
  }

  render() {
    return <OriginalComponent {...this.props}>{this.props.children}</OriginalComponent>
  }
}

export default AddPathContext
