import React, { Component, PropTypes } from 'react'

import { Col } from 'components/grid'
import { Navigator } from 'components/Menu'
import { ExclamatedNote } from 'components/kesko'
import FeedbackReplyByPhoneForm from './FeedbackReplyByPhoneForm'

import { Feedback } from 'domain'
import FeedbackCollapsingSections from '../FeedbackCollapsingSections';

class FeedbackReplyByPhoneView extends Component {

  static propTypes = {
    feedback: PropTypes.instanceOf(Feedback),
    actions: PropTypes.shape({
      sendReplyAndMarkDone: PropTypes.func.isRequired,
      sendReplyAndMarkWaiting: PropTypes.func.isRequired,
      cancel: PropTypes.func.isRequired,
      init: PropTypes.func.isRequired,
      cleanup: PropTypes.func.isRequired
    }).isRequired
  };

  componentDidMount() {
    this.props.actions.init()
  }

  componentWillUnmount() {
    this.props.actions.cleanup()
  }

  render() {
    const {feedback, actions: {cancel, sendReplyAndMarkDone, sendReplyAndMarkWaiting}} = this.props
    return (
      <section className="feedback-reply-view">

        <Navigator backUrl=".." />

        <Col>
          <hr />
        </Col>

        <Col className="mt2 mb2">
          <ExclamatedNote translate={{note: 'feedback.replyByPhone.recordingNote'}} />
        </Col>

        <FeedbackCollapsingSections
          feedback={feedback}
          messagesVisible
        />

        <Col>
          <hr />
        </Col>

        <FeedbackReplyByPhoneForm
          feedback={feedback}
          localStorageKey={'phone-' + feedback.id}
          onCancel={cancel}
          submitAndDone={sendReplyAndMarkDone}
          submitAndWaiting={sendReplyAndMarkWaiting}
        />

      </section>
    )
  }
}

export default FeedbackReplyByPhoneView
