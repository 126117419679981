import React, { Component, PropTypes } from 'react'
import { List } from 'immutable'

import { Text } from 'containers/Text'
import { WaitFor } from 'components/Utils'

import SettingsForm from './SettingsForm'

class SettingsView extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      init: PropTypes.func.isRequired,
      createFacebookReceiver: PropTypes.func.isRequired,
      checkFacebookLogin: PropTypes.func.isRequired,
      facebookLoginSuccess: PropTypes.func.isRequired,
      removeFacebookReceiver: PropTypes.func.isRequired,
      removeFacebookLinkingConsent: PropTypes.func.isRequired,
      logoutFromFacebook: PropTypes.func.isRequired,
      changeStore: PropTypes.func.isRequired,
      saveStoreSettings: PropTypes.func.isRequired,
      saveResponseTemplate: PropTypes.func.isRequired
    }).isRequired,
    showFacebookSettings: PropTypes.bool,
    showResponseTemplates: PropTypes.bool,
    chainUnits: PropTypes.instanceOf(List),
    subjects: PropTypes.instanceOf(List),
    stores: PropTypes.instanceOf(List),
    permissions: PropTypes.object.isRequired,
    facebookPages: PropTypes.instanceOf(List),
    facebookReceivers: PropTypes.instanceOf(List),
    savingReceiver: PropTypes.bool,
    checkingFacebookLogin: PropTypes.bool,
    loggedInFacebook: PropTypes.bool,
    isStoreUser: PropTypes.bool.isRequired,
    allowFacebookLinking: PropTypes.bool,
    removingConsent: PropTypes.bool,
    storeSettings: PropTypes.object,
    allowedTransferFeedbackDomains: PropTypes.instanceOf(List),
    responseTemplates: PropTypes.instanceOf(List)
  };

  static defaultProps = {
    savingReceiver: false,
    checkingFacebookLogin: true,
    loggedInFacebook: false,
    allowFacebookLinking: false
  }

  componentDidMount() {
    this.props.actions.init()
  }

  render() {
    const {
      chainUnits, subjects, stores, isStoreUser, showFacebookSettings,
      facebookPages, facebookReceivers, savingReceiver, checkingFacebookLogin,
      loggedInFacebook, facebookUserName, allowFacebookLinking,
      removingConsent, storeSettings, permissions, allowedTransferFeedbackDomains,
      showResponseTemplates, responseTemplates,
      actions: {
        createFacebookReceiver,
        checkFacebookLogin,
        facebookLoginSuccess,
        removeFacebookReceiver,
        removeFacebookLinkingConsent,
        logoutFromFacebook,
        changeStore,
        saveStoreSettings,
        saveResponseTemplate
      }
    } = this.props
    return (
      <WaitFor condition={subjects && chainUnits && stores && responseTemplates}>
        <div className="settings-view">
          <h1 className="blue">
            <Text k="settings.title" />
          </h1>
          <SettingsForm
            showFacebookSettings={showFacebookSettings}
            showResponseTemplates={showResponseTemplates}
            isStoreUser={isStoreUser}
            chainUnits={chainUnits}
            subjects={subjects}
            stores={stores}
            permissions={permissions}
            facebookPages={facebookPages}
            facebookReceivers={facebookReceivers}
            savingReceiver={savingReceiver}
            checkingFacebookLogin={checkingFacebookLogin}
            loggedInFacebook={loggedInFacebook}
            facebookUserName={facebookUserName}
            allowFacebookLinking={allowFacebookLinking}
            removingConsent={removingConsent}
            allowedTransferFeedbackDomains={allowedTransferFeedbackDomains}
            responseTemplates={responseTemplates}
            facebookActions={{
              createFacebookReceiver,
              checkFacebookLogin,
              removeFacebookReceiver,
              removeFacebookLinkingConsent,
              logoutFromFacebook,
              facebookLoginSuccess
            }}
            storeSettingsActions={{
              changeStore,
              saveStoreSettings
            }}
            responseTemplateActions={{
              saveResponseTemplate
            }}
            storeSettings={storeSettings}
          />
        </div>
      </WaitFor>
    )
  }
}

export default SettingsView;
