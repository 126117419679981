import React, { Component } from 'react'
import { connect } from 'react-redux'

import { aadLogin, aadLoginError, login, whoami } from 'actions/LoginActions'
import { WaitFor } from 'components/Utils'
import { LoginPage } from 'components/Login'
import { trace } from 'utils/Log'

export class LoginInterceptor extends Component {

  reloadWhoamiIfNeeded = ({ token, userInfo }) => {
    if (token && !userInfo) {
      // Reload the whoami if token set, but no whoami info
      this.props.actions.whoami()
    }
  }

  tokenAndUserInfoSet = () => !!this.props.token && !!this.props.userInfo;

  componentDidMount() {
    if (this.props.token && !this.props.userInfo) {
      this.reloadWhoamiIfNeeded(this.props)
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.token !== this.props.token || newProps.userInfo !== this.props.userInfo) {
      trace('Reloading whoami', newProps.token, newProps.userInfo)
      this.reloadWhoamiIfNeeded(newProps)
    }
  }

  render() {
    const { actions, token, userInfo, aadLoginInProgress, loginError, location: { pathname } } = this.props
    trace('LoginInterceptor: is logged in?', token, userInfo)
    if (!token) {
      return (
        <LoginPage
          actions={actions}
          aadLoginInProgress={aadLoginInProgress}
          loginError={loginError}
          path={pathname}
        />
      )
    }
    return (
      <WaitFor condition={this.tokenAndUserInfoSet}>
        {this.props.children}
      </WaitFor>
    )
  }
}

export default connect(
  (state) => ({
    token: state.getIn(['client', 'token']),
    userInfo: state.getIn(['client', 'userInfo']),
    aadLoginInProgress: state.getIn(['client', 'aadLoginInProgress']),
    loginError: state.getIn(['client', 'loginError'])
  }),
  dispatch => ({
    actions: {
      whoami: () => dispatch(whoami()),
      login: ({ username, password, rememberMe }) => dispatch(login(username, password, rememberMe)),
      aadLogin: (authCode) => dispatch(aadLogin(authCode)),
      aadLoginError: (errorCode) => dispatch(aadLoginError(errorCode))
    }
  })
)(LoginInterceptor)
