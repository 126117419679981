import React, { Component, PropTypes } from 'react'

import { Col } from 'components/grid'
import { Text } from 'containers/Text'

import LoginForm from './LoginForm'
import { Icon, KeskoIcons } from 'components/Icons'
import { getPageParam } from 'components/Utils'
import { warn } from 'utils/Log'

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const authCode = getPageParam('code')
    if (!!authCode) {
      this.props.actions.aadLogin(authCode)
      window.history.replaceState(null, null, '/ui/#' + getPageParam('state'))
    } else {
      const errorDescription = getPageParam('error_description')
      if (!!errorDescription) {
        if (errorDescription.startsWith('AADSTS50105')) {
          this.props.actions.aadLoginError('missingAuthority')
          window.history.replaceState(null, null, '/ui/')
        } else {
          this.props.actions.aadLoginError('authenticationFailed')
          window.history.replaceState(null, null, '/ui/')
        }
      }
    }
  }

  submit(values) {
    return new Promise((resolve, reject) => {
      this.props.actions.login(values).catch(err => {
        warn('Login error', err)
        const errMsg = ['accountFreezed', 'missingBusinessUnits', 'missingAuthority', 'credentialsNotFound'].includes(err.code) ? err.code : 'default'
        reject({_error: 'loginPage.loginFailed.' + errMsg})
      })
    })
  }

  render() {
    const { initialValues, aadLoginInProgress, loginError, path } = this.props
    return (
      <main className="clearfix container p1">
        <Col className="center" xs="10" md="6" centered={true}>
          <Col className="clearfix mt2 orange" centered={true}>
            <Icon icon={KeskoIcons.keskoLogo} size="xxl" />
          </Col>

          <h1 className="orange mb2"><Text k="loginPage.title" /></h1>

          <LoginForm
            onSubmit={this.submit}
            initialValues={initialValues}
            aadLoginInProgress={aadLoginInProgress}
            loginError={loginError}
            path={path}
          />

        </Col>
      </main>
    )
  }
}

LoginPage.defaultProps = {
  initialValues: {},
  aadLoginInProgress: false
}
LoginPage.propTypes = {
  aadLoginInProgress: PropTypes.bool,
  actions: PropTypes.shape({
    login: PropTypes.func.isRequired,
    aadLogin: PropTypes.func.isRequired,
    aadLoginError: PropTypes.func.isRequired
  }).isRequired
}

export default LoginPage
