import { fromJS } from 'immutable'
import createReducer from './createReducer'
import { LoginActions } from 'actions/LoginActions'
import { ClientActions } from 'actions/ClientActions'
import { RouteUtil } from 'routes'
import { Company, ContextType, Permissions } from 'domain'
import { mainViewName, secondaryViewName } from 'data/globals'
import { toJS } from 'utils'

const initialState = fromJS({token: undefined})

const Roles = {
  SHOPKEEPER: 'shopkeeper',
  HANDLER: 'handler',
  ADMIN: 'admin'
}

const companies = user => user.get('authorization')
  .filter(a => a.get('role').startsWith('company'))
  .flatMap(a => a.get('scopes'))
  .filter(s => s.get('type') === 'company')
  .map(s => s.get('id'))

const isReadOnly = user => user.get('authorization').filter(a => !a.get('role').endsWith('Reader')).isEmpty()

const isAdminUser = user => !user.get('authorization').filter(a => a.get('role') === Roles.ADMIN).isEmpty()

const isCompanyStoreUser = (isStoreUser, businessUnits, company) => isStoreUser && (businessUnits.find(bu => bu.company === company) !== undefined)

const isCompanyResponsible = user => user.get('authorization').map(a => a.get('role')).includes('companyResponsible')

const hasSecondaryInterests = (companyUser, user) => companyUser && user.get('profile') && user.get('profile').get('secondaryInterests') &&
  user.get('profile').get('secondaryInterests').get('interestReceiverType')

const businessUnitsWhere = role => user => toJS(user.get('authorization')
  .filter(a => a.get('role') === role)
  .flatMap(a => a.get('scopes'))
  .filter(s => s.get('type') === 'businessUnit')
  .map(s => {
    return {id: s.get('id'), name: s.get('name'), company: s.get('company')}
  }))

const businessUnitIdsWhereShopkeeper = businessUnitsWhere(Roles.SHOPKEEPER)
const businessUnitIdsWhereHandler = businessUnitsWhere(Roles.HANDLER)


const allowReportingForShopkeepers = (shopKeeperBusinessUnits, permissions) => {
  if (shopKeeperBusinessUnits.length > 0) {
    permissions[Permissions.reportingBusinessUnits] = fromJS(shopKeeperBusinessUnits.map(businessUnit => businessUnit.id))
  }
}

const allowReportingForCompanyUser = (companyUser, permissions) => {
  if (companyUser) {
    permissions[Permissions.reporting] = true
  }
}

var getViewsForUser = function (companyUser, businessUnits, user) {
  const views = []
  if (companyUser) {
    views.push({
      name: mainViewName,
      type: ContextType.allFeedbacks,
      url: RouteUtil.allFeedbacks()
    })
    if (hasSecondaryInterests(companyUser, user)) {
      views.push({
        name: secondaryViewName,
        type: ContextType.secondaryFeedbacks,
        url: RouteUtil.secondaryFeedbacks()
      })
    }
  } else {
    businessUnits.forEach(businessUnit => views.push({
      type: ContextType.businessUnit,
      id: businessUnit.id,
      name: businessUnit.name ? businessUnit.name : '-',
      url: RouteUtil.businessUnit(businessUnit.id),
      company: businessUnit.company
    }))
  }
  return views;
};

const actionHandlers = {
  [LoginActions.UPDATE_TOKEN]: (state, {token}) => state.set('token', token),
  [LoginActions.UPDATE_USER_INFO]: (state, {userInfo}) => {
    const user = fromJS(userInfo)

    const isAdmin = isAdminUser(user)
    const authorizedCompanies = isAdmin ? Object.values(Company) : companies(user)
    const storeUser = authorizedCompanies.size == 0

    const shopKeeperBusinessUnits = businessUnitIdsWhereShopkeeper(user)
    const handlerBusinessUnits = businessUnitIdsWhereHandler(user)

    const permissions = {}
    const businessUnits = storeUser ? [...shopKeeperBusinessUnits, ...handlerBusinessUnits] : []

    const views = getViewsForUser(!storeUser, businessUnits, user)
    const selectedBusinessUnitCode = storeUser ? views[0].id : undefined
    const selectedBusinessUnitCompany = storeUser ? views[0].company : undefined
    allowReportingForCompanyUser(!storeUser, permissions)
    allowReportingForShopkeepers(shopKeeperBusinessUnits, permissions)
    const showResponseTemplates = isAdmin || isCompanyResponsible(user)

    return state.set('userInfo', user)
      .set('businessUnits', fromJS(businessUnits.map(businessUnit => businessUnit.id)))
      .set('permissions', fromJS(permissions))
      .set('admin', isAdmin)
      .set('authorizedCompanies', fromJS(authorizedCompanies))
      .set('readOnly', isReadOnly(user))
      .set('storeUser', storeUser)
      .set('keskoStoreUser', isCompanyStoreUser(storeUser, businessUnits, Company.KESKO))
      .set('kautoStoreUser', isCompanyStoreUser(storeUser, businessUnits, Company.KAUTO))
      .set('views', fromJS(views))
      .set('selectedBusinessUnitCode', selectedBusinessUnitCode)
      .set('selectedBusinessUnitCompany', selectedBusinessUnitCompany)
      .set('maintenanceMessage', user.get('maintenanceMessage'))
      .set('showResponseTemplates', showResponseTemplates)
  },

  [LoginActions.WHOAMI_FAILED]: () => initialState,
  [LoginActions.AUTH_FAILED]: () => initialState,
  [LoginActions.LOGOUT]: () => initialState,
  [LoginActions.CHANGE_BUSINESS_UNIT]: (state, {businessUnitCode}) => state.set('selectedBusinessUnitCode', businessUnitCode),
  [LoginActions.AAD_LOGIN_IN_PROGRESS]: (state, {isInProgress}) => state.set('aadLoginInProgress', isInProgress),
  [LoginActions.ERROR]: (state, {errorCode}) => state.set('loginError', errorCode),
  [LoginActions.SUCCESS]: (state) => state.delete('loginError'),
  [ClientActions.OBJECTED_ERROR]: (state) => state.set('objectedError', true),
  [ClientActions.ERROR]: (state) => state.set('errorState', true),
  [ClientActions.DISMISS_ERROR]: (state) => state.set('errorState', false)
};

export const reducer = createReducer(initialState, actionHandlers)
