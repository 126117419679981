import 'core-js/fn/object/assign';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from 'stores';

import { hashHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import { AppContainer } from 'react-hot-loader';

import { USER_LANG, changeLocale } from 'actions/I18nActions'
import Root from './containers/Root';
import {registerServiceWorker} from './pushNotifications'

import 'react-image-lightbox-with-rotate/style.css';

// Webpack magic to include styles
require('normalize.css/normalize.css');

// Create Redux store
const store = configureStore();

// Initialize the web fonts
const WebFont = require('webfontloader');
WebFont.load({
  custom: {
    families: ['Skatta Sans'],
    urls: [require('!!file-loader?name=styles/fonts.[hash].css!./styles/fonts.css')]
  }
});

// Create the redux history
const history = syncHistoryWithStore(
  hashHistory,
  store,
  {
    selectLocationState: state => state.get('routing')
  }
)

// redux-form adds lots of props that are not recognized by ReactDOMUnknownPropertyDevtool,
// which causes flood of warnings when it is enabled - so remove it for now :(
// caused by: https://github.com/facebook/react/pull/6800
const ReactDOMDebugTool = require('react/lib/ReactDOMDebugTool')
const ReactDOMUnknownPropertyDevtool = require('react/lib/ReactDOMUnknownPropertyDevtool')
ReactDOMDebugTool.removeDevtool(ReactDOMUnknownPropertyDevtool)

// Render the main component into the dom
ReactDOM.render(
  <AppContainer>
    <Root history={history} store={store} />
  </AppContainer>,
  document.getElementById('app'),
  () => {
    if (window.localStorage && window.localStorage.getItem(USER_LANG)) {
      store.dispatch(changeLocale(window.localStorage.getItem(USER_LANG)))
    }
  }
);

registerServiceWorker();
