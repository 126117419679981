import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ChangeContactView } from 'components/Feedback'
import { relativizeTarget } from 'components/Routing/RoutingUtils'
import { changeFeedbackContact } from 'actions/FeedbackActions'
import { reformatPhoneNumber } from '../utils/Helpers';

class ChangeContactRoute extends Component {
  static contextTypes = {
    currentPath: PropTypes.string,
    router: PropTypes.any
  };

  navigateToFeedbackView = () => {
    this.context.router.push(relativizeTarget(this.context.currentPath, '..'))
  };

  changeContact = (fields) => {
    const {name, phone, email} = fields
    return this.props.actions.changeContact(name, phone, email).then(this.navigateToFeedbackView)
  };

  render() {
    return (
      <ChangeContactView
        {...this.props}
        changeContact={this.changeContact}
        cancel={this.navigateToFeedbackView}
      />
    )
  }
}

export default connect(
  (state, props) => ({
    feedbackId: parseInt(props.params.feedbackId)
  }),
  (dispatch, props) => ({
    actions: bindActionCreators({
      changeContact: (name, phone, email) =>
        changeFeedbackContact(props.feedbackId, name, reformatPhoneNumber(phone), email)
    }, dispatch)
  })
)
(ChangeContactRoute)
