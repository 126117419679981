import React, { Component, PropTypes } from 'react';
import { TextProvider } from 'containers/Text';
import Routes from 'routes';
import DevTools from 'containers/DevTools';
import { Provider } from 'react-redux';

import { hot } from 'react-hot-loader';


class Root extends Component {
  render() {
    return (
      <Provider store={this.props.store}>
        <TextProvider>
          <div>
            <DevTools />
            <Routes history={this.props.history} />
          </div>
        </TextProvider>
      </Provider>
    )
  }
}

Root.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default hot(module)(Root);