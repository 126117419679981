
const ReceiverType = {
  kesko: 'kesko',
  krauta: 'krauta',
  kplussa: 'kplussa',
  intersport: 'intersport',
  budgetsport: 'budgetsport',
  kauto: 'kauto',
  onninen: 'onninen',
  chainAndStore: 'chainAndStore',
  chain: 'chain',
  store: 'store',
  subject: 'subject'
}

export default ReceiverType
