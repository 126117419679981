import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'
import { Map } from 'immutable'

import { TranslateProps } from 'containers/Text'
import AddErrorDisplay from 'components/Forms/common/AddErrorDisplay'
import { find, defined } from 'utils'
import {defaultOptionMapper } from '../../../utils/OptionMapper'

import { getText } from 'containers/Text/utils'

/**
 * The values in options and value props should be
 * mappable by optionMapper to a shape like:
 *
 <pre>{
  id: string, // The key for the options, should be unique and defined
  label: string, // the displayed label for the option
  value: any // The value for redux-form, e.g., the original object
}</pre>
 *
 * If a translated option is wished, instead of returning the label
 * attribute, return a 'k' attribute like this:
 <pre>{
  id: string, // The key for the options, should be unique and defined
  k: string/array, // the displayed label for the option
  value: any // The value for redux-form, e.g., the original object
}</pre>
 *
 * The k attribute will be translated to label
 */

const verifyMappedOption = option => {
  defined(option, 'option')
  defined(option.id, 'id of option ' + JSON.stringify(option))
  defined(option.label, 'label of option ' + JSON.stringify(option))
  defined(option.value, 'value of option ' + JSON.stringify(option))
  return option
}

class Selection extends Component {
  static propTypes = {
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    optionMapper: PropTypes.func.isRequired,
    options: PropTypes.array,
    value: PropTypes.any,
    notSelectedLabel: PropTypes.string.isRequired,
    showNotSelected: PropTypes.bool.isRequired
  };
  static defaultProps = {
    showNotSelected: true,
    optionMapper: defaultOptionMapper
  };
  static contextTypes = {
    translations: PropTypes.instanceOf(Map)
  };

  parse = (event) => {
    const selectedId = event.target.value;
    const { optionMapper, options } = this.props
    const mappedOptions = options.map(optionMapper).map(this.translateOption).map(verifyMappedOption)
    return find(mappedOptions, o => o.id === selectedId)
  };

  callParsed = (fn) => event => fn(this.parse(event));

  translateOption = option => {
    if (option.k) {
      option.label = getText(this.context.translations, option.k)
    }
    return option
  };

  render() {
    const { value, options, optionMapper, notSelectedLabel, showNotSelected, onBlur, onChange, className, ...rest } = this.props
    const selectable = options.map(optionMapper).map(this.translateOption).map(verifyMappedOption)
    const selected = value ? optionMapper(value).id : null
    const classes = classNames(
      className,
      'field fill'
    )
    return (
      <select
        onBlur={this.callParsed(onBlur)}
        onChange={this.callParsed(onChange)}
        value={selected}
        className={classes}
        {...rest}>
        {(showNotSelected || !value) && <option key={null} value={null}>{notSelectedLabel}</option>}
        {selectable.map(option => (
          <option key={option.id} value={option.id}>{option.label}</option>
        ))}
      </select>
    )
  }
}

const EnhancedComponent = AddErrorDisplay(TranslateProps(Selection))

// Add default translated "no selection", can be overridden by props
EnhancedComponent.defaultProps = {
  translate: {
    notSelectedLabel: 'defaults.select.noSelection'
  }
}
export default EnhancedComponent
