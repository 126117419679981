import React, {Component, PropTypes} from 'react'

import { Text } from 'containers/Text'
import ResponseTemplateForm from './ResponseTemplateForm'
import { templateMapper } from 'utils/OptionMapper'
import Selection from 'components/Forms/common/Selection'
import LabeledInput from 'components/Forms/LabeledInput'
import { Col } from 'components/grid'
import { reduxForm } from 'redux-form'
import { List } from 'immutable'

class ResponseTemplateView extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      saveResponseTemplate: PropTypes.func.isRequired
    }).isRequired,
    responseTemplates: PropTypes.instanceOf(List)
  };

  static defaultProps = {
    actions: {
      saveResponseTemplate: () => {}
    }
  }

  render() {
    const { responseTemplates, submitting, fields: { selectedTemplate }, actions: { saveResponseTemplate } } = this.props;

    return (
      <div className="store-receiver-settings-view">
        <h2>
          <Text k="settings.responseTemplates.title" />
        </h2>
        <Col className="mb2">
          <Text k="settings.responseTemplates.description" />
        </Col>
        <Col>
          <LabeledInput labelKey="settings.responseTemplates.select" className="pb2">
            <Selection
              value=""
              disabled={submitting}
              showNotSelected={false}
              options={responseTemplates.toJS()}
              optionMapper={templateMapper}
              {...selectedTemplate}
          />
          </LabeledInput>
        </Col>
        {!!selectedTemplate.value && (
          <ResponseTemplateForm
            saveResponseTemplate={saveResponseTemplate}
            templateId={selectedTemplate.value.id}
            templateCategories={selectedTemplate.value.categories}
            initialValues={{
              name: selectedTemplate.value.name,
              template: selectedTemplate.value.template
            }}
          />
        )}
      </div>
    )
  }
}

export default reduxForm({
  form: 'selectedResponseTemplate',
  fields: [
    'selectedTemplate'
  ],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS()
})(ResponseTemplateView);
