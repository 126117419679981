import React, { Component, PropTypes } from 'react';
import { List } from 'immutable'

import { Col } from 'components/grid'
import FeedbackCommentForm from './FeedbackCommentForm'
import { Navigator } from 'components/Menu'

import { Feedback } from 'domain'
import FeedbackCollapsingSections from '../FeedbackCollapsingSections';

class FeedbackCommentView extends Component {

  render() {
    const {feedback, attachments, cancel, saveComment} = this.props
    const triggerSaveComment = ({comment, attachmentIds}) => saveComment(comment, attachmentIds)
    return (
      <section className="feedback-comment-view">
        <Navigator backUrl=".." />
        <Col>
          <hr />
        </Col>
        <FeedbackCollapsingSections
          feedback={feedback}
        />
        <Col>
          <hr />
        </Col>
        <FeedbackCommentForm
          attachments={attachments}
          saveComment={triggerSaveComment}
          onCancel={cancel}
          actions={this.props.actions}
          localStorageKey={`${feedback.id}-comment`}
        />
      </section>
    )
  }
}

FeedbackCommentView.propTypes = {
  feedback: PropTypes.instanceOf(Feedback),
  attachments: PropTypes.instanceOf(List),
  saveComment: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    uploadAttachment: PropTypes.func.isRequired,
    addAttachment: PropTypes.func.isRequired,
    updateAttachment: PropTypes.func.isRequired,
    removeAttachment: PropTypes.func.isRequired
  }).isRequired
}

export default FeedbackCommentView
