import React, { Component, PropTypes } from 'react'

import { Icon, KeskoIcons } from 'components/Icons'
import { Collapse } from 'containers/Layout'

import QuickSearchForm from './QuickSearchForm'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


class Header extends Component {
  constructor(props) {
    super(props);

    this.sidebarContent = null;
    this.openMenu = this.openMenu.bind(this);
  }

  static propTypes = {
    openMenu: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    toHomePage: PropTypes.func.isRequired
  };

  bindCollapse = collapse => this.searchBox = collapse;
  bindSearchForm = searchForm => this.searchForm = searchForm;

  toggleSearch = event => {
    this.searchBox.toggle()
    event.stopPropagation()
    event.preventDefault()
  };

  doSearch = query => this.props.search(query).then(() => this.searchBox.close());

  componentDidMount() {
    this.sidebarContent = document.querySelector('.sidebar-content');
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  openMenu() {
    disableBodyScroll(this.sidebarContent);
    this.props.openMenu();
  }

  render() {
    const {toHomePage} = this.props;

    return (
      <div className="header">
        <div className="icons-row">
          <div className="open-menu-button " onClick={this.openMenu}>
            <Icon icon={KeskoIcons.menu} size={Icon.sizes.unset} />
          </div>
          <div>
            <Icon icon={KeskoIcons.keskoLogo} size={Icon.sizes.unset} className="kesko-logo" onClick={toHomePage} />
          </div>
          <div className="toggle-search-button" onClick={this.toggleSearch}>
            <Icon icon={KeskoIcons.spyglass} size={Icon.sizes.unset} />
          </div>
        </div>
        <div>
          <Collapse collapseId="header-search" ref={this.bindCollapse} initiallyVisible={false}>
            <QuickSearchForm
              ref={this.bindSearchForm}
              onSubmit={this.doSearch}
              autoFocusInput={true}
              className="border-bottom clearfix"
            />
          </Collapse>
        </div>
      </div>
    )
  }
}

export default Header
