import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Col } from 'components/grid'
import { Selection } from 'components/Forms/common'

class FeedbackCategorySelection extends Component {

  render() {
    const { translations, category, notSelectedLabel } = this.props;
    const categories = translations.get('feedback').get('category');
    return (
      <Col className="clearfix mb1 fill">
        <Selection {...category}
                   options={Array.from(categories.keys())}
                   optionMapper={categoryMapper}
                   showNotSelected={true}
                   translate={{notSelectedLabel}}/>
      </Col>
    );
  }
}

const categoryMapper = option => ({
  k: ['feedback', 'category', option],
  id: option,
  value: option
})

const mapStateToProps = state => ({
  translations: state.getIn(['i18n', 'translations', state.getIn(['i18n', 'locale'])])
})

export default connect(mapStateToProps)(FeedbackCategorySelection)
