import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form'

import { Col } from 'components/grid'
import { TextArea, LabeledInput } from 'components/Forms'
import SaveToLocalStorage from 'components/Forms/SaveToLocalStorage'

import { Text } from 'containers/Text'
import { ErrorBlock } from 'components/Forms'
import { createValidator, required, maxLength } from 'utils/Validation'

import FacebookReplyNote from './FacebookReplyNote'
import Button from '../../kesko/common/Button';

const SavingTextArea = SaveToLocalStorage(TextArea)

class FacebookReplyForm extends Component {

  static propTypes = {
    localStorageKey: PropTypes.any.isRequired
  }

  static defaultProps = {
    submitAndDone: () => {
    },
    submitAndWaiting: () => {
    },
    submitAndSaveDraft: () => {
    }
  }

  bindTextArea = (area) => {
    this._savingTextArea = area
  };

  forgetStoredCommentIfSuccessful = (cb) => {
    return function (event) {
      const promise = cb(event)
      promise.then(this._savingTextArea.clear)
      return promise
    }.bind(this)
  }

  render() {
    const {localStorageKey, fields, fields: {message}, submitting, handleSubmit, onCancel, error} = this.props

    return (
      <div className="feedback-reply-form">
        <h2>
          <Text k="feedback.reply.replyViaFacebook" />
        </h2>

        <Col className="clearfix mb2">
          <FacebookReplyNote />
        </Col>

        <Col className="clearfix">
          <ErrorBlock fields={fields}
                      submitError={error}
                      translatePrefix="feedback.reply.fields"
                      className="mb2"
          />
        </Col>

        <form>
          <Col className="mb2">
            <LabeledInput labelKey={'feedback.reply.fields.message.placeholder'}>
              <SavingTextArea
                ref={this.bindTextArea}
                rows="10"
                disabled={submitting}
                localStorageKey={localStorageKey}
                {...message}
              />
            </LabeledInput>
          </Col>

          <div className="reply-buttons">
            <Button
              onClick={onCancel}
              disabled={submitting}
              translation="feedback.reply.cancel"
              theme={Button.theme.cancel}
            />
            <Button
              onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(this.props.submitAndSaveDraft))}
              disabled={submitting}
              translation="feedback.reply.saveDraft"
              theme={Button.theme.state_pending}
            />
            <Button
              onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(this.props.submitAndWaiting))}
              disabled={submitting}
              translation="feedback.reply.saveAndWaiting"
              theme={Button.theme.state_pending}
            />
            <Button
              onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(this.props.submitAndDone))}
              disabled={submitting}
              translation="feedback.reply.saveAndDone"
              theme={Button.theme.state_complete}
            />
          </div>

        </form>
      </div>
    )
  }
}

const ReduxFacebookReplyForm = reduxForm({
  form: 'replyViaFacebook',
  fields: ['message'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    message: [required(), maxLength(50000)]
  })
})(FacebookReplyForm)

export default ReduxFacebookReplyForm
