import React, {Component, PropTypes} from 'react'
import {reduxForm} from 'redux-form'

import {Col} from 'components/grid'
import {Attachments} from 'components/Attachments'
import {ErrorBlock, LabeledInput, ResponseTemplateSelection, SaveToLocalStorage, TextArea} from 'components/Forms'
import {overrideDragAndDropEvents} from 'components/Forms/common/FormUtils'
import {Text, TranslateChildrenProps} from 'containers/Text'
import {Confirmation} from 'containers/Dialog'
import {createValidator, maxLength, required} from 'utils/Validation'

import FeedbackReplyNote from './FeedbackReplyNote'
import Button from 'components/kesko/common/Button'
import TextWithIcon from 'components/kesko/TextWithIcon'
import KeskoIcons from 'components/Icons/KeskoIcons'
import {fixLineChangesForReplyMessage} from 'utils/fixLineChanges'
import {hasItems} from 'utils'

const SavingTextArea = SaveToLocalStorage(TextArea)

class FeedbackReplyForm extends Component {

  static propTypes = {
    localStorageKey: PropTypes.any.isRequired,
    initialValues: PropTypes.shape({
      templateIds: PropTypes.array,
      message: PropTypes.string,
      attachmentIds: PropTypes.array
    })
  }

  bindTextArea = (area) => {
    this._savingTextArea = area
  };

  forgetStoredCommentIfSuccessful = (cb) => {
    return function (event) {
      const promise = cb(event)
      promise.then(this._savingTextArea.clear)
      return promise
    }.bind(this)
  }

  onDrop = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  bindClearResponseConfirmation = (dialog) => this._clearResponseConfirmation = dialog;

  openClearResponseConfirmation = () => {
    this._clearResponseConfirmation.open()
  };

  clearResponse = () => {
    const {actions, fields: {message, templateIds}} = this.props
    if (!hasItems(templateIds) && message.initialValue === '') {
      return message.onChange('');
    } else {
      return actions.setFormValues('', [])
    }
  };

  render() {
    const {
      localStorageKey, submitting, handleSubmit, error, attachments, draftAttachments,
      showResponseProposals,
      feedback: {allResponseTemplates, greeting, signature},
      fields: {
        message, templateIds, attachmentIds
      },
      actions: {
        cancel, sendReplyAndMarkDone, sendReplyAndMarkWaiting, saveDraft, setFormValues,
        uploadAttachment, addAttachment, updateAttachment, removeDraftAttachment, removeAttachment
      }
    } = this.props;

    const errorFields = {message}

    const possibleTemplates = !!allResponseTemplates ? allResponseTemplates.toJSON() : [];

    const messageLabelKey = hasItems(templateIds)
        ? 'feedback.reply.fields.message.label'
        : 'feedback.reply.fields.message.placeholder';

    const onTemplateChange = (chosenTemplates) => {
      if (hasItems(chosenTemplates)) {
        const templates = possibleTemplates.filter(pt => chosenTemplates.find(ct => ct.id === pt.id));
        const newReply = fixLineChangesForReplyMessage(greeting, templates, signature);
        return setFormValues(newReply, chosenTemplates.map(t => t.id));
      }
      return this.clearResponse();
    };

    const disableTemplateSelection = submitting || (message.value !== message.initialValue && message.value.trim() !== '');

    return (
      <div className="feedback-reply-form">
        <h2 className="blue">
          <Text k="feedback.reply.replyWithEmail"/>
        </h2>

        <Col className="clearfix mb2">
          <FeedbackReplyNote showResponseProposalNote={showResponseProposals}/>
        </Col>

        <Col className="clearfix">
          <ErrorBlock
            fields={errorFields}
            submitError={error}
            translatePrefix="feedback.reply.fields"
            className="mb2"
          />
        </Col>

        <form>
          <ResponseTemplateSelection
            disabled={disableTemplateSelection}
            templateIds={templateIds}
            possibleTemplates={possibleTemplates}
            onTemplateChange={onTemplateChange}
          />

          <Col className="mb2">
            <LabeledInput labelKey={messageLabelKey}>
              <SavingTextArea
                ref={this.bindTextArea}
                rows="10"
                resizable={true}
                disabled={submitting}
                onDrop={this.onDrop}
                localStorageKey={localStorageKey}
                {...overrideDragAndDropEvents(message)}
              />
            </LabeledInput>

            <TextWithIcon
              className="template-clear"
              onClick={this.openClearResponseConfirmation}
              translation="feedback.reply.fields.template.clear"
              icon={KeskoIcons.trash}
            />

            <Attachments
              attachments={attachments}
              draftAttachments={draftAttachments}
              attachmentIds={attachmentIds}
              translatePrefix="feedback.reply"
              actions={{uploadAttachment, addAttachment, updateAttachment, removeAttachment, removeDraftAttachment}}
            />
          </Col>

          <Col>
            <hr />
          </Col>

          <div className="reply-buttons">
            <Button
              onClick={cancel}
              disabled={submitting}
              translation="feedback.reply.cancel"
              theme={Button.theme.cancel}
            />
            <Button
              onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(saveDraft))}
              disabled={submitting}
              translation="feedback.reply.saveDraft"
              theme={Button.theme.state_pending}
            />
            <Button
              onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(sendReplyAndMarkWaiting))}
              disabled={submitting}
              translation="feedback.reply.saveAndWaiting"
              theme={Button.theme.state_pending}
            />
            <Button
              onClick={handleSubmit(this.forgetStoredCommentIfSuccessful(sendReplyAndMarkDone))}
              disabled={submitting}
              translation="feedback.reply.saveAndDone"
              theme={Button.theme.state_complete}
            />
          </div>

        </form>
        <TranslateChildrenProps>
          <Confirmation
            translate={{
              title: 'feedback.reply.confirmClearResponseTitle',
              body: 'feedback.reply.confirmClearResponseBody',
              yesText: 'yes',
              noText: 'no'
            }}
            ref={this.bindClearResponseConfirmation}
            onConfirm={this.clearResponse}
          />
        </TranslateChildrenProps>
      </div>
    )
  }
}

const ReduxFeedbackReplyForm = reduxForm({
  form: 'replyToFeedback',
  fields: ['message', 'templateIds', 'attachmentIds[]'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    message: [required(), maxLength(50000)]
  })
})(FeedbackReplyForm)

export default ReduxFeedbackReplyForm
