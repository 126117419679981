module.exports = {
  state: {
    NEW: 'Uusi',
    WAITING: 'Kesken',
    DONE: 'Käsitelty'
  },
  formButtons: {
    save: 'Tallenna',
    cancel: 'Peruuta'
  },
  proposal: {
    template: {
      label: 'Vastausehdotukset',
      note: 'Huom! Vastausapulainen ei ole erehtymätön, joten muista tarkistaa vastausehdotus sekä korjata sen mahdolliset puutteet. Voit myös valita alla olevasta pudotusvalikosta jonkun muun vastauspohjan. Vastaukseen lisätään automaattisesti viestisi jälkeen logo ja linkki, jolla asiakas voi tarvittaessa jatkaa asian käsittelyä.'
    },
    nameSuffix: ' (Vastausapulaisen ehdotus)'
  },
  feedback: {
    responseNeeded: 'Asiakas odottaa vastausta', // !DONE and requiresResponse
    responseSent: 'Asiakkaalle vastattu', // DONE and answered
    responseNotSent: 'Asiakkaalle ei vastattu', // DONE, but not answered
    messageSent: 'Asiakkaalle on lähetetty viesti',
    messageReceived: 'Asiakas on lähettänyt viestin',
    transferred: 'Siirretty muualle',
    handler: 'Käsittelijä',
    context: {
      chain: 'Ketju: ',
      subject: 'Aihe: ',
      allFeedbacks: 'Omat kiinnostuksen kohteet',
      secondaryFeedbacks: 'Toissijaiset kiinnostuksen kohteet',
      businessUnit: ''
    },
    transcript: {
      success: 'Konekäännetty ääniviesti',
      pending: 'Hitsit, koneen antama tekstikäännös ei ole vielä valmis. Kuuntele ääniviesti alta.',
      failedError: 'Oho, konekääntäminen epäonnistui! Kuuntele ääniviesti alta.',
      failedEmpty: 'Oho, koneen mielestä ääniviesti ei sisällä puhetta. Kuuntele ääniviesti alta.',
      failedQuality: 'Oho, konekäännöksen laatu ei täyttänyt asetettuja vaatimuksia. Kuuntele ääniviesti alta.'
    },
    customer: {
      removed: 'Asiakkaan tiedot on poistettu',
      anonymous: 'Nimetön palaute',
      name: 'Asiakas',
      companyName: 'Yritys',
      otherCustomerId: 'Muu tarkentava tieto',
      phone: 'Puhelin',
      email: 'Sähköposti',
      streetAddress: 'Katuosoite',
      postalCode: 'Postinumero',
      postName: 'Postitoimipaikka',
      preferredContactChannel: 'Toivottu yhteydenottotapa',
      channels: {
        phone: 'Puhelin',
        email: 'Sähköposti',
        uberall: 'Uberall',
        facebook: 'Facebook yksityisviesti'
      },
      info: 'Yhteystiedot'
    },
    state: 'Tila',
    methodOfContact: 'Yhteydenottotapa',
    messageHistory: 'Viestihistoria',
    messages: 'Asiakasviestit',
    images: 'Kuvaliitteet',
    audios: 'Ääniviestit',
    files: 'Muut liitteet',
    virusScanningStatus: {
      ok: '',
      notNeeded: '',
      fail: 'tiedosto ei läpäissyt virusskannausta tai se ei ole tuettua tyyppiä',
      pending: 'virusskannaus kesken',
      notUploaded: 'virusskannaus kesken'
    },
    meta: {
      id: 'Tunniste',
      category: {
        title: 'Kategoria'
      },
      receiverName: 'Vastaanottaja',
      facebookReceiverName: {
        title: 'Facebook-sivu'
      },
      ambiance: {
        title: 'Palautteen kokonaissävy',
        HIGHLY_POSITIVE: 'Erittäin positiivinen',
        POSITIVE: 'Positiivinen',
        NEUTRAL: 'Neutraali',
        NEGATIVE: 'Negatiivinen',
        HIGHLY_NEGATIVE: 'Erittäin negatiivinen'
      },
      topicsPos: 'Positiiviset puheenaiheet',
      topicsNeu: 'Neutraalit puheenaiheet',
      topicsNeg: 'Negatiiviset puheenaiheet',
      area: {
        title: 'Ketjun alue'
      },
      channel: {
        title: 'Palautteen lähde'
      },
      purchaseDate: {
        title: 'Kaupassa asiointipäivä'
      },
      deviceModel: {
        title: 'Käytetty laite'
      },
      applicationVersion: {
        title: 'Sovellusversio'
      },
      plussaCardNumber: {
        title: 'Plussa-kortin numero'
      },
      tempPlussaCardNumber: {
        title: 'Väliaikaisen Plussa-kortin numero'
      },
      plussaPersonId: {
        title: 'Plussa-asiakasnumero'
      },
      b2cAccountId: {
        title: 'K-Tunnus ID'
      },
      nps: {
        title: 'Asiakaspulssin arvosana'
      },
      rating: {
        title: 'Arviointi'
      },
      orderNumber: {
        title: 'Tilausnumero'
      },
      originalBusinessUnit: {
        title: 'Kauppa'
      },
      orderExperienceSentiment: {
        title: 'Tilauskokemuksen sävy'
      },
      carRegistrationNumber: {
        title: 'Rekisterinumero'
      },
      carBrand: {
        title: 'Brändi'
      },
      clientBrowser: {
        title: 'Selain'
      },
      clientLocation: {
        title: 'Sivu jolta palaute lähetettiin'
      },
      clientReferrer: {
        title: 'Sivu jolta palautteenantoon tultiin'
      },
      clientScreenSize: {
        title: 'Näytön koko (leveys x korkeus)'
      },
      clientWindowSize: {
        title: 'Ikkunan koko (leveys x korkeus)'
      },
      handlingTime: {
        title: 'Käsittelyaika'
      },
      reportAmbianceError: {
        link: 'Ilmoita palautteen virheellisestä sävystä',
        reported: 'Ilmoitus palautteen virheellisestä sävystä tehty.',
        confirmTitle: 'Ilmoitus virheellisestä sävystä',
        confirmBody: 'Palautteen kokonaissävy muodostuu automaattisesti palautteen sanallisen sisällön perusteella. Asiakaspulssin arvosana (0-10) ei vaikuta analyysiin. Kokonaissävyn automaattinen luokittelu osuu oikeaan n. 85%:ssa tapauksista.\n\nOnko palautteen kokonaissävy väärin?'
      },
      reportTopicError: {
        link: 'Ilmoita palautteen virheellisestä puheenaiheesta',
        reported: 'Ilmoitus palautteen virheellisestä puheenaiheesta tehty.',
        confirmTitle: 'Ilmoitus virheellisestä puheenaiheesta',
        confirmBody: 'Palautteen puheenaiheet muodostetaan automaattisesti palautteen sanallisen sisällön perusteella. Vaikka analyysin luotettavuus on n. 95%, mukaan voi eksyä virheitä.\n\nOvatko palautteen puheenaiheet väärin?'
      },
      reportThreat: {
        link: 'Ilmoita palautteen uhkaavasta sisällöstä',
        reported: 'Ilmoitus uhkaavasta sisällöstä tehty.',
        confirmTitle: 'Ilmoitus uhkaavasta sisällöstä',
        confirmBody: 'Onko palautteen sisältö uhkaava?'
      }
    },
    events: 'Tapahtumaloki',
    event: {
      response_attachment_scan_failed: 'Liitteen lisääminen vastaukseen epäonnistui, vastausta ei lähetetty asiakkaalle',
      feedback_received: 'Palaute vastaanotettu: {target}',
      feedback_opened: 'Palaute avattu',
      etuma_analysis_ready: 'Palaute analysoitu Lumoassa',
      feedback_ready: 'Palaute valmis käsiteltäväksi',
      email_response_to_customer: 'Asiakkaalle vastattu osoitteeseen {target}',
      phone_response_to_customer: 'Asiakkaalle vastattu numeroon {target}',
      uberall_response_to_customer: 'Asiakkaalle on vastattu Uberall-viestillä Hymyssä',
      uberall_response_to_customer_ua: 'Asiakkaalle on vastattu Uberall-viestillä Uberallissa',
      facebook_response_to_customer_fb: 'Asiakkaalle vastattu Facebook-yksityisviestillä Facebookissa',
      facebook_response_to_customer_hymy: 'Asiakkaalle vastattu Facebook-yksityisviestillä Hymyssä',
      follow_up_received: 'Jatkoviesti vastaanotettu',
      follow_up_received_feedback_reopened: 'Jatkoviesti vastaanotettu, palaute avattu uudelleen',
      comment_added: 'Sisäinen kommentti lisätty',
      comment_removed: 'Sisäinen kommentti poistettu',
      marked_as_handled: 'Palaute merkitty käsitellyksi',
      marked_as_handled_automatically: 'Palaute merkitty käsitellyksi automaattisesti',
      feedback_reopened: 'Palaute avattu uudelleen',
      feedback_reassigned: 'Palautteen käsittelijä vaihdettu: {target}',
      change_receiver: 'Palaute siirretty: {target}',
      comment_request_sent: 'Kommenttipyyntö lähetetty: {target}',
      comment_response_received: 'Vastaus kommenttipyyntöön vastaanotettu',
      transfer_feedback: 'Palaute siirretty käsiteltäväksi: {target}',
      forward_feedback: 'Palaute välitetty tiedoksi: {target}',
      report_analysis_error: 'Virhe Etuma-analyysissä raportoitu',
      report_ambiance_error: 'Virhe Etuma-analyysin sävyssä raportoitu',
      report_topic_error: 'Virhe Etuma-analyysin puheenaiheissa raportoitu',
      change_customer_contact: 'Asiakkaan yhteystietoja muutettu',
      sent_copy_to_customer: 'Kopio viestistä lähetetty asiakkaalle osoitteeseen {target}',
      threat_reported: 'Uhkaavasta palautteesta ilmoitettu',
      facebook_connection_disabled: 'Facebook-linkitys sivulle {target} katkaistu',
      facebook_connection_enabled: 'Facebook-linkitys sivulle {target} palautettu',
      facebook_responding_disabled: 'Palautteeseen vastaaminen estetty, uusi palaute: {target}',
      error: 'Virhe viestin lähetyksessä: {target}',
      error_feedback_reopened: 'Virhe viestin lähetyksessä, palaute avattu uudelleen: {target}',
      response_draft_saved: 'Vastausluonnos talletettu',
      customer_info_removed: 'Asiakkaan yhteystiedot poistettu',
      customer_info_usage_limited: 'Asiakkaan tietojen käyttöä rajoitettu',
      customer_info_usage_objected: 'Asiakkaan tietojen käyttöä vastustettu',
      hymy_user_info_removed: 'Hymy-käyttäjien tiedot poistettu',
      feedback_archived: 'Palaute arkistoitu',
      audio_transcribed: 'Ääniviesti muunnettu tekstiksi',
      audio_transcribed_failed: 'Ääniviestin muuntaminen tekstiksi epäonnistui',
      response_proposal_added: 'Palautteelle on saatu vastausehdotus vastausapulaiselta',
      attachments_removed: 'Palautteen liitetiedostot poistettu',
      surveypal_invitation_scheduled: 'Surveypal kutsu ajastettu: {target}',
      surveypal_invitation_sent: 'Surveypal kutsu lähetetty: {target}',
      surveypal_invitation_skipped: 'Surveypal kutsua ei lähetetty',
      email_notifications_sent: 'Sähköposti-ilmoitukset lähetetty käsittelijöille',
      push_message_notifications_sent: 'Push message -ilmoitukset lähetetty käsittelijöille',
      elisa_desk_notification_sent: 'Ilmoitus lähetetty Elisa Deskiin'
    },
    transfer: {
      link: 'Siirrä muualle käsittelyyn',
      title: 'Siirrä muualle käsittelyyn',
      fields: {
        target: {
          none: 'Valitse kohde *',
          required: 'Valitse kohde'
        },
        commentText: {
          placeholder: 'Kommentti *',
          required: 'Syötä kommentti'
        },
        targetType: {
          required: 'Valitse kohde *',
          label: 'Kohde *',
          store: 'Kauppa',
          chain: 'Ketju',
          other: 'Muu',
          email: 'Sähköpostiosoite',
          subject: 'Aihe',
          emailList: 'Aiemmin käytetty sähköpostiosoite'
        },
        targetOther: {
          required: 'Valitse kohde *'
        },
        targetStore: {
          required: 'Valitse kauppa *'
        },
        targetChain: {
          required: 'Valitse ketju *'
        },
        targetEmail: {
          placeholder: 'Syötä tai valitse sähköpostiosoite *',
          email: 'Tarkista sähköpostiosoite',
          required: 'Syötä sähköpostiosoite'
        },
        targetEmailList: {
          required: 'Valitse sähköpostiosoite *'
        },
        targetSubject: {
          required: 'Valitse aihe *'
        },
        targetHandler: {
          notSelected: 'Ei käsittelijää'
        },
        language: 'Sähköpostiviestin kieli'
      },
      save: 'Siirrä',
      cancel: 'Peruuta',
      saveFailed: 'Palautteen siirto epäonnistui',
      note: 'Huomioi, että palautteen lisäksi viestissä välitetään myös asiakkaan yhteystiedot ja linkki mahdollisiin liitteisiin. Älä siirrä K-ryhmän ulkopuolelle palautetta, joka sisältää henkilötietoja! Jos asiakkaan tietoja pitää siirtää, pyydä ensin asiakkaalta lupa. Jos haluat, että sinuun ollaan yhteydessä asian etenemisestä, pyydä tätä erikseen kommentissa. Lisää tarvittaessa myös omat yhteystietosi.'
    },
    comments: {
      title: 'Sisäiset kommentit',
      note: 'Kommentit ovat vain sisäiseen käyttöön eivätkä näy asiakkaalle',
      goToCommenting: 'Kommentoi',
      addComment: 'Kommentoi palautetta',
      save: 'Tallenna',
      cancel: 'Peruuta',
      saveFailed: 'Kommentin tallennus epäonnistui',
      commentRequest: 'Kommenttipyyntö',
      commentResponse: 'Vastaus kommenttipyyntöön',
      transferComment: 'Siirretty kohteeseen',
      forwardComment: 'Välitetty osoitteisiin',
      responseComment: 'Puhelu asiakkaalle:',
      addAttachment: 'Lisää liitetiedosto',
      attachmentHint: 'Yhden liitetiedoston suurin sallittu koko on 10 megatavua. Viesti voi sisältää korkeintaan 5 liitetiedostoa.',
      error: {
        genericError: 'Virhe palautteen käsittelyssä',
        commentRequestDeliveryFailed: 'Kommenttipyynnön lähetys epäonnistui',
        responseDeliveryFailed: 'Vastauksen lähetys epäonnistui',
        transferCommentDeliveryFailed: 'Palautteen siirto epäonnistui',
        forwardCommentDeliveryFailed: 'Palautteen välittäminen epäonnistui',
        copyOfFeedbackDeliveryFailed: 'Kopion lähettäminen palautteesta epäonnistui',
        followUpDeliveryFailed: 'Kopion lähettäminen jatkoviestistä epäonnistui',
        assignmentDeliveryFailed: 'Ilmoituksen lähettäminen uudelle käsittelijälle epäonnistui'
      },
      removeComment: {
        link: 'Poista',
        confirmTitle: 'Poista kommentti',
        confirmBody: 'Haluatko varmasti poistaa kommentin?'
      },
      fields: {
        comment: {
          placeholder: 'Kommentti *',
          required: 'Syötä kommentti'
        }
      },
      attachment: {
        format: 'Lisäämäsi liitetiedosto on liian iso. Yhden liitetiedoston suurin sallittu koko on 10 megatavua.',
        uploading: 'Ole hyvä ja odota, että liitetiedostojen lataus on valmis',
        uploadError: 'Lataus epäonnistui',
        max: 'Enintään 5 liitetiedostoa sallittu, ole hyvä ja poista liitetiedostoja.'
      }
    },
    reply: {
      goToReply: 'Vastaa',
      replyWithEmail: 'Vastaa asiakkaalle sähköpostilla',
      note: 'Vastaus lähetetään suoraan asiakkaalle. Vastaukseen lisätään automaattisesti viestisi jälkeen logo ja linkki, jolla asiakas voi tarvittaessa jatkaa asian käsittelyä.',
      replyViaUberall: 'Vastaa asiakkaalle Uberall-viestillä',
      replyViaFacebook: 'Vastaa asiakkaalle Facebook yksityisviestillä',
      noteFacebook: 'Vastaus lähetetään yksityisviestinä suoraan asiakkaalle. Viestiin ei lisätä automaattista tervehdystä tai allekirjoitusta.',
      noteUberall: 'Vastaus lähetetään Uberall-palvelun kautta. Viestiin ei lisätä automaattista tervehdystä tai allekirjoitusta.',
      saveAndDone: 'Lähetä vastaus, käsitelty',
      saveAndWaiting: 'Lähetä vastaus, kesken',
      saveDraft: 'Tallenna luonnos, kesken',
      cancel: 'Peruuta',
      saveFailed: 'Vastauksen lähettäminen epäonnistui',
      saveDraftFailed: 'Luonnoksen tallentaminen epäonnistui',
      confirmClearResponseTitle: 'Tyhjennä vastaus',
      confirmClearResponseBody: 'Haluatko varmasti tyhjentää vastauksen?',
      templates: 'Vastauspohjat',
      fields: {
        message: {
          placeholder: 'Muistathan lisätä vastauksen loppuun nimesi ja kauppasi. *',
          label: 'Vastaus *',
          required: 'Syötä vastaus',
          maxLength: 'Vastauksessa saa olla enintään 50 000 merkkiä'
        },
        template: {
          placeholder: 'Valitse halutessasi vastauspohja',
          clear: 'Tyhjennä vastaus'
        }
      },
      selection: {
        title: 'Vastaa asiakkaalle',
        replyByEmail: 'Sähköpostilla',
        replyByPhone: 'Soittamalla',
        replyViaUberall: 'Uberall-viestillä',
        replyViaFacebook: 'Facebook yksityisviestillä',
        preferredContactMethod: 'Toivottu yhteydenottotapa',
        cancel: 'Peruuta'
      },
      responding: {
        note: 'Huom!',
        startedResponding: 'aloitti vastaamisen'
      },
      attachment: {
        format: 'Lisäämäsi liitetiedosto on liian iso. Yhden liitetiedoston suurin sallittu koko on 10 megatavua.',
        uploading: 'Ole hyvä ja odota, että liitetiedostojen lataus on valmis',
        uploadError: 'Lataus epäonnistui',
        max: 'Enintään 5 liitetiedostoa sallittu, ole hyvä ja poista liitetiedostoja.'
      }
    },
    requestComment: {
      link: 'Pyydä kommentti sähköpostilla',
      title: 'Pyydä kommentti sähköpostilla',
      note: 'Alkuperäinen palaute ei välity kommenttipyynnön mukana. Kopioi se tarvittaessa kommenttipyyntö-kenttään, mutta varmista, että asiakkaan henkilötietoja ei päädy K-ryhmän ulkopuolelle. Jos asiakkaan tietoja pitää siirtää kommenttipyynnön mukana, pyydä ensin asiakkaalta lupa. Lähetetyssä viestissä lukee palautteen tunniste, jonka kautta Hymyn käyttäjä voi hakea palautteen järjestelmästä. Muista lisätä viestiin tarvittaessa oma tervehdys ja allekirjoitus. Syötä vähintään yksi sähköpostiosoite, johon haluat lähettää kommenttipyynnön.',
      fields: {
        emails: {
          placeholder: 'Syötä tai valitse sähköpostiosoite',
          label: 'Sähköpostiosoite *',
          required: 'Syötä vähintään yksi sähköpostiosoite'
        },
        commentText: {
          placeholder: 'Kommenttipyyntö *',
          required: 'Syötä kommenttipyyntö',
          maxLength: 'Kommenttipyynnössä saa olla enintään 50 000 merkkiä'
        }
      },
      saveFailed: 'Kommenttipyynnön lähettäminen epäonnistui',
      emailFormat: 'Tarkista sähköpostiosoite',
      save: 'Lähetä',
      cancel: 'Peruuta',
      language: 'Kommenttipyynnön kieli',
      addReceiver: 'Lisää vastaanottaja'
    },
    list: {
      loadingMore: 'Ladataan palautteita...',
      atBottom: 'Ei enempää palautteita :)'
    },
    markAsDone: {
      link: 'Merkitse käsitellyksi',
      dialog: {
        title: 'Merkitse käsitellyksi',
        body: 'Onko palautteen käsittely valmis?'
      }
    },
    reopen: {
      link: 'Avaa',
      dialog: {
        title: 'Avaa käsitelty palaute',
        body: 'Tarvitaanko palautteeseen vielä toimenpiteitä?'
      }
    },
    changeHandler: {
      link: 'Vaihda käsittelijää',
      title: 'Vaihda käsittelijää',
      none: 'Ei käsittelijää',
      handlerId: {
        required: 'Valitse käsittelijä'
      },
      comment: {
        placeholder: 'Kommentti'
      },
      saveFailed: 'Käsittelijän vaihto epäonnistui'
    },
    filter: {
      NEW: 'Uusi',
      WAITING: 'Kesken',
      requiresResponse: 'Odottaa vastausta',
      myFeedback: 'Omat palautteeni',
      myWaiting: 'Omat kesken'
    },
    forward: {
      link: 'Välitä tiedoksi sähköpostiin',
      title: 'Välitä tiedoksi sähköpostiin',
      save: 'Välitä',
      cancel: 'Peruuta',
      saveFailed: 'Palautteen välitys epäonnistui',
      fields: {
        comment: {
          placeholder: 'Kommentti *',
          required: 'Syötä kommentti'
        },
        emails: {
          placeholder: 'Sähköpostiosoite *',
          required: 'Syötä vähintään yksi sähköpostiosoite'
        },
        language: 'Sähköpostiviestin kieli'
      },
      note: 'Tällä toiminnolla voit välittää tiedoksi palautteen ja siihen tulleet vastaukset. Huomioithan, että asiakkaan tiedot välitetään valitsemiisi osoitteisiin. Syötä vähintään yksi sähköpostiosoite.'
    },
    changeContact: {
      link: 'Muuta asiakkaan yhteystietoja',
      title: 'Asiakkaan yhteystiedot',
      save: 'Tallenna',
      cancel: 'Peruuta',
      saveFailed: 'Yhteystietojen tallennus epäonnistui',
      fields: {
        name: {
          placeholder: 'Nimi',
          required: 'Syötä nimi'
        },
        phone: {
          placeholder: 'Puhelinnumero',
          format: 'Ole hyvä ja tarkista puhelinnumero'
        },
        email: {
          placeholder: 'Sähköpostiosoite',
          email: 'Tarkista sähköpostiosoite'
        }
      },
      noteChanging: 'Muista lähettää asiakkaalle tarvittaessa uusi vastaus korjattuun sähköpostiosoitteeseen.',
      noteAdding: 'Jos lisäät asiakkaan yhteystiedot palautteeseen, muuttuu palaute vastausta vaativaksi palautteeksi ja asiakkaalle tulee vastata Hymyn kautta.'
    },
    replyByPhone: {
      replyByPhone: 'Vastaa asiakkaalle soittamalla',
      recordingNote: 'Kerro asiakkaalle heti puhelun alussa, jos tallennat puhelun!',
      note: 'Soita asiakkaalle käyttäen valitsemaasi laitetta. Kirjoita puhelun sisällöstä kommentti.',
      saveAndDone: 'Soitettu, käsitelty',
      saveAndWaiting: 'Soitettu, kesken',
      cancel: 'Peruuta',
      saveFailed: 'Vastauksen tallennus epäonnistui',
      fields: {
        comment: {
          placeholder: 'Kommentti *',
          required: 'Syötä kommentti',
          maxLength: 'Kommentissa saa olla enintään 50 000 merkkiä'
        }
      }
    },
    adminTools: {
      link: 'Admin-käyttäjän lisätoiminnot',
      title: 'Admin-käyttäjän lisätoiminnot',
      save: 'Hyväksy',
      cancel: 'Peruuta',
      saveFailed: 'Toiminnon suorittaminen epäonnistui',
      usage_limited: {
        label: 'Rajoita asiakastietojen käyttöä',
        note: 'Tietoja säilytetään, mutta niitä ei käsitellä muutoin kuin oikeudellisen vaateen yhteydessä.'
      },
      usage_objected: {
        label: 'Asiakas on vastustanut asiakastietojen käyttöä',
        note: 'Asiakas ei halua, että häntä koskevia tietoja käytetään esimerkiksi suoramarkkinoinnissa, profiloinnissa tai raporteissa.',
        toBeObjected: 'Seuraavien asiakastietojen käyttöä tullaan rajoittamaan:'
      },
      archived: {
        label: 'Arkistoi palaute',
        note: 'Arkistoitu palaute ei näy enää käyttöliittymässä eikä raporteilla.'
      },
      fields: {
        status: {
          placeholder: 'Valitse toiminto *'
        },
        comment: {
          placeholder: 'Kommentti'
        }
      }
    },
    exportMessages: {
      link: 'Lataa viestit'
    },
    category: {
      feedback: 'Palaute',
      productWish: 'Tuotetoive',
      offerRequest: 'Tarjouspyyntö',
      newSales: 'Myynnin palaute',
      afterSales: 'Jälkimarkkinoinnin palaute',
      sales: 'Myynti',
      reclamation: 'Reklamaatio',
      webStoreBusinessUnit:"Verkkokauppapalaute"

    },
    usageObjected: {
      note: 'Asiakas on rajoittanut tietojensa käyttöä. Palautteen välittäminen asiakastietoineen tämän rekisterin ulkopuolelle (esim. tavarantoimittajalle, kauppiaille tai toiselle toimialalle) on kielletty.'
    },
    errors: {
      objectedError: 'Oho, asiakastietojen rajoittaminen ei onnistunut. Tarkistathan, onko samoja asiakastietoja jo rajoitettu ja ota tarvittaessa yhteyttä Hymyn tukeen.'
    }
  },
  back: 'Takaisin',
  loginPage: {
    title: 'Hymy :)',
    login: 'Kirjaudu',
    alternativeLogin: 'Vaihtoehtoinen kirjautumistapa',
    loginFailed: {
      default: 'Käyttäjätunnus tai salasana väärin',
      credentialsNotFound: 'Käyttäjätunnus tai salasana väärin',
      accountFreezed: 'Liian monta epäonnistunutta kirjautumisyritystä. Käyttäjätunnuksesi on lukittu yhden tunnin.',
      missingBusinessUnits: 'Käyttäjätunnukseltasi puuttuu voimassaoleva Hymyn käyttöoikeus. Jos olet ruokakauppias, ole yhteydessä kaupan palvelukeskukseen. Jos olet K-Raudan kauppias tai K-Auton/K-Caaran työntekijä, ota yhteyttä Tiedon asiakastukeen. Jos olet kaupan työntekijä, saat käyttöoikeudet kauppiaalta. K-Citymarketin käyttötavaran työntekijät ja keskolaiset saavat tunnukset oman yksikön assistentilta.',
      missingAuthority: 'Käyttäjätunnukseltasi puuttuu voimassaoleva Hymyn käyttöoikeus. Jos olet ruokakauppias, ole yhteydessä kaupan palvelukeskukseen. Jos olet K-Raudan kauppias tai K-Auton/K-Caaran työntekijä, ota yhteyttä Tiedon asiakastukeen. Jos olet kaupan työntekijä, saat käyttöoikeudet kauppiaalta. K-Citymarketin käyttötavaran työntekijät ja keskolaiset saavat tunnukset oman yksikön assistentilta.',
      authenticationFailed: 'Kirjautuminen epäonnistui'
    },
    username: {
      placeholder: 'Käyttäjätunnus *',
      required: 'Syötä käyttäjätunnus'
    },
    password: {
      placeholder: 'Salasana *',
      required: 'Syötä salasana'
    },
    rememberMe: 'Muista minut'
  },
  customerReply: {
    expired: {
      title: 'Palaute on vanhentunut',
      body: 'Kiitos luottamuksestanne Keskoa kohtaan. Voitte antaa uutta palautetta verkkosivujemme kautta'
    },
    messageTitle: 'Voit jatkaa asian käsittelyä kirjoittamalla viestisi alle',
    previousMessages: 'Asiaan liittyvät aiemmat viestit',
    fields: {
      message: {
        placeholder: 'Viesti *',
        required: 'Syötä viesti'
      }
    },
    thanks: {
      title: 'Anna meille palautetta',
      body: 'Kiitos viestistäsi!'
    },
    send: 'Lähetä',
    sendMeACopy: 'Haluan viestistä kopion sähköpostiini'
  },
  actions: {
    title: 'Muut toiminnot',
    save: 'Tallenna',
    cancel: 'Peruuta'
  },
  sidebar: {
    title: 'Hymy :)',
    reports: 'Raportit',
    logout: 'Kirjaudu ulos',
    search: 'Hae palautteista',
    myProfile: 'Oma profiili',
    feedbacks: 'Palautteet',
    summaryReport: 'Yleiskatsaus (kuvaaja)',
    feedbackListReport: 'Palautteet',
    feedbackMessagesReport: 'Palautteet ja viestiketju',
    receiversReport: 'Yleiskatsaus (taulukko)',
    responseSummaryReport: 'Yleiskatsaus (vastausajat)',
    addNewFeedback: 'Lisää uusi palaute',
    giveFeedback: 'Anna palautetta Hymystä',
    settings: 'Asetukset',
    support: 'Hymyn ohjeet'
  },
  defaults: {
    select: {
      noSelection: 'Ei valintaa'
    }
  },
  search: {
    title: 'Hae palautteita',
    note: 'Tekstihaku etsii hakusanoja asiakasviesteistä ja sisäisistä viesteistä. Tekstihaku löytää myös asiakkaiden nimiä, palautteiden tunnisteita ja puheenaiheita.',
    execute: 'Hae',
    cancel: 'Peruuta',
    backToHome: 'Etusivulle',
    sidebar: {
      search: {
        placeholder: 'Hae...'
      }
    },
    fields: {
      search: {
        placeholder: 'Tekstihaku',
        required: 'Syötä vähintään 3 merkkiä',
        minLength: 'Syötä vähintään 3 merkkiä'
      }
    },
    filter: {
      customer: 'Asiakas',
      handler: 'Käsittelijä',
      store: 'Kauppa'
    }
  },
  searchResults: {
    title: 'Hakutulos',
    empty: 'Antamallasi haulla ei löytynyt tuloksia',
    showingResultsForPre: 'Näytetään tulokset hakusanalle',
    showingResultsForPost: '',
    backToSearch: 'Tarkenna hakua'
  },
  yes: 'Kyllä',
  no: 'Ei',
  loading: 'Ladataan...',
  remove: 'Poista',
  profile: {
    title: 'Oma profiili',
    firstVisit: {
      description: 'Hei! Tervetuloa käyttämään Hymy-järjestelmää.',
      moreDescription: 'Täytä tietosi alle ja paina "Tallenna"'
    },
    language: {
      title: 'Järjestelmän kieli'
    },
    hello: {
      title: 'Vastauspohjissa käytettävä tervehdys',
      preview: 'Tervehdyksen esikatselu',
      placeholder: 'Voit kirjoittaa haluamasi tervehdyksen tähän. Jos jätät tervehdyksen tyhjäksi, näet oletustervehdyksen esikatselussa. Oletustervehdyksen kieli valitaan automaattisesti vastauspohjan kielen perusteella.'
    },
    signature: {
      title: 'Vastauspohjissa käytettävä allekirjoitus',
      preview: 'Allekirjoituksen esikatselu',
      placeholder: 'Voit kirjoittaa haluamasi allekirjoituksen tähän. Jos jätät allekirjoituksen tyhjäksi, näet oletusallekirjoituksen esikatselussa. Oletusallekirjoituksen kieli valitaan automaattisesti vastauspohjan kielen perusteella.'
    },
    subscriptions: {
      title: 'Viestit järjestelmästä',
      email: 'Sähköpostiosoite:',
      fields: {
        sendNotificationsStore: 'Lähetä minulle sähköposti-ilmoitus aina kun Hymyyn tulee uusi vastausta vaativa palaute',
        sendNotificationsNoResponseStore: 'Lähetä minulle sähköposti-ilmoitus aina kun Hymyyn tulee uusi palaute, joka ei vaadi vastausta',
        sendNotificationsKesko: 'Lähetä minulle sähköposti-ilmoitus aina kun kiinnostuksen kohteilleni tulee uusi vastausta vaativa palaute',
        sendNotificationsNoResponseKesko: 'Lähetä minulle sähköposti-ilmoitus aina kun kiinnostuksen kohteilleni tulee uusi palaute, joka ei vaadi vastausta',
        sendSummaries: 'Lähetä minulle kolme kertaa viikossa (ma, ke, pe) kooste käsittelemättömistä vastausta vaativista palautteista, jotka ovat kiinnostuksen kohteitani',
        sendPushMessages: 'Lähetä ilmoitus valitsemilleni laitteille, kun Hymyyn tulee uusi vastausta vaativa palaute  (Selain pyytää ilmoitusten esittämiselle oikeuksia, jos niitä ei ole määritelty)'
      }
    },
    responseProposals: {
      title: 'Vastausapulainen',
      fields: {
        proposeResponses: 'Näytä Vastausapulaisen valitsema vastausehdotus palautteeseen vastatessa'
      }
    },
    excludes: {
      title: 'Älä lähetä minulle sähköposti-ilmoituksia näistä kaupoista',
      notSelected: '(valitse kaupat)'
    },
    interests: {
      enableSecondaryInterests: 'Minulla on myös toissijaisia kiinnostuksen kohteita',
      title: 'Omat kiinnostuksen kohteet',
      note: 'Omat kiinnostuksen kohteet rajaavat automaattisesti palautelistauksissa näkyvät palautteet, saamasi ilmoitukset ja raportoinnin haut. Voit silti tehdä raportointia ja hakua mistä tahansa ketjusta, alueesta tai palautekanavasta. Voit päivittää omia kiinnostuksen kohteita milloin tahansa.',
      categoryTitle: 'Kategoria',
      feedbackCategoryNote: 'Voit rajata palautelistauksessa näytettävät palautteet ja ilmoitukset uusista palautteista valitsemalla kategorian, josta olet kiinnostunut.',
      allFeedbackCategories: 'Kaikki',
      title2: 'Toissijaiset kiinnostuksen kohteet',
      note2: 'Toissijaiset kiinnostuksen kohteet rajaavat automaattisesti palautelistauksissa näkyvät palautteet ja saamasi ilmoitukset.'
    },
    saveFailed: 'Profiilin tallennus epäonnistui',
    save: 'Tallenna',
    cancel: 'Peruuta',
    fields: {
      signature: {
        maxLength: 'Allekirjoituksessa saa olla enintään 256 merkkiä'
      },
      greeting: {
        maxLength: 'Tervehdyksessä saa olla enintään 300 merkkiä'
      }
    },
    pushNotifications: {
      title: 'Ilmoitukset järjestelmästä',
      userAgent: 'Lisätyt laitteet',
      thisDevice: 'Tämä laite',
      enableOnThisDevice: 'Salli ilmoitukset tällä laitteella',
      confirmRemovePushMessageTokenTitle: 'Haluatko varmasti estää valitun selaimen/laitteen ilmoitukset?',
      confirmRemovePushMessageTokenBody: 'Tämä lopettaa ilmoitusten lähettämisen valitulle laitteelle/selaimelle. Voit sallia ilmoitukset myöhemmin uudelleen.'
    }
  },
  settings: {
    title: 'Asetukset',
    facebook: {
      title: 'Facebook-sivujen yksityisviestien linkittäminen Hymyyn',
      login: 'Kirjaudu Facebookiin',
      loggingIn: 'Kirjaudutaan Facebookiin...',
      loggedIn: 'Olet kirjautunut Facebookiin nimellä:',
      logout: 'Poista Facebook-tilisi linkitys',
      confirmLogoutTitleStart: 'Haluatko varmasti poistaa Facebook-tilisi "',
      confirmLogoutTitleEnd: '" linkityksen?',
      confirmLogoutBody: 'Tämä poistaa kaikki Facebook-sivujen linkitykset, jotka on tehty tällä tunnuksella. Tunnuksen linkityksen poistaminen ei poista Hymyyn jo tallennettuja viestejä.',
      loggingOut: 'Poistetaan linkitystä...',
      targetType: {
        label: 'Valitse vastaanottaja, jolle yksityisviestit ohjataan Hymyssä:',
        notSelected: 'Valitse vastaanottaja',
        chain: 'Ketju: ',
        subject: 'Aihe: '
      },
      page: {
        label: 'Valitse Facebook-sivu, jonka yksityisviestit haluat linkittää:',
        notSelected: 'Valitse Facebook-sivu'
      },
      receivers: {
        title: 'Linkitetyt Facebook-sivut',
        labels: {
          pageName: 'Linkitetty sivu',
          userName: 'Linkittäjä',
          chainOrSubjectName: 'Vastaanottaja'
        },
        add: 'Linkitä Hymyyn',
        remove: 'Poista linkitys'
      },
      allowLinking: 'Haluan, että hallitsemieni Facebook-sivujen yksityisviestit haetaan ja tallennetaan Hymyyn, ja Hymyssä kirjoitetut vastaukset lähetetään Facebookiin. Ymmärrän, että jos myöhemmin poistan Facebook-sivun tai Facebook-tilin linkityksen, Hymyyn jo tallennettuja viestejä ei poisteta.',
      confirmRemoveConsentTitle: 'Haluatko varmasti perua hyväksyntäsi?',
      confirmRemoveConsentBody: 'Hyväksynnän peruminen poistaa kaikki Facebook-sivujen linkitykset, jotka sinä olet lisännyt Hymyyn. Hymyyn jo talletettuja viestejä ei poisteta. Haluatko varmasti perua hyväksyntäsi?',
      confirmRemoveReceiverTitleStart: 'Haluatko poistaa Facebook-sivun "',
      confirmRemoveReceiverTitleEnd: '" linkityksen?',
      confirmRemoveReceiverBody: 'Sivun linkityksen poistaminen ei poista Hymyyn jo tallennettuja viestejä.'
    },
    store: {
      title: 'Kauppakohtaiset asetukset',
      select: 'Valitse kauppa',
      noStoreSelected: 'Ei valittua kauppaa',
      showGreeting: 'Näytä kaupan tervehdys palautelomakkeella',
      showRetailerImage: 'Näytä kauppiaan kuva palautelomakkeella',
      preview: 'Esikatselu',
      greetingTitle: 'Palautelomakkeella näytettävä tervehdysteksti',
      greetingFi: {
        title: 'Suomeksi',
        maxLength: 'Tervehdyksessä saa olla enintään 256 merkkiä'
      },
      greetingSv: {
        title: 'Ruotsiksi',
        maxLength: 'Tervehdyksessä saa olla enintään 256 merkkiä'
      },
      greetingEn: {
        title: 'Englanniksi',
        maxLength: 'Tervehdyksessä saa olla enintään 256 merkkiä'
      },
      offerRequestTransferEmail: {
        title: 'Siirrä kaupan tarjouspyynnöt sähköpostiosoitteeseen',
        email: 'Tarkista sähköpostiosoite',
        wrongDomain: 'Sähköpostiosoite ei ole sallittu'
      },
      offerRequestProTransferEmail: {
        title: 'Siirrä kaupan K-Rauta PRO tarjouspyynnöt sähköpostiosoitteeseen',
        email: 'Tarkista sähköpostiosoite',
        wrongDomain: 'Sähköpostiosoite ei ole sallittu'
      }
    },
    responseTemplates: {
      title: 'Vastauspohjat',
      description: 'Tällä sivulla voit muokata käytössäsi olevia vastauspohjia. Huomioithan, että vastauspohjat eivät ole henkilökohtaisia, vaan tekemäsi muutokset näkyvät myös muille Hymyn käyttäjille. Käytäthän siis harkintaa pohjia muokatessasi, erityisesti nimeämisen osalta.',
      select: 'Valitse vastauspohja',
      categoriesTitle: 'Vastauspohjan kategoriat',
      template: {
        title: 'Vastauspohjan teksti',
        required: 'Vastauspohja ei voi olla tyhjä',
        maxLength: 'Vastauspohjassa saa olla korkeintaan 50 000 merkkiä'
      },
      name: {
        title: 'Vastauspohjan nimi',
        required: 'Vastauspohjan nimi ei voi olla tyhjä',
        maxLength: 'Vastauspohjan nimessä saa olla korkeintaan 100 merkkiä'
      }
    }
  },
  receiverFilter: {
    receiverTypes: {
      all: 'Kaikki',
      notSelected: 'Ei valittu',
      chainAndStore: 'Ketjut ja kaupat',
      chain: 'Vain ketjut',
      store: 'Vain kaupat',
      subject: 'Aiheet',
      kesko: 'Vain Kesko PT',
      krauta: 'Vain K-Rauta',
      kplussa: 'Vain K-Plussa',
      intersport: 'Vain Intersport',
      kauto: 'Vain K-Auto',
      onninen: 'Vain Onninen',
      budgetsport: 'Vain Budget Sport'
    },
    filterRows: {
      receiverType: {
        label: 'Rajaus',
        notSelected: 'Valitse rajaus',
        chainUnit: 'Ketju',
        businessUnitInclusive: 'Kaupat',
        businessUnitExclusive: 'Mutta ei kauppoja',
        businessUnitIds: 'Kauppojen toimipistenumerot',
        subject: 'Aiheen nimi'
      },
      chainUnit: {
        label: 'Ketju',
        notSelected: 'Valitse ketju'
      },
      chainUnitArea: {
        label: 'Alue',
        notSelected: 'Kaikki alueet'
      },
      businessUnit: {
        label: 'Kaupan nimi tai toimipistenumero',
        notSelected: 'Syötä kaupat',
        placeholder: 'Kauppa'
      },
      businessUnitIds: {
        label: 'Kauppojen toimipistenumerot',
        placeholder: 'Erota toimipistenumerot välilyönnillä'
      },
      subject: {
        label: 'Aiheen nimi',
        notSelected: 'Kaikki aiheet'
      }
    },
    addReceiverFilter: 'Lisää tarkentava rajaus'
  },
  reporting: {
    title: 'Palautteet-raportti',
    messages: {
      title: 'Palautteet ja viestiketjut-raportti'
    },
    summary: {
      title: 'Yleiskatsaus (kuvaaja)',
      pies: {
        status: {
          title: 'Palautteen tila'
        },
        sentiment: {
          title: 'Palautteen sävy',
          POSITIVE: 'Positiivinen',
          NEUTRAL: 'Neutraali',
          NEGATIVE: 'Negatiivinen'
        },
        requiresResponse: {
          title: 'Vastattava',
          yes: 'Kyllä',
          no: 'Ei'
        }
      },
      trend: {
        title: 'Palautemäärä',
        types: {
          all: 'Kaikki',
          other: 'Muut'
        }
      },
      topics: {
        title: 'Puheenaihelista',
        heading: {
          negative: 'neg',
          neutral: 'neu',
          positive: 'pos'
        },
        unknownTopic: 'Määrittelemätön puheenaihe'
      },
      total: 'Palautteita yhteensä',
      responseAverage: 'Vastausaikojen keskiarvo',
      reactAverage: 'Reagointiaikojen keskiarvo'
    },
    form: {
      fields: {
        receiver: {
          label: 'Palautteen vastaanottaja'
        },
        channel: {
          label: 'Palautteen lähde',
          placeholder: '',
          notSelected: 'Ei rajausta'
        },
        dateRange: {
          label: 'Aikaväli',
          placeholder: 'Valitse aikaväli',
          clear: 'Tyhjennä'
        },
        status: {
          label: 'Tila',
          placeholder: '',
          notSelected: 'Kaikki'
        },
        requiresResponse: {
          label: 'Vastaus pyydetty',
          notSelected: 'Kaikki',
          options: {
            true: 'Kyllä',
            false: 'Ei'
          }
        },
        sentiment: {
          label: 'Palautteen sävy',
          notSelected: 'Kaikki',
          placeholder: '',
          options: {
            HIGHLY_POSITIVE: 'Erittäin positiivinen',
            POSITIVE: 'Positiivinen',
            NEUTRAL: 'Neutraali',
            NEGATIVE: 'Negatiivinen',
            HIGHLY_NEGATIVE: 'Erittäin negatiivinen'
          }
        },
        topic: {
          label: 'Puheenaihe',
          placeholder: '',
          notSelected: 'Ei rajausta'
        },
        handler: {
          label: 'Käsittelijä',
          placeholder: '',
          notSelected: 'Ei rajausta'
        },
        searchString: {
          label: 'Hakusana',
          placeholder: 'Erota hakusanat välilyönnillä. Voit käyttää myös sanan osia.',
          hint: 'Kentän vakiohaku on AND, eli kaikki sanat pitää löytyä palautteesta.\u000AVoit tarkentaa hakua käyttämällä sanoja OR ja NOT.\u000AEsimerkiksi "kenkä OR saapas" löytää palautteet, joissa käytetään jompaa kumpaa tai molempia sanoja.\u000AHaku "korko NOT kenkä" löytää palautteet, joissa on käytetty sanaa "korko", mutta joissa ei ole sanaa "kenkä".'
        },
        feedbackIds: {
          label: 'Palautteen tunniste',
          placeholder: 'Erottele tunnisteet tarvittaessa välilyönnillä'
        },
        store: {
          label: 'Kauppa',
          notSelected: 'Ei rajausta'
        }
      },
      load: 'Lataa tiedosto',
      submit: 'Näytä'
    },
    responseSummary: {
      title: 'Yleiskatsaus (vastausajat)',
      loadKeskoSummary: 'Lataa Kesko-yhteenveto',
      loadSelectedSummary: 'Lataa valittu yhteenveto'
    },
    results: {
      paging: {
        showingResults: 'Näytetään tulokset',
        noResults: 'Ei tuloksia',
        next: 'Seuraava »',
        previous: '« Edellinen'
      },
      labels: {
        date: 'Pvm',
        status: 'Tila',
        requiresResponse: 'Vastattava',
        handler: 'Käsittelijä',
        receiver: 'Vastaanottaja',
        feedback: 'Palaute',
        analysisTopics: 'Puheenaiheet',
        totalCount: 'Palautteita yhteensä kpl',
        notAnsweredCount: 'Odottaa vastausta kpl',
        newAndNoResponseRequiredCount: 'Uusia, ei vastattavia kpl',
        negatives: 'Negatiivisia kpl',
        neutrals: 'Neutraaleja kpl',
        positives: 'Positiivisia kpl',
        averageReactTime: 'Reagointiaika (ka, hh:mm)',
        averageResponseTime: 'Vastausaika (ka, hh:mm)',
        averageHandlingTime: 'Käsittelyaika (ka, hh:mm)',
        id: 'Tunniste',
        messages: 'Asiakasviestit',
        comments: 'Sisäiset viestit'
      },
      notes: {
        averageReactTime: 'Keskiarvo vastausta vaativan palautteen saapumisesta Hymyyn siihen kun palautteelle tehdään joku toiminto esim. käsittelijän vaihto, kommentti, palautteen välittäminen tai palautteeseen vastaaminen. Pelkkä palautteen avaaminen ei aseta reagointiaikaa.',
        averageResponseTime: 'Keskiarvo vastausta vaativan palautteen saapumisesta Hymyyn siihen kun asiakkaalle vastataan ensimmäisen kerran (Lähetä vastaus/Soitettu, kesken tai Lähetä vastaus/Soitettu, käsitelty).',
        averageHandlingTime: 'Keskiarvo palautteen saapumisesta Hymyyn siihen kun palaute on merkitty käsitellyksi.'
      },
      requiresResponse: {
        true: 'Kyllä',
        false: 'Ei'
      }
    },
    receivers: {
      title: 'Yleiskatsaus (taulukko)',
      types: {
        all: 'Kaikki',
        other: 'Muut'
      }
    },
    error: 'Raporttia haettaessa tapahtui virhe. Tarkista raportin hakuehdot tai ota yhteyttä Hymyn tukeen (hymy.support@kesko.fi).'
  },
  error: {
    notFound: {
      title: 'Sivua ei löydy',
      text: 'Etsimääsi sivua ei valitettavasti löytynyt tai sinulla ei ole oikeuksia siihen.',
      goBack: 'Palaa takaisin'
    },
    genericError: 'Oho, tapahtui virhe. Ole hyvä tarkista internet-yhteytesi, päivitä sivu ja yritä uudelleen. Jos ongelma toistuu, ota yhteyttä Hymyn tukeen.'
  },
  language: {
    fi: 'Suomi',
    sv: 'Ruotsi'
  },
  tokenInput: {
    clearAllText: 'Tyhjennä',
    clearValueText: 'Tyhjennä',
    noResultsText: 'Ei tuloksia'
  },
  giveFeedback: {
    title: 'Anna palautetta Hymystä',
    note: 'Tällä lomakkeella voit lähettää risuja, ruusuja ja kehitysehdotuksia Hymystä. Viestiin lisätään automaattisesti palautteen antajan nimi ja sähköpostiosoite. Jos palautteesi koskee yksittäistä palautetta, niin lisääthän mukaan palautteen tunnisteen.',
    save: 'Lähetä',
    cancel: 'Peruuta',
    saveFailed: 'Palautteen lähettäminen epäonnistui',
    fields: {
      feedback: {
        placeholder: 'Palaute *',
        required: 'Kirjoita palaute'
      },
      responseRequested: {
        label: 'Toivon palautteeseeni vastausta'
      }
    }
  },
  support: {
    title: 'Hymyn ohjeet',
    store: 'Kauppa',
    kesko: 'Kesko',
    storeLinks: {
      hymyKnet: {
        linkText: 'Hymyn ohjeet K-Netissä',
        url: 'https://keskogroup.sharepoint.com/sites/k-netti-ohjeet/SitePages/Hymyn-j%C3%A4rjestelm%C3%A4ohje.aspx'
      },
      dreambrokerKcm: {
        linkText: 'Hymyn ohjevideot KCM KT:lle',
        url: 'https://www.dreambroker.com/channel/ccwiza6u'
      },
      kcmMyk: {
        beforeLinkText: 'Hymyn käyttöohje löytyy ',
        linkText: 'KCM KT MyK:ssa',
        afterLinkText: ' kohdasta Tavaratalotoiminnot > Ohjeet > Kaupan työohjeet > HYMY-palautejärjestelmä',
        url: 'https://keskogroup.sharepoint.com/sites/myk-kcm/tavaratalotoiminnot/ohjeet/kaupantyoohjeet/Pages/default.aspx'
      },
      kcmMykHymy: {
        linkText: 'Hymyn käyttöohje KCM KT:lle',
        url: 'https://keskogroup.sharepoint.com/sites/myk-kcm/tavaratalotoiminnot/asiakkaanaani/Hymy-palautej%c3%a4rjestelm%c3%a4/Documents/Hymy%20ohje%20kauppa.pdf'
      }
    },
    keskoLinks: {
      dreamBroker: {
        linkText: 'Hymyn ohjevideot',
        url: 'https://www.dreambroker.com/channel/esbxab00'
      },
      myk: {
        linkText: 'Hymyn sivu MyK:ssa',
        url: 'https://keskogroup.sharepoint.com/sites/myk-fi/yhteisetkaytannot/branditjamarkkinointi/asiakkaanaani/Pages/Hymy-asiakaspalautej%C3%A4rjestelma.aspx'
      },
      keskoSharepoint: {
        linkText: 'Hymyn käyttöohje keskolaisille',
        url: 'https://keskogroup.sharepoint.com/:b:/r/teams/PT-Laatuohjelma/Yleiset%20ja%20taustaaineistot/Hymy%20ohje%20keskolainen.pdf?csf=1&e=82FK5y'
      },
      storeSharepoint: {
        linkText: 'Hymyn käyttöohje kaupoille',
        url: 'https://keskogroup.sharepoint.com/:b:/r/teams/PT-Laatuohjelma/Yleiset%20ja%20taustaaineistot/Hymy%20ohje%20kauppa.pdf?csf=1&e=TSBvl1'
      }
    }
  },
  datePicker: {
    range: {
      noLimit: 'Ei rajausta',
      previousWeek: 'Edellinen viikko',
      previousMonth: 'Edellinen kuukausi',
      previousYear: 'Edellinen vuosi',
      last14days: 'Viimeiset 14 päivää',
      last30days: 'Viimeiset 30 päivää',
      currentYear: 'Kuluva vuosi',
      custom: 'Vapaa aikaväli'
    }
  }
}
