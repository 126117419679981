import { window } from 'utils'
import { trace } from 'utils/Log'

const privateParts = {}

export const TOKEN_NAME = 'authToken';

class Session {
  constructor(tokenGetter, tokenSetter, whoamiGetter) {
    this._tokenGetter = tokenGetter
    this._tokenSetter = tokenSetter
    this._whoamiGetter = whoamiGetter;
  }

  set token(token) {
    trace('Session: setting authentication token', token)
    this._tokenSetter(token)
    if (window.localStorage) {
      if (!token) {
        window.localStorage.removeItem(TOKEN_NAME)
      } else {
        window.localStorage.setItem(TOKEN_NAME, token)
      }
    }
  }

  get token() {
    this.refreshIfTokenNotSet()
    return this._tokenGetter()
  }

  get userInfo() {
    return this._whoamiGetter()
  }

  isAuthenticated() {
    return !!this.token;
  }

  refreshIfTokenNotSet() {
    if (!this._tokenGetter() && window.localStorage) {
      this._tokenSetter(window.localStorage.getItem(TOKEN_NAME))
    }
    return this
  }
}

const SESSION = 'session'

const SessionHolder = {
  get: function() {
    return privateParts[SESSION]
  },
  create: function(tokenGetter, tokenSetter, whoamiGetter) {
    privateParts[SESSION] = new Session(tokenGetter, tokenSetter, whoamiGetter)
    return SessionHolder.get()
  },
  clear: function() {
    delete privateParts[SESSION]
  }
}
export default SessionHolder
