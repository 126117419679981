import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux'
import { SupportView } from 'components/Support';
import { Company } from 'domain'

class SupportViewRoute extends Component {
  render() {
    return (
      <SupportView
        userInfo={this.props.userInfo}
        translations={this.props.translations}
      />
    )
  }
}

export default connect(
  (state) => ({
    userInfo: {
      isKeskoStoreUser: state.getIn(['client', 'keskoStoreUser']),
      isKeskoCompanyUser: state.getIn(['client', 'authorizedCompanies']).contains(Company.KESKO),
      isAdminUser: state.getIn(['client', 'admin'])
    },
    translations: state.getIn(['i18n', 'translations', state.getIn(['i18n', 'locale'])])
  }),
)
(SupportViewRoute)

SupportViewRoute.propTypes = {
  userInfo: PropTypes.shape({
    isKeskoStoreUser: PropTypes.bool.isRequired,
    isKeskoCompanyUser: PropTypes.bool.isRequired,
    isAdminUser: PropTypes.bool.isRequired
  }).isRequired
};
