import React, { Component, PropTypes } from 'react'
import {connect} from 'react-redux'
import { List } from 'immutable'

import { TokenInput, Selection, TextInput } from 'components/Forms'
import { Icon, KeskoIcons } from 'components/Icons'
import { ReceiverType, ReceiverFilterType } from 'domain'

import { Text } from 'containers/Text'
import {businessUnitMapper, chainUnitAreaMapper, chainUnitMapper, subjectMapper} from '../../utils/OptionMapper'

const receiverMapper = option => ({
  k: ['receiverFilter', 'filterRows', 'receiverType', option],
  id: option,
  value: option
})

class ReceiverFilterRow extends Component {

  static contextTypes = {
    locale: PropTypes.string
  };

  static propTypes = {
    possibleReceiverFilters: React.PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired,
    businessUnitOptions: PropTypes.instanceOf(List).isRequired,
    chainUnitOptions: PropTypes.instanceOf(List).isRequired,
    subjectOptions: PropTypes.instanceOf(List).isRequired
  };

  handleRemove = () => this.props.onRemove(this.props.idx);

  render() {
    const { receiver, possibleReceiverFilters, businessUnitOptions, chainUnitOptions, subjectOptions, authorizedCompanies,
      row: { receiverType, chainUnit, chainUnitArea, businessUnits, businessUnitIds, subject }, submitting } = this.props
    const showCompanyName = authorizedCompanies.size > 1
    return (
      <div className="flex flex-center clearfix">
        <div className="flex-auto flex flex-wrap">
          <div className="col-12 sm-col-4">
            <div className="pr2 pb2">
              <label>
                <Text k="receiverFilter.filterRows.receiverType.label" />
              </label>
              <Selection
                disabled={submitting}
                options={possibleReceiverFilters}
                optionMapper={receiverMapper}
                showNotSelected={false}
                translate={{ notSelectedLabel: 'receiverFilter.filterRows.receiverType.notSelected' }}
                {...receiverType}
              />
            </div>
          </div>
          { receiverType.value === ReceiverFilterType.chainUnit && (
            <div className="col-12 sm-col-4">
              <div className="pr2 pb2">
                <label>
                  <Text k="receiverFilter.filterRows.chainUnit.label" />
                </label>
                <Selection
                  disabled={submitting}
                  options={chainUnitOptions.toJS()}
                  optionMapper={chainUnitMapper(showCompanyName)}
                  showNotSelected={false}
                  translate={{ notSelectedLabel: 'receiverFilter.filterRows.chainUnit.notSelected' }}
                  {...chainUnit}
                />
              </div>
            </div>
          )}
          { receiverType.value === ReceiverFilterType.chainUnit && chainUnit.value && (receiver.value === ReceiverType.chainAndStore || receiver.value === ReceiverType.store) && (
            <div className="col-12 sm-col-4">
              <div className="pr2 pb2">
                <label>
                  <Text k="receiverFilter.filterRows.chainUnitArea.label" />
                </label>
                <TokenInput
                  disabled={submitting}
                  options={chainUnit.value.areas}
                  optionMapper={chainUnitAreaMapper}
                  translate={{
                    notSelectedLabel: 'receiverFilter.filterRows.chainUnitArea.notSelected'
                  }}
                  {...chainUnitArea}
                />
              </div>
            </div>
          )}
          { receiverType.value && receiverType.value !== ReceiverFilterType.chainUnit && receiverType.value !== ReceiverFilterType.subject && receiverType.value !== ReceiverFilterType.businessUnitIds && (
            <div className="col-12 sm-col-8">
              <div className="pr2 pb2">
                <label>
                  <Text k="receiverFilter.filterRows.businessUnit.label" />
                </label>
                <TokenInput
                  disabled={submitting}
                  options={businessUnitOptions}
                  optionMapper={businessUnitMapper}
                  translate={{
                    placeholder: 'receiverFilter.filterRows.businessUnit.placeholder',
                    notSelectedLabel: 'receiverFilter.filterRows.businessUnit.notSelected'
                  }}
                  {...businessUnits}
                />
              </div>
            </div>
          )}
          { receiverType.value === ReceiverFilterType.businessUnitIds && (
            <div className="col-12 sm-col-8">
              <div className="pr2 pb2">
                <label>
                  <Text k="receiverFilter.filterRows.businessUnitIds.label" />
                </label>
                <TextInput
                  disabled={submitting}
                  translate={{ placeholder: 'receiverFilter.filterRows.businessUnitIds.placeholder' }}
                  {...businessUnitIds}
                />
              </div>
            </div>
          )}
          { receiverType.value === ReceiverFilterType.subject && (
            <div className="col-12 sm-col-8">
              <div className="pr2 pb2">
                <label>
                  <Text k="receiverFilter.filterRows.subject.label" />
                </label>
                <TokenInput
                  disabled={submitting}
                  options={subjectOptions.toJS()}
                  optionMapper={subjectMapper(this.context.locale, showCompanyName)}
                  translate={{
                    notSelectedLabel: 'receiverFilter.filterRows.subject.notSelected'
                  }}
                  {...subject}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex-none pl2 flex my-auto">  {/* padding bottom cancels the bottom margin for centering */}
          <Icon
            icon={KeskoIcons.trash}
            size="xl"
            className="orange clickable mt2"
            onClick={this.handleRemove}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    authorizedCompanies: state.getIn(['client', 'authorizedCompanies'])
  })
)
(ReceiverFilterRow);
