import {
  saveComment as clientSaveComment,
  sendCommentRequest as clientSendCommentRequest,
  fetchSingleFeedback
} from './ClientActions'
import { clearAttachments } from './AttachmentActions'

export const CommentActions = {
  SAVE: 'Comment/Save/Success',
  SAVE_FAILED: 'Comment/Save/Failure'
}

export const saveCommentSuccess = feedbackId => ({ type: CommentActions.SAVE, feedbackId })
export const saveCommentFailed = feedbackId => ({ type: CommentActions.SAVE_FAILED, feedbackId })

export const triggerSaveComment = (feedbackId, commentText, attachmentIds) => dispatch =>
  dispatch(clientSaveComment(feedbackId, commentText, attachmentIds))
    .then(() => {
      dispatch(clearAttachments())
      dispatch(fetchSingleFeedback(feedbackId))
      dispatch(saveCommentSuccess(feedbackId))
    }, () => {
      dispatch(saveCommentFailed(feedbackId))
      return Promise.reject({ _error: 'feedback.comments.saveFailed' })
    })

export const triggerSendCommentRequest = (feedbackId, commentText, emails, language) => dispatch =>
  dispatch(clientSendCommentRequest(feedbackId, commentText, emails, language)).then(
    () => { dispatch(fetchSingleFeedback(feedbackId)) },
    () => Promise.reject({ _error: 'feedback.requestComment.saveFailed' })
  )