import React, { PropTypes } from 'react'
import PureComponent from 'react-pure-render/component'

import { MediaObject } from 'components/grid'
import { Spinner } from 'components/Icons'
import { Text } from 'containers/Text'

class FeedbackListLoadingIndicator extends PureComponent {
  render() {
    const {loading} = this.props;
    return (
      <div className="feedback-list-loading">
        {loading && (
          <div className="silver">
            <MediaObject
              object={<Spinner className="mr2 blue" />}
              content={<div><Text k="feedback.list.loadingMore" /></div>}
            />
          </div>
        )}
      </div>
    )
  }
}

FeedbackListLoadingIndicator.propTypes = {
  loading: PropTypes.bool.isRequired
};

FeedbackListLoadingIndicator.defaultProps = {
  loading: false
};

export default FeedbackListLoadingIndicator
