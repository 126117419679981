import { List } from 'immutable'
import Serializable from './Serializable'
import MessageType from './MessageType'
import Message from './Message'
import Customer from './Customer'
import Handler from './Handler'
import ResponseDraft from './ResponseDraft'
import ResponseTemplate from './ResponseTemplate'
import Status from './Status'
import Locale from './Locale'
import FeedbackEvent from './FeedbackEvent'

import { oneOf } from 'utils'
import { warn } from 'utils/Log'

const byMsgType = acceptedTypes => msg => {
  return acceptedTypes.indexOf(msg.messageType) !== -1
}
const byCreatedDateAsc = (a, b) => a.created - b.created

const langToTopic = {
  [Locale.fi]: 'name',
  [Locale.sv]: 'nameSv',
  [Locale.en]: 'nameEn'
}
const allowedLocales = Object.keys(langToTopic)

const EMPTY_LIST = List([])
class Feedback extends Serializable {
  constructor(data) {
    super(data, 'Feedback')
    this._mapCreatedDate()
    this._mapReceivedDate()
    this._mapModifiedDate()
    this._mapPurchaseDate()
    this._mapMessages()
    this._mapCustomerInfo()
    this._mapHandler()
    this._mapResponseDraft()
    this._mapAllResponseTemplates()
    this._mapEvents()
  }

  _mapResponseDraft() {
    const responseDraft = this._data.get('responseDraft');
    if (!!responseDraft) {
      this._responseDraft = new ResponseDraft(responseDraft)
    }
  }

  _mapAllResponseTemplates() {
    if (!!this._data.get('allResponseTemplates')) {
      this._data = this._data.updateIn(
        ['allResponseTemplates'],
        List(),
        list => list.map(template => new ResponseTemplate(template))
      );
      this._allResponseTemplates = this._data.get('allResponseTemplates', List())
    }
  }

  _mapCustomerInfo() {
    const customer = this._data.get('customerContactInfo');
    if (!!customer) {
      this._customer = new Customer(customer)
    }
  }

  _mapMessages() {
    if (!!this._data.get('messages')) {
      this._data = this._data.updateIn(
        ['messages'],
        List(),
        list => list.map(msg => new Message(msg)).sort(byCreatedDateAsc)
      )
      this._messages = this._data.get('messages', List())
        .filter(byMsgType([
          MessageType.feedback,
          MessageType.response,
          MessageType.followUp
        ]))
      this._comments = this._data.get('messages', List())
        .filter(byMsgType([
          MessageType.comment,
          MessageType.commentRequest,
          MessageType.commentResponse,
          MessageType.transferComment,
          MessageType.forwardComment,
          MessageType.responseComment,
          MessageType.error
        ]))
    }

    if (!!this._data.get('facebookHistoryMessages')) {
      this._data = this._data.updateIn(
        ['facebookHistoryMessages'],
        List(),
        list => list.map(msg => new Message(msg)).sort(byCreatedDateAsc)
      )
      this._facebookHistoryMessages = this._data.get('facebookHistoryMessages', List())
    }
  }

  _mapCreatedDate() {
    const created = this._data.get('created');
    if (!!created) {
      const date = new Date(Date.parse(created));
      this._data = this._data.set('created', date)
    }
  }

  _mapReceivedDate() {
    const received = this._data.get('received');
    if (!!received) {
      const date = new Date(Date.parse(received));
      this._data = this._data.set('received', date)
    }
  }

  _mapModifiedDate() {
    const modified = this._data.get('modified');
    if (!!modified) {
      const date = new Date(Date.parse(modified));
      this._data = this._data.set('modified', date)
    }
  }

  _mapPurchaseDate() {
    const purchaseDate = this._data.get('purchaseDate');
    if (!!purchaseDate) {
      const date = new Date(Date.parse(purchaseDate));
      this._data = this._data.set('purchaseDate', date)
    }
  }

  _mapHandler() {
    const assignedTo = this._data.get('assignedTo')
    const assignedToUserId = this._data.get('assignedToUserId')
    const values = [assignedTo, assignedToUserId].filter(a => a)
    if (values.length === 2) {
      this._handler = new Handler({
        userId: assignedToUserId,
        userName: assignedTo
      })
    } else if (values.length === 1) {
      warn('Feedback had assignedTo field but not assignedToUserId or vice versa', assignedTo, assignedToUserId)
    }
  }

  _mapEvents() {
    this._data = this._data.updateIn(
        ['events'],
        List(),
        list => list.map(event => new FeedbackEvent(event)).sort((a, b) => b.created - a.created)
      )
  }

  updated(cb) {
    return new Feedback(cb(this._data).toJS())
  }

  get id() {
    return this._data.get('id')
  }

  get created() {
    return this._data.get('created')
  }

  get received() {
    return this._data.get('received')
  }

  get modified() {
    return this._data.get('modified')
  }

  get title() {
    return this._data.get('feedbackTitle')
  }

  get preview() {
    return this._data.get('feedbackPreview')
  }

  get requiresResponse() {
    return this._data.get('requiresResponse')
  }

  get answered() {
    return this._data.get('answered')
  }

  get lastMessageFrom() {
    return this._data.get('lastMessageFrom')
  }

  get status() {
    return this._data.get('feedbackStatus')
  }

  get category() {
    return this._data.get('category')
  }

  get handler() {
    return this._handler
  }

  get assignedTo() {
    warn('Feedback#assignedTo is deprectated, use #handler')
    return this._data.get('assignedTo')
  }

  get assignedToUserId() {
    warn('Feedback#assignedToUserId is deprectated, use #handler')
    return this._data.get('assignedToUserId')
  }

  get messages() {
    return this._messages
  }

  get facebookHistoryMessages() {
    return this._facebookHistoryMessages
  }

  get comments() {
    return this._comments
  }

  get mergedMessages() {
    return this._data.get('mergedMessages')
  }

  get mergedComments() {
    return this._data.get('mergedComments')
  }

  get text() {
    if (!this.messages) {
      return undefined
    }
    const msg = this.messages.get(0);
    return !msg ? undefined : ''+msg.message
  }

  get customer() {
    return this._customer
  }

  get version() {
    return this._data.get('feedbackVersion')
  }

  get businessUnitId() {
    return this._data.getIn(['feedbackReceiver', 'businessUnitId'])
  }

  get businessUnitIds() {
    return this._data.getIn(['feedbackReceiver', 'businessUnitIds'])
  }

  get receiverName() {
    return this._data.getIn(['feedbackReceiver', 'displayName'])
  }

  get chainUnitId() {
    return this._data.getIn(['feedbackReceiver', 'chainUnitId'])
  }

  get companyId() {
    return this._data.getIn(['feedbackReceiver', 'companyId'])
  }

  get facebookReceiverName() {
    return this._data.get('facebookReceiverName')
  }

  get analysisAmbiance() {
    return this._data.getIn(['analysis', 'ambiance'])
  }

  get transferred() {
    return this._data.get('transferred')
  }

  get canReply() {
    return this.status !== Status.DONE && this.requiresResponse && !this.customer.removed
  }

  get receiverName() {
    return this._data.getIn(['feedbackReceiver', 'displayName'])
  }

  get receiverArea() {
    return this._data.getIn(['feedbackReceiver', 'area'])
  }

  get nps() {
    return this._data.getIn(['nps'])
  }

  get feedbackChannel() {
    return this._data.get('feedbackChannel')
  }

  get feedbackSubChannel() {
    return this._data.get('feedbackSubChannel')
  }

  get purchaseDate() {
    return this._data.get('purchaseDate')
  }

  get deviceModel() {
    return this._data.get('deviceModel')
  }

  get applicationVersion() {
    return this._data.get('applicationVersion')
  }

  get orderExperienceSentiment() {
    return this._data.get('orderExperienceSentiment')
  }

  get carRegistrationNumber() {
    return this._data.get('carRegistrationNumber')
  }

  get carBrand() {
    return this._data.get('carBrand')
  }

  get clientBrowser() {
    return this._data.get('clientBrowser')
  }

  get clientLocation() {
    return this._data.get('clientLocation')
  }

  get clientReferrer() {
    return this._data.get('clientReferrer')
  }

  get clientScreenSize() {
    return this._data.get('clientScreenSize')
  }

  get clientWindowSize() {
    return this._data.get('clientWindowSize')
  }

  get isAmbianceErrorReported() {
    return this._data.getIn(['analysis', 'isAmbianceErrorReported'])
  }

  get isTopicErrorReported() {
    return this._data.getIn(['analysis', 'isTopicErrorReported'])
  }

  get isThreatReported() {
    return this._data.get('isThreatReported')
  }

  get orderNumber() {
    return this._data.get('orderNumber')
  }

  get tempPlussaCardNumber() {
    return this._data.get('tempPlussaCardNumber')
  }

  get originalBusinessUnitId() {
    return this._data.get('originalBusinessUnitId')
  }

  get originalBusinessUnitDisplayName() {
    return this._data.get('originalBusinessUnitDisplayName')
  }

  get rating() {
    return this._data.get('rating')
  }

  get responseDraft() {
    return this._responseDraft
  }

  get allResponseTemplates() {
    return this._allResponseTemplates
  }

  get events() {
    return this._data.get('events')
  }

  get signature() {
    return this._data.get('signature');
  }

  get greeting() {
    return this._data.get('greeting');
  }

  get handlingTime() {
    return this._data.get('handlingTime');
  }

  get usageObjected() {
    return this._data.get('usageObjected');
  }

  analysisTopicsInLocale(lang = Locale.fi) {
    oneOf(allowedLocales, lang)
    return this._data.getIn(['analysis', 'topics'], EMPTY_LIST)
      .map(topic => topic.get(langToTopic[lang]))
      .toJS()
  }

  analysisTopicsPosInLocale(lang = Locale.fi) {
    oneOf(allowedLocales, lang)
    const topics = this._data.getIn(['analysis', 'topicsPos'], EMPTY_LIST)
    .map(topic => topic.get(langToTopic[lang]))
    if (topics.find(topic => topic.includes('|'))) {
      return topics
      .filter(topic => topic.includes('|'))
      .toJS()
    }
    
    return topics.toJS()
  }

  analysisTopicsNeuInLocale(lang = Locale.fi) {
    oneOf(allowedLocales, lang)
    const topics = this._data.getIn(['analysis', 'topicsNeu'], EMPTY_LIST)
      .map(topic => topic.get(langToTopic[lang]))
    if (topics.find(topic => topic.includes('|'))) {
      return topics
      .filter(topic => topic.includes('|'))
      .toJS()
    } 
    
    return topics.toJS()
  }

  analysisTopicsNegInLocale(lang = Locale.fi) {
    oneOf(allowedLocales, lang)
    const topics = this._data.getIn(['analysis', 'topicsNeg'], EMPTY_LIST)
    .map(topic => topic.get(langToTopic[lang]))
    if (topics.find(topic => topic.includes('|'))) {
      return topics
      .filter(topic => topic.includes('|'))
      .toJS()
    }
    
    return topics.toJS()
  }

  toJSON() {
    const json = super.toJSON();
    delete json.handler
    return json
  }
}

export default Feedback
