import React, { Component, PropTypes } from 'react'

import { Col } from 'components/grid'
import { Navigator } from 'components/Menu'
import { Text } from 'containers/Text'
import { WaitFor } from 'components/Utils'
import { ExclamatedNote } from 'components/kesko'

import TransferFeedbackForm from './TransferFeedbackForm'
import FeedbackComments from '../FeedbackComments'
import FeedbackMessages from '../FeedbackMessages'
import FacebookMessageHistory from '../FacebookMessageHistory'
import CollapsingCustomerInfo from '../CollapsingCustomerInfo'

class TransferFeedbackView extends Component {

  static propTypes = {
    transfer: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      init: PropTypes.func.isRequired,
      fetchStoreHandlers: PropTypes.func.isRequired
    }).isRequired
  };

  componentDidMount() {
    this.props.actions.init()
  }

  render() {
    const { feedback, externalSystemOptions, businessUnitOptions, handlerOptions, chainUnitOptions, subjectOptions, cancel, transfer,
      transferFeedbackAddresses, isStoreUser, actions: { fetchStoreHandlers } } = this.props
    return (
      <WaitFor condition={externalSystemOptions && chainUnitOptions && businessUnitOptions && subjectOptions && transferFeedbackAddresses}>
        <section className="px1">
          <Navigator backUrl=".." />
          <Col><hr /></Col>
          <CollapsingCustomerInfo collapseId={`TransferFeedbackView-${feedback.id}-customerinfo`}
                                  customer={feedback.customer}
                                  initiallyVisible={false}
          />
          <FacebookMessageHistory collapseId={`TransferFeedbackView-${feedback.id}-facebookHistoryMessages`}
                                  facebookHistoryMessages={feedback.facebookHistoryMessages}
                                  initiallyVisible={false}
          />
          <FeedbackMessages collapseId={`TransferFeedbackView-${feedback.id}-messages`}
                            messages={feedback.messages}
                            showEditLinks={false}
                            initiallyVisible={false}
          />
          <FeedbackComments collapseId={`TransferFeedbackView-${feedback.id}-comments`}
                            comments={feedback.comments}
                            showEditLinks={false}
                            showNote={false}
                            initiallyVisible={false}
          />
          <Col><hr /></Col>

          <Col><h2 className="blue mt3 mb3"><Text k="feedback.transfer.title" /></h2></Col>

          <ExclamatedNote
            translate={{ note: 'feedback.transfer.note' }}
            className="mb2"
          />

          <TransferFeedbackForm
            externalSystemOptions={externalSystemOptions}
            usageObjected={feedback.usageObjected}
            businessUnitOptions={businessUnitOptions}
            handlerOptions={handlerOptions}
            chainUnitOptions={chainUnitOptions}
            subjectOptions={subjectOptions}
            transferFeedbackAddresses={transferFeedbackAddresses}
            fetchStoreHandlers={fetchStoreHandlers}
            isStoreUser={isStoreUser}
            transfer={transfer}
            onCancel={cancel}
            localStorageKey={`${feedback.id}-transferFeedback`}
          />

        </section>
      </WaitFor>
    )
  }
}

export default TransferFeedbackView
