import React, { Component, PropTypes } from 'react'
import { List } from 'immutable'

import { TriangledTitle } from 'components/kesko'

import Text from 'containers/Text'
import { CollapsingContainer } from 'containers/Layout'

import FeedbackDate from '../FeedbackDate'
import { getText } from 'containers/Text/utils'

class EventRow extends React.PureComponent {
  static contextTypes = {
    translations: PropTypes.instanceOf(Object)
  };

  eventText(event) {
    let baseText = getText(this.context.translations, 'feedback.event.' + event.type)
    return baseText.replace('{target}', event.target)
  }

  render() {
    const {event} = this.props;
    return (
      <div className="event-row">
        <div className="left-block">
          <FeedbackDate className="feedback-date" date={event.created} full={true} />
          <span className="silver">
            {this.eventText(event)}
            </span>
        </div>
        <div className="right-block">
          <span className="feedback-actor">
            {event.actor}
          </span>
        </div>
      </div>
    )
  }
}

const EventBlock = ({events}) => (
  <div className="event-block">
    {events.map((event, k) => <EventRow event={event} key={k} />)}
  </div>
);

class FeedbackEvents extends Component {
  constructor(props) {
    super(props);
    this.renderTitle = this.renderTitle.bind(this)
  }

  renderTitle({visible, handleClick}) {
    const {events} = this.props
    const titleElement = (
      <span>
        <Text k="feedback.events" />
        <span> ({events.size})</span>
      </span>
    );

    return (
      <TriangledTitle
        titleElement={titleElement}
        toggled={visible}
        onClick={handleClick}
      />
    )
  }

  render() {
    const {events, collapseId, initiallyVisible} = this.props;
    return (
      <div className="feedback-events">
        <CollapsingContainer collapseId={collapseId} initiallyVisible={initiallyVisible} title={this.renderTitle} animated={false}>
          <EventBlock events={events} />
        </CollapsingContainer>
      </div>
    )
  }
}

FeedbackEvents.defaultProps = {
  initiallyVisible: false
};

FeedbackEvents.propTypes = {
  collapseId: PropTypes.any.isRequired,
  initiallyVisible: PropTypes.bool.isRequired,
  events: PropTypes.oneOfType([
    PropTypes.instanceOf(List)
  ]).isRequired
};

export default FeedbackEvents
