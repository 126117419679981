import React, { Component, Children, PropTypes } from 'react'
import classNames from 'classnames'

import { Col } from 'components/grid'

export const FlexSize = {
  fixed: 'flex-1',
  auto: 'flex-auto'
}

class FormButtons extends Component {
  static propTypes = {
    flexSize: PropTypes.oneOf(Object.values(FlexSize)).isRequired
  };
  static defaultProps = {
    flexSize: FlexSize.fixed
  };
  render() {
    const { flexSize } = this.props
    const buttons = Children.map(this.props.children, (elem) => {
      const flexOrder = elem.props.flexOrder
      const classes = classNames(
        flexSize,
        'flex',
        'mb2',
        'mr2',
        {
          [`flex-order-${flexOrder}`]: !!flexOrder
        }
      )
      // Align them right inside the cells
      const style = !!flexOrder ? { marginLeft: 'auto' } : {}
      const clone = React.cloneElement(elem, {
        className: 'sm-btn-maxwidth',
        style
      })
      return (
        <div className={classes}>
          <div className="fill flex">
            {clone}
          </div>
        </div>
      )
    })

    return (
      <Col className="mt3">
        <div className="flex flex-justify flex-stretch flex-wrap">
          {buttons}
        </div>
      </Col>
    )
  }
}

export default FormButtons
