import React, { Component, PropTypes } from 'react'

export default class DynamicRedirectTo extends Component {
  static contextTypes = {
    router: PropTypes.any
  };

  redirectMe() {
    if (this.props.resolved) {
      this.context.router.replace(this.props.redirectTo)
    }
  }

  componentDidMount() {
    this.redirectMe()
  }
  componentWillReceiveProps() {
    this.redirectMe()
  }
  render() {
    return <span/>
  }

}
