import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ForwardFeedbackView } from 'components/Feedback'
import { relativizeTarget } from 'components/Routing/RoutingUtils'
import { forwardFeedbackToEmail } from 'actions/FeedbackActions'
import { fetchTransferFeedbackAddresses } from '../actions/ClientActions';
import { toJS } from '../utils/Helpers';

class ForwardFeedbackRoute extends Component {
  static contextTypes = {
    currentPath: PropTypes.string,
    router: PropTypes.any
  };

  navigateToFeedbackView = () => {
    this.context.router.push(relativizeTarget(this.context.currentPath, '..'))
  };

  forward = (fields) => {
    var {comment, emails, language} = fields
    return this.props.actions.forward(comment, emails, language).then(this.navigateToFeedbackView)
  };

  render() {
    return (
      <ForwardFeedbackView
        {...this.props}
        forward={this.forward}
        cancel={this.navigateToFeedbackView}
      />
    )
  }
}

export default connect(
  (state, props) => ({
    feedbackId: parseInt(props.params.feedbackId),
    userEmails: toJS(state.getIn(['globalData', 'transferFeedbackAddresses']))
  }),
  (dispatch, props) => ({
    actions: bindActionCreators({
      forward: (comment, emails, language) =>
        forwardFeedbackToEmail(props.feedbackId, comment, emails, language),
      getUserEmails: fetchTransferFeedbackAddresses
    }, dispatch)
  })
)
(ForwardFeedbackRoute)
