import React, { Component } from 'react'

import { MediaObject } from 'components/grid'
import { Icon, KeskoIcons } from 'components/Icons'

import { Text } from 'containers/Text'

class AddReceiverFilterButton extends Component {
  render() {
    return (
      <MediaObject
        object={<Icon icon={KeskoIcons.plus} size="xl" className="mt1 orange"/>}
        content={<span className="h3 ml1 silver"><Text k="receiverFilter.addReceiverFilter" /></span>}
        {...this.props}
      />
    )
  }
}

export default AddReceiverFilterButton
