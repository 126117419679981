import { fromJS } from 'immutable'

import { ContextActions } from 'actions/ContextActions'
import { ReportActions } from 'actions/ReportActions'
import { ContextType } from 'domain'

import createReducer from './createReducer'

const initialState = fromJS({ loading: false })
export const reducer = createReducer(initialState, {
  [ReportActions.CLEAR]: () => initialState,

  [ReportActions.GENERATE]: (state, { filter }) => state.set('filter', fromJS(filter)).remove('reportError'),
  [ReportActions.GENERATE_EXPORT]: (state, { filter }) => state.set('filter', fromJS(filter)).remove('reportError'),
  [ReportActions.GENERATE_MESSAGES]: (state, { filter }) => state.set('filter', fromJS(filter)).remove('messagesError'),
  [ReportActions.GENERATE_MESSAGES_EXPORT]: (state, { filter }) => state.set('filter', fromJS(filter)).remove('messagesError'),
  [ReportActions.GENERATE_SUMMARY]: (state, { filter }) => state.set('filter', fromJS(filter)).remove('summaryError'),
  [ReportActions.GENERATE_SUMMARY_EXPORT]: (state, { filter }) => state.set('filter', fromJS(filter)).remove('summaryError'),
  [ReportActions.GENERATE_RECEIVERS]: (state, { filter }) => state.set('filter', fromJS(filter)).remove('receiversError'),
  [ReportActions.GENERATE_RECEIVERS_EXPORT]: (state, { filter }) => state.set('filter', fromJS(filter)).remove('receiversError'),
  [ReportActions.GENERATE_RESPONSE_SUMMARY_EXPORT]: (state, { filter }) => state.set('filter', fromJS(filter)).remove('responseSummaryError'),
  [ReportActions.GENERATE_RESPONSE_SUMMARY_KESKO_EXPORT]: (state, { filter }) => state.set('filter', fromJS(filter)).remove('responseSummaryError'),
  [ReportActions.SAVE]: (state, { report }) => state.set('result', fromJS(report)),
  [ReportActions.SAVE_MESSAGES]: (state, { report }) => state.set('messagesResult', fromJS(report)),
  [ReportActions.SAVE_SUMMARY]: (state, { summary }) => state.set('summaryResult', fromJS(summary)),
  [ReportActions.SAVE_RECEIVERS]: (state, { receivers }) => state.set('receiversResult', fromJS(receivers)),
  [ReportActions.REPORT_ERROR]: (state, { err }) => state.set('reportError', fromJS(err)),
  [ReportActions.MESSAGES_ERROR]: (state, { err }) => state.set('messagesError', fromJS(err)),
  [ReportActions.SUMMARY_ERROR]: (state, { err }) => state.set('summaryError', fromJS(err)),
  [ReportActions.RECEIVERS_ERROR]: (state, { err }) => state.set('receiversError', fromJS(err)),
  [ReportActions.RESPONSE_SUMMARY_ERROR]: (state, { err }) => state.set('responseSummaryError', fromJS(err)),

  [ReportActions.START_LOADING]: state => state.set('loading', true),
  [ReportActions.END_LOADING]: state => state.set('loading', false),

  // When moving out of reporting context, we should clear the report to save memory
  [ContextActions.CLEAR]: () => initialState,
  [ContextActions.SET]: (state, { context }) => context.type === ContextType.reporting ? state : initialState
})
