import React, { Component, PropTypes } from 'react'

import { TextWithHeading } from 'components/kesko'
import { KeskoIcons } from 'components/Icons'

import { Feedback, Status } from 'domain'

class FeedbackStatusInfo extends Component {

  static propTypes = {
    feedback: PropTypes.instanceOf(Feedback).isRequired
  };

  requiresResponse() {
    return this.props.feedback.requiresResponse;
  }

  usageObjected() {
    return this.props.feedback.usageObjected;
  }

  hasHandler() {
    return !!this.props.feedback.handler;
  }

  transferred() {
    return this.props.feedback.transferred && this.props.feedback.status === Status.DONE;
  }

  handlerBlock() {
    const fb = this.props.feedback;
    return (
      <TextWithHeading
        icon={KeskoIcons.profile}
        heading="feedback.handler"
        body={fb.handler.name}
      />
    )
  }

  responseBlock() {
    const {feedback: {status, answered, customer, requiresResponse, lastMessageFrom}} = this.props
    let icon
    let heading
    if (status === Status.DONE && requiresResponse) {
      icon = answered ? KeskoIcons.tick : KeskoIcons.exclamation;
      heading = answered ? 'feedback.messageSent' : 'feedback.responseNotSent'
    } else if (status === Status.WAITING) {
      icon = this.getPreferredContactIcon(customer);
      heading = lastMessageFrom === 'handler' ? 'feedback.messageSent' : 'feedback.messageReceived'
    } else if (requiresResponse) {
      icon = this.getPreferredContactIcon(customer);
      heading = 'feedback.responseNeeded'
    } else {
      return false
    }
    return (
      <TextWithHeading
        icon={icon}
        heading={heading}
      />
    )
  }

  getPreferredContactIcon(customer) {
    if (customer && customer.preferredContactChannel === 'facebook') {
      return KeskoIcons.facebook
    } else if (customer && customer.preferredContactChannel === 'phone') {
      return KeskoIcons.phone
    } else {
      return KeskoIcons.mail
    }
  }

  transferredBlock() {
    return (
      <TextWithHeading
        icon={KeskoIcons.send}
        heading="feedback.transferred"
      />
    )
  }

  render() {
    const responseBlockComponent = this.responseBlock()
    return (
      <div className="feedback-status-info" {...this.props}>
        {this.transferred() && (this.transferredBlock())}
        {!this.transferred() && responseBlockComponent}
        {this.hasHandler() && this.handlerBlock()}
      </div>
    )
  }
}

export default FeedbackStatusInfo
