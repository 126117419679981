import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ExposeWrapped } from 'containers/Utils'
import { toggle, open, close, register, deregister } from 'actions/CollapseActions'

export class Collapse extends Component {

  componentWillMount = () => this.props.actions.register(this.props.visible);
  componentWillUnmount = () => {
    if (this.props.deregisterOnUnmount) {
      this.props.actions.deregister()
    }
  };

  doAndReturnPromise = (cb) => new Promise((resolve) => {
    cb();
    setTimeout(resolve, this.props.transitionTime)
  });

  toggle = () => this.doAndReturnPromise(this.props.actions.toggle);
  open = () => this.doAndReturnPromise(this.props.actions.open);
  close = () => this.doAndReturnPromise(this.props.actions.close);
  isOpen = () => this.props.visible;

  render() {
    const {visible, children, collapseId, animated, transition, transitionTime, className, wrapperClassName} = this.props;
    const wrapperClasses = classNames(
      'clearfix',
      wrapperClassName
    );

    const content = visible ? (
      <div key={`Collapse-${collapseId}`} className={wrapperClasses}>
        {children}
      </div>
    ) : null;

    const animatedContent = (
      <ReactCSSTransitionGroup
        transitionName={transition}
        transitionEnterTimeout={transitionTime}
        transitionLeaveTimeout={transitionTime}
        className={className}
      >
        {content}
      </ReactCSSTransitionGroup>
    );

    return animated ? animatedContent : content;
  }
}

Collapse.defaultProps = {
  deregisterOnUmount: false,
  initiallyVisible: false,
  transition: 'trans-collapse',
  transitionTime: 300,
  animated: true
};

Collapse.propTypes = {
  collapseId: PropTypes.any.isRequired,
  deregisterOnUmount: PropTypes.bool.isRequired,
  initiallyVisible: PropTypes.bool.isRequired,
  transition: PropTypes.string.isRequired,
  transitionTime: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  animated: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => ({
  visible: state.getIn(['collapses', props.collapseId], props.initiallyVisible)
});

const mapDispatchToProps = (dispatch, props) => ({
  actions: bindActionCreators({
    register: (state) => register(props.collapseId, state),
    deregister: () => deregister(props.collapseId),
    toggle: () => toggle(props.collapseId),
    open: () => open(props.collapseId),
    close: () => close(props.collapseId)
  }, dispatch)
});

export default ExposeWrapped(['isOpen', 'toggle', 'open', 'close'])(connect(mapStateToProps, mapDispatchToProps, undefined, {withRef: true})(Collapse))
