import React, { Component, PropTypes } from 'react'
import { Link } from 'react-router'

import { relativizeTarget } from './RoutingUtils'

class RelativeLink extends Component {
  render() {
    const { to, ...props } = this.props
    const normalized = relativizeTarget(this.context.currentPath, to)
    return (
      <Link to={normalized} {...props}>{this.props.children}</Link>
    )
  }
}
RelativeLink.contextTypes = {
  currentPath: PropTypes.string
}
RelativeLink.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired
}

export default RelativeLink
