import Serializable from './Serializable'

class Customer extends Serializable {
  constructor(data) {
    super(data, 'Customer')
  }

  get name() {
    return this._data.get('name')
  }

  get companyName() {
    return this._data.get('companyName')
  }

  get otherCustomerId() {
    return this._data.get('otherCustomerId')
  }

  get email() {
    return this._data.get('email')
  }

  get phone() {
    return this._data.get('phone')
  }

  get streetAddress() {
    return this._data.get('streetAddress')
  }

  get postalCode() {
    return this._data.get('postalCode')
  }

  get postName() {
    return this._data.get('postName')
  }

  get preferredContactChannel() {
    return this._data.get('preferredContactChannel')
  }

  get removed() {
    return this._data.get('removed')
  }

  get plussaCardNumber() {
    return this._data.get('plussaCardNumber')
  }

  get plussaPersonId() {
    return this._data.get('plussaPersonId')
  }

  get b2cAccountId() {
    return this._data.get('b2cAccountId')
  }

}

export default Customer
