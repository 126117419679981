import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'

import { Col } from 'components/grid'

import { RelativeLink } from 'components/Routing'
import { createUuid } from 'utils'

import { Text } from 'containers/Text'
import { Dialog } from 'containers/Dialog'
import { Feedback } from 'domain'
import FeedbackDate from './FeedbackDate'

import { fetchRespondingUsers } from 'actions/ClientActions'
import Button from '../kesko/common/Button';
import KeskoIcons from '../Icons/KeskoIcons';

const toList = obj => obj ? obj.toList() : obj;

const ContactChannelButton = ({translation, isPreferred, relativeLinkTo}) => {
  return (
    <RelativeLink to={relativeLinkTo}>
      {isPreferred ? (
      <Button uppercase className="preferred-contact-button">
        <span>
          <Text k={translation} className="underline"/>
          <br />
          <Text k="feedback.reply.selection.preferredContactMethod" className="no-transform regular"/>
        </span>
      </Button>
      ) : (
      <Button translation={translation} uppercase/>
      )}
    </RelativeLink>
  );
};

const SingleRespondingUser = ({user}) => (
  <Col>
    <span>{user.name} </span>
    <span><Text k="feedback.reply.responding.startedResponding" /> </span>
    <span><FeedbackDate date={user.started} /></span>
  </Col>
);

const mapStateToProps = state => ({
  respondingUsers: toList(state.getIn(['globalData', 'respondingUsers']))
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    fetchRespondingUsers: () => dispatch(fetchRespondingUsers(props.feedback.id))
  }
})

class ReplyToFeedbackLink extends Component {
  static propTypes = {
    feedback: PropTypes.instanceOf(Feedback).isRequired,
    respondingUsers: PropTypes.instanceOf(List),
    actions: PropTypes.shape({
      fetchRespondingUsers: PropTypes.func.isRequired
    }).isRequired
  };

  static contextTypes = {
    router: PropTypes.any
  };

  uuid = createUuid();

  bindDialog = dialog => this.dialog = dialog
  openDialog = event => {
    event.preventDefault()
    event.stopPropagation()
    this.props.actions.fetchRespondingUsers()
    this.dialog.open()
  };
  cancel = () => this.dialog.close();

  renderDialog() {
    const {feedback, respondingUsers} = this.props;

    const respondingUsersBlock = respondingUsers && respondingUsers.size > 0 ? (
      <Col className="mb2 silver">
        <h3 className="blue mt0"><Text k="feedback.reply.responding.note" /></h3>
        {respondingUsers.map((user, k) => <SingleRespondingUser key={k} user={user} />).toJS()}
      </Col>
    ) : null;

    const replyViaUberall = feedback.customer.preferredContactChannel === 'uberall' ? (
      <ContactChannelButton
        relativeLinkTo="replyViaUberall"
        translation="feedback.reply.selection.replyViaUberall"
        isPreferred={true}
      />
    ) : null;

    const replyViaFacebook = feedback.customer.preferredContactChannel === 'facebook' ? (
      <ContactChannelButton
        relativeLinkTo="replyViaFacebook"
        translation="feedback.reply.selection.replyViaFacebook"
        isPreferred={true}
      />
    ) : null;

    const replyViaEmail = feedback.customer.email ? (
      <ContactChannelButton
        relativeLinkTo="reply"
        translation="feedback.reply.selection.replyByEmail"
        isPreferred={feedback.customer.preferredContactChannel === 'email'}
      />
    ) : null;

    return (
      <Dialog dialogId={this.uuid} ref={this.bindDialog} titleText="feedback.reply.selection.title">
        {respondingUsersBlock}
        {replyViaUberall}
        {replyViaFacebook}
        {replyViaEmail}
        <ContactChannelButton
          relativeLinkTo="replyByPhone"
          translation={"feedback.reply.selection.replyByPhone"}
          isPreferred={feedback.customer.preferredContactChannel === 'phone'}
        />
        <Button
          uppercase
          theme={Button.theme.cancel}
          translation="feedback.reply.selection.cancel"
          onClick={this.cancel}
        />
      </Dialog>
    );
  }

  render() {
    const { classNames } = this.props
    const className = classNames ? 'reply-to-feedback-block ' + classNames : 'reply-to-feedback-block'
    return (
      <div className={className}>
        <Button
          onClick={this.openDialog}
          theme={Button.theme.primary}
          iconLeft={KeskoIcons.reply}
          translation="feedback.reply.goToReply"
        />
        {this.renderDialog()}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplyToFeedbackLink);
