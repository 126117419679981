
export const DialogActions = {
  OPEN: 'Dialog/Open',
  CLOSE: 'Dialog/Close',
  DESTROY: 'Dialog/Destroy'
}

export const open = (dialogId) => ({ type: DialogActions.OPEN, dialogId })
export const close = (dialogId) => ({ type: DialogActions.CLOSE, dialogId })
export const destroy = (dialogId) => ({ type: DialogActions.DESTROY, dialogId })
