import React, { Component, PropTypes } from 'react'

import { TranslateProps } from 'containers/Text'

import AddErrorDisplay from 'components/Forms/common/AddErrorDisplay'

const MONDAY = 1

const RANGE = 'range';
const NUM_CALS = 1;
const EARLIEST_POSSIBLE_DATE = new Date(2010, 0, 1);

const today = () => {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate())
}

class DateRangePicker extends Component {

  static contextTypes = {
    locale: PropTypes.string.isRequired
  };

  static propTypes = {
    singleDateRange: PropTypes.bool.isRequired,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
    value: PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date)
    })
  };

  static defaultProps = {
    singleDateRange: true
  };

  componentWillMount() {
    require.ensure(['moment', 'moment-range', 'moment/locale/fi', 'moment/locale/sv', 'react-daterange-picker'], require => {
      const moment = require('moment')
      require('moment-range')
      require('moment/locale/fi')
      require('moment/locale/sv')
      moment.locale(this.context.locale)
      this.DatepickerComponent = require('react-daterange-picker')
      this.toMomentValue = value => {
        if (value && value.start) {
          const start = moment(value.start)
          const end = moment(value.end)
          return moment.range(start, end)
        }
        return undefined
      }
      this.forceUpdate()
    }, 'daterangepicker')
  }

  handleSelect = value => {
    this.props.onChange({
      start: value.start.toDate(),
      end: value.end.toDate()
    })
  };

  render() {
    const { value, singleDateRange, min, max } = this.props
    const minimumDate = min || EARLIEST_POSSIBLE_DATE
    const maximumDate = max || today()
    if (this.DatepickerComponent && this.toMomentValue) {
      const dateValue = this.toMomentValue(value)
      const ReactDaterangePicker = this.DatepickerComponent
      return (
        <ReactDaterangePicker
          value={dateValue}
          firstOfWeek={MONDAY}
          singleDateRange={singleDateRange}
          selectionType={RANGE}
          numberOfCalendars={NUM_CALS}
          minimumDate={minimumDate}
          maximumDate={maximumDate}
          onSelect={this.handleSelect}
        />
      )
    }
    return (<span/>)
  }
}

const EnhancedComponent = AddErrorDisplay(TranslateProps(DateRangePicker))
EnhancedComponent.propTypes = DateRangePicker.propTypes
EnhancedComponent.defaultProps = DateRangePicker.defaultProps
export default EnhancedComponent
