import React, { Component, PropTypes } from 'react'
import { List } from 'immutable'

import FacebookSection from './Facebook/FacebookSection'
import StoreSettingsView from './StoreSettings/StoreSettingsView'
import ResponseTemplateView from './ResponseTemplate/ResponseTemplateView'

class SettingsForm extends Component {
  static propTypes = {
    facebookActions: PropTypes.shape({
      createFacebookReceiver: PropTypes.func.isRequired,
      checkFacebookLogin: PropTypes.func.isRequired,
      removeFacebookReceiver: PropTypes.func.isRequired,
      removeFacebookLinkingConsent: PropTypes.func.isRequired,
      facebookLoginSuccess: PropTypes.func.isRequired,
      logoutFromFacebook: PropTypes.func.isRequired
    }).isRequired,
    storeSettingsActions: PropTypes.shape({
      changeStore: PropTypes.func.isRequired,
      saveStoreSettings: PropTypes.func.isRequired
    }).isRequired,
    responseTemplateActions: PropTypes.shape({
      saveResponseTemplate: PropTypes.func.isRequired
    }).isRequired,
    showFacebookSettings: PropTypes.bool,
    showResponseTemplates: PropTypes.bool,
    chainUnits: PropTypes.instanceOf(List),
    subjects: PropTypes.instanceOf(List),
    stores: PropTypes.instanceOf(List),
    permissions: PropTypes.object.isRequired,
    facebookPages: PropTypes.instanceOf(List),
    facebookPage: PropTypes.object,
    facebookReceivers: PropTypes.instanceOf(List),
    savingReceiver: PropTypes.bool.isRequired,
    checkingFacebookLogin: PropTypes.bool.isRequired,
    loggedInFacebook: PropTypes.bool.isRequired,
    allowFacebookLinking: PropTypes.bool.isRequired,
    isStoreUser: PropTypes.bool.isRequired,
    removingConsent: PropTypes.bool,
    storeSettings: PropTypes.object,
    allowedTransferFeedbackDomains: PropTypes.instanceOf(List),
    responseTemplates: PropTypes.instanceOf(List)
  }

  render() {
    const {
      isStoreUser, chainUnits, subjects, stores, permissions, showFacebookSettings,
      facebookPages, facebookReceivers, savingReceiver, checkingFacebookLogin, loggedInFacebook,
      facebookUserName, allowFacebookLinking, removingConsent, storeSettings, allowedTransferFeedbackDomains,
      showResponseTemplates, responseTemplates, storeSettingsActions, facebookActions, responseTemplateActions
    } = this.props;
    return (
      <div>
        {showFacebookSettings && (
          <FacebookSection
            initialValues={{allowLinking: allowFacebookLinking}}
            chainUnits={chainUnits}
            subjects={subjects}
            stores={stores}
            facebookPages={facebookPages}
            facebookReceivers={facebookReceivers}
            savingReceiver={savingReceiver}
            checkingFacebookLogin={checkingFacebookLogin}
            loggedInFacebook={loggedInFacebook}
            facebookUserName={facebookUserName}
            isStoreUser={isStoreUser}
            removingConsent={removingConsent}
            actions={facebookActions}
          />
        )}
        <StoreSettingsView
          storeSettings={storeSettings}
          stores={stores.toJS()}
          permissions={permissions}
          actions={storeSettingsActions}
          allowedTransferFeedbackDomains={allowedTransferFeedbackDomains.toJS()}
        />
        {showResponseTemplates && (
          <ResponseTemplateView
            initialValues={{selectedTemplate: responseTemplates.get(0)}}
            responseTemplates={responseTemplates}
            actions={responseTemplateActions}
          />
        )}
      </div>
    )
  }
}

export default SettingsForm
