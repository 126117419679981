import React, {Component, PropTypes} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {FeedbackReplyView} from 'components/Feedback'
import {RoutingUtils} from 'components/Routing'
import Feedback from 'domain/Feedback'
import {reply as sendResponse, saveResponseDraft} from '../actions/FeedbackActions'
import {addAttachment, clearAttachments, removeAttachment, updateAttachment, uploadAttachment} from 'actions/AttachmentActions'
import {initializeDraftAttachments, removeDraftAttachment} from 'actions/DraftAttachmentActions'
import {startResponding, stopResponding} from 'actions/ClientActions'
import {resetForm, setReplyFormValues} from 'actions/FeedbackReplyActions'

class ReplyToFeedbackRoute extends Component {
  componentWillMount() {
    this.props.actions.initializeDraftAttachments(this.props.feedback)
  }

  componentWillUnmount() {
    this.props.actions.resetForm();
  }

  static contextTypes = {
    currentPath: PropTypes.string,
    router: PropTypes.any
  }
  static propTypes = {
    feedback: PropTypes.instanceOf(Feedback).isRequired
  }

  navigateToFeedbackView = () => {
    this.context.router.push(RoutingUtils.relativizeTarget(this.context.currentPath, '..'))
  }

  sendReplyAndMarkDone = reply => this.props.actions.reply(reply, true).then(this.navigateToFeedbackView)
  sendReplyAndMarkWaiting = reply => this.props.actions.reply(reply, false).then(this.navigateToFeedbackView)
  saveDraft = reply => this.props.actions.saveDraft(reply).then(this.navigateToFeedbackView)

  init = () => {
    this.props.actions.resetForm();
    this.props.actions.startResponding()
  }

  cancel = () => {
    this.props.actions.clearAttachments()
    this.navigateToFeedbackView()
  }
  cleanup = () => this.props.actions.stopResponding();

  render() {
    const props = {
      feedback: this.props.feedback,
      attachments: this.props.attachments,
      draftAttachments: this.props.draftAttachments,
      userName: this.props.userName,
      proposeResponses: this.props.proposeResponses,
      initialMessage: this.props.initialMessage,
      initialTemplateIds: this.props.initialTemplateIds,
      isInitialization: this.props.isInitialization,
      actions: {
        setFormValues: this.props.actions.setFormValues,
        sendReplyAndMarkDone: this.sendReplyAndMarkDone,
        sendReplyAndMarkWaiting: this.sendReplyAndMarkWaiting,
        saveDraft: this.saveDraft,
        cancel: this.cancel,
        init: this.init,
        cleanup: this.cleanup,
        addAttachment: this.props.actions.addAttachment,
        updateAttachment: this.props.actions.updateAttachment,
        removeAttachment: this.props.actions.removeAttachment,
        uploadAttachment: this.props.actions.uploadAttachment,
        removeDraftAttachment: this.props.actions.removeDraftAttachment
      }
    }
    return (<FeedbackReplyView {...props} />)
  }
}

const mapStateToProps = state => ({
  userName: state.getIn(['client', 'userInfo', 'displayName']),
  attachments: state.getIn(['attachments', 'attachments']),
  draftAttachments: state.getIn(['attachments', 'draftAttachments']),
  proposeResponses: state.getIn(['client', 'userInfo', 'profile', 'proposeResponses']),
  initialMessage: state.getIn(['feedbackReply', 'message']),
  initialTemplateIds: state.getIn(['feedbackReply', 'templateIds']),
  isInitialization: state.getIn(['feedbackReply', 'isInitialization'])
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: bindActionCreators({
    reply: (reply, markAsHandled) => dispatch(() => sendResponse(props.feedbackId, reply.message, reply.attachmentIds, markAsHandled, reply.templateIds)),
    saveDraft: reply => dispatch(() => saveResponseDraft(props.feedbackId, reply.message, reply.attachmentIds)),
    startResponding: () => dispatch(() => startResponding(props.feedbackId)),
    stopResponding: () => dispatch(() => stopResponding(props.feedbackId)),
    clearAttachments: () => dispatch(() => clearAttachments()),
    resetForm: () => dispatch(() => resetForm()),
    setFormValues: (message, templateIds) => dispatch(() => setReplyFormValues(message, templateIds)),
    addAttachment,
    updateAttachment,
    removeAttachment,
    removeDraftAttachment,
    uploadAttachment,
    initializeDraftAttachments
  }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ReplyToFeedbackRoute)
