import { loginSuccessful, loginError, whoamiFailed, authError, aadLoginError, aadLoginSuccessful} from './LoginActions'
import { loadSingleFeedback } from './FeedbackActions'
import { loadAndInitializeBusinessUnit } from './BusinessUnitActions'
import { addFeedbacks, startLoading, endLoading } from './FeedbackListActions'
import { show404Page, showFeedbackList } from './ErrorActions'
import { setGlobalData } from './GlobalDataActions'
import { info, warn } from 'utils/Log'
import { defined } from 'utils'
import { clearAttachments } from './AttachmentActions';

const prefix = action => 'Client/' + action

export const ClientActions = {
  ERROR: prefix('Error'),
  OBJECTED_ERROR: prefix('OBJECTED_ERROR'),
  DISMISS_ERROR: prefix('DISMISS_ERROR'),

  FETCH_BUSINESS_UNIT: prefix('GET/BusinessUnit'),
  FETCH_BUSINESS_UNITS: prefix('GET/BusinessUnits'),
  FETCH_BUSINESS_UNIT_FEEDBACKS: prefix('GET/BusinessUnitFeedbacks'),
  FETCH_CHAIN_UNITS: prefix('GET/ChainUnits'),
  FETCH_FEEDBACK_PREVIEWS: prefix('GET/FeedbackPreviews'),
  FETCH_FEEDBACK: prefix('GET/SingleFeedback'),
  FETCH_EXTERNAL_SYSTEMS: prefix('GET/ExternalSystems'),
  FETCH_FEEDBACK_CHANNELS: prefix('GET/FeedbackChannels'),
  FETCH_SUBJECTS: prefix('GET/Subjects'),
  FETCH_TOPIC: prefix('GET/Topics'),
  FETCH_COMPANY_HANDLERS: prefix('GET/CompanyHandlers'),
  FETCH_REPORTING_HANDLERS: prefix('GET/ReportingHandlers'),
  FETCH_TRANSFER_FEEDBACK_ADDRESSES: prefix('GET/TransferFeedbackAddresses'),

  SAVE_COMMENT: prefix('PUT/Comment/Init'),
  SAVE_COMMENT_SUCCESS: prefix('PUT/Comment/Success'),

  SAVE_COMMENT_REQUEST: prefix('PUT/CommentRequest/Init'),
  SAVE_COMMENT_REQUEST_SUCCESS: prefix('PUT/CommentRequest/Success'),

  SAVE_RESPONSE_DRAFT: prefix('PUT/SaveResponseDraft/Init'),
  SAVE_RESPONSE_DRAFT_SUCCESS: prefix('PUT/SaveResponseDraft/Success'),

  SEND_RESPONSE: prefix('PUT/Response/Init'),
  SEND_RESPONSE_SUCCESS: prefix('PUT/Response/Success'),

  ADD_RESPONSE_COMMENT: prefix('PUT/ResponseComment/Init'),
  ADD_RESPONSE_COMMENT_SUCCESS: prefix('PUT/ResponseComment/Success'),

  TRANSFER_FEEDBACK: prefix('PUT/Transfer/Init'),
  TRANSFER_FEEDBACK_SUCCESS: prefix('PUT/Transfer/Success'),

  FORWARD_FEEDBACK: prefix('PUT/Feedback/Forward'),
  CHANGE_FEEDBACK_CONTACT: prefix('PUT/Feedback/Contact'),

  SEND_FEEDBACK: prefix('PUT/SendFeedback'),

  MARK_FEEDBACK_DONE: prefix('PUT/Feedback/Done'),
  MARK_FEEDBACK_READ: prefix('PUT/Feedback/MarkRead'),
  UPDATE_FEEDBACK_HANDLER: prefix('PUT/Feedback/Handler'),
  REOPEN_FEEDBACK: prefix('PUT/Feedback/Reopen'),
  REPORT_AMBIANCE_ERROR: prefix('PUT/Feedback/ReportAmbianceError'),
  REPORT_TOPIC_ERROR: prefix('PUT/Feedback/ReportTopicError'),
  REPORT_THREAT: prefix('PUT/Feedback/ReportThreat'),
  UPDATE_FEEDBACK_STATUS: prefix('PUT/Feedback/UpdateStatus'),
  REMOVE_MESSAGE: prefix('DELETE/Feedback/Message'),

  AAD_LOGIN: prefix('POST/AadLogin'),
  LOGIN: prefix('POST/Login'),
  LOGOUT: prefix('POST/Logout'),
  WHOAMI: prefix('GET/Whoami'),

  SEARCH: prefix('GET/Search'),

  GENERATE_REPORT: prefix('POST/Report'),
  GENERATE_REPORT_EXPORT: prefix('PUT/ReportExport'),
  GENERATE_MESSAGES_REPORT: prefix('PUT/MessagesReport'),
  GENERATE_MESSAGES_REPORT_EXPORT: prefix('PUT/MessagesReportExport'),
  GENERATE_SUMMARY_REPORT: prefix('PUT/SummaryReport'),
  GENERATE_SUMMARY_REPORT_EXPORT: prefix('PUT/SummaryReportExport'),
  GENERATE_RECEIVERS_REPORT: prefix('PUT/ReceiversReport'),
  GENERATE_RECEIVERS_REPORT_EXPORT: prefix('PUT/ReceiversReportExport'),
  GENERATE_RESPONSE_SUMMARY_REPORT_EXPORT: prefix('PUT/ResponseSummaryReportExport'),
  GENERATE_RESPONSE_SUMMARY_KESKO_REPORT_EXPORT: prefix('PUT/ResponseSummaryKeskoReportExport'),
  EXPORT_FEEDBACK_MESSAGES: prefix('GET/ExportMessages'),

  START_RESPONDING: prefix('PUT/StartResponding'),
  STOP_RESPONDING: prefix('PUT/StopResponding'),
  FETCH_RESPONDING_USERS: prefix('GET/RespondingUsers'),

  SAVE_PROFILE: prefix('PUT/Profile'),

  FETCH_STORE_SETTINGS: prefix('GET/StoreSettings'),
  SAVE_STORE_SETTINGS: prefix('PUT/StoreSettings'),

  FETCH_FACEBOOK_DATA: prefix('GET/Facebook/Data'),
  CREATE_FACEBOOK_RECEIVER: prefix('PUT/Facebook/Receiver'),
  REMOVE_FACEBOOK_RECEIVER: prefix('DELETE/Facebook/Receiver'),
  REMOVE_FACEBOOK_LINKING_CONSENT: prefix('DELETE/Facebook/LinkingConsent'),
  FACEBOOK_LOGOUT: prefix('DELETE/Facebook/Logout'),

  UPLOAD_ATTACHMENT: prefix('POST/Attachment/Upload'),

  FETCH_ALLOWED_TRANSFER_DOMAINS: prefix('GET/StoreSettings/AllowedTransferDomains'),

  FETCH_RESPONSE_TEMPLATES: prefix('GET/ResponseTemplates'),
  SAVE_RESPONSE_TEMPLATE: prefix('PUT/ResponseTemplate'),

  ADD_INFORMATION_TO_OBJECTEDLIST: prefix('PUT/Feedback/CustomerInformationUsageObjected')
}

export const clientError = err => {
  return {
    type: ClientActions.ERROR,
    payload: {
      err
    }
  }
}

export const objectedError = err => {
  return {
    type: ClientActions.OBJECTED_ERROR,
    payload: {
      err
    }
  }
}

export const dismissError = () => {
  return {
    type: ClientActions.DISMISS_ERROR
  }
}

const dispatchClientError = err => dispatch => {
  if (err.response && err.response.status === 401) {
    dispatch(dismissError())
    dispatch(authError())
  } else if (err.response && err.response.status === 403) {
    info('Got response with 403, moving to 404 page')
    dispatch(dismissError())
    dispatch(show404Page())
  } else {
    dispatch(clientError(err))
  }
}

export const fetchBusinessUnit = businessUnitId => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_BUSINESS_UNIT,
    clientMethod: 'getBusinessUnit',
    payload: {
      businessUnitId
    },
    success: data => dispatch => {
      dispatch(dismissError())
      dispatch(loadAndInitializeBusinessUnit(data))
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchExternalSystems = () => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_EXTERNAL_SYSTEMS,
    clientMethod: 'getExternalSystems',
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchFeedbackChannels = () => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_FEEDBACK_CHANNELS,
    clientMethod: 'getFeedbackChannels',
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchSubjects = () => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_SUBJECTS,
    clientMethod: 'getSubjects',
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchTopics = () => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_TOPICS,
    clientMethod: 'getTopics',
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchBusinessUnits = () => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_BUSINESS_UNITS,
    clientMethod: 'getBusinessUnits',
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchChainUnits = () => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_CHAIN_UNITS,
    clientMethod: 'getChainUnits',
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchCompanyHandlers = companyId => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_COMPANY_HANDLERS,
    clientMethod: 'getCompanyHandlers',
    payload: {
      companyId
    },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchReportingHandlers = () => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_REPORTING_HANDLERS,
    clientMethod: 'getReportingHandlers',
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchRespondingUsers = feedbackId => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_RESPONDING_USERS,
    clientMethod: 'getRespondingUsers',
    payload: {
      feedbackId
    },
    success: data => dispatch => {
      dispatch(dismissError())
      dispatch(setGlobalData('respondingUsers', data))
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchTransferFeedbackAddresses = () => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_TRANSFER_FEEDBACK_ADDRESSES,
    clientMethod: 'getTransferFeedbackAddresses',
    success: data => dispatch => {
      dispatch(dismissError())
      dispatch(setGlobalData('transferFeedbackAddresses', data))
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchResponseTemplates = () => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_RESPONSE_TEMPLATES,
    clientMethod: 'getResponseTemplates',
    success: data => dispatch => {
      dispatch(dismissError())
      dispatch(setGlobalData('responseTemplates', data))
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const saveResponseTemplate = (id, name, template) => dispatch => new Promise((resolve, reject) => {
  defined(id, 'saveResponseTemplate>id')
  defined(name, 'saveResponseTemplate>name')
  defined(template, 'saveResponseTemplate>template')
  dispatch({
    type: ClientActions.SAVE_RESPONSE_TEMPLATE,
    clientMethod: 'putResponseTemplate',
    payload: { id, name, template },
    success: () => dispatch => {
      dispatch(dismissError())
      resolve()
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchPreviews = (payload) => (dispatch) => new Promise((resolve, reject) => {
  const { feedbackListId, filter, lastFeedbackId, lastDate } = payload
  defined(feedbackListId, 'fetchPreviews>feedbackListId')
  defined(filter, 'fetchPreviews>filter')
  dispatch(startLoading(feedbackListId))
  dispatch({
    type: ClientActions.FETCH_FEEDBACK_PREVIEWS,
    clientMethod: 'getFeedbackPreviews',
    payload: {
      ...filter,
      lastFeedbackId,
      lastDate
    },
    success: ({ feedbackPreviews, hasMore }) => dispatch => {
      dispatch(dismissError())
      dispatch(addFeedbacks(feedbackListId, feedbackPreviews, hasMore))
      dispatch(endLoading(feedbackListId))
      resolve()
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      dispatch(endLoading(feedbackListId))
      reject(err)
    }
  })
})

const fetchSingleFeedbackDefaultError = (dispatch, resolve, reject, err) => {
  if (err.response && err.response.status === 401) {
    dispatch(dismissError())
    dispatch(authError())
  } else if (err.response && err.response.status === 403) {
    info('Got response with status 403, moving to 404 page')
    dispatch(dismissError())
    dispatch(show404Page())
  } else if (err.response && err.response.status === 404) {
    dispatch(dismissError())
    dispatch(show404Page())
  } else {
    dispatch(clientError(err))
  }
  reject(err)
}

export const fetchSingleFeedbackCustom403ErrorShowFeedbacks = (notAuthorizedAction) => (dispatch, resolve, reject, err) => {
  if (err.response && err.response.status === 403) {
    warn('Got response with 403, moving to feedback list')
    dispatch(dismissError())
    dispatch(notAuthorizedAction)
    dispatch(showFeedbackList())
  } else {
    dispatch(clientError(err))
  }
}

export const fetchSingleFeedback = (feedbackId, clientErrorAction = fetchSingleFeedbackDefaultError) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.FETCH_FEEDBACK,
      clientMethod: 'getSingleFeedback',
      payload: {
        feedbackId
      },
      success: fb => dispatch => {
        dispatch(dismissError())
        dispatch(loadSingleFeedback(fb))
        resolve(fb)
      },
      error: err => dispatch => {
        clientErrorAction(dispatch, resolve, reject, err)
      }
    })
  })
}

export const startResponding = feedbackId => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.START_RESPONDING,
      clientMethod: 'startResponding',
      payload: {
        feedbackId,
        feedbackVersion
      },
      success: versionResponse => dispatch => {
        dispatch(dismissError())
        resolve(versionResponse)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const stopResponding = feedbackId => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.STOP_RESPONDING,
      clientMethod: 'stopResponding',
      payload: {
        feedbackId,
        feedbackVersion
      },
      success: versionResponse => dispatch => {
        dispatch(dismissError())
        resolve(versionResponse)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const markFeedbackDone = feedbackId => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.MARK_FEEDBACK_DONE,
      clientMethod: 'markFeedbackDone',
      payload: {
        feedbackId,
        feedbackVersion
      },
      success: versionResponse => dispatch => {
        dispatch(dismissError())
        resolve(versionResponse)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })

}

export const reopenFeedback = feedbackId => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.REOPEN_FEEDBACK,
      clientMethod: 'reopenFeedback',
      payload: {
        feedbackId,
        feedbackVersion
      },
      success: versionResponse => dispatch => {
        dispatch(dismissError())
        resolve(versionResponse)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const reportFeedbackAmbianceError = feedbackId => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.REPORT_AMBIANCE_ERROR,
      clientMethod: 'reportAmbianceError',
      payload: {
        feedbackId,
        feedbackVersion
      },
      success: versionResponse => dispatch => {
        dispatch(dismissError())
        resolve(versionResponse)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const reportFeedbackTopicError = feedbackId => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.REPORT_TOPIC_ERROR,
      clientMethod: 'reportTopicError',
      payload: {
        feedbackId,
        feedbackVersion
      },
      success: versionResponse => dispatch => {
        dispatch(dismissError())
        resolve(versionResponse)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}


export const setFeedbackCustomerInformationAsObjected = (feedbackId, comment) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.ADD_INFORMATION_TO_OBJECTEDLIST,
      clientMethod: 'setCustomerInformationAsObjected',
      payload: {
        feedbackId,
        comment
      },
      success: response => dispatch => {
        dispatch(dismissError())
        dispatch(saveCommentSuccess(feedbackId))
        resolve(response)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const reportFeedbackThreat = feedbackId => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.REPORT_THREAT,
      clientMethod: 'reportThreat',
      payload: {
        feedbackId,
        feedbackVersion
      },
      success: versionResponse => dispatch => {
        dispatch(dismissError())
        resolve(versionResponse)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const updateFeedbackStatus = (feedbackId, status, comment) => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.UPDATE_FEEDBACK_STATUS,
      clientMethod: 'updateFeedback',
      payload: {
        feedbackId,
        status,
        comment,
        feedbackVersion
      },
      success: versionResponse => dispatch => {
        dispatch(dismissError())
        resolve(versionResponse)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const removeMessage = (feedbackId, messageId) => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.REMOVE_MESSAGE,
      clientMethod: 'removeMessage',
      payload: {
        feedbackId,
        messageId,
        feedbackVersion
      },
      success: versionResponse => dispatch => {
        dispatch(dismissError())
        resolve(versionResponse)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const saveCommentSuccess = (feedbackId) => ({
  type: ClientActions.SAVE_COMMENT_SUCCESS,
  feedbackId
})

export const saveComment = (feedbackId, commentText, attachmentIds) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.SAVE_COMMENT,
      clientMethod: 'putComment',
      payload: {
        feedbackId,
        commentText,
        attachmentIds
      },
      success: response => dispatch => {
        dispatch(dismissError())
        dispatch(saveCommentSuccess(feedbackId))
        resolve(response)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const sendCommentRequestSuccess = (feedbackId) => ({
  type: ClientActions.SAVE_COMMENT_REQUEST_SUCCESS,
  feedbackId
})

export const sendCommentRequest = (feedbackId, commentRequestText, emails, language) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.SAVE_COMMENT_REQUEST,
      clientMethod: 'putCommentRequest',
      payload: {
        feedbackId,
        commentRequestText,
        emails,
        language
      },
      success: response => dispatch => {
        dispatch(dismissError())
        dispatch(sendCommentRequestSuccess(feedbackId))
        resolve(response)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const saveResponseDraftSuccess = (feedbackId) => ({
  type: ClientActions.SAVE_RESPONSE_DRAFT_SUCCESS,
  feedbackId
})

export const saveResponseDraft = (feedbackId, responseText, attachmentIds) => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.SAVE_RESPONSE_DRAFT,
      clientMethod: 'putResponseDraft',
      payload: {
        feedbackId,
        feedbackVersion,
        responseText,
        attachmentIds
      },
      success: response => dispatch => {
        dispatch(dismissError())
        dispatch(clearAttachments())
        dispatch(saveResponseDraftSuccess(feedbackId))
        resolve(response)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const sendResponseSuccess = (feedbackId) => ({
  type: ClientActions.SEND_RESPONSE_SUCCESS,
  feedbackId
})

export const sendResponse = (feedbackId, responseText, attachmentIds, markAsHandled, contactChannel, templateIds) => {
  return (dispatch, getState) => {
    const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version

    return new Promise((resolve, reject) => {
      dispatch({
        type: ClientActions.SEND_RESPONSE,
        clientMethod: 'putResponse',
        payload: {
          feedbackId,
          feedbackVersion,
          responseText,
          attachmentIds,
          markAsHandled,
          contactChannel,
          templateIds
        },
        success: response => dispatch => {
          dispatch(dismissError())
          dispatch(sendResponseSuccess(feedbackId))
          resolve(response)
        },
        error: err => dispatch => {
          dispatch(dispatchClientError(err))
          reject(err)
        }
      })
    })
  }
}

export const addResponseCommentSuccess = (feedbackId) => ({
  type: ClientActions.ADD_RESPONSE_COMMENT_SUCCESS,
  feedbackId
})

export const addResponseComment = (feedbackId, commentText, markAsHandled) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.ADD_RESPONSE_COMMENT,
      clientMethod: 'putResponseComment',
      payload: {
        feedbackId,
        commentText,
        markAsHandled
      },
      success: response => dispatch => {
        dispatch(dismissError())
        dispatch(addResponseCommentSuccess(feedbackId))
        resolve(response)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const transferFeedbackSuccess = (feedbackId) => ({
  type: ClientActions.TRANSFER_FEEDBACK_SUCCESS,
  feedbackId
})

export const transferFeedback = (feedbackId, commentText, targetType, targetStore, targetHandler, targetChain, targetOther, targetEmail, targetSubject, language) => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.TRANSFER_FEEDBACK,
      clientMethod: 'transferFeedback',
      payload: {
        feedbackId,
        feedbackVersion,
        commentText,
        targetType,
        targetStore,
        targetHandler,
        targetChain,
        targetOther,
        targetEmail,
        targetSubject,
        language
      },
      success: response => dispatch => {
        dispatch(dismissError())
        dispatch(transferFeedbackSuccess(feedbackId))
        resolve(response)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const forwardFeedback = (feedbackId, comment, emails, language) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.FORWARD_FEEDBACK,
      clientMethod: 'forwardFeedback',
      payload: {
        feedbackId,
        comment,
        emails,
        language
      },
      success: versionResponse => dispatch => {
        dispatch(dismissError())
        resolve(versionResponse)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const changeFeedbackContact = (feedbackId, name, phone, email) => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.CHANGE_FEEDBACK_CONTACT,
      clientMethod: 'changeFeedbackContact',
      payload: {
        feedbackId,
        feedbackVersion,
        name,
        phone,
        email
      },
      success: versionResponse => dispatch => {
        dispatch(dismissError())
        resolve(versionResponse)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const changeFeedbackHandler = (feedbackId, handlerId, comment) => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.UPDATE_FEEDBACK_HANDLER,
      clientMethod: 'putFeedbackHandler',
      payload: {
        feedbackId,
        feedbackVersion,
        userId: handlerId,
        comment
      },
      success: versionResponse => dispatch => {
        dispatch(dismissError())
        resolve(versionResponse)
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const markFeedbackRead = (feedbackId) => (dispatch, getState) => {
  const feedbackVersion = getState().getIn(['feedbacks', feedbackId]).version;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.MARK_FEEDBACK_READ,
      clientMethod: 'markFeedbackRead',
      payload: {
        feedbackId,
        feedbackVersion
      },
      success: versionResponse => dispatch => {
        dispatch(dismissError())
        dispatch(fetchSingleFeedback(feedbackId))
        resolve(versionResponse)
      },
      error: err => dispatch => {
        dispatch(clientError(err))
        reject(err)
      }
    })
  })
}


export const whoami = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.WHOAMI,
      clientMethod: 'whoami',
      payload: {},
      success: userInfo => dispatch => {
        dispatch(dismissError())
        resolve(userInfo)
      },
      error: err => dispatch => {
        dispatch(whoamiFailed())
        reject(err)
      }
    })
  })
}

export const aadLogin = (authCode) => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.AAD_LOGIN,
    clientMethod: 'aadLogin',
    payload: { authCode },
    success: ({ token }) => dispatch => {
      dispatch(dismissError())
      dispatch(aadLoginSuccessful(token))
      resolve()
    },
    error: err => dispatch => err.response.json().then(json => {
      dispatch(aadLoginError(json.code))
      reject({ code: json.code })
    })
  })
})

export const login = (username, password, rememberMe) => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.LOGIN,
    clientMethod: 'login',
    payload: { username, password, rememberMe },
    success: ({ token }) => dispatch => {
      dispatch(dismissError())
      dispatch(loginSuccessful(token))
      resolve()
    },
    error: err => dispatch => err.response.json().then(json => {
      dispatch(loginError(json.code))
      reject({ code: json.code })
    })
  })
})

export const logout = () => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.LOGOUT,
    clientMethod: 'logout',
    payload: {},
    success: () => dispatch => {
      dispatch(dismissError())
      resolve()
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const searchFeedbacks = (searchString, exclusiveSearchField) => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.SEARCH,
    clientMethod: 'getSearchFeedbacks',
    payload: { searchString, exclusiveSearchField },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => () => {
      dispatch(clientError(err))
      reject(err)
    }
  })
})

export const generateReport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.GENERATE_REPORT,
    clientMethod: 'postForReport',
    payload: { filter },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => () => {
      reject(err)
    }
  })
})

export const generateReportExport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.GENERATE_REPORT_EXPORT,
    clientMethod: 'putForReportExport',
    payload: { filter },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => () => {
      reject(err)
    }
  })
})

export const generateMessagesReport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.GENERATE_MESSAGES_REPORT,
    clientMethod: 'putForMessagesReport',
    payload: { filter },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => () => {
      reject(err)
    }
  })
})

export const generateMessagesReportExport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.GENERATE_MESSAGES_REPORT_EXPORT,
    clientMethod: 'putForMessagesReportExport',
    payload: { filter },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => () => {
      reject(err)
    }
  })
})

export const generateSummaryReport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.GENERATE_SUMMARY_REPORT,
    clientMethod: 'putForSummaryReport',
    payload: { filter },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => () => {
      reject(err)
    }
  })
})

export const generateSummaryReportExport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.GENERATE_SUMMARY_REPORT_EXPORT,
    clientMethod: 'putForSummaryReportExport',
    payload: { filter },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => () => {
      reject(err)
    }
  })
})

export const generateReceiversReport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.GENERATE_RECEIVERS_REPORT,
    clientMethod: 'putForReceiversReport',
    payload: { filter },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => () => {
      reject(err)
    }
  })
})

export const generateReceiversReportExport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.GENERATE_RECEIVERS_REPORT_EXPORT,
    clientMethod: 'putForReceiversReportExport',
    payload: { filter },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => () => {
      reject(err)
    }
  })
})

export const generateResponseSummaryReportExport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.GENERATE_RESPONSE_SUMMARY_REPORT_EXPORT,
    clientMethod: 'putForResponseSummaryReportExport',
    payload: { filter },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => () => {
      reject(err)
    }
  })
})

export const generateResponseSummaryKeskoReportExport = filter => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.GENERATE_RESPONSE_SUMMARY_KESKO_REPORT_EXPORT,
    clientMethod: 'putForResponseSummaryKeskoReportExport',
    payload: { filter },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => () => {
      reject(err)
    }
  })
})

export const saveProfile = (profile) => dispatch => new Promise((resolve, reject) => {
  defined(profile, 'saveProfile>profile')
  dispatch({
    type: ClientActions.SAVE_PROFILE,
    clientMethod: 'putProfile',
    payload: { profile },
    success: () => dispatch => {
      dispatch(dismissError())
      resolve()
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const sendFeedback = (feedback, responseRequested) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.SEND_FEEDBACK,
      clientMethod: 'sendFeedback',
      payload: { feedback, responseRequested },
      success: () => dispatch => {
        dispatch(dismissError())
        resolve()
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
}

export const exportFeedbackMessages = feedbackId => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.EXPORT_FEEDBACK_MESSAGES,
    clientMethod: 'getExportMessages',
    payload: { feedbackId },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchFacebookData = (facebookUserId, accessToken) => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_FACEBOOK_DATA,
    clientMethod: 'getFacebookData',
    payload: { facebookUserId, accessToken },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchStoreSettings = (businessUnitId) => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FETCH_STORE_SETTINGS,
    clientMethod: 'getStoreSettings',
    payload: { businessUnitId },
    success: data => dispatch => {
      dispatch(dismissError())
      resolve(data)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const fetchAllowedTransferFeedbackDomains = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.FETCH_ALLOWED_TRANSFER_DOMAINS,
      clientMethod: 'getAllowedTransferFeedbackDomains',
      success: data => dispatch => {
        dispatch(dismissError());
        resolve(data);
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err));
        reject(err)
      }
    })
  });
}

export const saveStoreSettings = (
  businessUnitId,
  showGreeting,
  showRetailerImage,
  greetings,
  offerRequestTransferEmail,
  offerRequestProTransferEmail
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ClientActions.SAVE_STORE_SETTINGS,
      clientMethod: 'saveStoreSettings',
      payload: {businessUnitId, showGreeting, showRetailerImage, greetings, offerRequestTransferEmail, offerRequestProTransferEmail},
      success: () => dispatch => {
        dispatch(dismissError())
        resolve()
      },
      error: err => dispatch => {
        dispatch(dispatchClientError(err))
        reject(err)
      }
    })
  })
};

export const createFacebookReceiver = (accessToken, facebookUserId, chainUnitId, feedbackSubject, storeId, facebookPageId) => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.CREATE_FACEBOOK_RECEIVER,
    clientMethod: 'createFacebookReceiver',
    payload: { accessToken, facebookUserId, chainUnitId, feedbackSubject, storeId, facebookPageId },
    success: receiverId => dispatch => {
      dispatch(dismissError())
      resolve(receiverId)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const removeFacebookReceiver = pageId => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.REMOVE_FACEBOOK_RECEIVER,
    clientMethod: 'removeFacebookReceiver',
    payload: { pageId },
    success: () => dispatch => {
      dispatch(dismissError())
      resolve()
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const removeFacebookLinkingConsent = () => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.REMOVE_FACEBOOK_LINKING_CONSENT,
    clientMethod: 'removeFacebookLinkingConsent',
    success: () => dispatch => {
      dispatch(dismissError())
      resolve()
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const logoutFromFacebook = (facebookUserId, accessToken) => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.FACEBOOK_LOGOUT,
    clientMethod: 'facebookLogout',
    payload: { facebookUserId, accessToken },
    success: () => dispatch => {
      dispatch(dismissError())
      resolve()
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})

export const uploadAttachment = formData => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: ClientActions.UPLOAD_ATTACHMENT,
    clientMethod: 'uploadAttachment',
    payload: { formData },
    success: response => dispatch => {
      dispatch(dismissError())
      resolve(response)
    },
    error: err => dispatch => {
      dispatch(dispatchClientError(err))
      reject(err)
    }
  })
})
