import React, { Component, PropTypes } from 'react'
import { findDOMNode } from 'react-dom'

import { Col } from 'components/grid'
import { Icon, KeskoIcons } from 'components/Icons'
import { TriangledTitle } from 'components/kesko'
import { RelativeLink } from 'components/Routing'
import { CollapsingContainer } from 'containers/Layout'
import { Text } from 'containers/Text'
import { document } from 'utils'
import QuickSearchForm from './QuickSearchForm'
import { RouteUtil } from 'routes'
import TextWithIcon from '../kesko/TextWithIcon';
import { enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.sidebarContent = null;
    this.closeMenu = this.closeMenu.bind(this);
    this.toHomePage = this.toHomePage.bind(this);
  }

  static propTypes = {
    open: PropTypes.bool.isRequired,
    userName: PropTypes.node,
    userEmail: PropTypes.node,
    permissions: PropTypes.object.isRequired,
    addFeedbackUrl: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      close: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
      search: PropTypes.func.isRequired,
      toHomePage: PropTypes.func.isRequired
    }).isRequired,
    showFacebookSettings: PropTypes.bool,
    showResponseTemplates: PropTypes.bool,
    showSupportLink: PropTypes.bool
  };

  static defaultProps = {
    open: false,
    permissions: {}
  };

  componentDidMount() {
    document.body.addEventListener('click', this.clickOnBody);
    document.body.addEventListener('touchend', this.clickOnBody);
    findDOMNode(this).addEventListener('click', this.stopPropagation);
    findDOMNode(this).addEventListener('touchend', this.stopPropagation);
    this.sidebarContent = document.querySelector('.sidebar-content');
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.clickOnBody);
    document.body.removeEventListener('touchend', this.clickOnBody);
    findDOMNode(this).removeEventListener('click', this.stopPropagation);
    findDOMNode(this).removeEventListener('touchend', this.stopPropagation);
    clearAllBodyScrollLocks();
    this.closeMenu();
  }

  closeMenu() {
    enableBodyScroll(this.sidebarContent);
    this.props.actions.close();
  }

  toHomePage() {
    this.closeMenu();
    this.props.actions.toHomePage();
  }

  clickOnBody = (event) => {
    if (this.props.open) {
      const clickInsideSideBar = this.sideBar.contains(event.target);
      if (!clickInsideSideBar) {
        event.stopPropagation();
        event.preventDefault();
        this.closeMenu();
      }
    }
  };

  logout = () => {
    this.closeMenu();
    this.props.actions.logout();
  };

  renderTitle({visible, handleClick}) {
    const titleElement = (
      <Text k="sidebar.reports" />
    );

    return (
      <TriangledTitle
        titleElement={titleElement}
        toggled={visible}
        onClick={handleClick}
      />
    )
  }

  hymyLogo = () => (
    <div className="sidebar-hymy-logo" onClick={this.toHomePage}>
      <Icon icon={KeskoIcons.keskoLogo} size={Icon.sizes.xl} />
      <span className="h2">
        <Text k="sidebar.title" />
      </span>
    </div>
  );

  supportLink = () => {
    const { showSupportLink } = this.props;
    return showSupportLink ? (
      <Col>
        <RelativeLink to={RouteUtil.supportView()} onClick={this.closeMenu}>
          <TextWithIcon
            translation="sidebar.support"
            icon={KeskoIcons.faq}
          />
        </RelativeLink>
      </Col>
    ) : null;
  };

  toggleSidebarButton = (sidebarOpen) => {
    const icon = sidebarOpen ? (
      <Icon
        icon={KeskoIcons.cross}
        size={Icon.sizes.unset}
        className="close-sidebar-icon lg-hide"
      />
    ) : null;

    return (
      <div className="toggle-sidebar-button" onClick={this.closeMenu}>
        {icon}
      </div>
    )
  };

  render() {
    const {
      userName, permissions, open, addFeedbackUrl, selectedBusinessUnit, showFacebookSettings, showResponseTemplates
    } = this.props;
    const close = this.closeMenu;

    const reportingEnabled = (permissions, selectedBusinessUnit) => {
      return permissions.reporting || (permissions.reportingBusinessUnits !== undefined && permissions.reportingBusinessUnits.indexOf(selectedBusinessUnit) !== -1)
    };

    const showSettingsLink = showFacebookSettings
      || (!!permissions.reportingBusinessUnits && permissions.reportingBusinessUnits.length > 0)
      || showResponseTemplates;

    /* eslint-disable react/jsx-no-bind */
    return (
      <nav className={`sidebar-container ${open ? 'open' : ''}`}>
        <div className={`sidebar ${open ? 'open' : ''}`} ref={sideBar => this.sideBar = sideBar}>

          {this.toggleSidebarButton(open)}

          <section className="sidebar-content">
            <Col className="orange">
              {this.hymyLogo()}
            </Col>
            <Col>
              <RelativeLink to={RouteUtil.profile()} onClick={close}>
                <TextWithIcon
                  translation={userName}
                  icon={KeskoIcons.profile}
                  className="sidebar-profile-link"
                />
              </RelativeLink>
            </Col>
            <Col className="quick-search-col border-bottom lg-show">
              <QuickSearchForm onSubmit={this.props.actions.search} />
            </Col>
            { addFeedbackUrl && (
              <Col className="clearfix">
                <a href={addFeedbackUrl} onClick={close}>
                  <TextWithIcon
                    translation="sidebar.addNewFeedback"
                    icon={KeskoIcons.plus}
                  />
                </a>
              </Col>
            )}
            <Col className="clearfix">
              <RelativeLink to={RouteUtil.home()} onClick={close}>
                <TextWithIcon
                  translation="sidebar.feedbacks"
                  icon={KeskoIcons.feedback}
                />
              </RelativeLink>
            </Col>
            {reportingEnabled(permissions, selectedBusinessUnit) && (
              <Col>
                <Col className="clearfix">
                  <hr />
                </Col>
                <CollapsingContainer collapseId={"SideBar-Reports"} initiallyVisible={true} title={this.renderTitle} animated={false}>
                  <Col>
                    <RelativeLink to={RouteUtil.summaryReport()} onClick={close}>
                      <TextWithIcon
                        translation="sidebar.summaryReport"
                      />
                    </RelativeLink>
                  </Col>
                  <Col>
                    <RelativeLink to={RouteUtil.receiversReport()} onClick={close}>
                      <TextWithIcon
                        translation="sidebar.receiversReport"
                      />
                    </RelativeLink>
                  </Col>
                  <Col>
                    <RelativeLink to={RouteUtil.responseSummaryReport()} onClick={close}>
                      <TextWithIcon
                        translation="sidebar.responseSummaryReport"
                      />
                    </RelativeLink>
                  </Col>
                  <Col>
                    <RelativeLink to={RouteUtil.feedbackListReport()} onClick={close}>
                      <TextWithIcon
                        translation="sidebar.feedbackListReport"
                      />
                    </RelativeLink>
                  </Col>
                  <Col>
                    <RelativeLink to={RouteUtil.feedbackMessagesReport()} onClick={close}>
                      <TextWithIcon
                        translation="sidebar.feedbackMessagesReport"
                      />
                    </RelativeLink>
                  </Col>
                </CollapsingContainer>
              </Col>
            )}
            <Col className="clearfix">
              <hr />
            </Col>
            <Col className="bottom-links-block clearfix">
              <Col>
                <RelativeLink to={RouteUtil.profile()} onClick={close}>
                  <TextWithIcon
                    translation="sidebar.myProfile"
                    icon={KeskoIcons.profile}
                  />
                </RelativeLink>
              </Col>
              {showSettingsLink && (
                <Col>
                  <RelativeLink to={RouteUtil.settings()} onClick={close}>
                    <TextWithIcon
                      translation="sidebar.settings"
                      icon={KeskoIcons.settings}
                    />
                  </RelativeLink>
                </Col>
              )}
              <Col>
                <RelativeLink to={RouteUtil.hymyFeedback()} onClick={close}>
                  <TextWithIcon
                    translation="sidebar.giveFeedback"
                    icon={KeskoIcons.idea}
                  />
                </RelativeLink>
              </Col>
              {this.supportLink()}
              <Col>
                <RelativeLink to={''} onClick={this.logout}>
                  <TextWithIcon
                    translation="sidebar.logout"
                    icon={KeskoIcons.exit}
                  />
                </RelativeLink>
              </Col>
            </Col>
          </section>
        </div>
      </nav>
    )
  }
}

export default SideBar
