import React, {Component, PropTypes} from 'react'

import {Text} from 'containers/Text'
import StoreSettingsForm from './StoreSettingsForm'
import {storeMapper} from 'utils/OptionMapper'
import Selection from 'components/Forms/common/Selection'
import LabeledInput from 'components/Forms/LabeledInput'
import {reduxForm} from 'redux-form'
import ChainId from 'domain/ChainId'

class StoreSettingsView extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      changeStore: PropTypes.func.isRequired,
      saveStoreSettings: PropTypes.func.isRequired
    }).isRequired,
    storeSettings: PropTypes.shape({
      retailerName: PropTypes.string,
      showRetailerImage: PropTypes.bool,
      showGreeting: PropTypes.bool,
      retailerImageUrl: PropTypes.string,
      greetingFi: PropTypes.object,
      greetingSv: PropTypes.object,
      greetingEn: PropTypes.object,
      offerRequestTransferEmail: PropTypes.string,
      offerRequestProTransferEmail: PropTypes.string
    }),
    enableOfferRequestTransferEmail: PropTypes.bool,
    stores: PropTypes.array,
    permissions: PropTypes.object,
    allowedTransferFeedbackDomains: PropTypes.array
  };

  static defaultProps = {
    actions: {
      changeStore: () => {},
      saveStoreSettings: () => {}
    }
  }

  componentDidMount() {
    const {fields: {selectedStore}} = this.props;
    const allowedStores = this.getStores();
    if (!selectedStore.value && allowedStores.length > 0) {
      const preSelectFirstStore = allowedStores[0];
      this.handleChange({id: preSelectFirstStore.id});
    }
  }

  getStores = () => {
    const {stores, permissions: {reportingBusinessUnits}} = this.props;
    if (!reportingBusinessUnits) {
      return [];
    }
    return stores.filter(s => reportingBusinessUnits.includes(s.id));
  }

  handleChange = (event) => {
    const {actions: {changeStore}, fields: {selectedStore}} = this.props;
    changeStore(event.id);
    selectedStore.onChange(event);
  }

  disableGreetings = () => {
    const {
      fields: {selectedStore},
      stores, allowedTransferFeedbackDomains
    } = this.props;
    const hasSelectedStore = selectedStore && selectedStore.value && selectedStore.value.id;
    if (allowedTransferFeedbackDomains.length > 0 && hasSelectedStore) {
      const onninenStore = stores
        .filter(s => s.id === selectedStore.value.id)
        .filter(s => s.chainId === ChainId.onninen);
      return onninenStore.length > 0;
    }
    return false;
  }

  enableOfferRequestTransferEmail = () => {
    const {
      fields: {selectedStore},
      stores, allowedTransferFeedbackDomains
    } = this.props;
    const hasSelectedStore = selectedStore && selectedStore.value && selectedStore.value.id;
    if (allowedTransferFeedbackDomains.length > 0 && hasSelectedStore) {
      const krautaStore = stores
        .filter(s => s.id === selectedStore.value.id)
        .filter(s => s.chainId === ChainId.krauta);
      return krautaStore.length > 0;
    }
    return false;
  }

  render() {
    if (this.getStores().length <= 0) {
      return null;
    }
    const {
      storeSettings, submitting, allowedTransferFeedbackDomains,
      fields: {selectedStore},
      actions: {saveStoreSettings}
    } = this.props;

    const getGreetingContent = (greeting) => {
      return greeting.content || greeting.defaultContent;
    };

    return (
      <div className="store-receiver-settings-view">
        <h2>
          <Text k="settings.store.title" />
        </h2>
        <LabeledInput labelKey="settings.store.select" className="pb2">
          <Selection
            disabled={submitting}
            showNotSelected={false}
            options={this.getStores()}
            optionMapper={storeMapper}
            value={selectedStore.value}
            onBlur={selectedStore.onBlur}
            onChange={this.handleChange}
        />
        </LabeledInput>
        {!!storeSettings && !!selectedStore.value && (
          <StoreSettingsForm
            saveStoreSettings={saveStoreSettings}
            retailerName={storeSettings.retailerName}
            retailerImageUrl={storeSettings.retailerImageUrl}
            defaultGreetingFi={storeSettings.greetingFi.defaultContent}
            defaultGreetingSv={storeSettings.greetingSv.defaultContent}
            defaultGreetingEn={storeSettings.greetingEn.defaultContent}
            enableOfferRequestTransferEmail={this.enableOfferRequestTransferEmail()}
            disableGreetings={this.disableGreetings()}
            allowedTransferFeedbackDomains={allowedTransferFeedbackDomains}
            initialValues={{
              businessUnitId: selectedStore.value.id,
              showGreeting: storeSettings.showGreeting,
              showRetailerImage: storeSettings.showRetailerImage,
              greetingFi: getGreetingContent(storeSettings.greetingFi),
              greetingSv: getGreetingContent(storeSettings.greetingSv),
              greetingEn: getGreetingContent(storeSettings.greetingEn),
              offerRequestTransferEmail: storeSettings.offerRequestTransferEmail,
              offerRequestProTransferEmail: storeSettings.offerRequestProTransferEmail
            }}
          />
        )}
      </div>
    )
  }
}

export default reduxForm({
  form: 'selectedStoreSettings',
  fields: ['selectedStore'],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS()
})(StoreSettingsView);
