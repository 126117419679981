import { fromJS } from 'immutable'
import { CollapseActions } from 'actions/CollapseActions'
import createReducer from './createReducer'

const initialState = fromJS({})

export const reducer = createReducer(initialState, {
  [CollapseActions.REGISTER]:   (state, action) => state.set(action.collapseId, action.state),
  [CollapseActions.DEREGISTER]: (state, action) => state.delete(action.collapseId),
  [CollapseActions.OPEN]:       (state, action) => state.set(action.collapseId, true),
  [CollapseActions.CLOSE]:      (state, action) => state.set(action.collapseId, false),
  [CollapseActions.TOGGLE]:     (state, action) => state.update(action.collapseId, false, prev => !prev)
})
