
import { Locale } from 'domain'
import { oneOf, window } from 'utils'

const allowedLocales = Object.values(Locale)

export const I18nActions = {
  CHANGE_LOCALE: 'I18n/ChangeLocale'
}

export const USER_LANG = 'user-lang'

export const changeLocale = locale => dispatch => {
  oneOf(allowedLocales, locale);
  if (window.localStorage) {
    window.localStorage.setItem(USER_LANG, locale)
  }
  dispatch({
    type: I18nActions.CHANGE_LOCALE,
    locale: locale
  })
}
