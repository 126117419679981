import React, { PropTypes, Component } from 'react'
import { List } from 'immutable'

import { Navigator } from 'components/Menu'
import { Text } from 'containers/Text'

import { FeedbackList } from 'components/Feedback'
import { RouteUtil } from 'routes'
import { QuickSearchFilters } from 'components/Search'

export default class SearchResultsView extends Component {

  feedbacks = props => props.feedbacks && props.feedbacks.size > 0 ?
    (<FeedbackList feedbacks={props.feedbacks} FeedbackListItem={props.FeedbackListItem} showReceiver={true} />) :
    (<p className="center silver"><Text k="searchResults.empty" /></p>);

  render() {
    const {searchString, actions: {toggleFilter}, activeFilter} = this.props;
    return (
      <div className="search-results-view">
        <Navigator
          backUrl={RouteUtil.home()}
          backText={<Text k="search.backToHome" />}
        />

        <QuickSearchFilters
          activeFilter={activeFilter}
          toggleFilter={toggleFilter}
        />

        <div className="h1 blue mb2">
          <Text k="searchResults.title" />
        </div>

        <p className="silver mb2 break-word">
          <Text k="searchResults.showingResultsForPre" />
          <span> '{searchString}'</span>
          <Text k="searchResults.showingResultsForPost" />
        </p>

        {this.feedbacks(this.props)}
      </div>
    );
  }
}

SearchResultsView.propTypes = {
  searchString: PropTypes.string.isRequired,
  feedbacks: PropTypes.instanceOf(List),
  FeedbackListItem: PropTypes.func.isRequired
};
