import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form'
import { List } from 'immutable'

import { Col } from 'components/grid'
import { ExclamatedNote, HeadingLine } from 'components/kesko'
import { ErrorBlock, LanguageSelection, LabeledCheckBox, TokenInput, TextArea, FeedbackCategorySelection } from 'components/Forms'
import { TopLevelReceiverSelection, ReceiverFilterRow, AddReceiverFilterButton } from 'components/ReceiverFilter'
import { possibleReceiverFiltersByReceiver } from 'components/ReceiverFilter/utils'

import { createValidator, required, maxLength } from 'utils/Validation'
import { hasItemsInList } from 'utils/Helpers'
import { Text } from 'containers/Text'
import { formModelToProfile } from './utils'
import Button from 'components/kesko/common/Button';
import PushMessageTokenForm from './PushMessage/PushMessageTokenForm'
import {businessUnitMapper} from 'utils/OptionMapper'

class ProfileForm extends Component {
  static propTypes = {
    isStoreUser: PropTypes.bool.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
    saveProfileAndReturn: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    businessUnits: PropTypes.instanceOf(List),
    chainUnits: PropTypes.instanceOf(List),
    subjects: PropTypes.instanceOf(List),
    allowableBusinessUnits: PropTypes.instanceOf(List),
    showPushMessageForm: PropTypes.bool
  };

  addRestriction = (possibleReceiverFilters) => () => this.props.fields.restrictions.addField({
    receiverType: possibleReceiverFilters.length === 1 ? possibleReceiverFilters[0] : null
  });

  addRestriction2 = (possibleReceiverFilters) => () => this.props.fields.restrictions2.addField({
    receiverType: possibleReceiverFilters.length === 1 ? possibleReceiverFilters[0] : null
  });

  clearRestrictions = () => {
    const {restrictions} = this.props.fields
    for (let i = restrictions.length - 1; i >= 0; i--) {
      restrictions.removeField(i)
    }
  };

  clearRestrictions2 = () => {
    const {restrictions2} = this.props.fields
    for (let i = restrictions2.length - 1; i >= 0; i--) {
      restrictions2.removeField(i)
    }
  };

  removeRestriction = idx => this.props.fields.restrictions.removeField(idx);
  removeRestriction2 = idx => this.props.fields.restrictions2.removeField(idx);
  transformValues = values => this.props.saveProfileAndReturn(formModelToProfile(values));

  populatePlaceholders = (original, defaultText, allowableBusinessUnits) => {
    const { displayName } = this.props.user;
    let text = original.value;
    if (!text || text === '') {
      text = defaultText
    }
    const storeName = hasItemsInList(allowableBusinessUnits) ? allowableBusinessUnits.get(0).name : 'K-Kauppa Hymy';
    text = text.replace('${hello}', 'Hei/Hej')
      .replace('${customerName}', 'Anna Asiakas')
      .replace('${greeting}', 'Ystävällisin terveisin/Med vänlig hälsning')
      .replace('${handlerName}', displayName)
      .replace('${storeName}', storeName);
    return (
      <Col>{text.split('\n').map((item, key) => {
        return <div className="silver break-word" key={key}>{item}<br /></div>
      })}
      </Col>
    )
  }

  renderNotificationEnablers = () => {
    const {
      showPushMessageForm, isStoreUser, allowableBusinessUnits, submitting,
      fields: {
        sendNotifications, sendNotificationsNoResponse, sendSummaries, sendPushMessages, pushMessageTokens, excludeBusinessUnits
      }
    } = this.props;
    return (
      <div>
        <Col>
          <LabeledCheckBox
            {...sendNotifications}
            label={`profile.subscriptions.fields.${isStoreUser ? 'sendNotificationsStore' : 'sendNotificationsKesko'}`}
          />
          <LabeledCheckBox
            {...sendNotificationsNoResponse}
            label={`profile.subscriptions.fields.${isStoreUser ? 'sendNotificationsNoResponseStore' : 'sendNotificationsNoResponseKesko'}`}
          />
          {!isStoreUser && (
            <LabeledCheckBox
              {...sendSummaries}
              label="profile.subscriptions.fields.sendSummaries"
            />
          )}
        </Col>
        {isStoreUser && (sendNotifications.value || sendNotificationsNoResponse.value) && allowableBusinessUnits && allowableBusinessUnits.size > 1 && (
          <Col className="mb2">
            <h3>
              <Text k="profile.excludes.title" />
            </h3>
            <TokenInput
              disabled={submitting}
              options={allowableBusinessUnits}
              optionMapper={businessUnitMapper}
              translate={{
                notSelectedLabel: 'profile.excludes.notSelected'
              }}
              {...excludeBusinessUnits}
            />
          </Col>
        )}
        {showPushMessageForm && (
          <PushMessageTokenForm fields={{sendPushMessages, pushMessageTokens}} />
        )}
      </div>
    );
  }

  renderFeedbackCategorySelection = () => {
    const {
      fields: {
        interestFeedbackCategory
      }
    } = this.props;
    return (
      <div>
        <Col>
          <h3>
            <Text k="profile.interests.categoryTitle" />
          </h3>
        </Col>
        <Col className="clearfix mb2">
          <ExclamatedNote translate={{note: 'profile.interests.feedbackCategoryNote'}} />
        </Col>
        <Col sm="6" className="mb2">
          <FeedbackCategorySelection
            category={interestFeedbackCategory}
            notSelectedLabel={'profile.interests.allFeedbackCategories'} />
        </Col>
      </div>
    );
  }

  render() {
    const {
      error, submitting, handleSubmit, fields, businessUnits, chainUnits, subjects, allowableBusinessUnits, isStoreUser, isReadOnly, onCancel,
      showInterestFeedbackCategorySelection,
      user: {
        profile
      },
      fields: {
        language, receiver, receiver2, restrictions, restrictions2, secondaryInterestsEnabled, signature, greeting, proposeResponses
      }
    } = this.props;

    const possibleReceiverFilters = possibleReceiverFiltersByReceiver(receiver)
    const possibleReceiverFilters2 = possibleReceiverFiltersByReceiver(receiver2)

    const email = profile ? profile.email : null;
    const defaultSignature = '${greeting},\n\n${handlerName}' + (isStoreUser ? '\n${storeName}' : '');
    return (
      <form onSubmit={handleSubmit(this.transformValues)} className="profile-form">

        <Col className="clearfix mb2">
          <ErrorBlock
            fields={fields}
            submitError={error}
            translatePrefix="profile.fields"
          />
        </Col>
        <LanguageSelection
          titleKey="profile.language.title"
          language={language}
        />
        <Col className="mt2 mb1">
          <hr />
        </Col>
        <h3>
          <Text k="profile.subscriptions.title" />
        </h3>
        {email && (
          <Col className="clearfix mb2">
            <HeadingLine
              heading={<Text k="profile.subscriptions.email" />}
              text={email}
            />
          </Col>
        )}
        {this.renderNotificationEnablers()}

        {isStoreUser && showInterestFeedbackCategorySelection && (
          <div>
            <Col>
              <hr />
            </Col>
            {this.renderFeedbackCategorySelection()}
          </div>
        )}
        {!isStoreUser && (
          <div>
            <Col>
              <hr />
            </Col>
            <Col>
              <h3>
                <Text k="profile.interests.title" />
              </h3>
            </Col>
            <Col className="clearfix mb2">
              <ExclamatedNote translate={{note: 'profile.interests.note'}} />
            </Col>
            <Col sm="6" className="mb2">
              <TopLevelReceiverSelection
                receiver={receiver}
                submitting={submitting}
                onChange={this.clearRestrictions}
                notSelectedLabel={'receiverFilter.receiverTypes.all'}
              />
            </Col>
            {possibleReceiverFilters.length > 0 && (
              <div>
                {restrictions.map((r, idx) => (
                  <Col key={idx}>
                    <Col className="mb2 clearfix sm-hide">
                      <hr />
                    </Col>
                    <Col>
                      <ReceiverFilterRow
                        idx={idx}
                        row={r}
                        receiver={receiver}
                        possibleReceiverFilters={possibleReceiverFilters}
                        submitting={submitting}
                        businessUnitOptions={businessUnits}
                        chainUnitOptions={chainUnits}
                        subjectOptions={subjects}
                        onRemove={this.removeRestriction}
                      />
                    </Col>
                  </Col>
                ))}
                <Col className="mb2 mt1 clickable">
                  <AddReceiverFilterButton onClick={this.addRestriction(possibleReceiverFilters)} />
                </Col>
              </div>
            )}
          </div>
        )}
        {!isStoreUser && (
          <div>
            <Col>
              <h3>
                <Text k="profile.interests.title2" />
              </h3>
            </Col>
            <Col className="clearfix mb2">
              <ExclamatedNote translate={{note: 'profile.interests.note2'}} />
            </Col>
            <Col className="clickable">
              <LabeledCheckBox
                {...secondaryInterestsEnabled}
                label="profile.interests.enableSecondaryInterests"
              />
            </Col>
            {secondaryInterestsEnabled.value && (
              <Col sm="6" className="mb2">
                <TopLevelReceiverSelection
                  receiver={receiver2}
                  submitting={submitting}
                  onChange={this.clearRestrictions2}
                  notSelectedLabel={'receiverFilter.receiverTypes.all'}
                />
              </Col>
            )}
            {secondaryInterestsEnabled.value && possibleReceiverFilters2.length > 0 && (
              <div>
                {restrictions2.map((r, idx) => (
                  <Col key={idx}>
                    <Col className="mb2 clearfix sm-hide">
                      <hr />
                    </Col>
                    <Col>
                      <ReceiverFilterRow
                        idx={idx}
                        row={r}
                        receiver={receiver2}
                        possibleReceiverFilters={possibleReceiverFilters2}
                        submitting={submitting}
                        businessUnitOptions={businessUnits}
                        chainUnitOptions={chainUnits}
                        subjectOptions={subjects}
                        onRemove={this.removeRestriction2}
                      />
                    </Col>
                  </Col>
                ))}
                <Col className="mb2 mt1 clickable">
                  <AddReceiverFilterButton onClick={this.addRestriction2(possibleReceiverFilters2)} />
                </Col>
              </div>
            )}
          </div>
        )}
        {!isStoreUser && showInterestFeedbackCategorySelection && (
          <div>
            {this.renderFeedbackCategorySelection()}
          </div>
        )}

        {!isReadOnly && (
        <Col>
        <Col>
          <hr />
        </Col>
        <Col>
          <div className="sm-flex">
            <div className="flex-none xs-col-12 sm-col-6">
              <h3>
                <Text k="profile.hello.title" />
              </h3>
              <TextArea
                rows="5"
                disabled={submitting}
                translate={{placeholder: 'profile.hello.placeholder'}}
                maxLength="300"
                {...greeting}
              />
            </div>
            <div className="flex-auto ml2 xs-col-12 sm-col-6">
              <h3>
                <Text k="profile.hello.preview" />
              </h3>
              {this.populatePlaceholders(greeting, '${hello},', allowableBusinessUnits)}
            </div>
          </div>
        </Col>

        <Col>
          <div className="sm-flex">
            <div className="flex-none xs-col-12 sm-col-6">
              <h3>
                <Text k="profile.signature.title" />
              </h3>
              <TextArea
                rows="5"
                disabled={submitting}
                translate={{placeholder: 'profile.signature.placeholder'}}
                maxLength="256"
                {...signature}
              />
            </div>
            <div className="flex-auto ml2 xs-col-12 sm-col-6">
              <h3>
                <Text k="profile.signature.preview" />
              </h3>
              {this.populatePlaceholders(signature, defaultSignature, allowableBusinessUnits)}
            </div>
          </div>
        </Col>
        </Col>
        )}

        {!isStoreUser && !isReadOnly && (
          <Col>
            <Col className="mt2">
              <hr />
            </Col>
            <Col>
              <h3>
                <Text k="profile.responseProposals.title" />
              </h3>
            </Col>
            <Col>
              <LabeledCheckBox
                {...proposeResponses}
                label="profile.responseProposals.fields.proposeResponses"
              />
            </Col>
          </Col>
        )}

        <div className="form-buttons-group">
          <Button
            onClick={onCancel}
            translation="profile.cancel"
            disabled={submitting}
            theme={Button.theme.cancel}
          />
          <Button
            translation="profile.save"
            type="submit"
            disabled={submitting}
            theme={Button.theme.primary}
          />
        </div>

      </form>
    )
  }
}

export default reduxForm({
  form: 'profileForm',
  fields: [
    'language',
    'receiver',
    'receiver2',
    'sendNotifications',
    'sendNotificationsNoResponse',
    'sendSummaries',
    'sendPushMessages',
    'pushMessageTokens[].registrationToken',
    'pushMessageTokens[].userAgent',
    'pushMessageTokens[].created',
    'excludeBusinessUnits',
    'signature',
    'greeting',
    'proposeResponses',
    'secondaryInterestsEnabled',
    'restrictions[].receiverType',
    'restrictions[].chainUnit',
    'restrictions[].chainUnitArea',
    'restrictions[].businessUnits',
    'restrictions[].businessUnitIds',
    'restrictions[].subject',
    'restrictions2[].receiverType',
    'restrictions2[].chainUnit',
    'restrictions2[].chainUnitArea',
    'restrictions2[].businessUnits',
    'restrictions2[].businessUnitIds',
    'restrictions2[].subject',
    'interestFeedbackCategory'
  ],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    language: [required()],
    greeting: [maxLength(300)],
    signature: [maxLength(256)]
  }),
  onSubmitFail: function () {
    window.scrollTo(0, 0)
  }
})(ProfileForm)
