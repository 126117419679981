import Serializable from './Serializable'

class FacebookPage extends Serializable {

  constructor(data) {
    super(data, 'FacebookPage')
  }

  get id() {
    return this._data.get('pageId')
  }

  get name() {
    return this._data.get('pageName')
  }
}

export default FacebookPage