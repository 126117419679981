import React, { Component } from 'react'
import { BaseButton } from 'components/kesko/common'

class LinkButton extends Component {
  render() {
    return (
      <BaseButton {...this.props}/>
    );
  }
}

LinkButton.defaultProps = {
  baseClasses: ['btn', 'btn-link', 'px0', 'underline', 'regular'],
  bg: 'white',
  color: 'orange'
};

export default LinkButton
