import React, { Component, PropTypes } from 'react'

import { List } from 'immutable'
import { Text, TranslateChildrenProps } from 'containers/Text'
import FacebookReceiverRow from './FacebookReceiverRow';
import { getText } from 'containers/Text/utils'
import TextWithIcon from 'components/kesko/TextWithIcon';
import KeskoIcons from 'components/Icons/KeskoIcons';
import { Confirmation } from 'containers/Dialog'

class FacebookReceiverSection extends Component {

  static propTypes = {
    receivers: PropTypes.instanceOf(List).isRequired,
    chainUnits: PropTypes.instanceOf(List).isRequired,
    subjects: PropTypes.instanceOf(List).isRequired,
    stores: PropTypes.instanceOf(List).isRequired,
    actions: PropTypes.shape({
      removeFacebookReceiver: PropTypes.func.isRequired
    }).isRequired
  };

  static contextTypes = {
    locale: PropTypes.string,
    translations: PropTypes.instanceOf(Object)
  };

  bindRemoveReceiverConfirmation = (dialog) => this._removeReceiverConfirmation = dialog;

  openRemoveReceiverConfirmation = () => {
    this._removeReceiverConfirmation.open()
  };

  render() {
    const {receivers, chainUnits, subjects, stores, actions: {removeFacebookReceiver}} = this.props;

    const removeReceiver = (receiver) => {
      removeFacebookReceiver(receiver)
    };

    const chainOrSubjectPrefix = (type) => {
      return getText(this.context.translations, 'settings.facebook.targetType.' + type, '')
    };

    const receiverName = receiver => {
      if (receiver.chainUnitId && receiver.chainUnitId !== '') {
        return chainOrSubjectPrefix('chain') + chainUnits.find(cu => cu.id === receiver.chainUnitId).name
      } else if (receiver.subjectId && receiver.subjectId !== '') {
        return chainOrSubjectPrefix('subject') + subjects.find(s => s.id === receiver.subjectId).localizedName(this.context.locale)
      } else {
        return stores.find(s => s.id === receiver.storeId).name
      }
    };

    const receiverRowsDesktop = receivers.map(receiver => (
      <FacebookReceiverRow
        key={receiver.id}
        receiver={receiver}
        chainUnits={chainUnits}
        subjects={subjects}
        stores={stores}
        actions={{removeFacebookReceiver}}
      />
    ));

    const removeReceiverConfirmationTitle = receiver => {
      return getText(this.context.translations, 'settings.facebook.confirmRemoveReceiverTitleStart') + receiver.pageName +
        getText(this.context.translations, 'settings.facebook.confirmRemoveReceiverTitleEnd')
    };

    /* eslint-disable react/jsx-no-bind */
    const receiverRowsMobile = receivers.map(receiver => (
      <div key={receiver.id} className="receiver-rows-mobile">
        <span className="mobile-value">
          <Text k="settings.facebook.receivers.labels.pageName" className="mobile-value-title" />
          <span>{receiver.pageName}</span>
        </span>
        <span className="mobile-value">
          <Text k="settings.facebook.receivers.labels.chainOrSubjectName" className="mobile-value-title" />
          <span>{receiverName(receiver)}</span>
        </span>
        <span className="mobile-value">
          <Text k="settings.facebook.receivers.labels.userName" className="mobile-value-title" />
          <span>{receiver.userName}</span>
        </span>
        <div className="remove-linking-row">
          <TextWithIcon
            onClick={this.openRemoveReceiverConfirmation}
            translation="settings.facebook.receivers.remove"
            icon={KeskoIcons.trash}
          />
          <TranslateChildrenProps>
            <Confirmation
              translate={{
                body: 'settings.facebook.confirmRemoveReceiverBody',
                yesText: 'yes',
                noText: 'no'
              }}
              title={removeReceiverConfirmationTitle(receiver)}
              ref={this.bindRemoveReceiverConfirmation}
              onConfirm={() => removeReceiver(receiver)}
            />
          </TranslateChildrenProps>
        </div>
      </div>
    ));

    return (
      <div className="facebook-receiver-section">
        <div className="desktop-view">
          <table className="table-light">
            <thead>
            <tr>
              <th>
                <Text k="settings.facebook.receivers.labels.pageName" />
              </th>
              <th>
                <Text k="settings.facebook.receivers.labels.chainOrSubjectName" />
              </th>
              <th>
                <Text k="settings.facebook.receivers.labels.userName" />
              </th>
              <th />
            </tr>
            </thead>
            <tbody>
            {receiverRowsDesktop}
            </tbody>
          </table>
        </div>
        <div className="mobile-view">
          {receiverRowsMobile}
        </div>
      </div>
    )
  }
}

export default FacebookReceiverSection
