import React, { PropTypes } from 'react'
import { fromJS } from 'immutable'
import PureComponent from 'react-pure-render/component'


class TextFormatter extends PureComponent {
  render() {
    const { text, className, ...props } = this.props;

    const paragraphs = (text || '')
      .replace(/\r\n/g, '\n')
      .replace(/\r/g, '\n')
      .replace(/[\n]{2,}/g, '\n\n')
      .split('\n\n');

    return (
      <div className={'break-word ' + className} {...props}>
        {paragraphs.map((p,idx) => {
          const lines = p.split('\n')
          const lineItems = fromJS(lines).flatMap((line, lidx) => [
            <span key={lidx}>{line}</span>, <br key={lidx+'-br'} />
          ]).skipLast(1).toJS()

          return (<p key={idx} className="mb1">{lineItems}</p>)
        })}
      </div>
    );
  }
}
TextFormatter.propTypes = {
  text: PropTypes.string
}

export default TextFormatter
