import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import Button from 'components/kesko/common/Button'
import ErrorBlock from 'components/Forms/common/ErrorBlock'
import Col from 'components/grid/Col'
import { TextArea, TextInput } from 'components/Forms/common'
import Text from 'containers/Text'
import { createValidator, required, maxLength } from 'utils/Validation'

class ResponseTemplateForm extends Component {

  static propTypes = {
    templateId: PropTypes.number,
    templateName: PropTypes.string,
    templateCategories: PropTypes.string,
    saveResponseTemplate: PropTypes.func.isRequired
  };

  saveTemplate = (data) => {
    const { saveResponseTemplate, templateId } = this.props;
    return saveResponseTemplate(templateId, data.name, data.template);
  }

  reset = () => {
    const { name, template } = this.props.fields;
    name.onChange(name.initialValue);
    template.onChange(template.initialValue);
  }

  render() {
    const { error, submitting, pristine, handleSubmit, fields: { name, template }, templateCategories } = this.props;

    return (
      <form className="basic-form" action="." noValidate="novalidate" onSubmit={handleSubmit(this.saveTemplate)}>
        <Col className="mb2">
          <h3>
            <Text k="settings.responseTemplates.name.title"/>
          </h3>
          <ErrorBlock
            fields={{name}}
            submitError={error}
            className="mb2 mt2"
            translatePrefix="settings.responseTemplates"
          />
          <TextInput
            disabled={submitting}
            maxLength="100"
            {...name}
          />
          <h3>
            <Text k="settings.responseTemplates.template.title"/>
          </h3>
          <ErrorBlock
            fields={{template}}
            submitError={error}
            className="mb2 mt2"
            translatePrefix="settings.responseTemplates"
          />
          <TextArea
            rows="10"
            disabled={submitting}
            maxLength="50000"
            {...template}
          />
          <h3>
            <Text k="settings.responseTemplates.categoriesTitle"/>
          </h3>
          <p>{templateCategories}</p>
        </Col>
        <div className="form-buttons-group">
          <Button
            translation="formButtons.save"
            type="submit"
            disabled={submitting || pristine}
            theme={Button.theme.primary}
            loading={submitting}
          />
          <Button
            onClick={this.reset}
            translation="formButtons.cancel"
            disabled={submitting}
            theme={Button.theme.cancel}
          />
        </div>
      </form>
    )
  }
}

export default reduxForm({
  form: 'responseTemplate',
  fields: [
    'name',
    'template'
  ],
  getFormState: (state, reduxMountPoint) => state.get(reduxMountPoint).toJS(),
  validate: createValidator({
    name: [required(), maxLength(100)],
    template: [required(), maxLength(50000)]
  })
})(ResponseTemplateForm);
