import React, { Component, PropTypes } from 'react'
import { Router, Route, IndexRoute, IndexRedirect } from 'react-router'
import { connect } from 'react-redux'

import { AddPathContext } from 'components/Routing'
import AllFeedbacksRoute from './AllFeedbacksRoute'
import SecondaryFeedbacksRoute from './SecondaryFeedbacksRoute'
import BusinessUnitRouteWrapper from './BusinessUnitRouteWrapper'
import BusinessUnitRoute from './BusinessUnitRoute'
import ChainUnitRoute from './ChainUnitRoute'
import SubjectRoute from './SubjectRoute'
import FeedbackRouteWrapper from './FeedbackRouteWrapper'
import SingleFeedbackRoute from './SingleFeedbackRoute'
import AddCommentRoute from './AddCommentRoute'
import ChangeHandlerRoute from './ChangeHandlerRoute'
import LoginInterceptor from './LoginInterceptor'
import ReplyToFeedbackRoute from './ReplyToFeedbackRoute'
import ReplyByPhoneRoute from './ReplyByPhoneRoute'
import ReplyViaFacebookRoute from './ReplyViaFacebookRoute'
import ReplyViaUberallRoute from './ReplyViaUberallRoute'
import TransferFeedbackRoute from './TransferFeedbackRoute'
import ForwardFeedbackRoute from './ForwardFeedbackRoute'
import ChangeContactRoute from './ChangeContactRoute'
import AdminToolsRoute from './AdminToolsRoute'
import SettingsRoute from './SettingsRoute'
import DynamicRedirectTo from './DynamicRedirectTo'
import SupportViewRoute from './SupportViewRoute';
import App from './App'
import RequestCommentRoute from './RequestCommentRoute'
import { ProfileRoute } from 'routes/Profile'
import { GiveFeedbackRoute } from 'routes/GiveFeedback'
import { SearchRouteWrapper, SearchResultRoute } from 'routes/Search'
import {
  FeedbackListReportingRoute,
  FeedbackMessagesReportingRoute,
  SummaryReportingRoute,
  ReceiversReportingRoute,
  ResponseSummaryReportingRoute
} from 'routes/Reporting'
import { Error404Route } from 'routes/Error'
import { SideBar, Header } from 'containers/Menu'

import { ScrollTo } from 'components/Utils'
import { defined, toJS } from 'utils'

import RouteUtil from './RouteUtil'

const Main = ({children}) => <div>{children}</div>

const RedirectToDefaultView = connect(state => {
  var defaultView = toJS(state.getIn(['client', 'views', 0]));
  const selectedBusinessUnitCode = state.getIn(['client', 'selectedBusinessUnitCode']);
  if (selectedBusinessUnitCode) {
    const views = toJS(state.getIn(['client', 'views']));
    defaultView = views.find(view => (view.id === selectedBusinessUnitCode));
  }
  defined(defaultView, 'defaultView not defined. Problem with client->views initialization?');
  const resolved = !!defaultView;
  const profile = state.getIn(['client', 'userInfo', 'profile'])
  const redirectTo = !!profile ? defaultView.url : RouteUtil.profile();
  return {
    resolved,
    redirectTo
  }
})(DynamicRedirectTo);

const ScrollToTop = ScrollTo(() => true, () => {
}, true);

const createRoutes = () => (
  <Route path="/" component={LoginInterceptor}>
    <Route component={AddPathContext(App)}>
      <Route components={{header: Header, sidebar: SideBar, main: Main}}>

        <IndexRoute component={RedirectToDefaultView} />

        <Route path="allFeedbacks" component={AllFeedbacksRoute} />
        <Route path="secondaryFeedbacks" component={SecondaryFeedbacksRoute} />
        <Route path="chainUnit/:chainUnitId" component={ChainUnitRoute} />
        <Route path="subject/:subjectId" component={SubjectRoute} />
        <Route path="businessUnit/:businessUnitId" component={BusinessUnitRouteWrapper}>
          <IndexRoute component={BusinessUnitRoute} />
        </Route>

        <Route path="feedback/:feedbackId" component={FeedbackRouteWrapper}>
          <IndexRoute component={ScrollToTop(SingleFeedbackRoute)} />
          <Route path="addComment" component={ScrollToTop(AddCommentRoute)} />
          <Route path="requestComment" component={ScrollToTop(RequestCommentRoute)} />
          <Route path="changeHandler" component={ScrollToTop(ChangeHandlerRoute)} />
          <Route path="reply" component={ScrollToTop(ReplyToFeedbackRoute)} />
          <Route path="replyByPhone" component={ScrollToTop(ReplyByPhoneRoute)} />
          <Route path="replyViaFacebook" component={ScrollToTop(ReplyViaFacebookRoute)} />
          <Route path="replyViaUberall" component={ScrollToTop(ReplyViaUberallRoute)} />
          <Route path="transfer" component={ScrollToTop(TransferFeedbackRoute)} />
          <Route path="forward" component={ScrollToTop(ForwardFeedbackRoute)} />
          <Route path="changeContact" component={ScrollToTop(ChangeContactRoute)} />
          <Route path="adminTools" component={ScrollToTop(AdminToolsRoute)} />
        </Route>

        <Route path="search/results" component={SearchRouteWrapper}>
          <IndexRoute component={SearchResultRoute} />
        </Route>

        <Route path="reporting">
          <IndexRedirect to="feedbackList" />
          <Route path="feedbackList" component={ScrollToTop(FeedbackListReportingRoute)} />
          <Route path="feedbackMessages" component={ScrollToTop(FeedbackMessagesReportingRoute)} />
          <Route path="summary" component={ScrollToTop(SummaryReportingRoute)} />
          <Route path="receivers" component={ScrollToTop(ReceiversReportingRoute)} />
          <Route path="responseSummary" component={ScrollToTop(ResponseSummaryReportingRoute)} />
        </Route>

        <Route path="profile" component={ScrollToTop(ProfileRoute)} />

        <Route path="settings" component={ScrollToTop(SettingsRoute)} />

        <Route path="giveFeedback" component={ScrollToTop(GiveFeedbackRoute)} />

        <Route path="support" components={ScrollToTop(SupportViewRoute)} />

        <Route path="404" component={ScrollToTop(Error404Route)} />
      </Route>
    </Route>
  </Route>
);

class Routes extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  render() {
    if (!this.routes) {
      this.routes = createRoutes();
    }
    return (
      <Router history={this.props.history}>
        {this.routes}
      </Router>
    )
  }
}

export default Routes
