/* eslint no-console:0 */
import { fromJS } from 'immutable'

import createReducer from './createReducer'

import { SearchFilterActions } from 'actions/SearchFilterActions'

const EMPTY = fromJS({});
export const reducer = createReducer(EMPTY, {

  [SearchFilterActions.SET]: (state, { filter, activeFilter, feedbackListId }) => fromJS({
    active: true,
    activeFilter,
    filter,
    feedbackListId
  }),

  [SearchFilterActions.CLEAR]: () => EMPTY
})
