import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { AdminToolsView } from 'components/Feedback'
import { relativizeTarget } from 'components/Routing/RoutingUtils'
import { updateStatus, setCustomerInformationAsObjected } from 'actions/FeedbackActions'

class AdminToolsRoute extends Component {
  static contextTypes = {
    currentPath: PropTypes.string,
    router: PropTypes.any
  };

  navigateToFeedbackView = () => {
    this.context.router.push(relativizeTarget(this.context.currentPath, '..'))
  };

  navigateAfterAction = () => {
    this.context.router.push(this.props.routeContext.url.toString());
  };

  updateStatus = (fields) => {
    const {status, comment} = fields;
    if(status == 'usage_objected'){
      return this.props.actions.addToObjectedList(comment).then(this.navigateAfterAction)
    }
    return this.props.actions.updateStatus(status, comment).then(this.navigateAfterAction)
  };

  render() {
    const possibleStatuses = ['usage_limited', 'usage_objected', 'archived'];
    return (
      <section className="admin-tools-view">
        <AdminToolsView
          {...this.props}
          objectedError={this.objectedError}
          possibleStatuses={possibleStatuses}
          updateStatus={this.updateStatus}
          cancel={this.navigateToFeedbackView}
        />
      </section>
    )
  }
}

export default connect(
  (state, props) => ({
    feedbackId: parseInt(props.params.feedbackId),
    objectedError: state.getIn(['client', 'objectedError'])
  }),
  (dispatch, props) => ({
    actions: bindActionCreators({
      updateStatus: (status, comment) => updateStatus(props.feedbackId, status, comment),
      addToObjectedList: (comment) => setCustomerInformationAsObjected(props.feedbackId, comment)
    }, dispatch)
  })
)
(AdminToolsRoute)
