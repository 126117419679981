import React from 'react'
import PureComponent from 'react-pure-render/component'
import classNames from 'classnames'

import { TranslateProps } from 'containers/Text'
import AddErrorDisplay from 'components/Forms/common/AddErrorDisplay'
import { overrideOnBlur, overrideDragAndDropEvents } from 'components/Forms/common/FormUtils'

const textareaFactory = React.createFactory('textarea')
const BaseTextArea = props => textareaFactory(props)

class TextArea extends PureComponent {

  onDrop = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  render() {
    const {name, className, ...props} = this.props
    const classes = classNames(
      'field',
      'fill',
      className
    )

    return (
      <BaseTextArea
        className={classes}
        name={name}
        onDrop={this.onDrop}
        {...overrideDragAndDropEvents(overrideOnBlur(props))}
      />
    )
  }
}

export default TranslateProps(AddErrorDisplay(TextArea))
