import React, { PropTypes } from 'react';
import { Icon } from 'components/Icons'

import StatusPill from './StatusPill'
import FeedbackDate from './FeedbackDate'
import KeskoIcons from '../Icons/KeskoIcons';

const chevron = showChevron => showChevron ? (
  <Icon
    icon={KeskoIcons.arrowSRight}
    className="orange"
    size={Icon.sizes.unset}
  />
) : null;

const StatusAndDateHeader = (props) => (
  <div className="status-and-date" {...props}>
    <div>
      <StatusPill status={props.status} />
    </div>
    <div className="date-block silver">
      <FeedbackDate full={props.fullDate} date={props.date} />
      {chevron(props.showChevron)}
    </div>
  </div>
);

StatusAndDateHeader.defaultProps = {
  fullDate: false,
  showChevron: false
};

StatusAndDateHeader.propTypes = {
  fullDate: PropTypes.bool.isRequired,
  showChevron: PropTypes.bool.isRequired,
  date: FeedbackDate.propTypes.date,
  status: StatusPill.propTypes.status
};

export default StatusAndDateHeader
