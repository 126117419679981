import React, { Component, PropTypes } from 'react'
import { Text } from 'containers/Text'
import classNames from 'classnames'
import { trace } from 'utils/Log'

const identity = a => a;

const prependToKey = (toPrepend, key) => {
  if (Array.isArray(key)) {
    return [toPrepend,...key]
  }
  return `${toPrepend}.${key}`
}

const ErrorRow = (err, translatePrefix) => {
  const key = translatePrefix ? prependToKey(translatePrefix, err) : err
  return (
    <span key={key}>
      <Text k={key} />
      <br/>
    </span>
  )
}

class ErrorBlock extends Component {
  constructor(props) {
    super(props)
  }

  getDisplayValue(fields) {
    return Object.keys(fields).map(fieldName => {
      const field = fields[fieldName]
      trace('ErrorBlock', fieldName, field)
      return field.invalid && field.touched && fieldName + '.' + field.error
    }).filter(identity)
  }

  render() {
    const { fields, className, translatePrefix, otherErrors, submitError, ...props } = this.props
    const displayedErrors = this.getDisplayValue(fields)
    const allErrors = [...displayedErrors, ...otherErrors, submitError].filter(a => a)
    const classes = classNames(
      'error-block rounded px2 py2',
      className
    )
    return allErrors.length === 0 ? (
      <span />
    ) : (
      <div className={classes} {...props}>
        {displayedErrors.map(err => ErrorRow(err, translatePrefix))}
        {otherErrors.map(err => ErrorRow(err))}
        {submitError && ErrorRow(submitError)}
      </div>
    )
  }
}
ErrorBlock.defaultProps = {
  translatePrefix: 'validation',
  submitError: undefined,
  otherErrors: [],
  fields: {}
}
ErrorBlock.propTypes = {
  /**
   * The prefix to apply to each and every error in fields.
   * NOTE: Does not apply to otherErrors
   */
  translatePrefix: PropTypes.string,

  /**
   * The translations keys of other visible errors
   */
  otherErrors: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])),

  submitError: PropTypes.string,

  /**
   * The fields as provided by redux-form
   */
  fields: PropTypes.any.isRequired
}

export default ErrorBlock
